import PropTypes from 'prop-types';
import * as React from 'react';
import { unwrappableString } from 'event_mgmt/shared/utils/FormattingUtils.jsx';

function WrappableName({
  nameable,
  className = '',
  style = {},
  onClick = () => {},
}) {
  return (
    <span
      role="presentation"
      className={className}
      style={style}
      onClick={onClick}
    >
      {unwrappableString(nameable.first_name)}{' '}
      {unwrappableString(nameable.last_name)}
    </span>
  );
}

WrappableName.propTypes = {
  className: PropTypes.string,
  nameable: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default WrappableName;
