import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    width: 11,
    height: 13,
  },
};

function LockIcon({ style = {}, color = 'var(--color-old-gray)', ...props }) {
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...merge(props, { style: merge(styles.root, style) })}
      viewBox="0 0 11 13"
    >
      <title>Locked</title>
      <g
        id="Memberships"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Memberships-Page---Invite-Only-(Client)"
          transform="translate(-753.000000, -297.000000)"
          fill={color}
        >
          <g id="Group" transform="translate(711.000000, 159.000000)">
            <path
              d="M45.5714286,144.142857 L50.1428571,144.142857 L50.1428571,142.428571 C50.1428571,141.797616 49.9196451,141.258931 49.4732143,140.8125 C49.0267835,140.366069 48.4880984,140.142857 47.8571429,140.142857 C47.2261873,140.142857 46.6875022,140.366069 46.2410714,140.8125 C45.7946406,141.258931 45.5714286,141.797616 45.5714286,142.428571 L45.5714286,144.142857 Z M53,145 L53,150.142857 C53,150.380954 52.9166675,150.583333 52.75,150.75 C52.5833325,150.916667 52.3809536,151 52.1428571,151 L43.5714286,151 C43.3333321,151 43.1309532,150.916667 42.9642857,150.75 C42.7976182,150.583333 42.7142857,150.380954 42.7142857,150.142857 L42.7142857,145 C42.7142857,144.761904 42.7976182,144.559525 42.9642857,144.392857 C43.1309532,144.22619 43.3333321,144.142857 43.5714286,144.142857 L43.8571429,144.142857 L43.8571429,142.428571 C43.8571429,141.333328 44.2499961,140.392861 45.0357143,139.607143 C45.8214325,138.821425 46.7618993,138.428571 47.8571429,138.428571 C48.9523864,138.428571 49.8928532,138.821425 50.6785714,139.607143 C51.4642896,140.392861 51.8571429,141.333328 51.8571429,142.428571 L51.8571429,144.142857 L52.1428571,144.142857 C52.3809536,144.142857 52.5833325,144.22619 52.75,144.392857 C52.9166675,144.559525 53,144.761904 53,145 Z"
              id="Lock_Icon"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default LockIcon;
