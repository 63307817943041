import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Grid,
  InfiniteScroller,
  Typography,
  Spinner,
} from '@upperhand/playmaker';
import { Box } from '@mui/material';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import BrowseEventsBookDrawer from 'containers/browseEventsBookDrawer/BrowseEventsBookDrawer.jsx';

import { TeamTypeDataStore, StaffDataStore } from 'dataStores';

import BrowseTeamsActions from './Actions';
import BrowseTeamsStore from './Store';

import TeamsCompact from './components/TeamsCompact.jsx';
import TeamsRoomy from './components/TeamsRoomy.jsx';
import TeamCard from './components/TeamCard.jsx';

const styles = {
  root: {
    backgroundColor: uhColors.backgroundGray,
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    padding: '16px',
  },
};

const getRecordLabelAndValue = recordList =>
  recordList
    .map(r => {
      // Our record types are inconsistent in how "name" is accessed.
      const recordName = typeof r.name === 'function' ? r.name() : r.name;

      return {
        label: recordName || '',
        value: r.id,
      };
    })
    .toArray();

const BrowseTeams = React.memo(props => {
  const {
    intl,
    browseTeamsStore = {},
    teamTypeDataStore = {},
    staffDataStore = {},
  } = props;

  const {
    browseTeams,
    teamTypeFilter,
    teamTypeLoading,
    isFiltersDrawerOpen,
    isMounted,
    isLoading,
    staffFilter,
    staffLoading,
    totalCount,
  } = browseTeamsStore;

  React.useEffect(() => {
    if (!isMounted) {
      BrowseTeamsActions.mounted();
    }
  }, [isMounted]);

  const BrowseTeamsView = smallScreen(880) ? TeamsCompact : TeamsRoomy;
  const shouldDisplayLoader = teamTypeLoading || staffLoading;

  if (shouldDisplayLoader) {
    return (
      <Box sx={{ ...styles.root, ...styles.loading }}>
        <Spinner />
      </Box>
    );
  }

  const { teamTypes } = teamTypeDataStore;
  const { staff } = staffDataStore;
  const teamItems = getRecordLabelAndValue(teamTypes);
  const staffItems = getRecordLabelAndValue(staff);

  return (
    <Box className="browse-teams" sx={styles.root}>
      <BrowseTeamsView
        teamItems={teamItems}
        teamTypeFilter={teamTypeFilter}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        staffItems={staffItems}
        staffFilter={staffFilter}
      >
        {!browseTeams.size && !isLoading ? (
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {t('.no_results', intl, __filenamespace)}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InfiniteScroller
              hasMore={browseTeams.size < totalCount}
              onScroll={BrowseTeamsActions.listTeams.defer}
              isLoading={isLoading}
              showLoader
            >
              {browseTeams.map(browseTeam => (
                <TeamCard
                  intl={intl}
                  key={browseTeam.id}
                  browseTeam={browseTeam}
                />
              ))}
            </InfiniteScroller>
          </Grid>
        )}
      </BrowseTeamsView>
      <BrowseEventsBookDrawer />
    </Box>
  );
});

BrowseTeams.propTypes = {
  browseTeamsStore: PropTypes.object,
  teamTypeDataStore: PropTypes.object,
  staffDataStore: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

const MemorizedBrowseTeams = React.memo(BrowseTeams);

export default compose(
  injectIntl,
  altContainer({
    stores: {
      browseTeamsStore: BrowseTeamsStore,
      teamTypeDataStore: TeamTypeDataStore,
      staffDataStore: StaffDataStore,
    },
  })
)(MemorizedBrowseTeams);
