export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'Sogility',
  siteURL: 'https://sogility.net/',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/sogility_logo.png',
    width: 380,
  },
  login: {
    desktopSignInColor: '#2FBE2D',
    mobileSignInColor: '#2FBE2D',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#2FBE2D',
    accent1Color: '#428399',

    primary: {
      main: '#2FBE2D',
    },
    secondary: {
      main: '#428399',
    },
  },
  sideNav: {
    selectedColor: '#2FBE2D',
  },
  icons: {
    cart: '#2FBE2D',
  },
  statusBar: {
    backgroundColor: '#2FBE2D',
  },
};
