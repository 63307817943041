import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import VerticalDivider from 'shared/components/VerticalDivider.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils';

function EventTitle({ event, touchable }) {
  if (!event.get('title')) {
    return <FormattedMessage id={messageId('.event_title', __filenamespace)} />;
  }
  if (touchable) {
    return <Link to={event.url()}>{event.get('title')}</Link>;
  }
  return <span>{event.get('title')}</span>;
}

function EventTitleAndDate({
  event,
  style = {},
  titleStyle = {},
  secondaryTextStyle = {},
  touchable = false,
}) {
  const styles = {
    root: {
      lineHeight: '1.5em',
      position: 'relative',
      flex: '1',
      flexWrap: 'wrap',
      paddingLeft: 16,
      width: '100%',
    },

    eventTypeBar: {
      width: 4,
      backgroundColor: event.get('event_type').color,
      border: 2,
      borderRadius: 2,
      position: 'absolute',
      left: 0,
      top: 3,
      bottom: 3,
    },

    primaryHeaderText: merge(
      {
        fontWeight: 500,
        fontSize: '1.2em',
        width: '100%',
        flex: '1',
        flexWrap: 'wrap',
      },
      titleStyle
    ),

    secondaryHeaderText: merge(
      {
        fontWeight: 400,
        color: grey[600],
        width: '100%',
      },
      secondaryTextStyle
    ),

    verticalDivider: {
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
      color: grey[400],
    },
  };

  return (
    <div style={merge(styles.root, style)}>
      <span style={styles.eventTypeBar}>&ensp;</span>
      <div
        style={
          event.get('title') === ''
            ? {
                color: grey[600],
                fontWeight: 500,
                fontSize: '1.2em',
                width: '100%',
              }
            : styles.primaryHeaderText
        }
      >
        <EventTitle event={event} touchable={touchable} />
      </div>
      <div style={styles.secondaryHeaderText}>
        <span>
          {event.get('event_type').name === ''
            ? 'Event Type'
            : event.get('event_type').name}
        </span>
        <VerticalDivider />
        <span>{event.scheduleText()}</span>
      </div>
    </div>
  );
}

EventTitleAndDate.propTypes = {
  event: PropTypes.instanceOf(CustomerEvent).isRequired,
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  secondaryTextStyle: PropTypes.object,
  touchable: PropTypes.bool,
};

export default EventTitleAndDate;
