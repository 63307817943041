import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';

const styles = {
  container: {
    backgroundColor: 'var(--color-old-gray)',
    color: 'var(--color-white)',
    padding: '15px 30px',
  },
  middleContainer: {
    padding: '8px 0',
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginRight: 8,
  },
  closeBtn: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: 'var(--color-ui-gray)',
  },
};

function Header({ event = new CustomerEvent(), onClose = () => null, intl }) {
  return (
    <div style={styles.container}>
      <Typography variant="drawerTitle">
        {t('.title', intl, __filenamespace)}
      </Typography>
      <div style={styles.middleContainer}>
        <Typography variant="h6">{event.get('title')}</Typography>
      </div>
      <FlexBoxCenter>
        <div
          style={{
            ...styles.badge,
            backgroundColor: event.getIn(['event_type', 'color']),
          }}
        />
        <Typography variant="drawerTitle">
          {event.getIn(['event_type', 'name'])}
        </Typography>
      </FlexBoxCenter>
      <IconButton sx={styles.closeBtn} onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );
}

Header.propTypes = {
  event: PropTypes.instanceOf(CustomerEvent),
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default injectIntl(Header);
