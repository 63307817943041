import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { uhColors } from 'shared/styles/uhStyles.jsx';

function CreditPass({
  color = uhColors.defaultIcon,
  secondaryColor = uhColors.darkGrey,
  ...props
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 32 24" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-153.000000, -608.000000)">
          <g transform="translate(153.000000, 608.000000)">
            <g id="Group">
              <path
                d="M0,8.66666667 L0,0 L32,0 L32,8.66666667 C30.1590508,8.66666667 28.6666667,10.1590508 28.6666667,12 C28.6666667,13.8409492 30.1590508,15.3333333 32,15.3333333 L32,24 L0,24 L0,15.3333333 C1.84094917,15.3333333 3.33333333,13.8409492 3.33333333,12 C3.33333333,10.1590508 1.84094917,8.66666667 0,8.66666667 Z"
                id="Combined-Shape"
                fill={color}
              />
              <path
                d="M13.8204389,18.6666667 L13,18.6666667 C12.4477153,18.6666667 12,18.2189514 12,17.6666667 L12,6.33333333 C12,5.78104858 12.4477153,5.33333333 13,5.33333333 L16.6833213,5.33333333 C19.4341197,5.33333333 21.9411765,6.09009009 21.9411765,9.6036036 C21.9411765,13.1891892 19.2251983,13.9279279 16.6485011,13.9279279 L14.8204389,13.9279279 L14.8204389,17.6666667 C14.8204389,18.2189514 14.3727236,18.6666667 13.8204389,18.6666667 Z M14.8039216,7.68627451 L14.8039216,11.6078431 L16.7279216,11.6078431 C18.4785882,11.6078431 19.1372549,10.9062781 19.1372549,9.62906998 C19.1372549,8.38783954 18.4959216,7.68627451 16.7279216,7.68627451 L14.8039216,7.68627451 Z"
                id="P"
                fill={secondaryColor}
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default CreditPass;
