import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer, Menu } from '@upperhand/playmaker';

import ListStep from 'containers/clientProfile/components/AccountCredits/steps/ListStep.jsx';
import CreationStep from 'containers/clientProfile/components/AccountCredits/steps/CreationStep.jsx';
import DetailsStep from 'containers/clientProfile/components/AccountCredits/steps/DetailsStep.jsx';
import ClearStep from 'containers/clientProfile/components/AccountCredits/steps/ClearStep.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import AccountCreditsActions from 'containers/clientProfile/components/AccountCredits/Actions';
import AccountCreditStore, {
  STEPS,
} from 'containers/clientProfile/components/AccountCredits/Store';

import './styles.scss';

function AccountCreditDrawer({
  intl = {},
  accountCreditStore = { open: false, step: STEPS.list, creditsAmount: 0 },
}) {
  const [anchor, setAnchor] = useState(null);
  const { open, step, credit, creditsAmount } = accountCreditStore;

  if (!open) {
    return null;
  }

  const getDrawerTitle = () => {
    if (step === STEPS.create || step === STEPS.clear) {
      return t('.create_title', intl, __filenamespace);
    }

    if (step === STEPS.details) {
      return t(`.${credit.get('credit_type')}`, intl, __filenamespace);
    }

    return t('.title', intl, __filenamespace);
  };

  const handleClose = () => {
    if (step === STEPS.create) {
      AccountCreditsActions.changeStep(STEPS.list);
      return;
    }

    if (step === STEPS.details) {
      AccountCreditsActions.toggleDetails();
      return;
    }

    if (step === STEPS.clear) {
      AccountCreditsActions.toggleClear();
      return;
    }

    AccountCreditsActions.close();
  };

  return (
    <Drawer
      open={open}
      classes={{ root: 'account-credits', content: 'account-credits__content' }}
      title={getDrawerTitle()}
      actions={
        step === STEPS.list && currentUser().isStaff()
          ? [
              {
                icon: 'threeDots',
                onClick: e => setAnchor(e.currentTarget),
              },
            ]
          : undefined
      }
      content={
        <>
          {step === STEPS.list && <ListStep />}
          {step === STEPS.create && <CreationStep />}
          {step === STEPS.details && <DetailsStep />}
          {step === STEPS.clear && <ClearStep />}
          <Menu
            anchor={anchor}
            onClose={() => setAnchor(null)}
            items={[
              {
                visible: true,
                label: t('.menu_create', intl, __filenamespace),
                onClick: () => AccountCreditsActions.changeStep(STEPS.create),
              },
              {
                visible: creditsAmount > 0,
                label: t('.menu_clear', intl, __filenamespace),
                onClick: () => AccountCreditsActions.toggleClear(),
              },
            ].filter(i => i.visible)}
          />
        </>
      }
      onClose={handleClose}
    />
  );
}

AccountCreditDrawer.propTypes = {
  intl: PropTypes.object,
  accountCreditStore: PropTypes.object,
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      accountCreditStore: AccountCreditStore,
    },
  })
)(AccountCreditDrawer);
