import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Avatar, Grid, Typography } from '@upperhand/playmaker';
import ReactTooltip from 'react-tooltip';

import { compose } from 'shared/utils/SharedUtils.js';
import { t, messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { ClientDataStore, CreditCountDataStore } from 'dataStores';

import AccountProfileButtons from './AccountProfileButtons.jsx';

const styles = {
  root: {
    border: `1px solid ${uhColors.pmIconGray}`,
    borderRadius: '2px',
    padding: 16,
    marginBottom: 8,
  },
  note: {
    padding: '8px 0',
  },
};

const getCustomerFeatures = () => {
  const { customer } = window;
  const customerHasCreditPasses = customer.features.credit_passes;
  const customerHasMemberships = customer.features.memberships;

  return {
    hasMemberships: customerHasMemberships,
    hasPasses: customerHasCreditPasses,
  };
};

function AccountProfile({
  clientDataStore = {},
  creditCountStore = {},
  clientId,
  eventId,
  intl,
  scheduleSession,
}) {
  const user = clientDataStore.clients.get(clientId);
  const fullName = `${user.first_name} ${user.last_name}`;

  const creditsRemaining = creditCountStore.eventClients.getIn(
    [eventId, clientId, 'credits_remaining'],
    0
  );
  const unlimited = creditCountStore.eventClients.getIn(
    [eventId, clientId, 'unlimited'],
    false
  );

  const clickableFeatures =
    !creditsRemaining && !unlimited ? getCustomerFeatures() : {};

  return (
    <div style={styles.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={2}>
            <UserAvatar user={user} />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1">{fullName}</Typography>
          </Grid>
          <Grid item xs={1}>
            <div data-tip data-for={`credit-count-${clientId}`}>
              <Avatar color={uhColors.primaryMain} size={24}>
                {unlimited ? '∞' : creditsRemaining}
              </Avatar>
            </div>
            <ReactTooltip
              id={`credit-count-${clientId}`}
              className="uh-tooltip"
              place="bottom"
            >
              <FormattedMessage
                id={messageId(
                  unlimited ? '.unlimitedCtTooltip' : '.creditCtTooltip',
                  __filenamespace
                )}
                values={{ creditsRemaining }}
              />
            </ReactTooltip>
          </Grid>
        </Grid>

        {!creditsRemaining && !unlimited && (
          <Grid item xs={12}>
            <div style={styles.note}>
              <Typography variant="subtitle1">
                {t('.needAddtlCredits', intl, __filenamespace)}
              </Typography>
            </div>
          </Grid>
        )}

        <AccountProfileButtons
          clickableFeatures={clickableFeatures}
          hasCreditsRemaining={unlimited || !!creditsRemaining}
          scheduleSession={() => scheduleSession(clientId)}
        />
      </Grid>
    </div>
  );
}

AccountProfile.propTypes = {
  clientDataStore: PropTypes.shape({
    clients: PropTypes.object,
    clientId: PropTypes.number,
  }),
  clientId: PropTypes.number.isRequired,
  creditCountStore: PropTypes.shape({
    records: PropTypes.object,
  }),
  eventId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  scheduleSession: PropTypes.func.isRequired,
};

export default compose(
  altContainer({
    stores: {
      creditCountStore: CreditCountDataStore,
      clientDataStore: ClientDataStore,
    },
  }),
  injectIntl
)(AccountProfile);
