import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ContentModal, Spinner, Typography } from '@upperhand/playmaker';

import Button from '@mui/material/Button';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import BalancesList from 'containers/reports/payAllBalances/components/BalancesList.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import { ClientDataStore } from 'dataStores';

import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions';
import PayAllBalancesActions from './Actions';
import PayAllBalancesStore from './Store';

import './styles.scss';

function PayAllBalances({ intl, payAllBalancesStore, clientDataStore }) {
  const { openModal, loadings, clientId, statistics, balancesIds, pagination } =
    payAllBalancesStore;
  const { clients } = clientDataStore;
  const initialLoading = loadings.get('initial');
  const balancesLoading = loadings.get('balances');
  const client = clients.get(clientId, null);
  const outstandingBalance =
    Math.abs(statistics.get('outstanding_balance')) || 0;

  if (!openModal) return null;

  return (
    <ContentModal
      classes={{
        root: 'pay-all-balances__root',
        title: 'pay-all-balances__title',
        closeButton: 'pay-all-balances__close-btn',
      }}
      open={openModal}
      onAbort={PayAllBalancesActions.close}
      showActions={false}
      title={t('.pay_all_balance', intl, __filenamespace)}
    >
      {initialLoading && (
        <div className="pay-all-balances__spinner">
          <Spinner />
        </div>
      )}
      {!initialLoading && client && (
        <>
          <div className="pay-all-balances__client-info">
            <UserAvatar user={client} />
            <Typography>{client.name()}</Typography>
            <Typography variant="subtitle1" align="right">
              <FormattedCurrency value={outstandingBalance} fromCents />
            </Typography>
          </div>
          <BalancesList
            isLoading={balancesLoading}
            balancesIds={balancesIds}
            pagination={pagination.get('balances')}
            onScroll={PayAllBalancesActions.balanceList.defer}
          />
        </>
      )}
      <Button
        fullWidth
        disabled={initialLoading}
        className="pay-all-balances__continue-btn"
        variant="contained"
        color="secondary"
        onClick={() => {
          QuickpayModalActions.togglePayAllQuickpayModal({
            mode: 'pay_all_balances',
            balanceRemaining: outstandingBalance,
            clientId,
          });
          PayAllBalancesActions.close.defer();
        }}
      >
        {t('.continue', intl, __filenamespace)}
      </Button>
    </ContentModal>
  );
}

PayAllBalances.propTypes = {
  intl: PropTypes.object.isRequired,
  payAllBalancesStore: PropTypes.object.isRequired,
  clientDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      payAllBalancesStore: PayAllBalancesStore,
      clientDataStore: ClientDataStore,
    },
  })
)(PayAllBalances);
