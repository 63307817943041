import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Set } from 'immutable';
import {
  Typography,
  Grid,
  TextField,
  Dropdown,
  DateTimeField,
  MultipleSelect,
} from '@upperhand/playmaker';

import ClearAction from 'containers/contacts/contactsFilters/components/ClearAction.jsx';
import FiltersBlock from 'containers/contacts/contactsFilters/steps/filterBlocks/FiltersBlock.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import { t, messageId } from 'shared/utils/LocaleUtils';

import {
  DATE_OPERATORS,
  GENDERS,
  MONTHS,
} from 'containers/contacts/contactsFilters/Store';

function Demographics({ filter = new ContactFilter(), onChange = () => null }) {
  const intl = useIntl();
  const mob = filter.get('month_of_birth', Set());
  const dateOfBirth = filter.getIn(['date_of_birth', 'start_date']);
  const dateOfBirthEnd = filter.getIn(['date_of_birth', 'end_date']);
  const dateOfBirthOperator = filter.getIn(['date_of_birth', 'operator']);
  const operatorIsNotBetween = dateOfBirthOperator !== DATE_OPERATORS.BETWEEN;
  const minAge = filter.get('min_age', null);
  const maxAge = filter.get('max_age', null);
  const gender = filter.get('gender', null);
  const zipCode = filter.get('zip_code', null);

  const operators = Object.values(DATE_OPERATORS).map(operator => ({
    label: intl.formatMessage({
      id: messageId(`.date_operators.${operator}`, __filenamespace),
    }),
    value: operator,
  }));

  const genders = Object.values(GENDERS).map(g => ({
    label: intl.formatMessage({
      id: messageId(`.genders.${g}`, __filenamespace),
    }),
    value: g,
  }));

  const months = Object.keys(MONTHS).map((month, index) => ({
    label: intl.formatMessage({
      id: messageId(`.months.${month.toLowerCase()}`, __filenamespace),
    }),
    value: index + 1,
  }));

  const handleClear = (name, value) => {
    onChange([
      {
        target: { name, value },
      },
    ]);
  };

  return (
    <FiltersBlock title="Demographics">
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.age', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Grid item xs={5}>
            <TextField
              fullWidth
              name="min_age"
              value={minAge}
              placeholder={t('.min_age_placeholder', intl, __filenamespace)}
              classes={{ root: 'filters-block__text-input' }}
              onChange={onChange}
            />
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={2}
            className="field-divider"
          >
            &nbsp; — &nbsp;
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              name="max_age"
              value={maxAge}
              placeholder={t('.max_age_placeholder', intl, __filenamespace)}
              classes={{ root: 'filters-block__text-input' }}
              onChange={onChange}
            />
          </Grid>
          {(minAge || maxAge) && (
            <ClearAction
              onClear={() => {
                handleClear('max_age', null);
                handleClear('min_age', null);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.dob', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Grid
            item
            container
            xs={12}
            className="filter-item"
            wrap={operatorIsNotBetween ? 'nowrap' : 'wrap'}
          >
            <Grid item xs={operatorIsNotBetween ? 6 : 12}>
              <Dropdown
                fullWidth
                placeholder={t('.dob_placeholder', intl, __filenamespace)}
                name="date_of_birth.operator"
                value={dateOfBirthOperator}
                classes={{
                  root: 'filters-block__text-input',
                  menuLabel: 'filters-block__dropdown-item',
                  selectedItem: 'filters-block__dropdown-selected-item',
                }}
                items={operators}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={operatorIsNotBetween ? 6 : 12}>
              <DateTimeField
                fullWidth
                errorText={filter.errors.getErrors(
                  ['date_of_birth', 'start_date'],
                  intl
                )}
                value={dateOfBirth ? moment(dateOfBirth) : undefined}
                onChange={value =>
                  onChange([
                    {
                      target: { name: 'date_of_birth.start_date', value },
                    },
                  ])
                }
              />
            </Grid>
            {!operatorIsNotBetween && (
              <Grid item xs={12}>
                <DateTimeField
                  fullWidth
                  errorText={filter.errors.getErrors(
                    ['date_of_birth', 'end_date'],
                    intl
                  )}
                  value={dateOfBirthEnd ? moment(dateOfBirthEnd) : undefined}
                  onChange={value =>
                    onChange([
                      {
                        target: { name: 'date_of_birth.end_date', value },
                      },
                    ])
                  }
                />
              </Grid>
            )}
          </Grid>
          {(dateOfBirthOperator || dateOfBirth || dateOfBirthEnd) && (
            <ClearAction
              onClear={() => {
                handleClear('date_of_birth.end_date', undefined);
                handleClear('date_of_birth.start_date', undefined);
                handleClear('date_of_birth.operator', undefined);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.mob', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Grid item xs={12}>
            <MultipleSelect
              fullWidth
              outline
              rounded={false}
              name="birthday month"
              classes={{
                root: 'filters-block__multiselect',
                menuItem: 'filters-block__multiselect-item',
                displayText: clsx(
                  'filters-block__multiselect-selected-item',
                  mob.size === 0 && 'filters-block__multiselect-empty'
                ),
              }}
              selectedItems={mob.toJS()}
              items={months}
              onChange={value =>
                onChange([
                  {
                    target: { name: 'month_of_birth', value: Set(value) },
                  },
                ])
              }
            />
          </Grid>
          {mob.size > 0 && (
            <ClearAction
              onClear={() => {
                handleClear('month_of_birth', Set());
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.gender', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Dropdown
            fullWidth
            placeholder={t('.gender_placeholder', intl, __filenamespace)}
            name="gender"
            value={gender}
            classes={{
              root: 'filters-block__text-input',
              menuLabel: 'filters-block__dropdown-item',
              selectedItem: 'filters-block__dropdown-selected-item',
            }}
            items={genders}
            onChange={onChange}
          />
          {gender && (
            <ClearAction
              onClear={() => {
                handleClear('gender', null);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.label', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <TextField
            fullWidth
            name="zip_code"
            value={zipCode}
            placeholder={t('.placeholder', intl, __filenamespace)}
            classes={{ root: 'filters-block__text-input' }}
            onChange={onChange}
          />
          {zipCode && (
            <ClearAction
              onClear={() => {
                handleClear('zip_code', null);
              }}
            />
          )}
        </Grid>
      </Grid>
    </FiltersBlock>
  );
}

Demographics.propTypes = {
  filter: PropTypes.instanceOf(ContactFilter),
  onChange: PropTypes.func,
};

export default memo(Demographics);
