import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { RetailVendorsDataStore } from 'dataStores';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import Content from './components/Content.jsx';

import RetailVendorsActions from './Actions';
import RetailVendorsStore from './Store';

function RetailVendors({
  intl,
  retailVendorsStore = {},
  retailVendorsDataStore = {},
  onChange = () => {},
  value = null,
}) {
  React.useEffect(() => {
    RetailVendorsActions.mounted();
  }, []);

  const { isModalOpen, modalData } = retailVendorsStore;
  const { records: vendors } = retailVendorsDataStore;

  return (
    <Content
      vendors={vendors}
      value={value}
      onChange={onChange}
      intl={intl}
      isModalOpen={isModalOpen}
      modalData={modalData}
      itemName={t('.item_name', intl, __filenamespace)}
      floatingLabelText={t('.floating_label', intl, __filenamespace)}
      canEdit={!currentCustomer().restrict_editing || hasAdminPermission()}
    />
  );
}

RetailVendors.propTypes = {
  intl: PropTypes.object.isRequired,
  retailVendorsStore: PropTypes.object,
  retailVendorsDataStore: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default compose(
  React.memo,
  altContainer({
    stores: {
      retailVendorsStore: RetailVendorsStore,
      retailVendorsDataStore: RetailVendorsDataStore,
    },
  }),
  injectIntl
)(RetailVendors);
