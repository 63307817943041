import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils';

function MobileCouponCard({ couponId = undefined, coupon = null, intl }) {
  // if no id, we need to render empty div for now... want to make this safe.
  // eventualy if no id and edit mode = true then "Create" mode
  if (!coupon) {
    return <div>{couponId}</div>;
  }

  return (
    <Card
      classes={{
        root: 'coupons__row-mobile',
        content: 'coupons__row-mobile-content',
      }}
      contentDirection="row"
      onClick={e => {
        redirectTo({
          path: customerScopedRoute(`/coupons/${coupon.get('id')}`),
        });
        e.preventDefault();
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        alignContent="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                align="left"
                className="coupons__name"
              >
                {coupon.get('name')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className="coupons__row-item_muted coupons__code"
              >
                {coupon.get('code')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid
            item
            container
            spacing={1}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" className="coupons__row-type">
                <FormattedMessage
                  id={messageId('.value', __filenamespace)}
                  values={{
                    value: coupon.discount.isPercent() ? (
                      <FormattedNumber
                        value={coupon.get('value') / 100}
                        // eslint-disable-next-line react/style-prop-object
                        style="percent"
                      />
                    ) : (
                      <FormattedCurrency
                        value={coupon.get('value')}
                        fromCents
                      />
                    ),
                  }}
                />
                <ChevronRightIcon />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="right"
                className="coupons__row-item_muted"
              >
                {t(`.${coupon.get('coupon_type')}`, intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

MobileCouponCard.propTypes = {
  couponId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  coupon: PropTypes.object,
};

export default MobileCouponCard;
