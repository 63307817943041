import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Confirmation, TextField } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import { SessionDataStore } from 'dataStores';
import { messageId, t } from 'shared/utils/LocaleUtils';

import EditDateTimeActions from '../actions';

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
    marginLeft: 10,
  },
  inputContainer: {
    marginTop: 15,
  },
};

function ConfirmChangesModal({
  isOpen,
  intl,
  note,
  sessionId,
  sessionDataStore = {},
}) {
  const { sessions } = sessionDataStore;
  const session = sessions.get(sessionId);
  if (!session) return null;
  const clientsCount = session.client_ids.count();

  return (
    <Confirmation
      cancelButtonLabel={t('.btn.cancel', intl, __filenamespace)}
      confirmButtonLabel={t('.btn.confirm', intl, __filenamespace)}
      hasCheckbox={false}
      onCancel={EditDateTimeActions.cancelChanges}
      onConfirm={EditDateTimeActions.saveChanges}
      open={isOpen}
      title={t('.title', intl, __filenamespace)}
    >
      <FormattedMessage
        id={messageId('.text', __filenamespace)}
        values={{ clientsCount }}
      />
      <div style={styles.inputContainer}>
        <TextField
          fullWidth
          label={t('.label.note', intl, __filenamespace)}
          onChange={(_, value) => EditDateTimeActions.update('note', value)}
          value={note}
        />
      </div>
    </Confirmation>
  );
}

ConfirmChangesModal.propTypes = {
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  note: PropTypes.string.isRequired,
  sessionDataStore: PropTypes.object,
  sessionId: PropTypes.string.isRequired,
};

export default compose(
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
    },
  })
)(ConfirmChangesModal);
