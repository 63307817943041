import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Spinner } from '@upperhand/playmaker';

import EventPrice from 'shared/components/EventPrice.jsx';
import ProductInformation from 'shared/components/ProductInformation.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import WhenSignedIn from 'shared/components/WhenSignedIn.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhContrast } from 'shared/styles/uhStyles.jsx';

import Content from './Content.jsx';

const styles = {
  header: merge(uhContrast.lightOnDark, {
    fontSize: 15,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  content: {
    padding: '2rem',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
};

function PurchaseDrawer({
  athletes,
  availableTimesStore,
  cart,
  event,
  isLoading,
  membershipDiscountStore,
  onRequestClose,
  open,
  pdRegistrationPackageStore,
  sessions,
  sessionsShowMore,
  sessionsLoadingMore,
  onSessionsLoadMore,
  sspStore,
  staff,
  width = STANDARD_DRAWER_WIDTH,
}) {
  return (
    <Drawer
      disableEnforceFocus
      width={width}
      anchor="right"
      open={open}
      PaperProps={{ sx: { width } }}
    >
      {isLoading ? (
        <div style={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <>
          <div style={styles.header}>
            <FlexBoxJustify style={{ height: 56, alignItems: 'center' }}>
              <FormattedMessage id={messageId('.purchase', __filenamespace)} />
              <IconButton onClick={onRequestClose} style={{ marginRight: -18 }}>
                <ClearIcon sx={{ color: '#5B6A72' }} />
              </IconButton>
            </FlexBoxJustify>
            <ProductInformation
              primaryText={event.customerEvent.title}
              secondaryText={event.customerEvent.scheduleText()}
              color={event.customerEvent.typeColor()}
              typeText={event.customerEvent.typeName()}
              price={
                pdRegistrationPackageStore.automationsLoaded ? (
                  <EventPrice
                    event={event.customerEvent}
                    automations={List([
                      pdRegistrationPackageStore.packagePricingDescription,
                      pdRegistrationPackageStore.paymentPlanDescription,
                    ])}
                  />
                ) : (
                  <span>&ndash;</span>
                )
              }
            />
          </div>
          <div style={styles.content}>
            <WhenSignedIn notSignedIn={<SignInPrompt redirectHash="%23pdo" />}>
              <Content
                athletes={athletes}
                availableTimesStore={availableTimesStore}
                cart={cart}
                event={event}
                membershipDiscountStore={membershipDiscountStore}
                pdRegistrationPackageStore={pdRegistrationPackageStore}
                sessions={sessions}
                sspStore={sspStore}
                staff={staff}
                sessionsShowMore={sessionsShowMore}
                sessionsLoadingMore={sessionsLoadingMore}
                onSessionsLoadMore={onSessionsLoadMore}
              />
            </WhenSignedIn>
          </div>
        </>
      )}
    </Drawer>
  );
}

export default PurchaseDrawer;
