import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Tabs } from '@upperhand/playmaker';

import Coupon from 'records/Coupon';
import { COUPON_DISCOUNTABLE } from 'records/DiscountableItem';
import { t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import DiscountableCard from './DiscountableCard.jsx';
import DiscountableItems from './DiscountableItems.jsx';

const DiscountableTabs = injectIntl(
  ({
    createMode = false,
    actions,
    coupon,
    createModal,
    searchableCreditPasses,
    searchableEvents,
    searchableMemberships,
    searchableVariants,
    selectedTab,
    searchableTeams,
    intl,
  }) => {
    const tabs = [
      {
        label: t(`.${COUPON_DISCOUNTABLE.Event}`, intl, __filenamespace),
        content: (
          <DiscountableCard
            actions={actions}
            coupon={coupon}
            searchableDataSource={searchableEvents}
            type={COUPON_DISCOUNTABLE.Event}
            intl={intl}
          />
        ),
      },
      currentCustomer().features.memberships && {
        label: t(`.${COUPON_DISCOUNTABLE.Membership}`, intl, __filenamespace),
        content: (
          <DiscountableCard
            actions={actions}
            coupon={coupon}
            searchableDataSource={searchableMemberships}
            type={COUPON_DISCOUNTABLE.Membership}
            intl={intl}
          />
        ),
      },
      coupon.get('coupon_type') === Coupon.ONE_TIME &&
        currentCustomer().features.credit_passes && {
          label: t(`.${COUPON_DISCOUNTABLE.CreditPass}`, intl, __filenamespace),
          content: (
            <DiscountableCard
              actions={actions}
              coupon={coupon}
              searchableDataSource={searchableCreditPasses}
              type={COUPON_DISCOUNTABLE.CreditPass}
              intl={intl}
            />
          ),
        },
      coupon.get('coupon_type') === Coupon.ONE_TIME &&
        currentCustomer().features.retail && {
          label: t(`.${COUPON_DISCOUNTABLE.Variant}`, intl, __filenamespace),
          content: (
            <DiscountableCard
              actions={actions}
              coupon={coupon}
              searchableDataSource={searchableVariants}
              type={COUPON_DISCOUNTABLE.Variant}
              intl={intl}
            />
          ),
        },
      currentCustomer().features.teams && {
        label: t(`.${COUPON_DISCOUNTABLE.Team}`, intl, __filenamespace),
        content: (
          <DiscountableCard
            actions={actions}
            coupon={coupon}
            searchableDataSource={searchableTeams}
            type={COUPON_DISCOUNTABLE.Team}
            intl={intl}
          />
        ),
      },
    ].filter(tab => !!tab);
    return (
      <Grid container spacing={1} xs={12} className="coupon__discountable-tabs">
        <Grid item xs={12} md={8}>
          <Tabs
            labelVariant="fieldLabel"
            onChange={actions.tabChanged}
            tabItems={tabs}
            value={selectedTab}
          />
          {(createMode || createModal) && (
            <DiscountableItems
              actions={actions}
              coupon={coupon}
              removableItems
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

DiscountableTabs.propTypes = {
  createMode: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  coupon: PropTypes.object.isRequired,
  searchableCreditPasses: PropTypes.array.isRequired,
  searchableEvents: PropTypes.array.isRequired,
  searchableMemberships: PropTypes.array.isRequired,
  searchableVariants: PropTypes.array.isRequired,
  selectedTab: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
};

export default DiscountableTabs;
