import * as React from 'react';
import PropTypes from 'prop-types';
import Coupon from 'records/Coupon';

import { Button, Grid, TextField, Typography } from '@upperhand/playmaker';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils';

function ModalTitle({ coupon = new Coupon(), actions, errors, intl }) {
  return (
    <Grid container item className="create-coupon">
      <Grid container item xs={6} md={4}>
        <Grid item xs={12}>
          <Typography
            className="create-coupon__name-label"
            variant="fieldLabel"
          >
            {t('.name', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12} className="create-coupon__coupon-input">
          <TextField
            errorText={errors.getErrors(['name'])}
            fullWidth
            icon="create"
            name="name"
            onChange={e => actions.updateField('name', e.target.value)}
            outline={false}
            placeholder={t('.name_placeholder', intl, __filenamespace)}
            transparent
            value={coupon.get('name')}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        md={8}
        justify="flex-start"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={10}
          md={8}
          justify="flex-end"
          alignItems="center"
        >
          {!smallScreen() && (
            <Button
              onClick={actions.cancelCreate}
              classes={{
                root: 'create-coupon__cancel-button',
              }}
              type="secondary"
            >
              {t('.cancel', intl, __filenamespace)}
            </Button>
          )}
          <Button
            onClick={actions.save}
            classes={{
              root: 'create-coupon__create-button',
            }}
            type="primary"
          >
            {t('.create', intl, __filenamespace)}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

ModalTitle.propTypes = {
  coupon: PropTypes.object,
  actions: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default ModalTitle;
