import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AddCircle({ color = 'var(--color-secondary)', ...restProps }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 32 32" {...restProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-423.000000, -251.000000)">
          <g transform="translate(423.000000, 251.000000)">
            <circle fill={color} cx="16" cy="16" r="16" />
            <polygon
              fill="var(--color-white)"
              transform="translate(16.071068, 16.071068)
                       rotate(-315.000000)
                       translate(-16.071068, -16.071068)"
              points="21.0710678 12.0710678 20.0710678 11.0710678 16.0710678 15.0710678 12.0710678
                    11.0710678 11.0710678 12.0710678 15.0710678 16.0710678 11.0710678 20.0710678
                    12.0710678 21.0710678 16.0710678 17.0710678 20.0710678 21.0710678 21.0710678
                    20.0710678 17.0710678 16.0710678"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default AddCircle;
