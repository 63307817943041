import * as React from 'react';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import NoteIcon from '@mui/icons-material/InsertDriveFile';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';

import history from 'routes/History.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { enabledCustomerPreferences } from 'shared/utils/CustomerUtils';
import { t } from 'shared/utils/LocaleUtils.js';

import { formatClientTime } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

function NotesButton({ className, onClick }) {
  return (
    <IconButton className={className} onClick={onClick}>
      <NoteIcon />
    </IconButton>
  );
}

function RSVPButton({ className, onClick }) {
  return (
    <IconButton className={className} onClick={onClick}>
      <MarkEmailUnreadOutlinedIcon />
    </IconButton>
  );
}

function DesktopView({
  startAt,
  endAt,
  eventTitle,
  eventTypeName,
  isPaid,
  isNoShow,
  isUpcoming,
  scheduleType,
  session,
  rsvpStatus,
  rsvpEventType,
  onNotesClick,
  onRsvpClick,
  intl,
}) {
  const hasAdminNote = Boolean(session?.note);
  const hasClientNote = Boolean(session?.client_note);
  const hasNotes = hasAdminNote || hasClientNote;
  const rsvpEnabled = enabledCustomerPreferences(['enable_rsvp']);
  const rsvpEnabledForEventType = rsvpEventType
    ? enabledCustomerPreferences(['enable_rsvp_sections'], [rsvpEventType])
    : false;
  const showRsvpBtn = rsvpEnabled && rsvpEnabledForEventType && isUpcoming;

  return (
    <>
      <Grid item className="schedule__row-item" xs={2}>
        <Typography variant="subtitle2" display="inline">
          {scheduleType}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={2}>
        <Typography variant="subtitle1">
          {startAt ? startAt.format('ddd, MMM D') : '-'}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={2}>
        <Typography variant="body1">
          {formatClientTime(startAt, 'h:mma')}
          &ensp;-&ensp;
          {formatClientTime(endAt, 'h:mma')}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={2}>
        <Typography variant="subtitle1" className="event-title">
          {eventTitle}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={clsx(
          'schedule__row-item',
          (!isPaid || isNoShow) && 'schedule__event-item-unpaid'
        )}
      >
        <Typography variant="body1" className="event-type">
          {eventTypeName}
        </Typography>
        <Grid item xs>
          {!isPaid && (
            <Typography variant="body2" className="unpaid-label">
              {t('.unpaid', intl, __filenamespace)}
            </Typography>
          )}
          {isNoShow && (
            <Typography variant="body2" className="noShow-label">
              {t('.is_no_show', intl, __filenamespace)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item className="schedule__row-item-actions" xs={2}>
        <div>
          {showRsvpBtn && (
            <RSVPButton
              className={`schedule__rsvp-${rsvpStatus}`}
              onClick={onRsvpClick}
            />
          )}
          {hasNotes && (
            <NotesButton
              className="schedule__row-items-notes"
              onClick={onNotesClick}
            />
          )}
        </div>
      </Grid>
    </>
  );
}

function MobileView({
  startAt,
  endAt,
  eventTitle,
  eventTypeName,
  isPaid,
  isNoShow,
  isUpcoming,
  scheduleType,
  session,
  rsvpStatus,
  rsvpEventType,
  onNotesClick,
  onRsvpClick,
  intl,
}) {
  const hasAdminNote = Boolean(session?.note);
  const hasClientNote = Boolean(session?.client_note);
  const hasNotes = hasAdminNote || hasClientNote;
  const rsvpEnabled = enabledCustomerPreferences(['enable_rsvp']);
  const rsvpEnabledForEventType = rsvpEventType
    ? enabledCustomerPreferences(['enable_rsvp_sections'], [rsvpEventType])
    : false;
  const showRsvpBtn = rsvpEnabled && rsvpEnabledForEventType && isUpcoming;
  const showActions = showRsvpBtn || hasNotes;

  return (
    <>
      <Grid item xs={showActions ? 6 : 12}>
        <Typography variant="subtitle2" display="inline">
          {scheduleType}
        </Typography>
      </Grid>
      {showActions && (
        <Grid item xs={6} className="schedule__row-item-actions">
          <div>
            {showRsvpBtn && (
              <RSVPButton
                className={`schedule__rsvp-${rsvpStatus}`}
                onClick={onRsvpClick}
              />
            )}
            {hasNotes && (
              <NotesButton
                className="schedule__row-items-notes"
                onClick={onNotesClick}
              />
            )}
          </div>
        </Grid>
      )}
      <Grid item className="schedule__row-item" xs={6}>
        <Typography variant="subtitle1">
          {startAt ? startAt.format('ddd, MMM D') : '-'}
        </Typography>
        <Typography variant="body1">
          {formatClientTime(startAt, 'h:mma')}
          &ensp;-&ensp;
          {formatClientTime(endAt, 'h:mma')}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={6}>
        <Typography variant="subtitle1" className="event-title">
          {eventTitle}
        </Typography>
        <Grid
          item
          xs="auto"
          className={clsx(
            'schedule__row-item',
            (!isPaid || isNoShow) && 'schedule__event-item-unpaid'
          )}
        >
          <Typography variant="body1" className="event-type">
            {eventTypeName}
          </Typography>
          <Grid item xs={12}>
            {!isPaid && (
              <Typography variant="body2" className="unpaid-label">
                {t('.unpaid', intl, __filenamespace)}
              </Typography>
            )}
            {isNoShow && (
              <Typography variant="body2" className="noShow-label">
                {t('.is_no_show', intl, __filenamespace)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function ScheduleListItem({
  isUpcoming,
  isMobile = false,
  onSessionSummaryOpen = () => null,
  onRescheduleOpen = () => null,
  actions,
  registrationId,
  registrations,
  events,
  sessions,
  intl,
}) {
  const registration = registrations.get(registrationId);
  const eventId = registration.get('event_id');
  const sessionId = registration.get('session_id');
  const orderId = registration.get('order_id');
  const orderItemId = registration.get('order_item_id');
  const event = events.get(eventId);
  const session = sessions.get(sessionId);
  const eventType = event?.get('event_type');
  const isFixedSchedule = event?.isFixedSchedule();
  const isOpenBooking = event?.isOpenBooking();
  const isTeamEvent = event?.isTeamSchedule();
  const eventSchedules = event?.getSchedule();
  const cancellationDeadline =
    session?.get('cancellation_deadline') ||
    eventSchedules?.get('cancellation_deadline');
  const eventTypeName = isTeamEvent
    ? event.team_type.name
    : eventType?.get('name');
  const eventTypeColor = isTeamEvent
    ? event.team_type.color
    : eventType?.get('color');
  const startAt = session?.get('starts_at');
  const endAt = session?.get('ends_at');
  const isPaid = registration.get('paid');
  const isStaff = currentUser().isStaff();
  const isClient = currentUser().isClient();
  const isCoach = currentUser().isCoach();
  const isNoShow = session?.attendance_map
    .get('no_show')
    .includes(registration?.client_id);
  const isManagedBy = currentUser().managed_by_id;
  const isReschedule = typeof onRescheduleOpen === 'function';
  const schedules = event?.schedules;
  const scheduleId = session?.get('schedule_id');
  const schedule = schedules?.find(sh => sh.id === scheduleId);
  const scheduleType = isTeamEvent
    ? schedule?.label
    : event?.getScheduleTypeLabel();

  const isDeadlineReached = () =>
    !!cancellationDeadline &&
    moment().add(cancellationDeadline, 'seconds').isSameOrAfter(startAt);

  const isCancellable = () =>
    !isFixedSchedule && moment(startAt).isAfter() && !isDeadlineReached();

  const showCancel = () => {
    if (isTeamEvent && isClient) {
      return false;
    }
    return isStaff ? moment(startAt).isAfter() : isCancellable();
  };

  const sessionSummaryDrawerHandler = (activeTab = 'details') => {
    if (typeof onSessionSummaryOpen === 'function') {
      onSessionSummaryOpen({
        sessionId,
        eventId,
        defaultSelectedTab: activeTab,
        registration,
      });
    }

    actions.openSessionSummaryDrawer({
      sessionId,
      eventId,
      defaultSelectedTab: activeTab,
      registrationId,
      closeSchedulesDrawer: !isMobile,
    });
  };

  const handleRsvpOpen = e => {
    e.stopPropagation();
    sessionSummaryDrawerHandler('rsvp');
  };

  const handleNotesOpen = e => {
    e.stopPropagation();
    sessionSummaryDrawerHandler('notes');
  };

  const menuItems = [
    {
      onClick: () => actions.openViewBalanceDrawer(null, [], orderItemId),
      label: t('.pay_balance', intl, __filenamespace),
      isAvailable: (isStaff || !isManagedBy) && !isPaid && orderItemId,
    },
    {
      onClick: () => actions.openOrderDetailsDrawer(orderId),
      label: t('.order_details', intl, __filenamespace),
      isAvailable: isStaff || !isManagedBy,
    },
    {
      onClick: () =>
        onRescheduleOpen({
          session,
          registration,
        }),
      label: t('.reschedule', intl, __filenamespace),
      isAvailable: isReschedule && isOpenBooking && isClient,
    },
    {
      onClick: sessionSummaryDrawerHandler,
      label: t('.session_summary', intl, __filenamespace),
      isAvailable: isStaff || isCoach,
    },
    {
      onClick: () => actions.setRegistrationToRemove({ registration, event }),
      label: t('.cancel_registration', intl, __filenamespace),
      isAvailable: showCancel(),
    },
  ].filter(item => item.isAvailable);

  return (
    <Card
      borderColor={eventTypeColor}
      borderPosition="left"
      contentDirection="row"
      classes={{
        root: 'schedule__row',
        content: 'schedule__row-content',
      }}
      menuOptions={menuItems.length ? menuItems : undefined}
      onClick={() => history.push(event.url())}
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        {!isMobile && (
          <DesktopView
            intl={intl}
            isUpcoming={isUpcoming}
            endAt={endAt}
            startAt={startAt}
            isPaid={isPaid}
            isNoShow={isNoShow}
            eventTypeName={eventTypeName}
            eventTitle={event?.title}
            scheduleType={scheduleType}
            session={session}
            rsvpStatus={registration.get('rsvp_status', 'pending')}
            rsvpEventType={event?.rsvpEventType}
            onNotesClick={handleNotesOpen}
            onRsvpClick={handleRsvpOpen}
          />
        )}
        {isMobile && (
          <MobileView
            intl={intl}
            isUpcoming={isUpcoming}
            endAt={endAt}
            isNoShow={isNoShow}
            startAt={startAt}
            isPaid={isPaid}
            eventTypeName={eventTypeName}
            eventTitle={event?.title}
            scheduleType={scheduleType}
            session={session}
            rsvpStatus={registration.get('rsvp_status', 'pending')}
            rsvpEventType={event?.rsvpEventType}
            onNotesClick={handleNotesOpen}
            onRsvpClick={handleRsvpOpen}
          />
        )}
      </Grid>
    </Card>
  );
}

ScheduleListItem.propTypes = {
  intl: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  registrationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  registrations: PropTypes.instanceOf(Map).isRequired,
  events: PropTypes.instanceOf(Map).isRequired,
  sessions: PropTypes.instanceOf(Map).isRequired,
  isMobile: PropTypes.bool,
  onSessionSummaryOpen: PropTypes.func,
  onRescheduleOpen: PropTypes.func,
};

export default React.memo(ScheduleListItem);
