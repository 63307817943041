import * as React from 'react';

function Intelligence({ color = '#9A9EAD', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 680 680"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g clipPath="url(#clip0_110_2)">
        <path
          fill={color}
          d="m381.42,677.13c-164.42,0 -298.15,-133.75 -298.15,-298.13c0,-3.88 0.073,-7.757 0.22,-11.63c31.38,137 154.8,237.12 297.93,237.12c143.13,0 266.45,-100.14 297.81,-237.14c0.15,3.87 0.23,7.74 0.23,11.63c0,164.4 -133.7,298.15 -298.04,298.15z"
        />
        <path
          fill={color}
          d="m300.69,677.13c-164.4,0 -298.15,-133.7 -298.15,-298c0,-164.3 133.75,-298.19 298.15,-298.19c3.887,0 7.77,0.077 11.65,0.23c-137,31.36 -237.13,154.83 -237.13,297.92c0,143.09 100.12,266.45 237.11,297.81c-3.86,0.153 -7.737,0.23 -11.63,0.23z"
        />
        <path
          fill={color}
          d="m598.51,310.65c-31.38,-137 -154.8,-237.11 -297.93,-237.11c-143.13,0 -266.45,100.11 -297.81,237.11c-0.15,-3.87 -0.23,-7.74 -0.23,-11.63c0,-164.4 133.7,-298.15 298,-298.15c164.3,0 298.19,133.75 298.19,298.13c0,3.91 -0.07,7.78 -0.22,11.65z"
        />
        <path
          fill={color}
          d="m380.65,597.06c-3.88,0 -7.757,-0.077 -11.63,-0.23c136.98,-31.37 237.12,-154.83 237.12,-297.92c0,-143.09 -100.14,-266.45 -237.14,-297.81c3.873,-0.147 7.753,-0.223 11.64,-0.23c164.4,0 298.15,133.7 298.15,298c0,164.3 -133.74,298.19 -298.14,298.19z"
        />
        <path
          fill={color}
          d="m309.85,459.89l0,-13.32c-13.38,9 -37.22,18.72 -64.61,18.72c-39,0 -60.27,-19.74 -60.27,-58.59l0,-8.3c0,-54.6 41.86,-61.1 100.4,-61.1l19.12,0l0,-11.12c0,-25.14 -5.42,-30.16 -37.92,-30.16c-22.184,0.073 -44.349,1.278 -66.41,3.61l0,-37.37c11.56,-4.31 44.77,-12.61 79.39,-12.61c64.29,0 87.74,20.84 87.74,76.53l0,133.71l-57.44,0zm-3.23,-90.24l-20.93,0c-28.49,0 -40.76,3.21 -40.76,26.63l0,8.31c0,16.13 9.75,20.83 23.45,20.83c16.21,0 32.1,-6.11 38.24,-9.71l0,-46.06z"
        />
        <path
          fill={color}
          d="m455.3,232l-66.06,0l0,-48.92l66.06,0l0,48.92zm-1.67,229.11l-62.71,0l0,-206.54l62.71,0l0,206.54z"
        />
      </g>
    </svg>
  );
}

export default Intelligence;
