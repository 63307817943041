import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Typography, Icon } from '@upperhand/playmaker';
import { toSlug } from 'shared/utils/StringUtils.jsx';

function StatisticItem({ title, tooltip = '', value = 0 }) {
  const tooltipId = toSlug(title);

  return (
    <>
      <div className="statistic-item">
        <Typography variant="body3" className="statistic-item__title">
          {title}
          {tooltip && (
            <span
              data-tip
              data-for={tooltipId}
              className="statistic-item__tooltip"
            >
              <Icon name="info" className="statistic-item__tooltip_icon" />
            </span>
          )}
        </Typography>
        <Typography variant="h5">{value}</Typography>
      </div>
      {tooltip && (
        <ReactTooltip id={tooltipId} className="uh-tooltip" effect="solid">
          {tooltip}
        </ReactTooltip>
      )}
    </>
  );
}

StatisticItem.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.any,
};

export default memo(StatisticItem);
