import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import { Grid, Typography } from '@upperhand/playmaker';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { SessionDataStore } from 'dataStores';

import SessionListing from './SessionListing.jsx';

const styles = {
  group: {
    position: 'relative',
    height: '20px',
    padding: '8px 0 0px',
    borderBottom: `1px solid ${uhColors.pmIconGray}`,
    margin: '0 0 20px',
  },
  groupTitle: {
    position: 'absolute',
    background: uhColors.white,
    paddingRight: '12px',
  },
};

function SessionGrouping({
  clientId,
  onSelect,
  selectedSessions,
  sessionIds,
  sessionsLoading,
  sessionDataStore = {},
}) {
  if (sessionIds.size === 0 && sessionsLoading) {
    return null;
  }

  if (sessionIds.size < 1) {
    return (
      <Typography variant="h5">
        No Sessions Available
        <Typography variant="body2">
          There are currently no sessions available for this event. Click Cancel
          to choose another event to book
        </Typography>
      </Typography>
    );
  }

  const { sessions } = sessionDataStore;
  const groupedSessions = sessions
    .filter(s => sessionIds.includes(s.id))
    .groupBy(x => x.starts_at.format('YYYYMMDD'))
    .map(s => s.sortBy(v => v.starts_at))
    .map(date => date.map(x => x.id))
    .sortBy((v, k) => k)
    .toOrderedSet();

  return groupedSessions.map(group => {
    const displayDate = sessions
      .get(group.first())
      .starts_at.format('DD ddd, MMM');

    return (
      <Grid
        key={group.first()}
        container
        alignItems="center"
        xs={12}
        spacing={2}
      >
        <Grid item xs={12}>
          <div style={styles.group}>
            <div style={styles.groupTitle}>
              <Typography variant="body3" display="inline">
                {displayDate}
              </Typography>
            </div>
          </div>
          <SessionListing
            sessionIds={group}
            selectedSessions={selectedSessions}
            clientId={clientId}
            onSelect={onSelect}
          />
        </Grid>
      </Grid>
    );
  });
}

SessionGrouping.propTypes = {
  clientId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedSessions: PropTypes.instanceOf(Set).isRequired,
  sessionDataStore: PropTypes.object,
  sessionIds: PropTypes.instanceOf(Set).isRequired,
  sessionsLoading: PropTypes.bool.isRequired,
};

export default altContainer({
  stores: {
    sessionDataStore: SessionDataStore,
  },
})(SessionGrouping);
