import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Membership({ color = '#9A9EAD', ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 28 28" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-16.000000, -555.000000)" fill={color}>
          <g transform="translate(16.000000, 555.000000)">
            <path
              d="M23.9996909,20.0000182 C21.7915091,20.0000182 19.9995091,21.7907455 19.9995091,
               24.0002 C19.9995091,26.2083818 21.7915091,28.0003818 23.9996909,28.0003818
               C26.2091455,28.0003818 27.9998727,26.2083818 27.9998727,24.0002 C27.9998727,
               21.7907455 26.2091455,20.0000182 23.9996909,20.0000182"
            />
            <path
              d="M17.8884364,19.138 C17.8006182,18.8478182 17.7522545,18.5614545 17.7191636,18.3349091
               L17.1909818,14.8489091 L15.9080727,18.97 C15.5797091,20.0403636 15.0082545,20.4985455
               13.9989818,20.4985455 C12.9922545,20.4985455 12.4220727,20.0403636 12.0911636,
               18.9687273 L10.8095273,14.8501818 L10.2788,18.3374545 C10.1706182,19.0896364
               9.96443636,20.4985455 8.27298182,20.4985455 C7.21534545,20.4985455 6.56243636,
               19.6025455 6.56243636,18.7727273 C6.56243636,18.3438182 6.77116364,17.1270909
               7.02189091,15.6990909 L8.09352727,9.56963636 C8.41170909,7.76872727 9.62207273,
               7.50018182 10.3144364,7.50018182 C12.0402545,7.50018182 12.4080727,8.64818182
               13.0762545,10.7354545 L13.9989818,13.5978182 L14.9229818,10.7341818 C15.5911636,
               8.64818182 15.9589818,7.50018182 17.6835273,7.50018182 C18.3771636,7.50018182
               19.5875273,7.76872727 19.9057091,9.56963636 L20.9786182,15.6990909 C21.0422545,
               16.0630909 21.1020727,16.4067273 21.1555273,16.7249091 C22.0388,16.3787273
               22.9958909,16.1814545 24.0000727,16.1814545 C25.3160727,16.1814545 26.5569818,
               16.5123636 27.6464364,17.0901818 C27.8717091,16.0949091 28.0002545,15.0627273
               28.0002545,14 C28.0002545,6.26818182 21.7333455,0 14.0002545,0 C6.26843636,0
               0.000254545454,6.26818182 0.000254545454,14 C0.000254545454,21.7330909 6.26843636,28
               14.0002545,28 C15.0629818,28 16.0938909,27.8714545 17.0891636,27.6474545 C16.5126182,
               26.5567273 16.1817091,25.3170909 16.1817091,23.9998182 C16.1817091,22.162 16.8231636,
               20.4743636 17.8884364,19.138"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Membership;
