import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  membershipInfo: {
    fontWeight: 'bold',
    paddingLeft: 16,
  },
};

function MembershipInfo({ event, memberships = List(), style = {} }) {
  const exclusiveMemberships = memberships.filter(membership =>
    membership.membership_event_permissions.some(
      mep =>
        (event.event_type.id !== null &&
          mep.event_type_id === event.event_type.id) ||
        (event.id !== null && mep.event_id === event.id)
    )
  );

  if (exclusiveMemberships.size > 0) {
    return (
      <FlexBoxCenter style={merge(styles.membershipInfo, style)}>
        <MembershipIcon style={{ marginRight: 12 }} />
        {exclusiveMemberships.map(m => m.name).join(', ')}
      </FlexBoxCenter>
    );
  }
  return null;
}

MembershipInfo.propTypes = {
  event: PropTypes.instanceOf(CustomerEvent).isRequired,
  memberships: PropTypes.instanceOf(List),
  style: PropTypes.object,
};

export default MembershipInfo;
