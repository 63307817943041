import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import LinearProgress from '@mui/material/LinearProgress';
import { Spinner } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';

import SearchBar from 'shared/components/SearchBar.jsx';
import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { RegistrationDataStore } from 'dataStores';

import CheckInActions from './actions';
import CheckInStore from './store';

import Attendees from './components/Attendees.jsx';

const styles = {
  flex: { flex: '1 0 0', flexDirection: 'column' },
  searchBar: {
    padding: '0 25px',
    fontSize: 13,
  },
  searchBarIcon: { height: 20, width: 20, marginRight: 16 },
  spinner: {
    textAlign: 'center',
    marginTop: 20,
  },
};

function CheckIn({
  checkInStore = {},
  registrationDataStore = {},
  intl,
  sessionId,
}) {
  React.useEffect(() => {
    CheckInActions.mounted.defer(sessionId);
  }, [sessionId]);

  const {
    clientIds,
    registrationIds,
    filteredCheckedInClientIds,
    filterString,
    isLoading,
    session,
  } = checkInStore;
  const { registrations } = registrationDataStore;
  const registeredClientIds = registrationIds
    .toList()
    .map(id => registrations.get(id)?.client_id)
    .count(v => filteredCheckedInClientIds.includes(v));
  const serverCheckedInClientIds = session?.getIn([
    'attendance_map',
    'checked_in',
  ]);

  const getProgressValue = () => {
    const maxProgress = clientIds.size;
    const currentProgress = filteredCheckedInClientIds.size;

    if (currentProgress === 0) return 0;

    return Math.round((currentProgress / maxProgress) * 100);
  };

  return isLoading ? (
    <div style={styles.spinner}>
      <Spinner type="indeterminate" />
    </div>
  ) : (
    <FlexBox style={styles.flex}>
      <FlexBoxJustify style={{ alignItems: 'center', height: 56 }}>
        <SearchBar
          autoFocus
          hintText={t('.search_hint_text', intl, __filenamespace)}
          hintStyle={{ color: uhColors.hint }}
          iconStyle={styles.searchBarIcon}
          onTypingEnd={CheckInActions.filterApplied}
          searchText={filterString}
        />
        <div style={{ fontSize: 15 }}>
          <FormattedMessage
            id={messageId('.fraction', __filenamespace)}
            values={{
              num: registeredClientIds,
              den: registrationIds.count(),
            }}
          />
        </div>
      </FlexBoxJustify>
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={getProgressValue()}
      />
      <Attendees
        session={session}
        clientIds={clientIds}
        registrationIds={registrationIds}
        filteredCheckedInClientIds={filteredCheckedInClientIds}
        serverCheckedInClientIds={serverCheckedInClientIds}
      />
    </FlexBox>
  );
}

CheckIn.propTypes = {
  checkInStore: PropTypes.object,
  intl: PropTypes.object,
  sessionId: PropTypes.string.isRequired,
};

export default altContainer({
  stores: {
    checkInStore: CheckInStore,
    registrationDataStore: RegistrationDataStore,
  },
})(CheckIn);
