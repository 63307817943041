import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';

import { Spinner, Typography } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import AccountProfilesActions from './Actions';
import AccountProfilesStore from './Store.js';

import AccountProfile from './components/AccountProfile.jsx';

const styles = {
  section: {},
  header: {
    margin: '16px 0px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
};

function AccountProfiles({
  accountProfilesStore = {},
  eventId,
  intl,
  scheduleSession,
}) {
  React.useEffect(() => {
    AccountProfilesActions.mount.defer(eventId);
  }, [eventId]);

  const { clientIds, loading } = accountProfilesStore;

  return (
    <section aria-label="Account profiles" style={styles.section}>
      <header style={styles.header}>
        <Typography variant="h4" component="h4">
          {t('.title', intl, __filenamespace)}
        </Typography>
      </header>

      {loading ? (
        <div style={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        clientIds.map(id => (
          <AccountProfile
            key={id}
            clientId={id}
            eventId={eventId}
            scheduleSession={scheduleSession}
          />
        ))
      )}
    </section>
  );
}

AccountProfiles.propTypes = {
  accountProfilesStore: PropTypes.shape({
    clientIds: PropTypes.object,
    loading: PropTypes.bool,
  }),
  eventId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  scheduleSession: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      accountProfilesStore: AccountProfilesStore,
    },
  })
)(AccountProfiles);
