import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import { List } from 'immutable';

import EventTypeEditingActions from 'shared/actions/EventTypeEditingActions.jsx';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Modal from 'components/EventTypeEditor/Modal.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

function EventTypeEditor({
  label = '',
  placeholder = '',
  selectedEventTypeId = null,
  eventTypes,
  onEventTypeSelect,
  errorText,
  onCreateOrUpdateSuccess,
  intl,
}) {
  const [isModalOpen, setModalState] = useState(false);

  const items = eventTypes.size
    ? eventTypes
        .map(eventType => ({
          value: eventType?.id,
          label: (
            <div className="event-type-editor__menu-item">
              <FiberManualRecordIcon sx={{ color: eventType?.color }} />
              <div>{eventType?.name}</div>
            </div>
          ),
        }))
        .toArray()
    : [];

  const canAddCreate = !(
    currentCustomer().restrict_editing && !currentUser().isAdmin()
  );

  const handleEditClick = () => {
    EventTypeEditingActions.initialLoad(selectedEventTypeId);
    setModalState(true);
  };

  const handleOnChange = event => {
    if (canAddCreate && event.target.value === 0) {
      EventTypeEditingActions.initialLoad();
      setModalState(true);
    } else {
      onEventTypeSelect(event.target.value);
    }
  };

  return (
    <div className="event-type-editor">
      <Dropdown
        fullWidth
        value={selectedEventTypeId}
        label={label}
        placeholder={placeholder || t('.placeholder', intl, __filenamespace)}
        errorText={errorText}
        onChange={handleOnChange}
        items={[
          ...(canAddCreate
            ? [
                {
                  value: 0,
                  label: t('.new_item', intl, __filenamespace),
                },
              ]
            : []),
          ...items,
        ]}
        name="event-type"
      />
      {canAddCreate && (
        <Modal
          intl={intl}
          isOpen={isModalOpen}
          onSuccess={() => {
            EventTypeEditingActions.createOrUpdate({
              callback: onCreateOrUpdateSuccess,
            });
            setModalState(false);
          }}
          onAbort={() => setModalState(false)}
        />
      )}
      {canAddCreate && selectedEventTypeId && (
        <div className="event-type-editor__edit-button">
          <Button variant="outlined" onClick={handleEditClick}>
            {t('.edit_button', intl, __filenamespace)}
          </Button>
        </div>
      )}
    </div>
  );
}

EventTypeEditor.propTypes = {
  errorText: PropTypes.string.isRequired,
  eventTypes: PropTypes.instanceOf(List).isRequired,
  intl: PropTypes.object.isRequired,
  label: PropTypes.string,
  onCreateOrUpdateSuccess: PropTypes.func.isRequired,
  onEventTypeSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  selectedEventTypeId: PropTypes.number,
};

export default injectIntl(EventTypeEditor);
