import PropTypes from 'prop-types';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { List } from 'immutable';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import CardMenu from 'shared/components/CardMenu.jsx';
import EventRate from 'shared/records/EventRate.jsx';
import EventRateDeletionActions from 'contacts/shared/actions/event_rates/EventRateDeletionActions';
import EventRateEditingActions from 'contacts/shared/actions/event_rates/EventRateEditingActions.js';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import SelectedEventOrType from 'shared/components/SelectedEventOrType.jsx';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  eventRateCard: {
    padding: 16,
    position: 'relative',
  },
  cardLabel: {
    fontSize: 13,
    fontWeight: 600,
    color: 'var(--color-old-gray)',
    marginBottom: 5,
  },
  cardValue: {
    fontSize: 13,

    color: 'var(--color-old-gray)',
  },
};

function SelectedEventsList({ selectedEvents }) {
  return (
    <div style={{ marginTop: 5 }}>
      {selectedEvents.map(event => (
        <SelectedEventOrType
          key={event.id}
          event={event}
          title={event.title}
          id={event.id}
          barColor={event.event_type.color}
        />
      ))}
    </div>
  );
}

function EventRateCard({
  disabledEditing = true,
  style = {},
  eventRate,
  selectedEvents,
  intl,
}) {
  return (
    <Paper style={merge(styles.eventRateCard, style)}>
      {(currentUser().isAdmin() || currentUser().isStaffAdmin()) && (
        <CardMenu>
          <MenuItem
            sx={{ fontSize: 'initial' }}
            disabled={disabledEditing}
            onClick={() => {
              EventRateEditingActions.begin(eventRate);
            }}
          >
            {t('actions.edit', intl, __filenamespace)}
          </MenuItem>
          <MenuItem
            sx={{ fontSize: 'initial' }}
            onClick={() => EventRateDeletionActions.removeEventRate(eventRate)}
          >
            {t('actions.remove', intl, __filenamespace)}
          </MenuItem>
        </CardMenu>
      )}

      <FlexBox style={{ flexDirection: 'column', marginBottom: 20 }}>
        <div style={styles.cardLabel}>
          <FormattedMessage
            id={messageId('.events', __filenamespace)}
            values={{ n: selectedEvents.size }}
          />
        </div>
        <SelectedEventsList selectedEvents={selectedEvents} />
      </FlexBox>
      <FlexBox>
        <div style={{ flex: '1 1 50%' }}>
          <FlexBox style={{ flexDirection: 'column' }}>
            <div style={styles.cardLabel}>
              <FormattedMessage
                id={messageId('.value_type', __filenamespace)}
              />
            </div>
            <div style={styles.cardValue}>
              <FormattedMessage
                id={messageId(
                  `.${
                    eventRate.rate_type === 'amount' ? 'dollar' : 'percentage'
                  }`,
                  __filenamespace
                )}
              />
            </div>
          </FlexBox>
        </div>
        <div style={{ flex: '1 1 50%', marginBottom: 10 }}>
          <FlexBox style={{ flexDirection: 'column' }}>
            <div style={styles.cardLabel}>
              <FormattedMessage id={messageId('.value', __filenamespace)} />
            </div>
            <div style={styles.cardValue}>
              {eventRate.rate_type === 'amount' ? (
                <FormattedCurrency value={eventRate.value} fromCents />
              ) : (
                /* eslint-disable react/style-prop-object */
                <FormattedNumber
                  value={eventRate.value / 100}
                  style="percent"
                />
                /* eslint-enable react/style-prop-object */
              )}
            </div>
          </FlexBox>
        </div>
      </FlexBox>
      {eventRate.discount_sensitive && (
        <FlexBoxCenter style={{ marginTop: 10, fontWeight: 'bold' }}>
          <FormattedMessage
            id={messageId('.discount_sensitive_rate', __filenamespace)}
          />
          <InfoIcon
            data-tip
            data-for="usage-info"
            style={{ height: 20, width: 20, marginLeft: 5 }}
          />
          <ReactTooltip
            id="usage-info"
            effect="solid"
            style={{ maxWidth: 240 }}
            className="charge-failure-tooltip uh-tooltip"
          >
            <div style={{ lineHeight: 1.4 }}>
              <FormattedMessage
                id={messageId('.sensitive_rate', __filenamespace)}
              />
            </div>
          </ReactTooltip>
        </FlexBoxCenter>
      )}
    </Paper>
  );
}

EventRateCard.propTypes = {
  disabledEditing: PropTypes.bool,
  eventRate: PropTypes.instanceOf(EventRate).isRequired,
  selectedEvents: PropTypes.instanceOf(List).isRequired,
  style: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default EventRateCard;
