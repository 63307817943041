import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Button, Typography } from '@upperhand/playmaker';
import altContainer from 'shared/hocs/altContainer.jsx';

import Circle from 'shared/components/Circle.jsx';
import { EventDataStore, EventTypeDataStore } from 'dataStores';
import { messageId, t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import CreditListDrawerActions from '../Actions';

function DetailRow({ color, label, link, sourceName }) {
  return (
    <Grid
      className="credit-grant-card-item"
      item
      container
      xs={12}
      alignItems="center"
    >
      {link && (
        <Link className="credit-grant-card-item__link" to={link}>
          {color && (
            <Circle diameter={10} color={color} style={{ marginBottom: 2 }} />
          )}
          {label && (
            <div className="credit-grant-card-item__label">{label}</div>
          )}
        </Link>
      )}
      {!link && (
        <>
          {color && (
            <Circle diameter={10} color={color} style={{ marginBottom: 2 }} />
          )}
          {label && (
            <div className="credit-grant-card-item__label">{label}</div>
          )}
        </>
      )}
      {sourceName && (
        <div className="credit-grant-card-item__source-type">{sourceName}</div>
      )}
    </Grid>
  );
}

function CreditGrantCard({
  intl,
  creditGrant,
  eventDataStore = {},
  eventTypeDataStore = {},
}) {
  const { events } = eventDataStore;
  const { eventTypes } = eventTypeDataStore;
  const isGrantExpired = creditGrant.isExpired();
  const isStaff = currentUser().isStaff();

  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      className="credit-grant-card"
    >
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        className="credit-grant-card__header"
      >
        <Grid item xs={10}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id={messageId('.credits', __filenamespace)}
              values={{
                n: creditGrant.unlimited
                  ? t('.unlimited', intl, __filenamespace)
                  : creditGrant.available,
              }}
            />
            &nbsp;
            {creditGrant.expires_at ? (
              <span
                className={
                  isGrantExpired ? 'credit-grant-card__expired' : undefined
                }
              >
                <FormattedMessage
                  id={messageId(
                    isGrantExpired ? '.expired_at' : '.expires_at',
                    __filenamespace
                  )}
                  values={{
                    expiresAt: creditGrant.expires_at.format('MM/DD/YYYY'),
                  }}
                />
              </span>
            ) : (
              t('.indefinite', intl, __filenamespace)
            )}
          </Typography>
        </Grid>
        {isStaff && (
          <Grid item xs={2}>
            <Button
              size="1x"
              type="tertiary"
              rounded
              onClick={() =>
                CreditListDrawerActions.openAdjustmentModal(creditGrant.id)
              }
            >
              {t('.edit', intl, __filenamespace)}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        className="credit-grant-card__body"
      >
        <DetailRow
          key="grant-source-type"
          sourceName={creditGrant.get('source_name', '')}
        />
        {creditGrant.all_events && t('.all_events', intl, __filenamespace)}
        {creditGrant.event_type_ids.map(id => {
          const eventType = eventTypes.get(id);
          if (!eventType) return null;

          return (
            <DetailRow
              key={`event-type-${eventType.id}`}
              color={eventType.color}
              label={eventType.name}
            />
          );
        })}
        {creditGrant.event_ids.map(id => {
          const event = events.get(id);

          if (!event) return null;

          const eventStaffPath = event.get('admin_path', '');
          const eventClientPath = event.get('path', '');

          return (
            <DetailRow
              key={`event-${event.id}`}
              color={event.event_type?.color}
              label={event.title}
              link={isStaff ? eventStaffPath : eventClientPath}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

CreditGrantCard.propTypes = {
  intl: PropTypes.object.isRequired,
  creditGrant: PropTypes.object.isRequired,
  eventDataStore: PropTypes.object,
  eventTypeDataStore: PropTypes.object,
};

export default altContainer({
  stores: {
    eventDataStore: EventDataStore,
    eventTypeDataStore: EventTypeDataStore,
  },
})(CreditGrantCard);
