import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import {
  BalancesDataStore,
  ClientDataStore,
  LocationDataStore,
  ScheduledPaymentDataStore,
} from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

import PaymentDetailsDrawerActions from './Actions.js';
import PaymentDetailsDrawerStore from './Store.js';

import Content from '../balanceDetailsDrawer/components/Content.jsx';

import '../balanceDetailsDrawer/styles.scss';

function PaymentDetailsDrawer({
  paymentDetailsDrawerStore = {},
  balancesDataStore = {},
  clientDataStore = {},
  locationDataStore = {},
  scheduledPaymentDataStore = {},
  intl,
}) {
  const {
    balanceCompoundId,
    isLoadingBalanceDetails,
    isLoadingBalance,
    isLoadingClient,
    isLoadingLocation,
    dotsMenuAnchorEl,
    remainingScheduledPayments,
  } = paymentDetailsDrawerStore;
  const { balances } = balancesDataStore;
  const { clients } = clientDataStore;
  const { locations } = locationDataStore;
  const { scheduledPayments: scheduledPaymentsData } =
    scheduledPaymentDataStore;
  const balance = balances.get(balanceCompoundId);
  const scheduledPayment = scheduledPaymentsData.get(balance?.productId);
  const threeDotsEnabled =
    !isLoadingBalance &&
    balance &&
    (balance.outstanding > 0 || balance.get('paid') > 0);

  return (
    <Drawer
      classes={{
        root: 'balance-details-drawer',
      }}
      open={isLoadingBalance || !!balance}
      key={balanceCompoundId}
      onClose={PaymentDetailsDrawerActions.closeDrawer}
      headerType="light"
      title={t('.title', intl, __filenamespace)}
      actions={
        threeDotsEnabled
          ? [
              {
                icon: 'threeDots',
                onClick: PaymentDetailsDrawerActions.toggleDotsMenu,
              },
            ]
          : []
      }
      content={
        isLoggedIn() ? (
          <Content
            intl={intl}
            isLoading={
              isLoadingBalance ||
              isLoadingBalanceDetails ||
              isLoadingClient ||
              isLoadingLocation
            }
            balance={balance}
            balanceDetails={balance?.get('details')}
            location={
              locations && balance && locations.get(balance.get('locationId'))
            }
            client={clients && balance && clients.get(balance.get('clientId'))}
            dotsMenuAnchorEl={dotsMenuAnchorEl}
            scheduledPayment={scheduledPayment}
            remainingScheduledPayments={remainingScheduledPayments}
            isPaymentDetailsDrawer
          />
        ) : (
          <SignInPrompt />
        )
      }
    />
  );
}

PaymentDetailsDrawer.propTypes = {
  paymentDetailsDrawerStore: PropTypes.object,
  clientDataStore: PropTypes.object,
  locationDataStore: PropTypes.object,
  balancesDataStore: PropTypes.object,
  scheduledPaymentDataStore: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      paymentDetailsDrawerStore: PaymentDetailsDrawerStore,
      balancesDataStore: BalancesDataStore,
      clientDataStore: ClientDataStore,
      locationDataStore: LocationDataStore,
      scheduledPaymentDataStore: ScheduledPaymentDataStore,
    },
  })
)(PaymentDetailsDrawer);
