import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Button, Grid } from '@upperhand/playmaker';

import BrowseEventsBookDrawerActions from 'containers/browseEventsBookDrawer/Actions.js';
import PortalTabsActions from 'client_portal/actions/PortalTabsActions.js';

import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils.js';

import {
  MEMBERSHIPS_TAB,
  PASSES_TAB,
} from 'client_portal/stores/PortalTabsStore.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

function SchedulingAction({ btnText, handleClick }) {
  return (
    <Grid item xs={12}>
      <Button type="primary" fullWidth onClick={handleClick}>
        {btnText}
      </Button>
    </Grid>
  );
}

const onViewButtonClicked = tab => {
  BrowseEventsBookDrawerActions.closeDrawer();
  PortalTabsActions.tabSelected(tab);
  redirectTo({
    path: customerScopedRoute(
      `/${tab === MEMBERSHIPS_TAB ? MEMBERSHIPS_TAB : 'credit_passes'}`
    ),
  });
};

function AccountProfileButtons({
  clickableFeatures = {},
  hasCreditsRemaining,
  intl,
  scheduleSession,
}) {
  if (hasCreditsRemaining) {
    return (
      <SchedulingAction
        btnText={t('.scheduleSessions', intl, __filenamespace)}
        handleClick={scheduleSession}
      />
    );
  }

  const { hasMemberships, hasPasses } = clickableFeatures;

  return (
    <>
      {hasMemberships && (
        <SchedulingAction
          btnText={t('.viewMemberships', intl, __filenamespace)}
          handleClick={() => onViewButtonClicked(MEMBERSHIPS_TAB)}
        />
      )}
      {hasPasses && (
        <SchedulingAction
          btnText={t('.viewPasses', intl, __filenamespace)}
          handleClick={() => onViewButtonClicked(PASSES_TAB)}
        />
      )}
    </>
  );
}

AccountProfileButtons.propTypes = {
  clickableFeatures: PropTypes.shape({
    hasMemberships: PropTypes.bool,
    hasPasses: PropTypes.bool,
  }),
  hasCreditsRemaining: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  scheduleSession: PropTypes.func.isRequired,
};

export default injectIntl(AccountProfileButtons);
