import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@upperhand/playmaker';

function DetailItemContainer({ children, onClick = null }) {
  if (onClick) {
    return (
      <Button
        onClick={onClick}
        type="tertiary"
        classes={{ root: 'details__container' }}
      >
        {children}
      </Button>
    );
  }

  return <div className="details__container">{children}</div>;
}

DetailItemContainer.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
};

export default DetailItemContainer;
