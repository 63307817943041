import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Spinner } from '@upperhand/playmaker';

import { enabledCustomerPreferences } from 'shared/utils/CustomerUtils';

import Header from './Header.jsx';
import EditMenu from './EditMenu.jsx';

import SessionTabs from './SessionTabs.jsx';
import EditDateTime from './editDateTime/EditDateTime.jsx';

const styles = {
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  hide: {
    display: 'none',
  },
  spinner: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Content({
  intl,
  selectedTab = 0,
  total = 0,
  eventId = null,
  isEditMode = false,
  isLoading = true,
  sessionId = null,
  editMenu = null,
  allowCancel = false,
  onNextSession = () => null,
  onPreviousSession = () => null,
  event = null,
}) {
  const isTeamEvent = event?.isTeamEvent();
  const rsvpEnabled = enabledCustomerPreferences(['enable_rsvp']);
  const rsvpEnabledForEventType = event?.rsvpEventType
    ? enabledCustomerPreferences(
        ['enable_rsvp_sections'],
        [event.rsvpEventType]
      )
    : false;
  const rsvpEnabledForEvent = rsvpEnabled && rsvpEnabledForEventType;

  return (
    <>
      {isLoading && (
        <div style={styles.spinner}>
          <Spinner type="indeterminate" />
        </div>
      )}
      {!isLoading && (
        <div style={styles.content}>
          <EditMenu
            intl={intl}
            anchorEl={editMenu.get('anchorEl')}
            allowCancel={allowCancel}
            sessionId={sessionId}
          />
          <Header
            sessionId={sessionId}
            isEditMode={isEditMode}
            onNextSession={onNextSession}
            onPreviousSession={onPreviousSession}
            event={event}
          />
          {isEditMode ? (
            <EditDateTime
              intl={intl}
              sessionId={sessionId}
              isTeamEvent={isTeamEvent}
            />
          ) : (
            <SessionTabs
              total={total}
              intl={intl}
              sessionId={sessionId}
              eventId={eventId}
              event={event}
              selectedTab={selectedTab}
              isTeamEvent={isTeamEvent}
              rsvpEnabled={rsvpEnabledForEvent}
            />
          )}
        </div>
      )}
    </>
  );
}

Content.propTypes = {
  editMenu: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number.isRequired,
  eventId: PropTypes.number,
  sessionId: PropTypes.string,
  total: PropTypes.number,
  allowCancel: PropTypes.bool,
  event: PropTypes.object,
  onNextSession: PropTypes.func,
  onPreviousSession: PropTypes.func,
};

export default Content;
