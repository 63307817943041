import React, { memo, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { List as ImmutableList, Map } from 'immutable';
import { injectIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Close from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import PackageSelector from 'shared/components/registration/PackageSelector.jsx';

import { formatClientTime } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import OrderItem from 'shared/records/OrderItem';
import PerSessionPricingDescription from 'shared/records/automations/PerSessionPricingDescription.jsx';

const styles = {
  cardContent: {
    padding: '1.5rem',
  },
  label: {
    fontWeight: 700,
    fontSize: '16px',
  },
  deleteBtn: {
    padding: 0,
  },
  datesContainer: {
    height: '100%',
    maxHeight: '200px',
    overflow: 'auto',
  },
  date: {
    width: '70%',
    minWidth: '85px',
  },
  timeContainer: {
    width: '100%',
  },
  startTime: {
    width: 'initial',
  },
  timeSeparator: {
    width: 'initial',
  },
  endTime: {
    width: 'initial',
  },
  staffName: showEditingIfo => ({
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: showEditingIfo ? 'left' : 'right',
  }),
};

function OpenBookingCardContent({
  event,
  intl = {},
  profiles = ImmutableList(),
  orderItem = new OrderItem({ orderable_type: 'registration_package' }),
  staff = Map(),
  showEditingIfo = false,
  packagePricing = new PerSessionPricingDescription(),
  membershipEventDiscounts = ImmutableList(),
  onUpdate = () => null,
}) {
  const [open, setOpen] = useState(false);
  const { orderable } = orderItem;
  const selectedProfile = profiles.first();
  const { tentative_details: tentativeDetails } = orderable;
  const scheduledSessionCount = tentativeDetails.size || 0;
  const regPkg = orderItem.orderable;
  const price = regPkg.full_price / regPkg.quantity / 100;
  const eventDiscount = membershipEventDiscounts?.findLast(
    m => m.membership_id === selectedProfile?.active_membership_id
  );
  const hasStaff = Boolean(event?.getSchedule().customer_user_ids.size);

  const handleAutomationOptionSelected = useCallback(
    (_, __, uuid) => {
      const selectedPackage = packagePricing.template_parameters.find(
        p => p.get('uuid') === uuid
      );
      const pkg = orderItem.orderable
        .set('automation_option_uuid', uuid)
        .set('quantity', selectedPackage.get('quantity'));

      onUpdate(orderItem.id, pkg);
    },
    [
      onUpdate,
      orderItem.id,
      orderItem.orderable,
      packagePricing?.template_parameters,
    ]
  );

  const handleDefaultOptionSelected = useCallback(
    (_, __, size) => {
      const pkg = orderItem.orderable
        .set('quantity', size)
        .set('automation_option_uuid', null)
        .set('automation_template_description_id', null);

      onUpdate(orderItem.id, pkg);
    },
    [onUpdate, orderItem.id, orderItem.orderable]
  );

  const handleTentativeDelete = useCallback(
    index => {
      const pkg = orderItem.orderable.set(
        'tentative_details',
        tentativeDetails.remove(index)
      );
      onUpdate(orderItem.id, pkg);
    },
    [onUpdate, orderItem.id, orderItem.orderable, tentativeDetails]
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const getDetails = () => (
    <>
      {tentativeDetails.size === 0 && (
        <Typography>{t('.no_session', intl, __filenamespace)}</Typography>
      )}
      <Box sx={styles.datesContainer}>
        {tentativeDetails.map((detail, index) => (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={styles.date}>
              {moment(detail.starts_at).format('MM/DD/YYYY')}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={styles.timeContainer}
            >
              <Typography sx={styles.startTime}>
                {formatClientTime(detail.starts_at, 'h:mma')}
              </Typography>
              <Typography sx={styles.timeSeparator}>&nbsp;-&nbsp;</Typography>
              <Typography sx={styles.endTime}>
                {formatClientTime(detail.ends_at, 'h:mma')}
              </Typography>
            </Stack>
            {detail.staff_ids.length > 0 && (
              <>
                {detail.staff_ids.map(s => (
                  <Typography key={s} style={styles.staffName(showEditingIfo)}>
                    {staff.get(s)?.name()}
                  </Typography>
                ))}
              </>
            )}
            {detail.staff_ids.length === 0 && hasStaff && (
              <Typography style={styles.staffName(showEditingIfo)}>
                {t('.any_available', intl, __filenamespace)}
              </Typography>
            )}
            {showEditingIfo && (
              <IconButton
                sx={styles.deleteBtn}
                onClick={() => handleTentativeDelete(index)}
              >
                <Close />
              </IconButton>
            )}
          </Stack>
        ))}
      </Box>
    </>
  );

  return (
    <Box sx={styles.cardContent}>
      {showEditingIfo && (
        <Stack spacing={1}>
          {selectedProfile && (
            <Stack>
              <Typography sx={styles.label}>
                {t('.profile_selected', intl, __filenamespace)}
              </Typography>
              <Stack
                key={selectedProfile.get('id')}
                direction="row"
                alignItems="center"
              >
                <UserAvatar user={selectedProfile} />
                <Typography>{selectedProfile.name()}</Typography>
              </Stack>
            </Stack>
          )}
          <Stack>
            <Typography sx={styles.label}>
              {t('.scheduled_session', intl, __filenamespace, {
                scheduledSessionCount,
              })}
            </Typography>
            {getDetails()}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography sx={styles.label}>
              {t('.credit_need', intl, __filenamespace, {
                scheduledSessionCount,
              })}
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              {t('.credit_need_info', intl, __filenamespace, {
                scheduledSessionCount,
              })}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={styles.label}>
              {t('.select_pkg_label', intl, __filenamespace)}
            </Typography>
            <PackageSelector
              discount={eventDiscount && eventDiscount.discount}
              packagePricingDescription={packagePricing}
              price={price}
              selectedValue={
                packagePricing ? regPkg.automation_option_uuid : regPkg.quantity
              }
              registrationPackage={regPkg}
              minPackageValue={
                tentativeDetails.size > 0 ? tentativeDetails.size : undefined
              }
              maxPackageValue={
                tentativeDetails.size > 0
                  ? tentativeDetails.size + 10
                  : undefined
              }
              onAutomationOptionSelect={handleAutomationOptionSelected}
              onDefaultOptionSelect={handleDefaultOptionSelected}
            />
          </Stack>
        </Stack>
      )}
      {!showEditingIfo && (
        <List sx={{ padding: 0 }}>
          <ListItemButton sx={{ padding: 0 }} onClick={handleClick}>
            <ListItemText
              primaryTypographyProps={{
                component: 'div',
              }}
              primary={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography sx={styles.label}>
                    {orderItem.secondary_display_accent_text}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography sx={styles.label}>
                      {t('.view_details', intl, __filenamespace)}
                    </Typography>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Stack>
                </Stack>
              }
            />
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {getDetails()}
          </Collapse>
        </List>
      )}
    </Box>
  );
}

OpenBookingCardContent.propTypes = {
  intl: PropTypes.object,
  profiles: PropTypes.instanceOf(ImmutableList),
  orderItem: PropTypes.instanceOf(OrderItem),
  staff: PropTypes.instanceOf(Map),
  showEditingIfo: PropTypes.bool,
  packagePricing: PropTypes.instanceOf(PerSessionPricingDescription),
  membershipEventDiscounts: PropTypes.instanceOf(ImmutableList),
  onUpdate: PropTypes.func,
};

export default compose(injectIntl, memo)(OpenBookingCardContent);
