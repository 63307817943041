import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ChatBubble({ color = '#9A9EAD', ...restProps }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 21 24" {...restProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-118.000000, -415.000000)" fill={color}>
          <g transform="translate(91.000000, 388.000000)">
            <path
              d="M37.523,27 C31.737,27 27.046,31.701 27.046,37.5 C27.046,39.718 27.737,41.772 28.909,
               43.467 L27,51 L37.084,47.978 C37.23,47.984 37.375,48 37.523,48 C43.309,48 48,43.299
               48,37.5 C48,31.701 43.309,27 37.523,27 M37.523,30 C41.646,30 45,33.364 45,37.5 C45,
               41.635 41.646,45 37.545,45 L37.456,44.995 C37.374,44.989 37.292,44.984 37.209,44.98
               L36.705,44.959 L36.222,45.104 L31.208,46.607 L31.816,44.204 L32.151,42.883 L31.377,
               41.762 C30.506,40.503 30.046,39.029 30.046,37.5 C30.046,33.364 33.401,30 37.523,30"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ChatBubble;
