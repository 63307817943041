import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';
import {
  Drawer,
  Grid,
  MultipleSelect,
  Spinner,
  TextField,
  CurrencyTextField,
  Button,
} from '@upperhand/playmaker';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { Filters, FilterPaymentType } from 'containers/reports/types';

import { hasFilterDrawerDefaults } from '../utils';
import BalanceReportActions from '../Actions';
import TimeRangeDropdown from './Header/TimeRangeDropdown.jsx';

const getRecordLabelAndValue = recordList =>
  recordList
    .map(r => {
      // Our record types are inconsistent in how "name" is accessed.
      const recordName = typeof r.name === 'function' ? r.name() : r.name;

      return {
        label: recordName || '',
        value: r.id,
      };
    })
    .toArray();

function FilterDrawer({
  defaultFilters,
  filterErrors,
  filters,
  intl,
  isChanged,
  locations,
  isLoadingLocation = false,
  isOpen = false,
  reportMode = true,
}) {
  const handleProductChange = event =>
    BalanceReportActions.updateFilter(Filters.PRODUCT, event.target.value);

  const handleProductTypeChange = event =>
    BalanceReportActions.updateFilter(Filters.PRODUCT_TYPE, event.target.value);

  const handlePaymentTypeChange = value =>
    BalanceReportActions.updateFilter(Filters.PAYMENT_TYPES, value);

  const handleLocationChange = value =>
    BalanceReportActions.updateFilter(Filters.LOCATION, value);

  const handleBalanceRemainingMinChange = (event, value) =>
    BalanceReportActions.updateFilter(
      Filters.BALANCE_REMAINING_MIN,
      parseFloat(value)
    );

  const handleBalanceRemainingMaxChange = (event, value) =>
    BalanceReportActions.updateFilter(
      Filters.BALANCE_REMAINING_MAX,
      parseFloat(value)
    );

  const locationItems = getRecordLabelAndValue(locations);
  const paymentTypeItems = Object.keys(FilterPaymentType)
    .slice(1)
    .map(key => ({
      label: t(
        `.payment_method_${FilterPaymentType[key]}`,
        intl,
        __filenamespace
      ),
      value: FilterPaymentType[key],
    }));

  return (
    <Drawer
      classes={{
        root: 'balances-report__filter-drawer',
        content: 'filter-drawer__content',
        footer: 'filter-drawer__footer',
      }}
      open={isOpen}
      onClose={BalanceReportActions.closeFilterDrawer}
      title={t('.title', intl, __filenamespace)}
      content={
        isLoggedIn() ? (
          <div>
            {isLoadingLocation ? (
              <FlexBoxJustifyCenter>
                <Spinner />
              </FlexBoxJustifyCenter>
            ) : (
              <Grid container xs={12} spacing={3} alignItems="center">
                {(isChanged ||
                  !hasFilterDrawerDefaults(filters, reportMode)) && (
                  <Grid justify="flex-end" container item xs={12}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a
                      className="filter-drawer__clear"
                      onClick={BalanceReportActions.clearFilters}
                    >
                      {t('.clear', intl, __filenamespace)}
                    </a>
                  </Grid>
                )}
                {reportMode ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label={t('.product', intl, __filenamespace)}
                        placeholder={t(
                          '.product_placeholder',
                          intl,
                          __filenamespace
                        )}
                        fullWidth
                        rounded={false}
                        outline
                        onChange={handleProductChange}
                        value={
                          filters.get('product') ||
                          defaultFilters.get('product')
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t('.product_type', intl, __filenamespace)}
                        placeholder={t(
                          '.product_type_placeholder',
                          intl,
                          __filenamespace
                        )}
                        fullWidth
                        rounded={false}
                        outline
                        onChange={handleProductTypeChange}
                        value={
                          filters.get('productType') ||
                          defaultFilters.get('productType')
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <TimeRangeDropdown
                      intl={intl}
                      onChange={value =>
                        BalanceReportActions.updateFilter(Filters.PERIOD, value)
                      }
                      value={
                        filters.get('period')
                          ? filters.get('period').value
                          : defaultFilters.get('period').value
                      }
                      reportMode={false}
                      label={t('.date_range', intl, __filenamespace)}
                    />
                  </Grid>
                )}

                <Grid item xs>
                  <MultipleSelect
                    items={paymentTypeItems}
                    name={t('.payment_type_placeholder', intl, __filenamespace)}
                    label={t('.payment_types', intl, __filenamespace)}
                    fullWidth
                    rounded={false}
                    outline
                    onChange={handlePaymentTypeChange}
                    selectedItems={
                      filters.get('paymentTypes') ||
                      defaultFilters.get('paymentTypes')
                    }
                  />
                </Grid>
                <Grid item xs>
                  <MultipleSelect
                    fullWidth
                    items={locationItems}
                    name={t('.location_placeholder', intl, __filenamespace)}
                    label={t('.location', intl, __filenamespace)}
                    rounded={false}
                    outline
                    selectedItems={
                      filters.get('location') || defaultFilters.get('location')
                    }
                    selectAll
                    onChange={handleLocationChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CurrencyTextField
                    label={t('.balance_remaining_min', intl, __filenamespace)}
                    placeholder={t(
                      '.balance_remaining_min_placeholder',
                      intl,
                      __filenamespace
                    )}
                    fullWidth
                    rounded={false}
                    outline
                    onBlur={handleBalanceRemainingMinChange}
                    value={
                      filters.get('balanceRemainingMin') ??
                      defaultFilters.get('balanceRemainingMin') ??
                      ''
                    }
                    errorText={filterErrors.getErrors(
                      'balanceRemainingMin',
                      intl
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CurrencyTextField
                    label={t('.balance_remaining_max', intl, __filenamespace)}
                    placeholder={t(
                      '.balance_remaining_max_placeholder',
                      intl,
                      __filenamespace
                    )}
                    fullWidth
                    rounded={false}
                    outline
                    onBlur={handleBalanceRemainingMaxChange}
                    value={
                      filters.get('balanceRemainingMax') ??
                      defaultFilters.get('balanceRemainingMax') ??
                      ''
                    }
                    errorText={filterErrors.getErrors(
                      'balanceRemainingMax',
                      intl
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        ) : (
          <SignInPrompt />
        )
      }
      footer={
        isLoggedIn() ? (
          <Button
            classes={{
              root: 'filter-drawer__footer-button',
              disabled: 'filter-drawer__footer-button-disabled',
              label: 'filter-drawer__footer-button-label',
            }}
            disabled={!isChanged}
            fullWidth
            onClick={BalanceReportActions.applyFilters}
            size={40}
            type="primary"
          >
            {t('.btn_apply', intl, __filenamespace)}
          </Button>
        ) : null
      }
    />
  );
}

FilterDrawer.propTypes = {
  defaultFilters: PropTypes.instanceOf(Map).isRequired,
  filterErrors: PropTypes.instanceOf(FieldErrors).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  intl: PropTypes.object.isRequired,
  locations: PropTypes.instanceOf(Map).isRequired,
  isChanged: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
  isLoadingLocation: PropTypes.bool,
  reportMode: PropTypes.bool,
};

export default injectIntl(FilterDrawer);
