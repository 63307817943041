import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Map, OrderedSet } from 'immutable';
import { TextField, Dropdown, Grid, Button } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t, messageId } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';
import { VIEW_MODES } from 'containers/classes/classesList/Store';

import {
  StaffDataStore,
  ResourceDataStore,
  LocationDataStore,
} from 'dataStores';

function AdvancedSearch({
  viewMode = 'sessions',
  classId = null,
  searchValue = '',
  staffFilter = 'all',
  resourceFilter = 'all',
  locationFilter = 'all',
  staffIds = OrderedSet(),
  resourcesIds = OrderedSet(),
  locationsIds = OrderedSet(),
  staffDataStore = { staff: Map() },
  resourceDataStore = { records: Map() },
  locationDataStore = { records: Map() },
  onSearchChange = () => null,
  onStaffFilterChange = () => null,
  onResourceFilterChange = () => null,
  onLocationFilterChange = () => null,
  onChangeMode = () => null,
  intl,
}) {
  const { staff } = staffDataStore;
  const { records: resourcesList } = resourceDataStore;
  const { records: locationsList } = locationDataStore;
  const staffItems = staffIds.size
    ? staffIds
        .map(id => ({
          label: (
            <FormattedMessage
              id={messageId('.staff', __filenamespace)}
              values={{ name: staff.get(id, { name: () => '' }).name() }}
            />
          ),
          value: id,
        }))
        .toArray()
    : [];

  const resourceItems = resourcesIds
    .map(id => ({
      label: (
        <FormattedMessage
          id={messageId('.resource', __filenamespace)}
          values={{ name: resourcesList?.get(id)?.name }}
        />
      ),
      value: id,
    }))
    .toArray();

  const locationsItems = locationsIds
    .map(id => ({
      label: (
        <FormattedMessage
          id={messageId('.location', __filenamespace)}
          values={{ name: locationsList?.get(id)?.name }}
        />
      ),
      value: id,
    }))
    .toArray();

  return (
    <div className="classes-list__search-container">
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={1}
        xs={12}
      >
        <Grid item xs={12} sm={4}>
          {viewMode === VIEW_MODES.SESSIONS && (
            <TextField
              fullWidth
              rounded
              outline={false}
              icon="search"
              placeholder="Search by attendee name"
              value={searchValue}
              onChange={onSearchChange}
            />
          )}
          {viewMode === VIEW_MODES.SCHEDULES && (
            <Dropdown
              id="location"
              name="location"
              fullWidth
              classes={{ selectedItem: 'elipsis' }}
              items={[
                {
                  label: t('.location_all', intl, __filenamespace),
                  value: 'all',
                },
              ].concat(locationsItems)}
              onChange={onLocationFilterChange}
              outline={false}
              rounded
              value={locationFilter}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            id="staff"
            name="staff"
            fullWidth
            classes={{ selectedItem: 'elipsis' }}
            items={[
              {
                label: t('.staff_all', intl, __filenamespace),
                value: 'all',
              },
            ].concat(staffItems)}
            onChange={onStaffFilterChange}
            outline={false}
            rounded
            value={staffFilter}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            id="resources"
            name="resources"
            fullWidth
            classes={{ selectedItem: 'elipsis' }}
            items={[
              {
                label: t('.resource_all', intl, __filenamespace),
                value: 'all',
              },
            ].concat(resourceItems)}
            onChange={onResourceFilterChange}
            outline={false}
            rounded
            value={resourceFilter}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            rounded
            type="secondary"
            classes={{ root: 'view-schedule' }}
            disabled={!classId}
            onClick={onChangeMode}
          >
            {viewMode === VIEW_MODES.SESSIONS
              ? t('.view_schedule', intl, __filenamespace)
              : t('.view_sessions', intl, __filenamespace)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

AdvancedSearch.propTypes = {
  intl: PropTypes.object.isRequired,
  classId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchValue: PropTypes.string,
  viewMode: PropTypes.string,
  staffFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resourceFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  staffIds: PropTypes.instanceOf(OrderedSet),
  resourcesIds: PropTypes.instanceOf(OrderedSet),
  locationsIds: PropTypes.instanceOf(OrderedSet),
  staffDataStore: PropTypes.shape({ staff: PropTypes.instanceOf(Map) }),
  resourceDataStore: PropTypes.shape({ records: PropTypes.instanceOf(Map) }),
  locationDataStore: PropTypes.shape({ records: PropTypes.instanceOf(Map) }),
  onSearchChange: PropTypes.func,
  onStaffFilterChange: PropTypes.func,
  onResourceFilterChange: PropTypes.func,
  onLocationFilterChange: PropTypes.func,
  onChangeMode: PropTypes.func,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      staffDataStore: StaffDataStore,
      resourceDataStore: ResourceDataStore,
      locationDataStore: LocationDataStore,
    },
  })
)(AdvancedSearch);
