import * as React from 'react';
import PropTypes from 'prop-types';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import BaseWaitlistDrawer from 'event_mgmt/display/components/waitlist_drawer/WaitlistDrawer.jsx';
import BrowseEventsActions from 'containers/browseEvents/Actions';
import BrowseEventsStore from 'containers/browseEvents/Store';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import PDRegistrationPackageStore from 'event_mgmt/display/stores/PurchaseDrawerRegistrationPackageStore.jsx';
import WaitlistStore from 'event_mgmt/shared/stores/WaitlistStore.jsx';

import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';

function WaitlistDrawer({
  athletesStore = {},
  browseEventsStore = {},
  eventStore = {},
  pdRegistrationPackageStore = {},
  waitlistStore = {},
}) {
  const { allAthletes } = athletesStore;
  const { waitlistDrawerOpen } = browseEventsStore;

  const getAuthorizedProfiles = () => {
    if (eventStore.customerEvent.isMembershipExclusive()) {
      return allAthletes.filter(a => a.hasAccessTo(eventStore.customerEvent));
    }
    return allAthletes;
  };
  return (
    <BaseWaitlistDrawer
      open={waitlistDrawerOpen}
      athletes={getAuthorizedProfiles()}
      event={eventStore}
      onRequestClose={BrowseEventsActions.closeWaitListDrawer}
      pdRegistrationPackageStore={pdRegistrationPackageStore}
      waitlistStore={waitlistStore}
      width={smallScreen() ? window.innerWidth * 0.9 : STANDARD_DRAWER_WIDTH}
    />
  );
}

WaitlistDrawer.propTypes = {
  athletesStore: PropTypes.object,
  browseEventsStore: PropTypes.object,
  eventStore: PropTypes.object,
  waitlistStore: PropTypes.object,
  pdRegistrationPackageStore: PropTypes.object,
};

export default compose(
  altContainer({
    stores: {
      athletesStore: AthleteStore,
      browseEventsStore: BrowseEventsStore,
      eventStore: EventStore,
      pdRegistrationPackageStore: PDRegistrationPackageStore,
      waitlistStore: WaitlistStore,
    },
  })
)(WaitlistDrawer);
