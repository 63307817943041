import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@upperhand/playmaker';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils.js';

const styles = {
  firstButton: {
    minWidth: 216,
  },
};

function ConfirmationButtons({
  isActive = true,
  classes,
  onConfirm,
  onCancel,
}) {
  return (
    <>
      <Button
        classes={{
          root: classes.firstButton,
        }}
        disabled={!isActive}
        fullWidth
        onClick={onConfirm}
        size={40}
        textTransform="uppercase"
        type="primary"
      >
        <FormattedMessage id={messageId('.save', __filenamespace)} />
      </Button>
      <Button fullWidth type="tertiary" size={40} onClick={onCancel}>
        <FormattedMessage id={messageId('.cancel', __filenamespace)} />
      </Button>
    </>
  );
}

ConfirmationButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), React.memo)(ConfirmationButtons);
