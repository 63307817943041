import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@upperhand/playmaker';

function FiltersBlock({ title = '', children = null }) {
  return (
    <Grid container direction="column" spacing={2} className="filters-block">
      <Grid item xs={12}>
        <Typography variant="fieldLabel" className="title">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

FiltersBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default FiltersBlock;
