import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Spinner } from '@upperhand/playmaker';
import clsx from 'clsx';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import {
  ClientDataStore,
  LocationDataStore,
  BalancesDataStore,
} from 'dataStores';
import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import PaymentPlanDetailsDrawer from 'containers/reports/paymentPlanDetailsDrawer/PaymentPlanDetailsDrawer.jsx';

import Header from './components/Header/Header.jsx';
import Table from './components/Table/Table.jsx';
import ColumnDrawer from './components/ColumnDrawer.jsx';
import FilterDrawer from './components/FilterDrawer.jsx';
import PaymentDetailsDrawer from '../paymentDetailsDrawer/PaymentDetailsDrawer.jsx';

import BalancesReportActions from './Actions';
import BalancesReportStore from './Store';

import './styles.scss';

function BalancesReport({
  balancesDataStore = {},
  balancesReportStore = {},
  clientDataStore = {},
  eventId = null,
  locationDataStore = {},
  reportMode = true,
  revenue = null,
}) {
  const isMobile = smallScreen();

  React.useEffect(() => {
    BalancesReportActions.mounted({ isMobile, eventId, reportMode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, reportMode]);

  const { balances } = balancesDataStore;
  const {
    activeFilters,
    balanceIds,
    columns,
    defaultFilters,
    drawerFilters,
    filterErrors,
    isColumnDrawerOpen,
    isFilterDrawerChanged,
    isFilterDrawerOpen,
    isLoadingClients,
    isLoadingLocation,
    isLoadingReport,
    isLoadingStatistics,
    page,
    perPage,
    statistics,
    totalCount,
    scheduledPayments,
  } = balancesReportStore;
  const { clients } = clientDataStore;
  const { records: locations } = locationDataStore;

  return (
    <div
      className={clsx(
        'balances-report',
        isMobile && 'balances-report__mobile',
        !reportMode && 'balances-report__payment'
      )}
    >
      {isLoadingClients && isLoadingReport && isLoadingStatistics ? (
        <FlexBoxJustifyCenter>
          <Spinner />
        </FlexBoxJustifyCenter>
      ) : (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <Header
              defaultFilters={defaultFilters}
              filters={activeFilters}
              reportMode={reportMode}
              showHeaderTitle={!eventId}
              statistics={statistics}
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              filters={activeFilters}
              isLoadingReport={isLoadingReport || isLoadingClients}
              balanceIds={balanceIds}
              balances={balances}
              page={page}
              perPage={perPage}
              reportMode={reportMode}
              totalCount={totalCount}
              statistics={statistics}
              columns={columns}
              clients={clients}
              revenue={revenue}
              scheduledPayments={scheduledPayments}
            />
          </Grid>
        </Grid>
      )}
      <BalanceDetailsDrawer />
      <PaymentDetailsDrawer />
      <ColumnDrawer columns={columns} isOpen={isColumnDrawerOpen} />
      <FilterDrawer
        defaultFilters={defaultFilters}
        filterErrors={filterErrors}
        filters={drawerFilters}
        isChanged={isFilterDrawerChanged}
        isLoadingLocation={isLoadingLocation}
        isOpen={isFilterDrawerOpen}
        locations={locations}
        reportMode={reportMode}
      />
      <PaymentPlanDetailsDrawer withBackLink />
      <QuickpayModal />
      <RefundModal />
    </div>
  );
}

BalancesReport.propTypes = {
  balancesDataStore: PropTypes.object,
  balancesReportStore: PropTypes.object,
  clientDataStore: PropTypes.object,
  locationDataStore: PropTypes.object,
  reportMode: PropTypes.bool,
};

export default altContainer({
  stores: {
    balancesDataStore: BalancesDataStore,
    balancesReportStore: BalancesReportStore,
    clientDataStore: ClientDataStore,
    locationDataStore: LocationDataStore,
  },
})(BalancesReport);
