import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import { Map } from 'immutable';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Client from 'shared/records/Client.jsx';
import { t } from 'shared/utils/LocaleUtils';

function Info({ intl, statistics, totalCount, client = null }) {
  return (
    <div className="info">
      <Grid className="info__header" container spacing={1}>
        <Grid item xs={12}>
          <div className="info__title">
            <Typography align="center" variant="body1">
              {t('.header', intl, __filenamespace)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            <FormattedCurrency
              value={Math.abs(statistics.get('outstanding_balance')) || 0}
              fromCents
            />
          </Typography>
        </Grid>
      </Grid>
      <ClientDetails client={client} />
      <div className="info__card">
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Typography className="info__title" variant="subtitle2">
              {t('.title', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.quantity', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {totalCount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.total', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                <FormattedCurrency
                  value={
                    (statistics.get('total_paid') || 0) -
                    (statistics.get('outstanding_balance') || 0)
                  }
                  fromCents
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.paid', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                <FormattedCurrency
                  value={statistics.get('total_paid') || 0}
                  fromCents
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Info.propTypes = {
  client: PropTypes.instanceOf(Client),
  intl: PropTypes.object.isRequired,
  statistics: PropTypes.instanceOf(Map).isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default Info;
