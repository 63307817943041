import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import { StaffDataStore } from 'dataStores';

const getStaffName = ({ intl, firstName = '', lastName = '' }) => {
  if (firstName === '') {
    return '';
  }
  if (lastName === '') {
    return firstName;
  }
  return t('.name', intl, __filenamespace, {
    firstName,
    lastName: lastName[0],
  });
};

function StaffDisplay({ intl, staffId, staffDataStore = {} }) {
  const { staff } = staffDataStore;
  const staffMember = staff.get(staffId);

  if (!staffMember) {
    return null;
  }

  return (
    <Grid spacing={1} container alignItems="center">
      <Grid item>
        <UserAvatar user={staffMember} />
      </Grid>
      <Grid item>
        <Typography variant="drawerTitle">
          {getStaffName({
            intl,
            firstName: staffMember.first_name,
            lastName: staffMember.last_name,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}

StaffDisplay.propTypes = {
  intl: PropTypes.object.isRequired,
  staffId: PropTypes.number.isRequired,
  staffDataStore: PropTypes.object,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      staffDataStore: StaffDataStore,
    },
  })
)(StaffDisplay);
