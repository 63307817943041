import * as React from 'react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import AltContainer from 'alt-container';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Divider from '@mui/material/Divider';
import Close from '@mui/icons-material/Close';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import MessageWindowStore from 'shared/stores/MessageWindowStore.jsx';

import uhTheme from '_uh_theme.jsx';

const message = (intl, messages) =>
  messages.map(e => (e == null ? '' : e.toString({ intl }))).join(' ');

const styles = {
  snackBar: {
    zIndex: 9999,
  },
  pb: {
    paddingBottom: '10px',
  },
  pt: {
    paddingTop: '10px',
  },
  p0: {
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
  },
  actionBtn: {
    fontSize: '14px',
    width: '140px',
  },
  backDrop: {
    zIndex: 9998,
  },
  paper: {
    padding: '10px',
  },
  closeIcon: {
    color: uhTheme.palette.default.main,
  },
};

function Content({
  intl,
  autoHideDuration = 600000,
  onActionTouchTap = MessageWindowActions.ackMessage,
  onRequestClose = MessageWindowActions.ackMessage,
  messages = List(),
  open = false,
  settings = {},
}) {
  const { position, actions, primaryText, secondaryText } = settings;

  if (actions.length) {
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: position.vertical || 'bottom',
            horizontal: position.horizontal || 'center',
          }}
          open={open}
          onClose={onRequestClose}
          autoHideDuration={autoHideDuration}
          sx={styles.snackBar}
        >
          <Paper sx={styles.paper}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={styles.pb}
            >
              <Typography sx={styles.title}>
                {message(intl, messages)}
              </Typography>
              <IconButton sx={styles.p0} onClick={onActionTouchTap}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />
            <Stack spacing={1} sx={{ ...styles.pb, ...styles.pt }}>
              {primaryText && <Typography>{primaryText}</Typography>}
              {secondaryText && <Typography>{secondaryText}</Typography>}
            </Stack>
            <Stack direction="row" spacing={1}>
              {actions.map((a, i) => (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  variant={i % 2 === 0 ? 'outlined' : 'contained'}
                  color="dark"
                  sx={styles.actionBtn}
                  onClick={e => {
                    e.preventDefault();
                    a.action();
                    onActionTouchTap(e);
                  }}
                >
                  {a.label}
                </Button>
              ))}
            </Stack>
          </Paper>
        </Snackbar>
        <Backdrop open={open} sx={styles.backDrop} />
      </>
    );
  }

  return (
    <Snackbar
      action={
        <IconButton onClick={onActionTouchTap}>
          <Close sx={styles.closeIcon} />
        </IconButton>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={onRequestClose}
      message={message(intl, messages)}
      autoHideDuration={autoHideDuration}
    />
  );
}

Content.propTypes = {
  intl: PropTypes.object.isRequired,
  autoHideDuration: PropTypes.number,
  messages: PropTypes.instanceOf(List),
  onActionTouchTap: PropTypes.func,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
  settings: PropTypes.object,
};

function MessageWindow({ intl }) {
  return (
    <AltContainer store={MessageWindowStore}>
      <Content intl={intl} />
    </AltContainer>
  );
}

export default injectIntl(MessageWindow);
