import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import {
  Grid,
  Dropdown,
  CurrencyTextField,
  RadioButton,
  Typography,
} from '@upperhand/playmaker';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import EventTypeEditor from 'components/EventTypeEditor';
import DiscountItemsSelector from 'memberships/components/DiscountsSelector/DiscountItemsSelector.jsx';
import SelectedDiscountsList from 'memberships/components/DiscountsSelector/SelectedDiscountsList.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils';
import { t } from 'shared/utils/LocaleUtils';
import {
  getParams,
  getUnselectedItems,
} from 'memberships/components/DiscountsSelector/helpers';

import MembershipRetailDiscount from 'shared/records/MembershipRetailDiscount.jsx';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';
import MembershipCreditPassDiscount from 'shared/records/MembershipCreditPassDiscount.jsx';
import Discount from 'shared/records/Discount.js';
import Membership, {
  MEMBERSHIP_DISCOUNTS_TYPES,
} from 'shared/records/Membership.jsx';
import MembershipTier from 'shared/records/MembershipTier';

function DiscountContent({
  index,
  actions,
  discount,
  params,
  membership,
  eventTypes,
  creditPasses,
  retailCategories,
}) {
  const intl = useIntl();

  if (!discount) return null;

  const isEventDiscount = discount instanceof MembershipEventDiscount;
  const isCreditPassDiscount = discount instanceof MembershipCreditPassDiscount;
  const isRetailDiscount = discount instanceof MembershipRetailDiscount;

  let unselectedItems = List();

  if (isEventDiscount) {
    unselectedItems = getUnselectedItems(
      membership,
      MembershipEventDiscount,
      params.filedIds,
      eventTypes
    );
  }

  if (isCreditPassDiscount) {
    unselectedItems = getUnselectedItems(
      membership,
      MembershipCreditPassDiscount,
      params.filedIds,
      creditPasses
    );
  }

  if (isRetailDiscount) {
    unselectedItems = getUnselectedItems(
      membership,
      MembershipRetailDiscount,
      params.filedIds,
      retailCategories
    );
  }

  const handleItemsSelect = e => {
    const updatedItems = discount
      .get(params.filedIds)
      .add(e.target !== undefined ? e.target.value : e);

    actions.discountFieldChanged({
      index,
      field: params.filedIds,
      value: updatedItems,
    });
  };

  const handleItemDelete = id => {
    const updatedItems = discount.get(params.filedIds).remove(id);

    actions.discountFieldChanged({
      index,
      field: params.filedIds,
      value: updatedItems,
    });
  };

  return (
    <>
      <Typography variant="fieldLabel">
        {t('.how_should_apply', intl, __filenamespace)}
      </Typography>
      <RadioButton
        name={params.field}
        orientation="horizontal"
        selectedValue={params.allValue}
        onChange={() => {
          actions.discountFieldChanged({
            index,
            field: params.field,
            value: !params.allValue,
          });
        }}
        options={params.options}
      />
      {isEventDiscount && !params.allValue && (
        <>
          <EventTypeEditor
            eventTypes={unselectedItems}
            onCreateOrUpdateSuccess={handleItemsSelect}
            onEventTypeSelect={handleItemsSelect}
            placeholder={t('.choose_event_type', intl, __filenamespace)}
            errorText=""
          />
          <SelectedDiscountsList
            items={eventTypes}
            discount={discount}
            discountField={params.filedIds}
            onDelete={handleItemDelete}
          />
        </>
      )}
      {isCreditPassDiscount && !params.allValue && (
        <>
          <DiscountItemsSelector
            name={params.filedIds}
            placeholder={t('.choose_credit_pass', intl, __filenamespace)}
            items={unselectedItems}
            onItemSelect={handleItemsSelect}
          />
          <SelectedDiscountsList
            items={creditPasses}
            discount={discount}
            discountField={params.filedIds}
            onDelete={handleItemDelete}
          />
        </>
      )}
      {isRetailDiscount && !params.allValue && (
        <>
          <DiscountItemsSelector
            name={params.filedIds}
            placeholder={t('.choose_retail_category', intl, __filenamespace)}
            items={unselectedItems}
            onItemSelect={handleItemsSelect}
          />
          <SelectedDiscountsList
            items={retailCategories}
            discount={discount}
            discountField={params.filedIds}
            onDelete={handleItemDelete}
          />
        </>
      )}
    </>
  );
}

function DiscountCard({
  index = 0,
  actions = {},
  discount = new MembershipEventDiscount(),
  eventTypes = List(),
  creditPasses = List(),
  retailCategories = List(),
  membership = new Membership(),
}) {
  const intl = useIntl();

  if (!discount || !membership) return null;

  if (!currentCustomer().retail_enabled) {
    delete MEMBERSHIP_DISCOUNTS_TYPES.RETAIL;
  }

  const discountsLabels = Object.keys(MEMBERSHIP_DISCOUNTS_TYPES);
  const discountsTypes = Object.values(MEMBERSHIP_DISCOUNTS_TYPES).map(
    (type, i) => ({
      label: t(`.${discountsLabels[i]}`, intl, __filenamespace),
      value: type,
    })
  );
  const params = getParams(discount, membership);

  const handleDeleteDiscount = () => {
    actions.discountRemoved({
      index,
    });
  };

  return (
    <Paper className="membership-discounts__card">
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Dropdown
            fullWidth
            value={params.discountType}
            label={t('.discount_type', intl, __filenamespace)}
            items={discountsTypes}
            onChange={e =>
              actions.discountTypeChanged({
                index,
                newType: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={6}>
              <Dropdown
                fullWidth
                label={t('.discount_value', intl, __filenamespace)}
                disabled={discount instanceof MembershipRetailDiscount}
                value={discount.discount_type || discount.discount.type}
                items={[
                  {
                    label: t('.amount', intl, __filenamespace),
                    value: Discount.ABSOLUTE,
                  },
                  {
                    label: t('.percent', intl, __filenamespace),
                    value: Discount.PERCENT,
                  },
                ]}
                onChange={e =>
                  actions.discountFieldChanged({
                    index,
                    field: 'discount_type',
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              {discount.isPercentDiscount() ? (
                <CurrencyTextField
                  fullWidth
                  label="&nbsp;"
                  currencySymbol="%"
                  errorText={discount.errors.getErrors('value', intl)}
                  max={100}
                  min={0.0}
                  name="value"
                  onChange={(e, value) =>
                    actions.discountFieldChanged({
                      value,
                      index,
                      field: 'value',
                    })
                  }
                  value={discount.value}
                />
              ) : (
                <CurrencyTextField
                  fullWidth
                  label="&nbsp;"
                  currencySymbol="$"
                  errorText={discount.errors.getErrors('value', intl)}
                  min={0.0}
                  name="value"
                  onChange={(e, value) =>
                    actions.discountFieldChanged({
                      index,
                      field: 'value',
                      value: Math.round((value + Number.EPSILON) * 100),
                    })
                  }
                  value={discount.value / 100}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12}>
          <DiscountContent
            actions={actions}
            params={params}
            index={index}
            discount={discount}
            membership={membership}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
          />
        </Grid>
      </Grid>
      <IconButton
        className="membership-discounts__delete-btn"
        onClick={handleDeleteDiscount}
      >
        <Close />
      </IconButton>
    </Paper>
  );
}

DiscountCard.propTypes = {
  index: PropTypes.number,
  discount: PropTypes.oneOfType([
    PropTypes.instanceOf(MembershipRetailDiscount),
    PropTypes.instanceOf(MembershipEventDiscount),
    PropTypes.instanceOf(MembershipCreditPassDiscount),
  ]),
  eventTypes: PropTypes.instanceOf(List),
  creditPasses: PropTypes.instanceOf(List),
  retailCategories: PropTypes.instanceOf(List),
  membership: PropTypes.oneOfType([
    PropTypes.instanceOf(Membership),
    PropTypes.instanceOf(MembershipTier),
  ]),
};

export default memo(DiscountCard);
