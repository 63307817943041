import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomerTermsBanner from 'shared/components/_CustomerTermsBanner.jsx';
import {
  FlexBoxJustifyCenter,
  FlexBoxJustify,
} from 'shared/components/FlexBox.jsx';
import TermsTemplate from 'shared/components/_TermsTemplate.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Terms() {
  const navigate = useNavigate();
  return (
    <div style={{ marginBottom: 54 }}>
      <FlexBoxJustify style={{ height: 116 }}>
        <img
          alt="logo"
          src={window.customer.logo.url}
          style={{ paddingTop: 25, paddingBottom: 25 }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            aria-label="сlose"
            size="large"
            onClick={() => navigate(customerScopedRoute('/settings'))}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      </FlexBoxJustify>
      <CustomerTermsBanner />
      <FlexBoxJustifyCenter
        style={{
          marginTop: '61px',
          overflowY: 'scroll',
        }}
      >
        <TermsTemplate terms={window.customer.policy_and_terms} />
      </FlexBoxJustifyCenter>
    </div>
  );
}

export default Terms;
