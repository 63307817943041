import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

function DateRange({
  startTime = null,
  endTime = null,
  format = 'MMM D, YYYY',
}) {
  if (startTime.isSame(endTime, 'day')) {
    return <span>{startTime.format(format)}</span>;
  }
  return (
    <FormattedMessage
      id={messageId('.date_range', __filenamespace)}
      values={{
        start: startTime.format(format),
        end: endTime.format(format),
      }}
    />
  );
}

DateRange.propTypes = {
  /**
   * The start time of the date range.
   */
  startTime: PropTypes.instanceOf(moment),

  /**
   * The end time of the date range.
   */
  endTime: PropTypes.instanceOf(moment),

  /**
   * The format to display the dates in. Defaults to 'MMM, D YYYY'.
   */
  format: PropTypes.string,
};

export default DateRange;
