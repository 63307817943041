/* eslint-disable spaced-comment,react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';
import { Menu } from '@upperhand/playmaker';

import DefaultPreview from 'components/ImageUpload/components/DefaultPreview.jsx';
import ModalCrop from 'components/ImageUpload/components/ModalCrop.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { isUrl } from 'shared/utils/SharedUtils.js';
import { getCroppedImg } from 'components/ImageUpload/helpers.js';
import { Image } from 'records';

const IMAGE_FILE_ACCEPT = {
  'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.bmp', '.webp', '.svg'],
};

class ImageUpload extends PureComponent {
  state = {
    anchorEl: null,
    isModalOpen: false,
    uploadedImage: new Image(),
  };

  get preview() {
    const {
      cropAspect = null,
      image = null,
      preview = null,
      previewContainerHeight = null,
      previewContainerWidth = null,
      uploadContainerHeight = 64,
      uploadContainerWidth = null,
    } = this.props;

    return React.cloneElement(preview || <DefaultPreview />, {
      cropAspect,
      image,
      onPopupOpen: this.handlePopupOpen,
      previewContainerHeight,
      previewContainerWidth,
      uploadContainerHeight,
      uploadContainerWidth,
    });
  }

  get modal() {
    const { isModalOpen, uploadedImage } = this.state;
    const { cropAspect = null, intl = null, circularCrop = false } = this.props;
    const image = uploadedImage.getSrcForCropping();
    const imageSrc = isUrl(image)
      ? `${image}?uuid=${crypto.randomUUID()}`
      : image;

    if (uploadedImage.isBlank()) {
      return null;
    }

    return (
      <ModalCrop
        intl={intl}
        isOpen={isModalOpen}
        filename={uploadedImage.file?.name || uploadedImage.name || 'image'}
        image={imageSrc}
        circularCrop={circularCrop}
        cropAspect={cropAspect}
        onSuccess={this.handleCrop}
        onAbort={this.handleCloseModal}
        onUpload={this.handleUploadClick}
      />
    );
  }

  get actions() {
    const {
      disabled = false,
      cropAspect = null,
      onRemove = null,
      intl = null,
    } = this.props;
    const { anchorEl } = this.state;

    if (!this.hasImage() || disabled) {
      return null;
    }

    // If we can't remove image, we have only one item, so no need to show popup
    if (onRemove) {
      return (
        <Menu
          anchor={anchorEl}
          onClose={this.handlePopupClose}
          classes={{
            root: 'upload-container__actions',
          }}
          items={[
            {
              label: t('.menu_edit', intl, __filenamespace),
              onClick: event =>
                cropAspect
                  ? this.handleCropClick(event)
                  : this.handleUploadClick(event),
            },
            ...(onRemove
              ? [
                  {
                    label: t('.menu_remove', intl, __filenamespace),
                    onClick: this.handleDeleteClick,
                  },
                ]
              : []),
          ]}
        />
      );
    }

    return null;
  }

  hasImage = () => {
    const { image = null } = this.props;

    return image && !image.isBlank();
  };

  handleOnDrop = files => {
    const { cropAspect = null, onChange, publicFile } = this.props;

    const imageUrl = URL.createObjectURL(files[0]);
    const uploadedImage = new Image({
      file: files[0],
      url: imageUrl,
      originalData: imageUrl,
      public_file: publicFile,
    });

    if (cropAspect) {
      this.setState({ isModalOpen: true, uploadedImage });
    } else {
      onChange(uploadedImage);
    }
  };

  handlePopupOpen = event => {
    const { onRemove = null, cropAspect = null } = this.props;

    event.stopPropagation();
    if (onRemove) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    } else if (cropAspect) {
      this.handleCropClick(event);
    } else {
      this.handleUploadClick(event);
    }
  };

  handlePopupClose = event => {
    event.stopPropagation();

    this.setState({
      anchorEl: null,
    });
  };

  handleUploadClick = event => {
    this.handlePopupClose(event);
    this.dropzone.open();
  };

  handleCropClick = async event => {
    const { image = null } = this.props;

    event.stopPropagation();

    // In case we're using custom preview component, there're might be a situation,
    // when we'll call crop modal, when don't have an image
    if (!image || image.isBlank()) {
      this.handleUploadClick(event);
    } else {
      this.setState({
        isModalOpen: true,
        uploadedImage: image,
        anchorEl: null,
      });
    }
  };

  handleDeleteClick = event => {
    const { onRemove = null } = this.props;

    this.handlePopupClose(event);
    if (onRemove) {
      onRemove(new Image());
    }
  };

  handleCrop = async (imageDOM, crop) => {
    const { onChange, publicFile } = this.props;
    const { uploadedImage } = this.state;

    const newImage =
      crop.height === 0 && crop.width === 0
        ? uploadedImage
        : await getCroppedImg(imageDOM, crop, uploadedImage);

    this.setState({
      isModalOpen: false,
      uploadedImage: new Image({ public_file: publicFile }),
    });
    onChange(new Image({ ...newImage, public_file: publicFile }));
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      disabled = false,
      cropAspect = null,
      style = {},
      className = '',
    } = this.props;
    const hasImage = this.hasImage();

    return (
      <>
        <Dropzone
          ref={ref => {
            this.dropzone = ref;
          }}
          multiple={false}
          accept={IMAGE_FILE_ACCEPT}
          onDrop={this.handleOnDrop}
          style={{ display: 'none' }}
          noKeyboard
          noClick={hasImage}
          noDrag={hasImage}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={clsx(
                className,
                'upload-container',
                disabled && 'upload-container_disabled'
              )}
              style={style}
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <input {...getInputProps({ disabled: false })} />
              {this.actions}
              {this.preview}
            </div>
          )}
        </Dropzone>
        {cropAspect && this.modal}
      </>
    );
  }
}

ImageUpload.propTypes = {
  /**
   * Show the crop area as a circle. If your aspect is not 1 (a square) then the circle will be warped into an oval shape
   */
  circularCrop: PropTypes.bool,
  /**
   * The string with additional styles for container
   */
  className: PropTypes.string,
  /**
   * A float value that shows relation between width/height of the image.
   * It doesnt depend on prop height and width and is used only for cropping.
   * If you want the get square (for avatar), you have to pass 1.
   */
  cropAspect: PropTypes.number,
  /**
   * Is used for blocking different click actions
   */
  disabled: PropTypes.bool,
  /**
   * The src for showing image
   */
  image: PropTypes.instanceOf(Image),
  intl: PropTypes.object,
  /**
   * The callback, that is called when image was changed
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The callback, that is called when image was deleted. Returns Image() record
   */
  onRemove: PropTypes.func,
  /**
   * The component, that is used for showing image.
   * If it wasn't passed, the default one will be shown
   */
  preview: PropTypes.element,
  /**
   * The height of preview image container. Values should be in px
   */
  previewContainerHeight: PropTypes.number,
  /**
   * The width of preview image container. Values should be in px
   */
  previewContainerWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * The object with additional styles for container
   */
  style: PropTypes.object,
  /**
   * The height of upload image container. Values should be in px
   */
  uploadContainerHeight: PropTypes.number,
  /**
   * The width of upload image container. Values should be in px
   */
  uploadContainerWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default injectIntl(ImageUpload);
