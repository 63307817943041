import * as React from 'react';
import { Map } from 'immutable';
import { TextField, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  wrapper: { marginBottom: '0.5rem' },
  avatar: { marginRight: '0.75em' },
  buttonWrapper: { alignSelf: 'flex-end' },
  button: { padding: '0', height: '20px', width: '20px' },
  icon: { height: '20px', width: '20px' },
  warning: { marginBottom: 20 },
};

function Warning({ warning }) {
  if (warning) {
    return <div style={styles.warning}>{warning}</div>;
  }
  return null;
}

function SelectedProfileList({
  intl,
  athletes,
  onRemove,
  style,
  embedErrors,
  warnings = Map(),
  showEmbedCardInput = false,
  embedCardIds = Map(),
  onEmbedCardChange = () => null,
  onEmbedInputBlur = () => null,
}) {
  return (
    <div style={style}>
      {athletes.size > 0 && showEmbedCardInput ? (
        <div style={{ marginBottom: 10 }}>
          <Typography>{t('.embed_note', intl, __filenamespace)}</Typography>
        </div>
      ) : null}
      {athletes.map(athlete => (
        <div key={athlete.id}>
          <FlexBoxJustify key={athlete.id} style={styles.wrapper}>
            <FlexBoxCenter>
              <UserAvatar user={athlete} style={styles.avatar} />
              {athlete.name()}
            </FlexBoxCenter>
            <div style={styles.buttonWrapper}>
              <IconButton
                iconStyle={styles.icon}
                onClick={() => onRemove(athlete.id)}
                style={styles.button}
              >
                <ClearIcon />
              </IconButton>
            </div>
          </FlexBoxJustify>
          {showEmbedCardInput ? (
            <div style={{ marginBottom: 10 }}>
              <TextField
                fullWidth
                errorText={
                  embedErrors
                    ? embedErrors?.getErrors(
                        embedCardIds.get(athlete.id.toString(), ''),
                        intl
                      )
                    : ''
                }
                placeholder={t(
                  '.embed_input_placeholder',
                  intl,
                  __filenamespace
                )}
                value={embedCardIds.get(athlete.id.toString(), '')}
                onChange={e => {
                  onEmbedCardChange({
                    customerId: athlete.id,
                    embedCardId: e.target.value,
                  });
                }}
                onBlur={onEmbedInputBlur}
              />
            </div>
          ) : null}
          <Warning warning={warnings.get(athlete.id)} />
        </div>
      ))}
    </div>
  );
}

export default injectIntl(SelectedProfileList);
