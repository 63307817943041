import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  decoration: {
    color: uhColors.iconGrey,
    fontSize: '14px',
    marginRight: '5px',
  },
  decorationRight: {
    marginRight: 0,
    marginLeft: '5px',
  },
};

function DecoratedTextField(props) {
  const {
    decoration = '$',
    decorationStyle = {},
    position = 'left',
    style = {},
    textFieldStyle = {},
    floatingLabelText = '',
    errorText = '',
    onChange = () => null,
    inputLabelProps = {},
    inputProps = {},
    inputElementProps = {},
    ...otherProps
  } = props;

  let iconStyle = styles.decoration;
  const defaultTextFieldStyle = { color: uhColors.leftNavGrey };

  const handleInputChange = e => {
    const { value } = e.target;

    onChange(e, value);
  };

  if (position === 'right') {
    iconStyle = merge(iconStyle, styles.decorationRight);
  }

  iconStyle = merge(iconStyle, decorationStyle);

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      error={!!errorText}
      helperText={errorText}
      style={merge(defaultTextFieldStyle, textFieldStyle, style)}
      label={floatingLabelText}
      inputProps={inputElementProps}
      InputLabelProps={merge({ sx: { fontSize: '14px' } }, inputLabelProps)}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...(position === 'left' && {
          startAdornment: (
            <i
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: decoration }}
              style={iconStyle}
            />
          ),
        }),
        ...(position === 'right' && {
          endAdornment: (
            <i
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: decoration }}
              style={iconStyle}
            />
          ),
        }),
        ...{ sx: { fontSize: '14px' } },
        ...inputProps,
      }}
      onChange={handleInputChange}
    />
  );
}

DecoratedTextField.propTypes = {
  decoration: PropTypes.string,
  decorationStyle: PropTypes.object,
  onChange: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  style: PropTypes.object,
  inputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  inputElementProps: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  errorText: PropTypes.string,
};

/*
 * Examples:
 *
 *
 *  The default USD field
    <DecoratedTextField value={amount} onChange={this.handleChange} />
 *
 *
 *  A Euro field with the Euro symbol on the right
    <DecoratedTextField
      value={value}
      onChange={this.handleChange}
      decoration='€'
      position='right'
    />
 *
 *
 *  A percentage field with the percent symbol on the right
    <DecoratedTextField
      value={value}
      onChange={this.handleChange}
      decoration='%'
      position='right'
    />
 *
 *
 *  A random left-hand decoration with extra styling for the decoration
    <DecoratedTextField
      value={value}
      onChange={this.handleChange}
      decoration='Age:'
      decorationStyle={{width: '3rem', textAlign: 'left'}}
    />
 *
 *
 */

export default DecoratedTextField;
