import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Card, Grid, Typography, Button } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { ClientDataStore } from 'dataStores';

import AccountCreditActions from 'containers/clientProfile/components/AccountCredits/Actions';

function AccountCreditsInfo({
  intl = {},
  clientId = 0,
  creditsAmount = 0,
  clientDataStore = {},
}) {
  const { clients } = clientDataStore;
  const client = clients.get(clientId);

  if (client.get('managed_by_id', false)) return null;

  return (
    <Card classes={{ root: 'account-credits-info' }}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" className="account-credits-info__header">
            {t('.header', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className="account-credits-info__value"
            variant="h3"
            color="secondary"
          >
            <FormattedCurrency value={creditsAmount} fromCents />
          </Typography>
        </Grid>
        <div className="account-credits-info__open-drawer-btn">
          <Button
            size="1x"
            type="tertiary"
            rounded
            onClick={() =>
              AccountCreditActions.open({ clientId, creditsAmount })
            }
          >
            {t('.view', intl, __filenamespace)}
          </Button>
        </div>
      </Grid>
    </Card>
  );
}

AccountCreditsInfo.propTypes = {
  intl: PropTypes.object,
  creditsAmount: PropTypes.number,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientDataStore: PropTypes.object,
};

export default compose(
  memo,
  injectIntl,
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
    },
  })
)(AccountCreditsInfo);
