import * as React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';
import {
  CurrencyTextField,
  DateTimeField,
  Dropdown,
  Icon,
  Grid,
  TextField,
} from '@upperhand/playmaker';

import Coupon, { REDEMPTION_LIMIT_STRATEGIES } from 'records/Coupon';
import { t } from 'shared/utils/LocaleUtils';

import DiscountableItems from './DiscountableItems.jsx';

function EditForm({ createModal = false, actions, coupon, errors, intl }) {
  return (
    <Grid className="coupon__form">
      <Grid
        container
        className={clsx(
          'coupon__form-item',
          errors.getErrors(['value']) && 'coupon__form-item-error'
        )}
        spacing={1}
        alignItems="flex-end"
      >
        <Grid item xs={6}>
          <Dropdown
            id="discount_type"
            errorText={errors.getErrors(['discount_type'])}
            fullWidth
            items={[
              { label: t('.amount', intl, __filenamespace), value: 'amount' },
              { label: t('.percent', intl, __filenamespace), value: 'percent' },
            ]}
            label={t('.discount', intl, __filenamespace)}
            labelVariant="fieldLabel"
            name="discount_type"
            onChange={event =>
              actions.updateField('discount_type', event.target.value)
            }
            value={coupon.get('discount_type')}
          />
        </Grid>
        <Grid item xs={6}>
          {coupon.discount.isPercent() ? (
            <TextField
              classes={{
                root:
                  errors.getErrors(['value']) &&
                  'coupon__form-item-input-error',
              }}
              errorText={errors.getErrors(['value'])}
              fullWidth
              name="value"
              rounded={false}
              onChange={e => actions.updateField('value', e.target.value)}
              outline
              value={coupon.get('value')}
            />
          ) : (
            <CurrencyTextField
              classes={{
                root:
                  errors.getErrors(['value']) &&
                  'coupon__form-item-input-error',
              }}
              errorText={errors.getErrors(['value'])}
              placeholder={t('.amount_placeholder', intl, __filenamespace)}
              fullWidth
              rounded={false}
              outline
              onChange={(_, value) =>
                actions.updateField(
                  'value',
                  Math.round((value + Number.EPSILON) * 100)
                )
              }
              value={coupon.get('value') / 100}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className="coupon__form-item">
        <TextField
          onChange={e => actions.updateField('code', e.target.value)}
          name="code"
          errorText={errors.getErrors(['code'])}
          fullWidth
          outline
          rounded={false}
          placeholder={t('.code_placeholder', intl, __filenamespace)}
          value={coupon.get('code')}
          label={t('.code', intl, __filenamespace)}
          labelVariant="fieldLabel"
        />
      </Grid>
      <Grid container className="coupon__form-item" spacing={1}>
        <Grid item xs={6}>
          <DateTimeField
            autoOk
            errorText={errors.getErrors(['start_date'])}
            fullWidth
            hintText={t('.start_date', intl, __filenamespace)}
            label={t('.start_date', intl, __filenamespace)}
            labelVariant="fieldLabel"
            name="start_date"
            placeholder="MM/DD/YYYY"
            onChange={date => actions.updateField('start_date', date)}
            type="date"
            value={coupon.get('start_date')}
          />
        </Grid>
        <Grid item xs={6}>
          <DateTimeField
            autoOk
            errorText={errors.getErrors(['end_date'])}
            fullWidth
            hintText={t('.end_date', intl, __filenamespace)}
            label={t('.end_date', intl, __filenamespace)}
            labelVariant="fieldLabel"
            name="end_date"
            placeholder="MM/DD/YYYY"
            onChange={date => actions.updateField('end_date', date)}
            type="date"
            value={coupon.get('end_date')}
          />
        </Grid>
      </Grid>
      <Grid container className="coupon__form-item" spacing={1}>
        <Grid item xs={4}>
          <span style={{ position: 'relative', display: 'flex' }}>
            <span data-tip data-for="coupon_type" className="coupon__tooltip">
              <Icon name="info" className="coupon__tooltip_icon" />
            </span>
            <ReactTooltip
              id="coupon_type"
              effect="solid"
              style={{ maxWidth: 240 }}
              className="charge-failure-tooltip uh-tooltip"
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('.coupon_type_tooltip', intl, __filenamespace),
                }}
              />
            </ReactTooltip>
          </span>
          <Dropdown
            id="coupon_type"
            fullWidth
            items={[
              {
                label: t('.one_time', intl, __filenamespace),
                value: Coupon.ONE_TIME,
              },
              {
                label: t('.recurring', intl, __filenamespace),
                value: Coupon.RECURRING,
              },
              {
                label: t('.recurring_once', intl, __filenamespace),
                value: Coupon.RECURRINGONCE,
              },
            ]}
            label={t('.coupon_type', intl, __filenamespace)}
            labelVariant="fieldLabel"
            name="coupon_type"
            onChange={event =>
              actions.updateField('coupon_type', event.target.value)
            }
            value={coupon.get('coupon_type')}
          />
        </Grid>
        <Grid item xs={2}>
          <div style={{ paddingTop: 25 }}>
            <TextField
              onChange={e =>
                actions.updateField('redemption_limit', e.target.value)
              }
              name="redemption_limit"
              fullWidth
              outline
              rounded={false}
              placeholder={t(
                '.redemption_limit_placeholder',
                intl,
                __filenamespace
              )}
              value={coupon.get('redemption_limit') || ''}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={{ textAlign: 'center', paddingTop: 40 }}>
            {t('.coupon_uses_label', intl, __filenamespace)}
          </div>
        </Grid>
        <Grid item xs={4} className="coupon__redemption-limit-strategy">
          <span style={{ position: 'relative', display: 'flex' }}>
            <span
              data-tip
              data-for="coupon_usage_limit"
              className="coupon__tooltip"
            >
              <Icon name="info" className="coupon__tooltip_icon" />
            </span>
            <ReactTooltip
              id="coupon_usage_limit"
              effect="solid"
              style={{ maxWidth: 240 }}
              className="charge-failure-tooltip uh-tooltip"
            >
              {t('.coupon_usage_limit_tooltip', intl, __filenamespace)}
            </ReactTooltip>
          </span>
          <Dropdown
            id="coupon_redemption_limit_strategy"
            fullWidth
            items={[
              {
                label: t('.per_client', intl, __filenamespace),
                value: REDEMPTION_LIMIT_STRATEGIES.per_client,
              },
              {
                label: t('.global', intl, __filenamespace),
                value: REDEMPTION_LIMIT_STRATEGIES.global,
              },
            ]}
            name="redemption_limit_strategy"
            onChange={event =>
              actions.updateField(
                'redemption_limit_strategy',
                event.target.value
              )
            }
            value={
              coupon.get('redemption_limit_strategy') ||
              REDEMPTION_LIMIT_STRATEGIES.per_client
            }
            label={t('.redemption_limit', intl, __filenamespace)}
            labelVariant="fieldLabel"
          />
        </Grid>
      </Grid>
      <Grid container className="coupon__form-item">
        <TextField
          errorText={errors.getErrors(['description'])}
          fullWidth
          label={t('.description', intl, __filenamespace)}
          labelVariant="fieldLabel"
          multiline
          name="description"
          onChange={e => actions.updateField('description', e.target.value)}
          outline
          placeholder={t('.description_placeholder', intl, __filenamespace)}
          rounded={false}
          rows={4}
          value={coupon.get('description')}
        />
      </Grid>
      {!createModal && (
        <DiscountableItems actions={actions} coupon={coupon} removableItems />
      )}
    </Grid>
  );
}

EditForm.propTypes = {
  coupon: PropTypes.object.isRequired,
  createModal: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default EditForm;
