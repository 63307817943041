import PropTypes from 'prop-types';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import uhTheme from '_uh_theme.jsx';

import MessageWindow from 'shared/components/_MessageWindow.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import UpperHandIcon from 'shared/components/icons/UpperHand.jsx';

import SignupError from 'containers/accounts/signupResults/components/SignupError.jsx';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import Customer from 'shared/records/Customer.jsx';
import Staff from 'shared/records/Staff.jsx';
import { FlexBoxColumnLayout } from 'shared/components/FlexBox.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils';

import BusinessProfileEditor from './components/BusinessProfileEditor.jsx';
import DuplicateOrgNameModal from './components/DuplicateOrgNameModal.jsx';
import StaffEditor from './components/StaffEditor.jsx';

import CustomerCreationActions from './Actions';
import CustomerCreationStore from './Store';

import './styles.scss';

const styles = {
  header: {
    color: uhColors.white,
    backgroundColor: uhTheme.palette.upperHandGreen,
    fontSize: '42px',
    width: '100%',
    margin: 'auto 0',
    padding: '50px 0px',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  subheader: {
    color: uhColors.primaryMain,
    backgroundColor: uhColors.white,
    fontSize: '24px',
    width: '100%',
    margin: 'auto 0',
    padding: '50px 0px',
    textAlign: 'center',
  },
  icon: {
    height: 50,
    width: 50,
    marginRight: 25,
    position: 'relative',
    top: 10,
  },
  createBtn: {
    justifyContent: 'flex-end',
  },
};

function Header() {
  return (
    <div style={styles.header}>
      <UpperHandIcon color="var(--color-old-gray)" style={styles.icon} />
      <span>Almost there...</span>
    </div>
  );
}

function Subheader({ name }) {
  return (
    <div style={styles.subheader}>
      <span>Tell us more about </span>
      <span style={{ fontWeight: 600 }}>{name}</span>
    </div>
  );
}

function CustomerAccountCreation({ customerCreationStore = {}, intl, token }) {
  React.useEffect(() => {
    CustomerCreationActions.verifyToken(token);
  }, [token]);

  const {
    customer,
    fieldErrors,
    isCustomerLoading,
    isDuplicateOrgNameModalOpen,
    isSigningUp,
    isValid,
    staffMember,
    success,
    verificationError,
  } = customerCreationStore;

  if (verificationError) {
    return (
      <SignupError
        emailSubject={t('.error_mail_subject', intl, __filenamespace)}
        errorMessage={verificationError}
      />
    );
  }

  if (success) {
    return (
      <Navigate
        to={{
          pathname: '/accounts/signup_success',
          state: { email: staffMember.email },
        }}
      />
    );
  }

  const createAccountBtn = (
    <Button
      variant="contained"
      disabled={!isValid || isSigningUp}
      className="create-account-btn"
      onClick={CustomerCreationActions.signUp}
    >
      {t('.create_account_btn', intl, __filenamespace)}
    </Button>
  );

  // We currently can't use CSS to alter flex behavior on mobile widths </3
  const smallScreenForm = (
    <div className="mobile-form">
      <div className="admin-form">
        <StaffEditor staffMember={staffMember} errors={fieldErrors} />
      </div>
      <div className="business-form">
        <BusinessProfileEditor
          intl={intl}
          customer={customer}
          errors={fieldErrors}
        />
      </div>
      <FlexBoxColumnLayout style={styles.createBtn}>
        {createAccountBtn}
      </FlexBoxColumnLayout>
    </div>
  );

  const largeScreenForm = (
    <FlexBoxColumnLayout childrenStyle={{ maxWidth: SINGLE_COLUMN_WIDTH }}>
      <div className="admin-form">
        <StaffEditor
          intl={intl}
          staffMember={staffMember}
          errors={fieldErrors}
        />
      </div>
      <div className="business-form">
        <BusinessProfileEditor
          intl={intl}
          customer={customer}
          errors={fieldErrors}
        />
        <FlexBoxColumnLayout style={styles.createBtn}>
          {createAccountBtn}
        </FlexBoxColumnLayout>
      </div>
    </FlexBoxColumnLayout>
  );

  return (
    <div className="container">
      <Header />
      <SpinWhileLoading isLoading={isCustomerLoading} spinWhile="isLoading">
        <Subheader name={customer.name} />
        <ResponsiveElement
          largeScreen={largeScreenForm}
          smallScreen={smallScreenForm}
        />
        <DuplicateOrgNameModal
          intl={intl}
          isOpen={isDuplicateOrgNameModalOpen}
          customerName={customer.name}
        />
        <MessageWindow />
      </SpinWhileLoading>
    </div>
  );
}

CustomerAccountCreation.propTypes = {
  customerCreationStore: PropTypes.shape({
    customer: PropTypes.instanceOf(Customer),
    fieldErrors: PropTypes.instanceOf(FieldErrors),
    isCustomerLoading: PropTypes.bool,
    isDuplicateOrgNameModalOpen: PropTypes.bool,
    isSigningUp: PropTypes.bool,
    isValid: PropTypes.bool,
    staffMember: PropTypes.instanceOf(Staff),
    success: PropTypes.bool,
    verificationError: PropTypes.string,
  }),
  intl: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      customerCreationStore: CustomerCreationStore,
    },
  })
)(CustomerAccountCreation);
