import PropTypes from 'prop-types';
import * as React from 'react';
/*
 * Could get fancy and try to detect dollars
 * and percentages and bold those parts of the
 * description. But, for now, this is just a
 * simple echo of the description.
 */
function Description({ membership, style = {} }) {
  return (
    <div className="mem-view-description" style={style}>
      {membership.description}
    </div>
  );
}

Description.propTypes = {
  membership: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default Description;
