import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Confirmation,
  Typography,
  RadioButton,
  Grid,
} from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import EventScheduleCancelActions from 'containers/eventScheduleCancellation/Actions';
import EventScheduleCancelStore, {
  creditOperations,
} from 'containers/eventScheduleCancellation/Store';

function CancelScheduleModal({
  intl,
  onConfirm = EventScheduleCancelActions.scheduleCancel,
  onCancel = () => EventScheduleCancelActions.toggleCancelModal({}),
  eventScheduleCancelStore = {},
  isTeamEvent = false,
}) {
  const { schedule, loading, creditOperation } = eventScheduleCancelStore;
  const getTitle = () =>
    isTeamEvent
      ? t('.team_title', intl, __filenamespace)
      : t('.title', intl, __filenamespace);
  return (
    <Confirmation
      level="warning"
      title={getTitle()}
      open={!!schedule}
      onConfirm={onConfirm}
      onCancel={onCancel}
      hasCheckbox={false}
      confirmationDisabled={loading}
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
    >
      <div className="cancel-schedule-modal">
        <Typography variant="body1" className="cancel-schedule-modal__warning">
          {t('.warning', intl, __filenamespace)}
        </Typography>
        <Typography variant="subtitle2">
          {t('.options', intl, __filenamespace)}
        </Typography>
        <RadioButton
          size="4x"
          selectedValue={creditOperation}
          onChange={(_, value) =>
            EventScheduleCancelActions.handleChange(value)
          }
          orientation="vertical"
          options={[
            {
              label: (
                <Grid container direction="column">
                  {t('.refund_title', intl, __filenamespace)}
                  <Typography variant="body2" color="secondary">
                    {t('.refund_subtitle', intl, __filenamespace)}
                  </Typography>
                </Grid>
              ),
              value: creditOperations.REFUND_CREDIT,
            },
            {
              label: (
                <Grid container direction="column">
                  {t('.revoke_title', intl, __filenamespace)}
                  <Typography variant="body2" color="secondary">
                    {t('.revoke_subtitle', intl, __filenamespace)}
                  </Typography>
                </Grid>
              ),
              value: creditOperations.REVOKE_CREDIT,
            },
          ]}
        />
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" component="strong">
              {t('.note_title', intl, __filenamespace)}
            </Typography>
            <Typography variant="body2" color="secondary" component="span">
              &nbsp;{t('.note_subtitle', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Confirmation>
  );
}

CancelScheduleModal.propTypes = {
  intl: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  eventScheduleCancelStore: PropTypes.object,
  isTeamEvent: PropTypes.bool,
};

export default compose(
  injectIntl,
  altContainer({
    stores: { eventScheduleCancelStore: EventScheduleCancelStore },
  })
)(CancelScheduleModal);
