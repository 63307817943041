import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { OrderedSet, List } from 'immutable';
import { Card, Typography, Grid, Icon } from '@upperhand/playmaker';
import Star from '@mui/icons-material/Star';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import DiscountBenefits from 'memberships/components/_DiscountBenefits/_DiscountBenefits.jsx';
import CreditBenefits from 'memberships/components/_CreditBenefits.jsx';
import ExclusiveEventBenefits from 'memberships/components/_ExclusiveEventBenefits.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import Membership from 'shared/records/Membership.jsx';

import { MembershipTierDataStore } from 'dataStores';

import './styles.scss';

const inlineStyles = {
  benefitsContainer: {
    padding: '10px 25px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  creditBenefit: {
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginLeft: -2,
  },
  itemText: {
    fontSize: 12,
    marginLeft: 4,
  },
};

function MembershipViewingTiers({
  title = '',
  membership,
  tiersIds,
  eventTypes,
  creditPasses,
  retailCategories,
  membershipTierDataStore,
}) {
  const intl = useIntl();
  const [expandedId, setExpandedId] = useState(null);
  const { tiers } = membershipTierDataStore;

  return (
    <div className="tiers-viewing">
      {title && (
        <Typography variant="h6" className="tiers-viewing__title">
          {title}
        </Typography>
      )}
      {tiersIds.map(id => {
        const tier = tiers.get(id);
        const benefitsExpanded = expandedId === tier.id;

        return (
          <div key={id}>
            <Card
              classes={{
                root: 'tiers-viewing__item',
                content: 'tiers-viewing__item-content',
              }}
            >
              <div
                className="tiers-viewing__item-info-container"
                style={{ borderLeftColor: membership.color }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    className="tiers-viewing__name-container"
                  >
                    <Typography variant="h6" className="tiers-viewing__name">
                      {tier.name}
                    </Typography>
                    {tier.best_value && (
                      <Star sx={{ color: 'var(--color-warning-yellow)' }} />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    className="tiers-viewing__price-container"
                  >
                    <Typography className="tiers-viewing__price">
                      {t('.price', intl, __filenamespace, {
                        price: (
                          <>
                            <FormattedCurrency value={tier.price} fromCents />
                            {tier.intervalMonths && (
                              <span>/{tier.intervalMonths}</span>
                            )}
                          </>
                        ),
                      })}
                      &nbsp;
                    </Typography>
                    {tier.commitmentMonths && (
                      <Typography className="tiers-viewing__commitment">
                        {t('.commitment', intl, __filenamespace, {
                          commitment: <span>{tier.commitmentMonths}</span>,
                        })}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sm={7}
                    justify="flex-end"
                    direction="column"
                  >
                    <button
                      disabled={!tier.hasBenefits}
                      type="button"
                      className="tiers-viewing__benefits-expander"
                      onClick={() =>
                        setExpandedId(benefitsExpanded ? null : tier.id)
                      }
                    >
                      <p>
                        {t('.view_benefits', intl, __filenamespace, {
                          tier_name: tier.name,
                        })}
                      </p>
                      <Icon name={benefitsExpanded ? 'arrowUp' : 'arrowDown'} />
                    </button>
                  </Grid>
                </Grid>
              </div>
              {benefitsExpanded && (
                <div style={inlineStyles.benefitsContainer}>
                  <CreditBenefits
                    membership={tier}
                    creditBenefitsStyle={inlineStyles.creditBenefit}
                  />
                  <DiscountBenefits
                    itemStyle={inlineStyles.item}
                    textStyle={inlineStyles.itemText}
                    customWrapper={inlineStyles.creditBenefit}
                    membership={tier}
                    eventTypes={eventTypes}
                    creditPasses={creditPasses}
                    retailCategories={retailCategories}
                  />
                  <ExclusiveEventBenefits
                    leftStyles
                    membership={tier}
                    eventTypes={eventTypes}
                  />
                </div>
              )}
            </Card>
          </div>
        );
      })}
    </div>
  );
}

MembershipViewingTiers.propTypes = {
  title: PropTypes.string,
  membership: PropTypes.instanceOf(Membership).isRequired,
  tiersIds: PropTypes.instanceOf(OrderedSet).isRequired,
  eventTypes: PropTypes.instanceOf(List).isRequired,
  creditPasses: PropTypes.instanceOf(List).isRequired,
  retailCategories: PropTypes.instanceOf(List).isRequired,
  membershipTierDataStore: PropTypes.object.isRequired,
};

export default compose(
  memo,
  altContainer({
    stores: {
      membershipTierDataStore: MembershipTierDataStore,
    },
  })
)(MembershipViewingTiers);
