import * as React from 'react';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import UnpaidClients from 'calendar/components/views/shared/event_card/_UnpaidClients.jsx';
import UnsignedWaiver from 'shared/components/icons/UnsignedWaiver.jsx';

import { FlexBoxJustify, FlexBox } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const styles = {
  PersonIcon: {
    width: '20px',
    height: '20px',
    margin: '0 8px 0 0',
    color: uhColors.iconGrey,
  },
  Count: {
    fontWeight: '600',
  },
};

function MultipleAttendees({
  event,
  eventTime,
  clients,
  handleAttendeesClick,
  session,
  intl,
}) {
  const isInstructor = currentUser().isInstructor();
  const maxSize = session?.get('max_size');
  const maxSizeSuffix = maxSize ? `${clients.size} / ${maxSize}` : '';
  const maxSizeToDisplay = event?.isClassSchedule()
    ? maxSizeSuffix
    : clients.size;

  return (
    <FlexBoxJustify style={{ alignItems: 'flex-start' }}>
      <FlexBox
        style={{
          alignItems: 'center',
        }}
      >
        <PersonIcon sx={styles.PersonIcon} />
        <Box
          className={isInstructor ? '' : 'event-card__attendee-link'}
          onClick={handleAttendeesClick}
        >
          <span>
            <span style={styles.Count}>{maxSizeToDisplay}</span>
            {t('.attendees', intl, __filenamespace)}
          </span>
          <UnpaidClients eventTime={eventTime} />
        </Box>
      </FlexBox>
      <FlexBox style={{ alignItems: 'flex-end' }}>
        {clients.find(
          client =>
            client &&
            !client.get('has_accepted_terms') &&
            !client.get('managed_by_id')
        ) && <UnsignedWaiver />}
      </FlexBox>
    </FlexBoxJustify>
  );
}

export default MultipleAttendees;
