import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Icon } from '@upperhand/playmaker';
import WrapperPreview from 'components/ImageUpload/components/WrapperPreview.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

function DefaultPreview({
  cropAspect = null,
  image = null,
  previewContainerHeight = null,
  previewContainerWidth = null,
  uploadContainerHeight = null,
  uploadContainerWidth = null,
  onPopupOpen = () => {},
  intl,
}) {
  const isEmpty = !image || image.isBlank();
  return (
    <WrapperPreview
      intl={intl}
      cropAspect={cropAspect}
      height={
        isEmpty
          ? uploadContainerHeight
          : previewContainerHeight || uploadContainerHeight
      }
      width={
        isEmpty
          ? uploadContainerWidth
          : previewContainerWidth || uploadContainerWidth
      }
      image={image}
    >
      {isEmpty ? null : (
        <div className="default-preview">
          <img
            src={image.getSrc()}
            crossOrigin="anonymous"
            alt={t('.image_alt', intl, __filenamespace)}
          />
          <Button
            classes={{ root: 'default-preview__popup' }}
            type="tertiary"
            icon={<Icon name="photo" className="default-preview__icon" />}
            rounded
            onClick={onPopupOpen}
          />
        </div>
      )}
    </WrapperPreview>
  );
}

DefaultPreview.propTypes = {
  cropAspect: PropTypes.number,
  image: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onPopupOpen: PropTypes.func,
  previewContainerHeight: PropTypes.number,
  previewContainerWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  uploadContainerHeight: PropTypes.number,
  uploadContainerWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default injectIntl(DefaultPreview);
