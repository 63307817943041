import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import StripedList from 'shared/components/StripedList.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { l, t } from 'shared/utils/LocaleUtils.js';
import { multiplyList } from 'shared/utils/ImmutableUtils';

import { boldText } from 'shared/styles/uhStyles.jsx';

function DiscountedAmount({ payment }) {
  return (
    <div>
      <span style={{ marginRight: 5 }}>
        <FormattedCurrency value={payment.get('amount')} fromCents />
      </span>
      <span style={{ textDecoration: 'line-through' }}>
        <FormattedCurrency value={payment.get('original_amount')} fromCents />
      </span>
    </div>
  );
}

function PaymentItem({ payment, style, intl }) {
  return (
    <FlexBoxJustify style={merge({ padding: '6px 10px' }, style)}>
      <div style={boldText}>
        {l(payment.get('date'), 'dates.shortNumeric', intl)}
      </div>
      {payment.get('discount') > 0 ? (
        <DiscountedAmount payment={payment} />
      ) : (
        <FormattedCurrency value={payment.get('amount')} fromCents />
      )}
    </FlexBoxJustify>
  );
}

function RecurringPaymentPlanSummary({
  plan,
  showTotal,
  style,
  paymentsStyle,
  quantity = 1,
  adjustedPayments,
  intl,
}) {
  let payments;
  if (adjustedPayments) {
    payments = adjustedPayments;
  } else {
    payments = multiplyList(plan.payments, quantity);
  }

  return (
    <div style={style}>
      <StripedList>
        {payments
          .sortBy(p => p.get('date'))
          .map((p, i) => (
            <PaymentItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${p.get('date')}-${i}`}
              payment={p}
              style={paymentsStyle}
              intl={intl}
            />
          ))
          .toList()}
      </StripedList>
      {showTotal && (
        <FlexBoxJustify
          style={{
            padding: '6px 10px',
            backgroundColor: 'var(--color-old-gray)',
            color: 'white',
          }}
        >
          {t('.total', intl, __filenamespace)}
          <FormattedCurrency intl={intl} value={plan.totalPrice()} fromCents />
        </FlexBoxJustify>
      )}
    </div>
  );
}

RecurringPaymentPlanSummary.propTypes = {
  quantity: PropTypes.number,
};

export default injectIntl(RecurringPaymentPlanSummary);
