/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Grid, Spinner, Typography } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import { t } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { Balance, Location } from 'records';
import Client from 'shared/records/Client.jsx';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import moment from 'moment-timezone';
import DotsMenu from './DotsMenu.jsx';
import BalanceInfo from './BalanceInfo.jsx';
import DetailItem from './DetailItem.jsx';

function ScheduledPayment({ scheduledPayment, intl }) {
  if (!scheduledPayment) return null;
  return (
    <Box className="details__card">
      <Box className="details__container">
        <Grid container xs={12} spacing={1}>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="subtitle2" className="details__title">
                {t('.scheduled_payment', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" align="right">
                <FormattedCurrency
                  value={scheduledPayment.get('amount') || 0}
                  fromCents
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body2" align="right">
                {scheduledPayment.get('dueDate')
                  ? moment(scheduledPayment.get('dueDate')).format(
                      'MMM DD, YYYY'
                    )
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
function Content({
  intl,
  balance = null,
  balanceDetails = null,
  client = null,
  dotsMenuAnchorEl = null,
  isLoading = false,
  location = null,
  showReceiptEnabled = currentUser().isStaff(),
  scheduledPayment = null,
  remainingScheduledPayments = 0,
  isPaymentDetailsDrawer = false,
}) {
  const paymentPlan = balanceDetails?.find(item => item.type === 'PaymentPlan');
  const totalBalance =
    balanceDetails?.find(item => item.type === 'OrderItem')?.get('amount') || 0;
  const total = scheduledPayment
    ? scheduledPayment.amount
    : Math.abs(totalBalance);
  const scheduledAmountPaid = balanceDetails
    ?.filter(item => item.type === 'Payment')
    .reduce((acc, item) => acc + item.amount, 0);
  const paid = scheduledPayment ? scheduledAmountPaid : balance?.get('paid');

  return (
    <div className="balance-details-drawer__content">
      <DotsMenu
        intl={intl}
        balance={balance}
        anchorEl={dotsMenuAnchorEl}
        showReceiptEnabled={showReceiptEnabled}
        isPaymentDetailsDrawer={isPaymentDetailsDrawer}
      />
      {isLoading ? (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <>
          <BalanceInfo
            balance={balance}
            client={client}
            location={location}
            intl={intl}
            total={total}
            paid={paid}
          />

          {paymentPlan && (
            <>
              <Typography className="details__header" variant="subtitle1">
                {t('.plan_details', intl, __filenamespace)}
              </Typography>
              <DetailItem
                detail={paymentPlan}
                intl={intl}
                remainingScheduledPayments={remainingScheduledPayments}
              />
            </>
          )}
          <Typography className="details__header" variant="subtitle1">
            {t(
              paymentPlan ? '.order_details' : '.details',
              intl,
              __filenamespace
            )}
          </Typography>

          {balanceDetails
            .filter(
              item =>
                item.type !== 'PaymentPlan' && item.type !== 'ScheduledPayment'
            )
            .map((item, key) => (
              <DetailItem key={key} detail={item} intl={intl} />
            ))}
          <ScheduledPayment scheduledPayment={scheduledPayment} intl={intl} />
        </>
      )}
    </div>
  );
}

Content.propTypes = {
  balance: PropTypes.instanceOf(Balance),
  balanceDetails: PropTypes.instanceOf(List),
  client: PropTypes.instanceOf(Client),
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.instanceOf(Location),
  showReceiptEnabled: PropTypes.bool,
};

export default Content;
