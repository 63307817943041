import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Checkbox } from '@upperhand/playmaker';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import { t } from 'shared/utils/LocaleUtils';

import { CashMessage } from '../messages';

const styles = {
  textField: {
    textAlign: 'right',
  },
  boldText: {
    fontWeight: 'bold !important',
  },
};

function PaymentCashForm({ intl, total, disableAmountField = false }) {
  const [amount, setAmount] = useState(null);
  const [showChangeCalculator, setShowChangeCalculator] = useState(false);
  const change = amount - total;

  return (
    <div className="cash-info">
      <div className="cash-info__toggle-calculator">
        <CashMessage />
        <Checkbox
          checked={showChangeCalculator}
          label={t('.toggle_change', intl, __filenamespace)}
          onChange={e => setShowChangeCalculator(e.target.checked)}
        />
      </div>
      {!disableAmountField && showChangeCalculator && (
        <>
          <CurrencyTextField
            disabled={disableAmountField}
            variant="standard"
            floatingLabelText={t('.amount_paid_label', intl, __filenamespace)}
            errorText={
              amount !== null && change < 0
                ? t('.amount_paid_error', intl, __filenamespace)
                : ''
            }
            fullWidth
            position="left"
            value={amount}
            onChange={(_, value) => setAmount(value)}
            converter={e => parseInt(e, 10) /* save as cents */}
            sx={styles.textField}
            style={{ marginBottom: 10 }}
          />
          <CurrencyTextField
            variant="standard"
            floatingLabelText={t('.change_due_label', intl, __filenamespace)}
            fullWidth
            disabled
            position="left"
            value={change < 0 ? 0 : change}
            converter={e => parseInt(e, 10) /* save as cents */}
            style={{ ...styles.boldText, ...styles.textField }}
          />
        </>
      )}
    </div>
  );
}

PaymentCashForm.propTypes = {
  intl: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  disableAmountField: PropTypes.bool,
};

export default injectIntl(PaymentCashForm);
