import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Alert, Typography, Confirmation } from '@upperhand/playmaker';
import { compose } from 'shared/utils/SharedUtils.js';
import ViewComponent from 'components/view/index.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { CouponDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils';

import CouponActions from './Actions';
import CouponStore from './Store';
import ConfirmRemoveModal from './components/ConfirmRemoveModal.jsx';
import Header from './components/Header.jsx';
import RecordCard from './components/Card.jsx';
import DiscountableTabs from './components/DiscountableTabs.jsx';

import './styles.scss';

function Coupon({
  couponDataStore = {},
  couponStore = {},
  couponID = undefined,
  createRoute = false,
  createModal = false,
  editRoute = false,
  intl,
}) {
  React.useEffect(() => {
    CouponActions.mounted.defer({
      couponID,
      createRoute,
      editRoute,
    });
  }, [couponID, createRoute, editRoute]);

  const { coupons } = couponDataStore;
  const {
    couponId,
    createMode,
    editMode,
    errors,
    updatedCoupon,
    alertMode,
    isLoading,
    isRemoving,
    searchableCreditPasses,
    searchableEvents,
    searchableMemberships,
    searchableVariants,
    selectedTab,
    saveConfirmation,
    searchableTeams,
  } = couponStore;

  return (
    <div className="coupon">
      <ViewComponent
        header={
          !createModal && (
            <Header
              actions={CouponActions}
              createMode={createMode}
              editMode={editMode}
              coupon={editMode ? updatedCoupon : coupons.get(couponId)}
              errors={errors}
              intl={intl}
            />
          )
        }
        isLoading={isLoading}
        leftColumn={
          <>
            <RecordCard
              actions={CouponActions}
              createModal={createModal}
              editMode={editMode}
              errors={errors}
              coupon={coupons.get(couponId)}
              updatedCoupon={updatedCoupon}
              intl={intl}
            />
            {isRemoving && (
              <ConfirmRemoveModal
                actions={CouponActions}
                isRemoving={isRemoving}
                coupon={coupons.get(couponId)}
                intl={intl}
              />
            )}
            {!!alertMode && (
              <Alert
                buttonText={t(
                  `.alert_btn_text_${alertMode}`,
                  intl,
                  __filenamespace
                )}
                type="warning"
                title={
                  <Typography
                    variant="dialogHeader"
                    className="coupon__alert-title"
                  >
                    {t(`.alert_title_${alertMode}`, intl, __filenamespace)}
                  </Typography>
                }
                onDismiss={CouponActions.dismissAlert}
                open={!!alertMode}
              >
                <Typography
                  variant="dialogContent"
                  className="coupon__alert-text"
                >
                  {t(`.alert_text_${alertMode}`, intl, __filenamespace)}
                </Typography>
              </Alert>
            )}
          </>
        }
        rightColumn={
          editMode ? (
            <DiscountableTabs
              actions={CouponActions}
              createModal={createModal}
              createMode={!!createMode}
              coupon={editMode ? updatedCoupon : coupons.get(couponId)}
              searchableCreditPasses={searchableCreditPasses}
              searchableEvents={searchableEvents}
              searchableMemberships={searchableMemberships}
              searchableVariants={searchableVariants}
              selectedTab={selectedTab}
              searchableTeams={searchableTeams}
              intl={intl}
            />
          ) : (
            <div />
          )
        }
      />
      <Confirmation
        hasCheckbox={false}
        open={saveConfirmation}
        title={t('.confirmation_title', intl, __filenamespace)}
        onConfirm={
          createModal ? CouponActions.cancelCreate : CouponActions.cancelUpdate
        }
        onCancel={CouponActions.toggleConfirmation}
      >
        {t('.confirmation_body', intl, __filenamespace)}
      </Confirmation>
    </div>
  );
}

Coupon.propTypes = {
  couponDataStore: PropTypes.object,
  couponStore: PropTypes.object,
  couponID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  createRoute: PropTypes.bool,
  createModal: PropTypes.bool,
  editRoute: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      couponDataStore: CouponDataStore,
      couponStore: CouponStore,
    },
  })
)(Coupon);
