import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ManagingUser({ color = '#9A9EAD', ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 12 12" {...props}>
      <g
        id="Memberships"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="CEL---Mem.-Drawer-Events"
          transform="translate(-983.000000, -94.000000)"
          fill={color}
        >
          <g
            id="Event-Details-Past"
            transform="translate(920.000000, 0.000000)"
          >
            <path
              d="M73.5,101.5 L70.2,101.5 L70.095375,101.5 C70.3515,101.05825 70.5,100.547125 70.5,100 L72.826125,100 C73.232625,100.267 73.5,100.690375 73.5,101.097625 L73.5,101.5 Z M70.5,104.5 L64.5,104.5 L64.5,104.097625 C64.5,103.690375 64.767375,103.267 65.173875,103 L66.72675,103 L69.826125,103 C70.232625,103.267 70.5,103.690375 70.5,104.097625 L70.5,104.5 Z M67.5,98.5 C68.328375,98.5 69,99.171625 69,100 C69,100.828375 68.328375,101.5 67.5,101.5 C66.758625,101.5 66.146625,100.961125 66.0255,100.253875 C66.00975,100.17175 66,100.087 66,100 C66,99.171625 66.671625,98.5 67.5,98.5 L67.5,98.5 Z M70.5,95.5 C71.32725,95.5 72,96.17275 72,97 C72,97.82725 71.32725,98.5 70.5,98.5 C70.34175,98.5 70.192125,98.468875 70.0485,98.42275 C69.804,98.028625 69.471375,97.696 69.07725,97.4515 C69.031125,97.307875 69,97.15825 69,97 C69,96.17275 69.67275,95.5 70.5,95.5 L70.5,95.5 Z M73.2,98.5 L73.095375,98.5 C73.3515,98.05825 73.5,97.547125 73.5,97 C73.5,95.34325 72.15675,94 70.5,94 C68.84325,94 67.5,95.34325 67.5,97 C65.84325,97 64.5,98.34325 64.5,100 C64.5,100.547125 64.648875,101.05825 64.904625,101.5 L64.8,101.5 C63.73125,101.9785 63,103.00375 63,104.097625 L63,105.25 C63,105.512875 63.4155,106 63.72675,106 L71.34375,106 C71.655,106 72,105.512875 72,105.25 L72,104.097625 C72,103.715875 71.90625,103.344625 71.745,103 L74.34375,103 C74.655,103 75,102.512875 75,102.25 L75,101.097625 C75,100.00375 74.26875,98.9785 73.2,98.5 L73.2,98.5 Z"
              id="Page-1"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ManagingUser;
