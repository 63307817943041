import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { OrderSource, PaymentSource, TransactionSource } from 'sources';
import PaysafeService from './PaysafeService';
import StripeService from './StripeService';
import { PaymentType, PaymentMethod, PaymentServiceResponse } from '../types';

/**
 * @description complete order websocket subscription
 * @private
 * @param itemId {string}
 * @param paymentServiceResponse {function} - action to communicate w/ store
 * @returns {ActionCable subscription}
 */
async function subscribeToOrderCompletion(itemId, paymentServiceResponse) {
  OrderSource.subscribeToChanges({
    id: itemId,
    success: order => {
      if (order.get('status') === 'completed') {
        paymentServiceResponse(
          PaymentServiceResponse.PAYMENT_SUCCESS,
          itemId,
          order
        );
        OrderSource.unsubscribeToChanges({ id: itemId });
      } else if (
        ['cancelled', 'refunded', 'error', 'in_progress'].includes(
          order.get('status')
        )
      ) {
        paymentServiceResponse(
          PaymentServiceResponse.PAYMENT_ERROR,
          order.get('failure_message', 'Error completing order.')
        );
        OrderSource.unsubscribeToChanges({ id: itemId });
      }
    },
  });
}

/**
 * @description Grabs a Payment and determines what response to give depending on its status
 * @private
 * @param paymentId {string}
 * @param paymentServiceResponse {function} - action to communicate w/ store
 * @returns {ActionCable subscription}
 */
function handleCompletedTransaction(
  paymentId,
  paymentServiceResponse,
  addonOrderId
) {
  PaymentSource.fetch({
    id: paymentId,
    success: payment => {
      if (payment.get('status') === 'failed') {
        paymentServiceResponse(
          PaymentServiceResponse.PAYMENT_ERROR,
          payment.get('failureReason')
        );
      } else if (addonOrderId) {
        subscribeToOrderCompletion(addonOrderId, paymentServiceResponse);
      } else {
        paymentServiceResponse(PaymentServiceResponse.PAYMENT_SUCCESS, payment);
      }
    },
    error: err =>
      paymentServiceResponse(PaymentServiceResponse.PAYMENT_ERROR, err),
  });
}

/**
 * @description get gateway token using provided card Record
 * @private
 * @param cardRecord {PaymentCard}
 * @returns {Promise<*>}
 */
async function getNewCardCharge(cardRecord) {
  let payload;
  let tokenFunction;
  const stripeCustomer = currentCustomer().use_stripe;

  if (stripeCustomer) {
    payload = cardRecord.stripePayload();
    tokenFunction = StripeService.tokenize;
  } else {
    payload = cardRecord.paysafePayload();
    tokenFunction = PaysafeService.tokenize;
  }

  try {
    return new Promise((resolve, reject) => {
      tokenFunction(payload, (...args) => {
        let _; // eslint-disable-line no-unused-vars
        let status;
        let result;
        let error;

        if (stripeCustomer) {
          [status, result] = args;
          if (status !== 200) reject(result.error);
        } else {
          // eslint-disable-next-line no-unused-vars
          [_, error, result] = args;
        }

        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * @description
 * @private
 * @param cardRecord {PaymentCard}
 * @returns {Promise<boolean>}
 */
async function getGatewayValidation(cardRecord) {
  const stripeCustomer = currentCustomer().use_stripe;

  if (stripeCustomer) {
    return StripeService.validateCard(cardRecord);
  }

  return PaysafeService.validateCard();
}

/**
 * @description create request payload based on payment method and card data
 * @private
 * @param paymentRecord {PaymentCard or PaymentAch} - optional
 * @param paymentMethod {string} - required
 * @param amount {number} - required, amount of card charge
 * @param itemId {string} - required, needed to allocate credit from payment
 * @returns {Promise<*>}
 */
async function buildPayload({
  accountCreditAmount,
  paymentRecord,
  paymentMethod,
  amount,
  itemId,
  use,
}) {
  let payload;
  let token;

  switch (paymentMethod) {
    case PaymentMethod.CARD_NEW:
      if (paymentRecord.info.isValid()) {
        let tokenRequest;

        try {
          await getGatewayValidation(paymentRecord.info);
          tokenRequest = await getNewCardCharge(paymentRecord.info);
        } catch (e) {
          throw new Error(e.message);
        }

        token = tokenRequest.id || tokenRequest.token;
        payload = {
          card_token_id: token,
          use,
          method: 'card',
          payment_method: 'card',
        };
      } else {
        // Payment form will display errors, so exit here
        payload = null;
        throw new Error('Card record is invalid.');
      }
      break;

    case PaymentMethod.CARD:
      // API uses the default card if no token is passed and method is `card`
      // force to `card` method until API accepts other values
      payload = {
        card_token_id: paymentRecord.id,
        use,
        method: 'card',
        payment_method: 'card',
      };
      break;

    case PaymentMethod.HANDPOINT_CLOUD:
      payload = {
        use,
        method: 'handpoint_cloud',
        payment_method: 'handpoint_cloud',
        // shared function for receiving card reader id?
        card_reader_id: window.localStorage.getItem('cardReaderId'),
      };
      break;

    case PaymentMethod.CASH:
    case PaymentMethod.CHECK:
    case PaymentMethod.PAY_LATER:
      payload = {
        method: paymentMethod,
        payment_method: paymentMethod,
      };
      break;

    case PaymentMethod.ACH_NEW:
      payload = {
        use,
        method: 'bank',
        ach_bank_account: {
          account_holder_name: paymentRecord.info.account_holder_name,
          account_number: paymentRecord.info.account_number,
          routing_number: paymentRecord.info.routing_number,
          city: paymentRecord.info.city,
          street: paymentRecord.info.street,
          zip: paymentRecord.info.zip,
        },
      };
      break;

    case PaymentMethod.ACH:
      payload = {
        use,
        method: 'bank',
        ach_payment_method_id: paymentRecord.id,
      };
      break;

    default:
      throw new Error('Unknown payment method.');
  }

  payload = {
    ...payload,
    amount,
    allocation: { [itemId]: amount },
    account_credit_amount: accountCreditAmount,
  };
  return JSON.stringify(payload);
}

/**
 * @description transaction websocket subscription
 * @private
 * @param transactionId {string}
 * @param paymentServiceResponse {function} - action to communicate w/ store
 * @returns {ActionCable subscription}
 */
async function subscribeToTransaction(transactionId, paymentServiceResponse) {
  TransactionSource.subscribeToChanges({
    id: transactionId,
    success: transaction => {
      if (transaction.get('status') === 'complete') {
        handleCompletedTransaction(
          transaction.get('paymentId'),
          paymentServiceResponse
        );
        TransactionSource.unsubscribeToChanges({ id: transactionId });
      } // otherwise wait for the next message
    },
  });
}

/**
 * @description finalize payment with any payment type
 * @public
 * @param itemId {string} - needed to complete order or payment plan
 * @param type {PaymentType} - needed to confirm the type of payment to complete
 * @param paymentServiceResponse {function} - action to communicate w/ store
 * @param amount {number} - required, amount of card charge
 * @param purchaserId {string} - needed to pay outstanding balance
 * @returns {Promise<*>}
 */
async function complete({
  itemId,
  addonOrderId,
  type,
  paymentServiceResponse,
  amount,
  purchaserId,
  ...rest
}) {
  let payload;
  try {
    payload = await buildPayload({ itemId, type, amount, ...rest });
  } catch (e) {
    paymentServiceResponse(PaymentServiceResponse.TOKEN_ERROR, e);
    return false;
  }

  paymentServiceResponse(PaymentServiceResponse.TOKEN_SUCCESS, '');

  switch (type) {
    /*
      TODO: Clean up payment types
    */
    case PaymentType.ORDER:
      return uhApiClient.post({
        url: `/orders/${itemId}/complete`,
        data: payload,
        success: () =>
          subscribeToOrderCompletion(itemId, paymentServiceResponse),
        error: err =>
          paymentServiceResponse(PaymentServiceResponse.PAYMENT_ERROR, err),
      });
    case PaymentType.BALANCE:
      return uhApiClient.post({
        url: `/clients/${purchaserId}/payment`,
        data: payload,
        success: data => {
          const parsedPayload = JSON.parse(payload);
          /*
            When paying balance only with account credits there is no transaction.
            So we don't need to subscribe for transaction complete.
           */
          if (
            data.success &&
            parsedPayload.amount === 0 &&
            parsedPayload.account_credit_amount > 0
          ) {
            paymentServiceResponse(PaymentServiceResponse.PAYMENT_SUCCESS, '');
            return;
          }

          if (data.status === 'complete') {
            handleCompletedTransaction(
              data.payment_id,
              paymentServiceResponse,
              addonOrderId
            );
          } else {
            subscribeToTransaction(data.id, paymentServiceResponse);
          }
        },
        error: err =>
          paymentServiceResponse(PaymentServiceResponse.PAYMENT_ERROR, err),
      });
    case PaymentType.PAYMENT_PLAN:
      return uhApiClient.post({
        url: `/payment_plans/${itemId}/payment`,
        data: payload,
        success: data => {
          const parsedPayload = JSON.parse(payload);
          if (
            data.success &&
            parsedPayload.amount === 0 &&
            parsedPayload.account_credit_amount > 0
          ) {
            paymentServiceResponse(PaymentServiceResponse.PAYMENT_SUCCESS, '');
            return;
          }
          if (data.status === 'complete') {
            handleCompletedTransaction(data.payment_id, paymentServiceResponse);
          } else {
            subscribeToTransaction(data.id, paymentServiceResponse);
          }
        },
        error: err =>
          paymentServiceResponse(PaymentServiceResponse.PAYMENT_ERROR, err),
      });

    case PaymentType.PAY_ALL_BALANCES:
      return uhApiClient.post({
        url: `/clients/${purchaserId}/pay_all`,
        data: payload,
        success: data => {
          const { success } = data;
          const handlerType = success
            ? PaymentServiceResponse.PAYMENT_SUCCESS
            : PaymentServiceResponse.PAYMENT_ERROR;
          const response = success
            ? data
            : new Error('Something went wrong during payment');
          response.type = PaymentType.PAY_ALL_BALANCES;

          paymentServiceResponse(handlerType, response);
        },
        error: err =>
          paymentServiceResponse(PaymentServiceResponse.PAYMENT_ERROR, err),
      });

    default:
      return false;
  }
}

const PaymentService = { complete };
export default PaymentService;
