import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Set, Map } from 'immutable';
import {
  Typography,
  Grid,
  MultipleSelect,
  Dropdown,
  DateTimeField,
  Chip,
} from '@upperhand/playmaker';

import FiltersBlock from 'containers/contacts/contactsFilters/steps/filterBlocks/FiltersBlock.jsx';
import ProductSelector from 'containers/contacts/contactsFilters/components/ProductSelector.jsx';
import ClearAction from 'containers/contacts/contactsFilters/components/ClearAction.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import { t, messageId } from 'shared/utils/LocaleUtils';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import {
  ACCOUNT_STATUSES,
  ACCOUNT_TYPES,
  SUBSCRIPTION_STATUSES,
  DATE_OPERATORS,
} from 'containers/contacts/contactsFilters/Store';

function Activity({
  filter = new ContactFilter(),
  products = [],
  productTypes,
  pagination = Map({
    products: Map({
      search: '',
      loading: false,
    }),
  }),
  onChange = () => null,
  onProductSearch = () => null,
  onProductTypeSearch = () => null,
}) {
  const intl = useIntl();
  const accountType = filter.get('account_type', null);
  const selectedStatuses = filter.get('account_statuses', Set());
  const selectedSubscriptionStatuses = filter.get(
    'subscription_statuses',
    Set()
  );
  const firstPurchaseDate = filter.getIn(['first_purchase_date', 'start_date']);
  const firstPurchaseDateEnd = filter.getIn([
    'first_purchase_date',
    'end_date',
  ]);
  const firstPurchaseDateOperator = filter.getIn([
    'first_purchase_date',
    'operator',
  ]);
  const operatorIsNotBetween =
    firstPurchaseDateOperator !== DATE_OPERATORS.BETWEEN;

  const lastPurchaseDate = filter.getIn(['last_purchase_date', 'start_date']);
  const lastPurchaseDateEnd = filter.getIn(['last_purchase_date', 'end_date']);
  const lastPurchaseDateOperator = filter.getIn([
    'last_purchase_date',
    'operator',
  ]);
  const operatorIsNotBetweenLast =
    lastPurchaseDateOperator !== DATE_OPERATORS.BETWEEN;

  const selectedEvents = filter.getIn(['purchased_products', 'events'], []);
  const selectedMemberships = filter.getIn(
    ['purchased_products', 'memberships'],
    []
  );
  const selectedCreditPasses = filter.getIn(
    ['purchased_products', 'credit_passess'],
    []
  );
  const selectedEventTypes = filter.getIn(
    ['purchased_products', 'event_types'],
    []
  );
  const selectedTeamTypes = filter.getIn(
    ['purchased_products', 'team_types'],
    []
  );
  const selectedMembershipType = filter.getIn(
    ['purchased_products', 'purchased_membership'],
    []
  );
  const selectedCreditPassType = filter.getIn(
    ['purchased_products', 'purchased_credit_pass'],
    []
  );

  const allSelectedProducts = [
    ...selectedEvents,
    ...selectedMemberships,
    ...selectedCreditPasses,
  ];

  const allSelectedProductTypes = [
    ...selectedEventTypes,
    ...selectedTeamTypes,
    ...selectedMembershipType,
    ...selectedCreditPassType,
  ];

  const operators = Object.values(DATE_OPERATORS).map(operator => ({
    label: intl.formatMessage({
      id: messageId(
        `containers.contacts.contactsFilters.steps.filterBlocks.Demographics.date_operators.${operator}`,
        __filenamespace
      ),
    }),
    value: operator,
  }));
  const accountStatuses = Object.values(ACCOUNT_STATUSES).map(status => ({
    label: intl.formatMessage({
      id: messageId(`.account_statuses.${status}`, __filenamespace),
    }),
    value: status,
  }));
  const accountTypes = Object.values(ACCOUNT_TYPES).map(type => ({
    label: intl.formatMessage({
      id: messageId(`.account_types.${type}`, __filenamespace),
    }),
    value: type,
  }));
  const subscriptionStatuses = Object.values(SUBSCRIPTION_STATUSES).map(
    subStatus => ({
      label: intl.formatMessage({
        id: messageId(`.subscription_statuses.${subStatus}`, __filenamespace),
      }),
      value: subStatus,
    })
  );

  const getSelectedProductType = product => {
    const isProduct = Boolean(product?.product_type);
    const isMembershipProduct =
      isProduct && product.product_type === 'membership';

    if (isMembershipProduct) {
      return 'memberships';
    }

    const isCreditPassProduct =
      isProduct && product.product_type === 'credit_pass';
    if (isCreditPassProduct) {
      return 'credit_passes';
    }

    const isEventProductType = !isProduct && product.type === 'event_type';
    if (isEventProductType) {
      return 'event_types';
    }

    const isTeamProductType = !isProduct && product.type === 'team_type';
    if (isTeamProductType) {
      return 'team_types';
    }

    const isMembershipProductType = !isProduct && product.type === 'membership';
    if (isMembershipProductType) {
      return 'purchased_membership';
    }

    const isCreditPassProductType =
      !isProduct && product.type === 'credit_pass';
    if (isCreditPassProductType) {
      return 'purchased_credit_pass';
    }

    return 'events';
  };

  const getEventObject = ({ productPath, selectedProducts, product }) => ({
    target: {
      name: productPath,
      value: [
        ...selectedProducts,
        {
          id: product.id,
          type: product.type,
          product_type: product.product_type,
          name: product.name || product.title,
        },
      ],
    },
  });

  const handleProductSelect = product => {
    const isProduct = Boolean(product?.product_type);
    const allSelected = isProduct
      ? allSelectedProducts
      : allSelectedProductTypes;

    const isSelected = allSelected.find(p => p.id === product.id);

    if (isSelected) {
      const message = isProduct
        ? `Product "${product.name || product.title}" already selected.`
        : `Product type "${product.name}" already selected.`;

      MessageWindowActions.addMessage.defer(message);
      return;
    }

    const productPath = `purchased_products.${getSelectedProductType(product)}`;
    const selectedProducts = filter.getIn(productPath.split('.'), []);
    const eventObject = getEventObject({
      productPath,
      selectedProducts,
      product,
    });

    onChange([eventObject]);
  };

  const handleDeleteProduct = product => {
    const productPath = `purchased_products.${getSelectedProductType(product)}`;
    const selectedProducts = filter
      .getIn(productPath.split('.'), [])
      .filter(p => p.id !== product.id);

    const eventObject = {
      target: {
        name: productPath,
        value: selectedProducts,
      },
    };
    onChange([eventObject]);
  };

  const handleClear = (name, value) => {
    onChange([
      {
        target: { name, value },
      },
    ]);
  };

  return (
    <FiltersBlock title="Activity">
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.account_status_label', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <MultipleSelect
            fullWidth
            outline
            rounded={false}
            name="account status"
            classes={{
              root: 'filters-block__multiselect',
              menuItem: 'filters-block__multiselect-item',
              displayText: clsx(
                'filters-block__multiselect-selected-item',
                selectedStatuses.size === 0 &&
                  'filters-block__multiselect-empty'
              ),
            }}
            selectedItems={selectedStatuses.toJS()}
            items={accountStatuses}
            onChange={value =>
              onChange([
                {
                  target: { name: 'account_statuses', value: Set(value) },
                },
              ])
            }
          />
          {selectedStatuses.size > 0 && (
            <ClearAction
              onClear={() => handleClear('account_statuses', Set())}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.account_type_label', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Dropdown
            fullWidth
            name="account_type"
            placeholder={t('.account_type_placeholder', intl, __filenamespace)}
            classes={{
              menuLabel: 'filters-block__dropdown-item',
              selectedItem: 'filters-block__dropdown-selected-item',
            }}
            value={accountType}
            items={accountTypes}
            onChange={onChange}
          />
          {accountType && (
            <ClearAction onClear={() => handleClear('account_type', null)} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.member_status_label', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <MultipleSelect
            fullWidth
            outline
            rounded={false}
            name="member status"
            classes={{
              root: 'filters-block__multiselect',
              menuItem: 'filters-block__multiselect-item',
              displayText: clsx(
                'filters-block__multiselect-selected-item',
                selectedSubscriptionStatuses.size === 0 &&
                  'filters-block__multiselect-empty'
              ),
            }}
            selectedItems={selectedSubscriptionStatuses.toJS()}
            items={subscriptionStatuses}
            onChange={value =>
              onChange([
                {
                  target: { name: 'subscription_statuses', value: Set(value) },
                },
              ])
            }
          />
          {selectedSubscriptionStatuses.size > 0 && (
            <ClearAction
              onClear={() => handleClear('subscription_statuses', Set())}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.fpd_label', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Grid
            item
            container
            xs={12}
            className="filter-item"
            wrap={operatorIsNotBetween ? 'nowrap' : 'wrap'}
          >
            <Grid item xs={operatorIsNotBetween ? 6 : 12}>
              <Dropdown
                fullWidth
                placeholder={t('.fpd_placeholder', intl, __filenamespace)}
                name="first_purchase_date.operator"
                value={firstPurchaseDateOperator}
                classes={{
                  root: 'filters-block__text-input',
                  menuLabel: 'filters-block__dropdown-item',
                  selectedItem: 'filters-block__dropdown-selected-item',
                }}
                items={operators}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={operatorIsNotBetween ? 6 : 12}>
              <DateTimeField
                fullWidth
                errorText={filter.errors.getErrors(
                  ['first_purchase_date', 'start_date'],
                  intl
                )}
                value={
                  firstPurchaseDate ? moment(firstPurchaseDate) : undefined
                }
                onChange={value =>
                  onChange([
                    {
                      target: { name: 'first_purchase_date.start_date', value },
                    },
                  ])
                }
              />
            </Grid>
            {!operatorIsNotBetween && (
              <Grid item xs={12}>
                <DateTimeField
                  fullWidth
                  errorText={filter.errors.getErrors(
                    ['first_purchase_date', 'end_date'],
                    intl
                  )}
                  value={
                    firstPurchaseDateEnd
                      ? moment(firstPurchaseDateEnd)
                      : undefined
                  }
                  onChange={value =>
                    onChange([
                      {
                        target: { name: 'first_purchase_date.end_date', value },
                      },
                    ])
                  }
                />
              </Grid>
            )}
          </Grid>
          {(firstPurchaseDateOperator ||
            firstPurchaseDate ||
            firstPurchaseDateEnd) && (
            <ClearAction
              onClear={() => {
                handleClear('first_purchase_date.operator', undefined);
                handleClear('first_purchase_date.start_date', undefined);
                handleClear('first_purchase_date.end_date', undefined);
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.lpd_label', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justify="center"
          className="filter-item"
          wrap="nowrap"
        >
          <Grid
            item
            container
            xs={12}
            className="filter-item"
            wrap={operatorIsNotBetweenLast ? 'nowrap' : 'wrap'}
          >
            <Grid item xs={operatorIsNotBetweenLast ? 6 : 12}>
              <Dropdown
                fullWidth
                placeholder={t('.lpd_placeholder', intl, __filenamespace)}
                name="last_purchase_date.operator"
                value={filter.getIn(['last_purchase_date', 'operator'])}
                classes={{
                  root: 'filters-block__text-input',
                  menuLabel: 'filters-block__dropdown-item',
                  selectedItem: 'filters-block__dropdown-selected-item',
                }}
                items={operators}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={operatorIsNotBetweenLast ? 6 : 12}>
              <DateTimeField
                fullWidth
                errorText={filter.errors.getErrors(
                  ['last_purchase_date', 'start_date'],
                  intl
                )}
                value={lastPurchaseDate ? moment(lastPurchaseDate) : undefined}
                onChange={value =>
                  onChange([
                    {
                      target: { name: 'last_purchase_date.start_date', value },
                    },
                  ])
                }
              />
            </Grid>
            {!operatorIsNotBetweenLast && (
              <Grid item xs={12}>
                <DateTimeField
                  fullWidth
                  errorText={filter.errors.getErrors(
                    ['last_purchase_date', 'end_date'],
                    intl
                  )}
                  value={
                    lastPurchaseDateEnd
                      ? moment(lastPurchaseDateEnd)
                      : undefined
                  }
                  onChange={value =>
                    onChange([
                      {
                        target: { name: 'last_purchase_date.end_date', value },
                      },
                    ])
                  }
                />
              </Grid>
            )}
          </Grid>
          {(lastPurchaseDateOperator ||
            lastPurchaseDate ||
            lastPurchaseDateEnd) && (
            <ClearAction
              onClear={() => {
                handleClear('last_purchase_date.operator', undefined);
                handleClear('last_purchase_date.start_date', undefined);
                handleClear('last_purchase_date.end_date', undefined);
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.purchased_product', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProductSelector
            products={products}
            searchValue={pagination.getIn(['products', 'search'])}
            productsLoading={pagination.getIn(['products', 'loading'])}
            onProductSearch={onProductSearch}
            onProductSelect={handleProductSelect}
          />
        </Grid>

        {allSelectedProducts.length > 0 && (
          <Grid item xs={12} className="purchased-products">
            <div className="purchased-products__list">
              {allSelectedProducts.map((product, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={product.name}
                  classes={{
                    root: 'purchased-products__list-item',
                    label: 'purchased-products__list-item-label',
                  }}
                  onDelete={() => handleDeleteProduct(product)}
                />
              ))}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="fieldLabel">
            {t('.purchased_product_type', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProductSelector
            products={productTypes}
            searchValue={pagination.getIn(['product_types', 'search'])}
            placeholder={t('.product_type_placeholder', intl, __filenamespace)}
            noFoundLabel={t('.no_product_types', intl, __filenamespace)}
            productsLoading={pagination.getIn(['product_types', 'loading'])}
            onProductSearch={onProductTypeSearch}
            onProductSelect={handleProductSelect}
          />
        </Grid>
        {allSelectedProductTypes.length > 0 && (
          <Grid item xs={12} className="purchased-products">
            <div className="purchased-products__list">
              {allSelectedProductTypes.map((productType, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={productType.name}
                  classes={{
                    root: 'purchased-products__list-item',
                    label: 'purchased-products__list-item-label',
                  }}
                  onDelete={() => handleDeleteProduct(productType)}
                />
              ))}
            </div>
          </Grid>
        )}
      </Grid>
    </FiltersBlock>
  );
}

Activity.propTypes = {
  products: PropTypes.array,
  pagination: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(ContactFilter),
  onChange: PropTypes.func,
  onProductSearch: PropTypes.func,
  onProductTypeSearch: PropTypes.func,
};

export default memo(Activity);
