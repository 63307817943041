import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, RadioButton, Typography } from '@upperhand/playmaker';
import { FormattedMessage } from 'react-intl';

import { messageId, t } from 'shared/utils/LocaleUtils';

import { creditOperations } from 'user_management/shared/stores/EnrolledEventListStore.jsx';

function CancelRegistrationModalContent({
  intl,
  isFixedSchedule,
  waiveBalance,
  onWaiveChange,
  onCreditOperationChange,
  creditOperation = undefined,
}) {
  return (
    <>
      <Grid container className="cancel-registration-modal__container">
        <FormattedMessage
          id={messageId('.unschedule_warning', __filenamespace)}
        />
      </Grid>
      <Grid container className="cancel-registration-modal__options">
        <Typography variant="subtitle2">
          {t('.options', intl, __filenamespace)}
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        className="cancel-registration-modal__container"
      >
        <RadioButton
          size="4x"
          selectedValue={creditOperation}
          onChange={(_, value) => onCreditOperationChange(value)}
          orientation="vertical"
          options={[
            {
              label: (
                <Grid container direction="column">
                  {t('.refund_title', intl, __filenamespace)}
                  <Typography variant="body2" color="secondary">
                    {t('.refund_subtitle', intl, __filenamespace)}
                  </Typography>
                </Grid>
              ),
              value: creditOperations.REFUND_CREDIT,
              disabled: isFixedSchedule,
            },
            {
              label: (
                <Grid container direction="column">
                  {t('.revoke_title', intl, __filenamespace)}
                  <Typography variant="body2" color="secondary">
                    {t('.revoke_subtitle', intl, __filenamespace)}
                  </Typography>
                </Grid>
              ),
              value: creditOperations.REVOKE_CREDIT,
            },
          ]}
        />
        {creditOperation === creditOperations.REVOKE_CREDIT && (
          <Grid item className="cancel-registration-modal__radio-buttons">
            <Checkbox
              onChange={(_, checked) => onWaiveChange(checked)}
              checked={waiveBalance}
              label={t('.waive_checkbox', intl, __filenamespace)}
            />
            <Typography
              variant="body2"
              color="secondary"
              className="cancel-registration-modal__waive-notice"
            >
              {t('.credits_waive_notice', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container direction="column">
        <Grid item>
          <Typography variant="body2" component="strong">
            {t('.note_title', intl, __filenamespace)}
          </Typography>
          <Typography variant="body2" color="secondary" component="span">
            {t('.note_subtitle', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

CancelRegistrationModalContent.propTypes = {
  intl: PropTypes.object.isRequired,
  creditOperation: PropTypes.string,
  waiveBalance: PropTypes.bool.isRequired,
  onCreditOperationChange: PropTypes.func.isRequired,
  onWaiveChange: PropTypes.func.isRequired,
};

export default CancelRegistrationModalContent;
