import clsx from 'clsx';
import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Grid, Spinner } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import PaymentPlanDetailsDrawer from 'containers/reports/paymentPlanDetailsDrawer/PaymentPlanDetailsDrawer.jsx';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { ClientDataStore, PaymentPlanDataStore } from 'dataStores';
import { compose } from 'shared/utils/SharedUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';

import Header from './components/Header/Header.jsx';
import Table from './components/Table/Table.jsx';

import PaymentPlansActions from './Actions';
import PaymentPlansStore from './Store';

import PaymentDetailsDrawer from '../paymentDetailsDrawer/PaymentDetailsDrawer.jsx';

import './styles.scss';

function PaymentPlans({
  clientDataStore = {},
  eventId = null,
  paymentPlanDataStore = {},
  paymentPlansStore = {},
  intl,
}) {
  React.useEffect(() => {
    PaymentPlansActions.mounted({ eventId });
  }, [eventId]);

  const { clients } = clientDataStore;
  const { records: paymentPlans } = paymentPlanDataStore;
  const {
    paymentPlanIds,
    page,
    perPage,
    totalCount,
    isLoading,
    isLoadingClients,
    activeFilters,
    defaultFilters,
  } = paymentPlansStore;

  return (
    <div
      className={clsx(
        'payment-plans',
        `payment-plans__event-page${smallScreen() ? '-mobile' : ''}`
      )}
    >
      <Grid container spacing={2} alignContent="flex-start" justify="center">
        <Grid item xs={12}>
          <Header
            defaultFilters={defaultFilters}
            activeFilters={activeFilters}
          />
        </Grid>
        {isLoading || isLoadingClients ? (
          <FlexBoxJustifyCenter>
            <Spinner />
          </FlexBoxJustifyCenter>
        ) : (
          <Grid item xs={12}>
            <Table
              isLoading={isLoading || isLoadingClients}
              paymentPlanIds={paymentPlanIds}
              paymentPlans={paymentPlans}
              clients={clients}
              page={page}
              perPage={perPage}
              totalCount={totalCount || 0}
              intl={intl}
            />
          </Grid>
        )}
      </Grid>
      <PaymentPlanDetailsDrawer />
      <PaymentDetailsDrawer />
      <QuickpayModal />
      <RefundModal />
    </div>
  );
}

PaymentPlans.propTypes = {
  clientDataStore: PropTypes.object,
  eventId: PropTypes.number,
  intl: PropTypes.object.isRequired,
  paymentPlanDataStore: PropTypes.object,
  paymentPlansStore: PropTypes.object,
};

export default compose(
  altContainer({
    stores: {
      clientDataStore: ClientDataStore,
      paymentPlanDataStore: PaymentPlanDataStore,
      paymentPlansStore: PaymentPlansStore,
    },
  }),
  injectIntl
)(PaymentPlans);
