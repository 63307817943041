import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Hand({
  handColor = 'var(--color-green)',
  backgroundColor = 'var(--color-white)',
  ...props
}) {
  return (
    <SvgIcon
      viewBox="0 0 174.4 194.7"
      style={{ enableBackground: 'new 0 0 174.4 194.7' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <g>
        <path
          fill={backgroundColor}
          className="st0"
          d="M159.1,114.9c-6.6-3.6-13.4-6.9-20.3-9.8V0L90.3,14.6c-9.9,3-16.6,12-16.6,22.4v12.4L38.2,60
  		c-5.5,1.7-9.8,5.5-12,10.5l-9.6,2.9C6.8,76.3,0,85.4,0,95.7v54.6c0,4.9,2.6,9.3,6.9,11.7l51.9,29.9c6.5,3.7,14.4,3.7,20.9,0
  		l52.2-29.9c4.3-2.3,6.9-6.8,6.9-11.7v-6l35.6-20.9L159.1,114.9z"
        />
        <path
          fill={handColor}
          className="st1"
          d="M154.2,123.7c-8.5-4.7-17-8.6-25.4-11.8V13.4L93.1,24.2c-5.6,1.7-9.5,6.9-9.5,12.8v19.8L41.1,69.6
  		c-3.9,1.2-6.6,4.8-6.6,8.9v0l-15,4.5c-5.6,1.7-9.5,6.9-9.5,12.8v8.4v6.9V133v17.3v0c0,1.2,0.7,2.4,1.8,2.9l37.6,21.7
  		c0.5,0.3,1,0.4,1.6,0.4c0,0,0,0,0.1,0c1.8,0,3.3-1.5,3.3-3.3c0-1.1-0.6-2.1-1.4-2.7l-24.8-22c-2.9-2.5-4.5-6.2-4.5-10v-8.4
  		c4.1-2.4,8.4-4.4,12.9-6.1c2.1,5.2,8.7,16.1,23.2,19.5c1.7,6.3,6.3,11.3,8.7,13.5c1.7-17.6,11.9-24.6,11.9-24.6
  		c-13.6,0-18.8-7-20.6-13.8c3.6-0.4,7.2-0.6,10.9-0.6c16.3,0,31.9,3.7,44.6,8.1v12.5c0,3.8-1.6,7.5-4.5,10l-25.2,22.3
  		c-1.2,1.2-1.3,3-0.3,4.3c0.6,0.9,1.7,1.4,2.7,1.4c0.5,0,1.1-0.1,1.6-0.4l37.6-21.7c1.1-0.6,1.8-1.7,1.8-2.9v0v-20.1
  		c2.9,1.3,5.6,2.6,7.9,3.7L154.2,123.7z M97.3,52.7V42.1c0-2.9,1.9-5.5,4.8-6.4l13.1-3.9v15.5v14.3v45.7c-6-1.7-11.9-3-17.9-3.8
  		V66.9V52.7z M83.6,71v31.1c-2.1-0.1-4.2-0.2-6.2-0.2c-1.6,0-3.1,0.1-4.7,0.1V74.3L83.6,71z M59.1,78.4v24.8
  		c-3.8,0.6-7.5,1.3-10.9,2.1V81.7L59.1,78.4z M23.6,104.1V96l10.9-3.3v4.8v12c-4.1,1.5-7.7,3.1-10.9,4.7V111V104.1z"
        />
      </g>
    </SvgIcon>
  );
}

export default Hand;
