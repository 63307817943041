import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { List } from 'immutable';
import { Typography, Grid, Dropdown } from '@upperhand/playmaker';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import { t } from 'shared/utils/LocaleUtils';

function SavedFilters({
  filter = new ContactFilter(),
  filters = List(),
  onFilterSelect = () => null,
}) {
  const intl = useIntl();

  const selectorItems = filters
    .map(f => ({ label: f.name, value: f.id }))
    .toJS();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="fieldLabel">
          {t('.label', intl, __filenamespace)}
        </Typography>
      </Grid>
      <Grid item xs={12} className="filter-item">
        <Dropdown
          fullWidth
          placeholder={t('.placeholder', intl, __filenamespace)}
          name="selected_filter"
          value={filter.get('id')}
          classes={{
            menuLabel: 'filters-block__dropdown-item',
            selectedItem: 'filters-block__dropdown-selected-item',
          }}
          items={selectorItems}
          onChange={e => onFilterSelect(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

SavedFilters.propTypes = {
  filter: PropTypes.instanceOf(ContactFilter),
  filters: PropTypes.instanceOf(List),
  onFilterSelect: PropTypes.func,
};

export default memo(SavedFilters);
