import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { isLoggedIn, currentUser } from 'shared/utils/UserUtils.jsx';
import {
  BalancesDataStore,
  ClientDataStore,
  CreditPassDataStore,
  EventDataStore,
  EventTypeDataStore,
} from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

import OrderSummaryDrawerActions from './Actions';
import OrderSummaryDrawerStore from './Store.js';

import Content from './components/Content.jsx';

import './styles.scss';

function OrderSummaryDrawer({
  balancesDataStore = {},
  clientDataStore = {},
  creditPassDataStore = {},
  eventDataStore = {},
  eventTypeDataStore = {},
  orderSummaryDrawerStore = {},
  intl,
}) {
  const { balances } = balancesDataStore;
  const { clients } = clientDataStore;
  const { creditPasses } = creditPassDataStore;
  const { events } = eventDataStore;
  const { eventTypes } = eventTypeDataStore;
  const {
    dotsMenuAnchorEl,
    isLoadingBalances,
    isLoadingBalanceDetails,
    isLoadingClients,
    isLoadingCreditPasses,
    isLoadingEvents,
    isLoadingEventTypes,
    isOpen,
    order,
    orderStatus,
    refundedAmount,
    waivedAmount,
    paidAmount,
    remainingAmount,
  } = orderSummaryDrawerStore;
  const isStaff = currentUser().isStaff();
  const actions = isStaff
    ? [
        {
          icon: 'threeDots',
          onClick: OrderSummaryDrawerActions.toggleDotsMenu,
        },
      ]
    : undefined;
  return (
    <Drawer
      classes={{
        root: 'order-summary-drawer',
      }}
      open={isOpen}
      onClose={OrderSummaryDrawerActions.closeDrawer}
      headerType="light"
      title={t('.title', intl, __filenamespace)}
      actions={actions}
      content={
        isLoggedIn() ? (
          <Content
            balances={balances}
            clients={clients}
            creditPasses={creditPasses}
            dotsMenuAnchorEl={dotsMenuAnchorEl}
            events={events}
            eventTypes={eventTypes}
            isLoading={
              isLoadingBalances ||
              isLoadingBalanceDetails ||
              isLoadingClients ||
              isLoadingEvents ||
              isLoadingCreditPasses ||
              isLoadingEventTypes
            }
            order={order}
            orderStatus={orderStatus}
            refundedAmount={refundedAmount}
            waivedAmount={waivedAmount}
            paidAmount={paidAmount}
            remainingAmount={remainingAmount}
          />
        ) : (
          <SignInPrompt />
        )
      }
    />
  );
}

OrderSummaryDrawer.propTypes = {
  balancesDataStore: PropTypes.object,
  clientDataStore: PropTypes.object,
  creditPassDataStore: PropTypes.object,
  eventDataStore: PropTypes.object,
  eventTypeDataStore: PropTypes.object,
  orderSummaryDrawerStore: PropTypes.object,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      balancesDataStore: BalancesDataStore,
      clientDataStore: ClientDataStore,
      creditPassDataStore: CreditPassDataStore,
      eventDataStore: EventDataStore,
      eventTypeDataStore: EventTypeDataStore,
      orderSummaryDrawerStore: OrderSummaryDrawerStore,
    },
  })
)(OrderSummaryDrawer);
