import React from 'react';
import PropTypes from 'prop-types';
import { Confirmation, Typography } from '@upperhand/playmaker';

import WarningIcon from 'shared/components/icons/WarningIcon.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Registration from 'shared/records/Registration';

import CancelRegistrationModalContent from './CancelRegistrationModalContent.jsx';

import './CancelRegistrationModal.scss';

function CancelRegistrationModal({
  intl,
  event = new CustomerEvent(),
  registration = new Registration(),
  creditOperation = undefined,
  waiveBalance = false,
  onCancel = () => null,
  onConfirm = () => null,
  onCreditOperationChange = () => null,
  onWaiveChange = () => null,
}) {
  const [isAgree, setAgree] = React.useState(false);

  React.useEffect(() => {
    if (!registration) {
      setAgree(false);
    }
  }, [registration]);

  if (!event) return null;

  const isFixedSchedule = event.isFixedSchedule();
  const sspTurnedOn = event.isSSPTurnedOn();
  const needAdditionalCheck = isFixedSchedule && !sspTurnedOn;

  const handleCancel = () => {
    setAgree(false);
    onCancel();
  };

  const handleConfirm = () => {
    if (needAdditionalCheck && !isAgree) {
      setAgree(true);
    } else {
      onConfirm();
    }
  };

  return (
    <Confirmation
      level="warning"
      cancelButtonLabel={t('.cancel_btn', intl, __filenamespace)}
      confirmButtonLabel={t('.confirm_btn', intl, __filenamespace)}
      open={!!registration}
      hasCheckbox={false}
      confirmationDisabled={false}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={
        <Typography variant="dialogHeader">
          <WarningIcon width={20} height={18} />
          <span className="cancel-registration-modal__title">
            {t('.title', intl, __filenamespace)}
          </span>
        </Typography>
      }
    >
      {needAdditionalCheck && !isAgree ? (
        <Typography variant="body1" component="strong">
          {t('.ssp_off_warning', intl, __filenamespace)}
        </Typography>
      ) : (
        <CancelRegistrationModalContent
          intl={intl}
          waiveBalance={waiveBalance}
          creditOperation={creditOperation}
          onWaiveChange={onWaiveChange}
          onCreditOperationChange={onCreditOperationChange}
          isFixedSchedule={isFixedSchedule}
        />
      )}
    </Confirmation>
  );
}

CancelRegistrationModal.propTypes = {
  intl: PropTypes.object.isRequired,
  event: PropTypes.instanceOf(CustomerEvent),
  registration: PropTypes.instanceOf(Registration),
  creditOperation: PropTypes.string,
  waiveBalance: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onCreditOperationChange: PropTypes.func,
  onWaiveChange: PropTypes.func,
};

export default React.memo(CancelRegistrationModal);
