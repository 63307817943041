import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';

import Scrollbar from 'shared/components/Scrollbar.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  container: {
    height: 300,
    padding: 0,
  },
  currencyIcon: {
    display: 'none',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
  },
  tableCell: {
    color: 'var(--color-old-gray)',
    fontWeigh: 'normal',
    fontSize: 13,
    padding: '0 10px',
    height: 44,
  },
  fieldHeader: {
    color: '#807F7F',
    fontWeigh: '600',
    fontSize: 13,
    padding: '10px',
    textAlign: 'left',
  },
  tableRow: {
    verticalAlign: 'midddle',
  },
  tableFirstCell: {
    paddingLeft: 24,
  },
  tableLastCell: {
    paddingRight: 24,
  },
  oddRow: {
    backgroundColor: '#f9f9f9',
  },
  editedRow: {
    backgroundColor: 'var(--color-highlight-yellow)',
  },
};

const VariantTableRow = injectIntl(
  ({ intl, variant, index, purchaseOrderItem, isEditMode, isEdited }) => (
    <tr
      style={{
        ...styles.tableRow,
        ...(index % 2 === 0 ? styles.oddRow : {}),
        ...(isEdited ? styles.editedRow : {}),
      }}
    >
      <td style={{ ...styles.tableCell, ...styles.tableFirstCell }}>
        {variant.name}
      </td>
      <td style={{ ...styles.tableCell, width: '10em' }}>{variant.variant}</td>
      <td style={{ ...styles.tableCell, width: '8em' }}>
        <div>{variant.sku || ''}</div>
      </td>
      <td
        style={{ ...styles.tableCell, ...styles.tableLastCell, width: '8em' }}
      >
        {isEditMode ? (
          <CurrencyTextField
            variant="standard"
            converter={e => parseInt(e, 10)}
            errorText={purchaseOrderItem.errors.getErrors('unit_cost', intl)}
            inputStyle={{ width: '6em', paddingLeft: 0 }}
            name="unit_cost"
            onChange={(_, value) => {
              InventoryDialogActions.localUpdatePurchaseOrderItem(
                purchaseOrderItem,
                variant,
                'unit_cost',
                value
              );
            }}
            placeholder="0.00"
            symbolStyle={styles.currencyIcon}
            textFieldStyle={{ width: '6em' }}
            value={
              purchaseOrderItem.unit_cost !== 0
                ? purchaseOrderItem.unit_cost
                : ''
            }
          />
        ) : (
          `$${intl.formatNumber(purchaseOrderItem.unit_cost / 100, {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: false,
          })}`
        )}
      </td>
      <td
        style={{ ...styles.tableCell, ...styles.tableLastCell, width: '8em' }}
      >
        {isEditMode ? (
          <TextField
            variant="standard"
            error={!!purchaseOrderItem.errors.getErrors('quantity', intl)}
            helperText={purchaseOrderItem.errors.getErrors('quantity', intl)}
            name="quantity"
            onChange={event => {
              InventoryDialogActions.localUpdatePurchaseOrderItem(
                purchaseOrderItem,
                variant,
                'quantity',
                event.target.value
              );
            }}
            placeholder="0"
            style={{ width: '6em' }}
            type="number"
            value={purchaseOrderItem.quantity || ''}
          />
        ) : (
          purchaseOrderItem.quantity
        )}
      </td>
    </tr>
  )
);

function VariantTable({ inventoryDialogStore = {}, isProductPage = false }) {
  const {
    variants,
    totalCount,
    purchaseOrder,
    isEditMode,
    editedOrderItems,
    isInventory,
  } = inventoryDialogStore;

  return (
    <Scrollbar
      style={styles.container}
      vertical
      showLoader
      manualHeight
      onScroll={
        isProductPage
          ? InventoryDialogActions.variantsList
          : InventoryDialogActions.purchaseOrderItemList
      }
      dataCount={variants.size}
      totalCount={totalCount}
    >
      <table style={styles.table}>
        <thead>
          <tr>
            <td
              aria-label="name"
              style={{ ...styles.fieldHeader, ...styles.tableFirstCell }}
            >
              <FormattedMessage id={messageId('.name', __filenamespace)} />
            </td>
            <td aria-label="variant" style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.variant', __filenamespace)} />
            </td>
            <td aria-label="sku" style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.sku', __filenamespace)} />
            </td>
            <td
              aria-label="unit_cost"
              style={{ ...styles.fieldHeader, ...styles.tableLastCell }}
            >
              <FormattedMessage id={messageId('.unit_cost', __filenamespace)} />
            </td>
            <td
              aria-label="qty"
              style={{ ...styles.fieldHeader, ...styles.tableLastCell }}
            >
              <FormattedMessage id={messageId('.qty', __filenamespace)} />
            </td>
          </tr>
        </thead>
        <tbody>
          {variants.map((variant, index) => (
            <VariantTableRow
              key={variant.id}
              variant={variant}
              index={index}
              purchaseOrderItem={purchaseOrder.getItem(variant.id)}
              isEditMode={isEditMode}
              isEdited={
                !isInventory &&
                editedOrderItems.findIndex(
                  item => item.variant_id === variant.id
                ) >= 0
              }
            />
          ))}
          {variants.size === 0 && (
            <tr style={{ ...styles.tableRow }}>
              <td
                aria-label="empty_variant_table"
                colSpan={5}
                style={{
                  ...styles.tableCell,
                  ...styles.tableFirstCell,
                  textAlign: 'center',
                }}
              >
                <FormattedMessage
                  id={messageId('.empty_variant_table', __filenamespace)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Scrollbar>
  );
}

VariantTable.propTypes = {
  inventoryDialogStore: PropTypes.object,
  isProductPage: PropTypes.bool,
};

export default VariantTable;
