import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function RetailCard({ inner, orderItem = {}, intl }) {
  return inner ? (
    <>
      <Grid item>
        <img
          src={orderItem
            .getIn(['orderable', 'image'])
            .getAlternative('thumb_small')}
          alt={orderItem.get('primary_display_text')}
          height={50}
          width={50}
          style={{ marginRight: 20, objectFit: 'contain' }}
        />
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <Typography className="order-item__name" align="left" variant="h4">
            {orderItem.getIn(['orderable', 'name'])}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="left" variant="body3">
            {orderItem.getIn(['orderable', 'variant'])}
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid item>
        <Typography className="order-item__title" variant="subtitle2">
          {t('.qty', intl, __filenamespace, {
            qty: orderItem.get('display_quantity'),
          })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" align="right">
          <FormattedCurrency value={orderItem.get('total') || 0} fromCents />
        </Typography>
      </Grid>
    </>
  );
}

RetailCard.propTypes = {
  intl: PropTypes.object.isRequired,
  orderItem: PropTypes.object,
};

export default RetailCard;
