import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CurrencyTextField, Typography } from '@upperhand/playmaker';

import { green } from '@mui/material/colors';

import PricingUtils from 'event_mgmt/shared/utils/PricingUtils.jsx';
import USDIcon from 'shared/components/icons/USDIcon.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import './style.scss';

function PriceFields({
  feePlan,
  fieldName,
  handleInputChange,
  intl,
  value,
  errorText = '',
  isFree = false,
  interchangePriceLabel = (
    <FormattedMessage
      id={messageId('.interchange_price_label', __filenamespace)}
    />
  ),
  priceFieldLabel = (
    <FormattedMessage id={messageId('.price_field_label', __filenamespace)} />
  ),
  showFieldLabel = true,
}) {
  const label = feePlan.interchange_plus
    ? interchangePriceLabel
    : priceFieldLabel;

  const getProcessingFee = () => PricingUtils.processingFee(value, feePlan);

  const getTotalEarnings = () =>
    PricingUtils.fixedDecimal(PricingUtils.totalEarnings(value, feePlan));

  const processingFeeExtraInformation = () => {
    if (!feePlan) {
      return '';
    }

    const { percentage } = feePlan;
    const dollarAmount = feePlan.dollar_amount.toFixed(2);

    return (
      <FormattedMessage
        id={messageId('.fee_info', __filenamespace)}
        values={{ n: percentage, m: dollarAmount }}
      />
    );
  };

  return (
    <>
      <div className="group">
        <CurrencyTextField
          label={showFieldLabel ? label : undefined}
          fullWidth
          disabled={isFree}
          name={fieldName}
          errorText={errorText}
          onBlur={(e, newValue) => handleInputChange(e, newValue)}
          value={value}
          onChange={(e, newValue) => handleInputChange(e, newValue)}
        />
      </div>
      {!feePlan.interchange_plus && currentCustomer().display_fees && (
        <>
          <div className="group">
            <CurrencyTextField
              label={
                <div className="processing-fee-label">
                  <div>{t('.fee_field_label', intl, __filenamespace)}</div>
                  <div className="processing-fee-extra-information">
                    {processingFeeExtraInformation()}
                  </div>
                </div>
              }
              fullWidth
              disabled
              name="processing_fee"
              value={getProcessingFee()}
            />
          </div>
          <div className="total-earnings-container group">
            <Typography variant="caption">
              {t('.earnings_field_label', intl, __filenamespace)}
            </Typography>
            <div className="total-earnings-value">
              <USDIcon color={green.A400} className="total-revenue-currency" />
              <div className="total-earnings">
                {PricingUtils.fixedDecimal(getTotalEarnings())}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

// ...

PriceFields.propTypes = {
  errorText: PropTypes.string,
  feePlan: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isFree: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showFieldLabel: PropTypes.bool,
  interchangePriceLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  priceFieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default injectIntl(PriceFields);
