import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import AssignedStaff from 'calendar/components/views/shared/event_card/_AssignedStaff.jsx';
import MultipleAttendees from 'calendar/components/views/shared/event_card/_MultipleAttendees.jsx';
import EventTimeNote from 'calendar/components/views/shared/event_card/_EventTimeNote.jsx';
import {
  SingleAttendee,
  UserIndicator,
} from 'calendar/components/views/shared/event_card/_SingleAttendee.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  EventTimeMeta: hovering => ({
    position: 'relative',
    padding: '8px 16px 16px',
    opacity: hovering ? '1' : '0',
    transform: hovering ? 'translate3d(0, 0, 0)' : 'translate3d(0, 48px, 0)',
    transformOrigin: 'top center',
    transition: 'all 200ms ease-out',
    transitionProperty: 'opacity, transform',
  }),
  EventTimeMetaDivider: {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    height: '1px',
    backgroundColor: uhColors.dividerGrey,
  },
  Attendees: {
    padding: '4px 0',
  },
  EventType: {
    fontWeight: '600',
  },
};

function Title({ eventTime, title }) {
  return (
    <div>
      {title}
      {!eventTime.isEventStandard() && (
        <span style={{ fontStyle: 'italic' }}>
          <FormattedMessage
            id={messageId(eventTime.getStatusIntl(), __filenamespace)}
          />
        </span>
      )}
    </div>
  );
}

function EventTimeMeta({
  event,
  eventTime,
  clients,
  staff,
  hovering,
  memberships,
  session,
  intl,
  onClickViewNotes,
  onCloseNotes,
  scheduleType,
  handleAttendeesClick,
  hasNotesPermission,
  readOnly,
}) {
  return (
    <div style={styles.EventTimeMeta(hovering)}>
      <div style={styles.EventTimeMetaDivider} />
      {scheduleType ? (
        <div style={styles.Attendees}>
          {clients.size === 1 ? (
            <>
              <FlexBox
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Title eventTime={eventTime} title={event.title} />
                  <div style={styles.EventType}>{event.event_type.name}</div>
                </div>
                <div />
                <UserIndicator
                  client={clients.first()}
                  hovering={hovering}
                  eventTime={eventTime}
                  scheduleType={scheduleType}
                />
              </FlexBox>
              <AssignedStaff staff={staff} intl={intl} />
            </>
          ) : (
            <>
              <Title eventTime={eventTime} title={event.title} />
              <div style={styles.EventType}>{event.event_type.name}</div>
              <AssignedStaff staff={staff} intl={intl} />
            </>
          )}
        </div>
      ) : (
        <>
          {clients.size === 1 && (
            <FlexBox
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <SingleAttendee
                eventTime={eventTime}
                client={clients.first()}
                membership={memberships.get(
                  clients.first().active_membership_id
                )}
                hovering={hovering}
                handleAttendeesClick={handleAttendeesClick}
              />
              <UserIndicator
                client={clients.first()}
                hovering={hovering}
                eventTime={eventTime}
                scheduleType={scheduleType}
              />
            </FlexBox>
          )}
          {clients.size > 1 && (
            <MultipleAttendees
              event={event}
              session={session}
              eventTime={eventTime}
              clients={clients}
              handleAttendeesClick={handleAttendeesClick}
              intl={intl}
            />
          )}
          <AssignedStaff staff={staff} intl={intl} />
          {hasNotesPermission && (
            <EventTimeNote
              event={event}
              eventTime={eventTime}
              clients={clients}
              labelText={t('.view_note', intl, __filenamespace)}
              onClickViewNotes={onClickViewNotes}
              onCloseNotes={onCloseNotes}
              readOnly={readOnly}
            />
          )}
        </>
      )}
    </div>
  );
}

export default injectIntl(EventTimeMeta);
