import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Button, DateTimePicker, Grid } from '@upperhand/playmaker';
import { List } from 'immutable';

import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';

import MiniCalendar from 'shared/components/calendar/MiniCalendar.jsx';

import { convertToDate } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';

function BlackoutDates({
  intl = {},
  availabilitySchedule = new AvailabilitySchedule(),
  exclusions = List(),
  onRemove = () => null,
}) {
  const shouldSelectDate = date =>
    availabilitySchedule.isDateExcluded(date)
      ? false
      : availabilitySchedule.includeDate(date);

  const shouldDisableDate = date => availabilitySchedule.isDateSpecific(date);

  const onDateSelect = (_, date) => {
    EventSessionCreationActions.addBlackoutDate(date);
  };

  const startDate = availabilitySchedule.get('start_date');

  return (
    <div className="events-session-creation__form-item">
      <div className="events-session-creation__form-item">
        <MiniCalendar
          locale="en-US"
          firstDayOfWeek={0}
          initialDate={convertToDate(startDate)}
          shouldDisableDate={shouldDisableDate}
          shouldSelectDate={shouldSelectDate}
          dayTooltip={() => null}
          onSelectDate={onDateSelect}
        />
      </div>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.blackout', intl, __filenamespace)}
      </Typography>
      <Button
        icon="add"
        type="tertiary"
        classes={{ root: 'add-blackout-day' }}
        onClick={() => EventSessionCreationActions.addBlackoutDate(moment())}
      >
        {t('.add', intl, __filenamespace)}
      </Button>
      {exclusions?.map((exclusion, index) => (
        <Grid container spacing={1} key={uuidv4()}>
          <Grid item xs={10}>
            <DateTimePicker
              autoOk
              fullWidth
              showClearIcon={false}
              type="date"
              placeholder="MM/DD/YYYY"
              value={exclusion}
              onChange={date =>
                EventSessionCreationActions.updateBlackoutDate({
                  date,
                  index,
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              rounded
              icon="delete"
              type="tertiary"
              classes={{ root: 'remove-btn' }}
              onClick={() => onRemove(index)}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

BlackoutDates.propTypes = {
  intl: PropTypes.object,
  availabilitySchedule: PropTypes.instanceOf(AvailabilitySchedule),
  exclusions: PropTypes.instanceOf(List),
  onRemove: PropTypes.func,
};

export default injectIntl(BlackoutDates);
