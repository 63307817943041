import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

function UserDetailAttribute({
  label = '',
  action = null,
  children = null,
  style = {},
}) {
  return (
    <div style={merge({ marginTop: 20 }, style)}>
      <FlexBoxJustify style={{ alignItems: 'center' }}>
        <div style={{ color: '#9d9d9d' }}>{label}</div>
        {action}
      </FlexBoxJustify>
      <div style={{ marginTop: 10 }}>
        {children || (
          <FormattedMessage id={messageId('.none_provided', __filenamespace)} />
        )}
      </div>
    </div>
  );
}

UserDetailAttribute.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  action: PropTypes.node,
  style: PropTypes.object,
};

export default UserDetailAttribute;
