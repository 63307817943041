import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import MembershipRetailDiscount from 'shared/records/MembershipRetailDiscount.jsx';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';
import MembershipCreditPassDiscount from 'shared/records/MembershipCreditPassDiscount.jsx';

function SelectedDiscountsList({
  discount = new MembershipEventDiscount(),
  discountField = '',
  items = List(),
  onDelete = () => {},
}) {
  const selectedItems = items?.filter(et =>
    discount.get(discountField)?.has(et.id)
  );

  return (
    <Box className="selected-discount-items">
      {selectedItems.map(item => (
        <Box
          key={item.id}
          className="selected-discount-items__item"
          sx={{ borderLeftColor: item.color || 'var(--color-primary)' }}
        >
          <p>{item.name}</p>
          <IconButton
            className="selected-discount-items__item-delete"
            onClick={() => onDelete(item.id)}
          >
            <Close />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}

SelectedDiscountsList.propTypes = {
  discount: PropTypes.oneOfType([
    PropTypes.instanceOf(MembershipEventDiscount),
    PropTypes.instanceOf(MembershipCreditPassDiscount),
    PropTypes.instanceOf(MembershipRetailDiscount),
  ]),
  discountField: PropTypes.string,
  items: PropTypes.instanceOf(List),
  onDelete: PropTypes.func,
};

export default memo(SelectedDiscountsList);
