import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer, Button } from '@upperhand/playmaker';

import Schedule from 'containers/eventSessionCreation/components/Schedule.jsx';
import Participants from 'containers/eventSessionCreation/components/Participants.jsx';
import StaffAndResources from 'containers/eventSessionCreation/components/StaffAndResources.jsx';
import EditConfirm from 'containers/eventSessionCreation/components/EditConfirm.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import EventSessionCreationActions from './Actions';
import EventSessionCreationStore, { STEPS, TEAMS_STEPS } from './Store';

import './styles.scss';

const getDrawerTitle = (creatingMode, isTeamsPage, intl) => {
  if (creatingMode) {
    return isTeamsPage
      ? t('.team_title', intl, __filenamespace)
      : t('.title', intl, __filenamespace);
  }
  return isTeamsPage
    ? t('.team_title_edit', intl, __filenamespace)
    : t('.title_edit', intl, __filenamespace);
};

function SessionCreationDrawer({
  intl = {},
  eventSessionCreationStore = {},
  isTeamsPage = false,
}) {
  const {
    eventId,
    currentStep,
    creatingSchedule,
    creatingMode,
    locationsLoading,
    showEditConfirm,
  } = eventSessionCreationStore;
  const steps = Object.keys(isTeamsPage ? TEAMS_STEPS : STEPS);

  const handleNext = () => {
    const index = steps.indexOf(currentStep);

    if (index < steps.length - 1) {
      EventSessionCreationActions.setCurrentStep(steps[index + 1]);
    }
  };

  const handlePrev = () => {
    const index = steps.indexOf(currentStep);

    if (index > 0) {
      EventSessionCreationActions.setCurrentStep(steps[index - 1]);
    }
  };

  const handleSubmit = () => {
    if (creatingMode) {
      EventSessionCreationActions.createSchedule();
    } else {
      EventSessionCreationActions.updateSchedule();
    }
  };

  const isLastStep = currentStep === STEPS.STAFF_AND_RESOURCES;
  const submitText = creatingMode ? 'Create Schedule' : 'Update Schedule';

  return (
    <Drawer
      classes={{ root: 'events-session-creation' }}
      open={!!eventId}
      title={getDrawerTitle(creatingMode, isTeamsPage, intl)}
      onClose={() => EventSessionCreationActions.toggleDrawer({})}
      content={
        <SpinWhileLoading isLoading={locationsLoading} absolute={false}>
          {currentStep === STEPS.SCHEDULE && (
            <Schedule isTeamsPage={isTeamsPage} />
          )}
          {currentStep === STEPS.PARTICIPANTS && <Participants />}
          {currentStep === STEPS.STAFF_AND_RESOURCES && <StaffAndResources />}
          <EditConfirm
            open={showEditConfirm}
            isTeamsPage={isTeamsPage}
            onClose={EventSessionCreationActions.toggleEditConfirm}
            onConfirm={() => EventSessionCreationActions.updateSchedule(true)}
          />
        </SpinWhileLoading>
      }
      footer={
        <div className="events-session-creation__footer">
          <Button
            type="secondary"
            disabled={currentStep === STEPS.SCHEDULE}
            classes={{ root: 'action-btn' }}
            onClick={handlePrev}
          >
            Previous
          </Button>
          <Button
            disabled={creatingSchedule}
            classes={{ root: 'action-btn' }}
            onClick={isLastStep ? handleSubmit : handleNext}
          >
            {isLastStep ? submitText : 'Next'}
          </Button>
        </div>
      }
    />
  );
}

SessionCreationDrawer.propTypes = {
  intl: PropTypes.object,
  eventSessionCreationStore: PropTypes.object,
  isTeamsPage: PropTypes.bool,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      eventSessionCreationStore: EventSessionCreationStore,
    },
  })
)(SessionCreationDrawer);
