import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { Avatar, Card, Grid, Typography } from '@upperhand/playmaker';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { t } from 'shared/utils/LocaleUtils';

import { Balance } from 'records';
import Client from 'shared/records/Client.jsx';
import ScheduledPayment from 'records/ScheduledPayment';

import BalanceDetailsDrawerActions from 'containers/reports/balanceDetailsDrawer/Actions';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

function RowDesktop({
  balance,
  columns,
  intl,
  scheduledPayment = null,
  client = new Client(),
}) {
  const paidAmount = scheduledPayment
    ? scheduledPayment.paidAmount
    : balance.get('paid');

  const unpaidAmount = scheduledPayment
    ? scheduledPayment.unpaidAmount
    : Math.abs(balance.get('balanceRemaining'));
  const effectiveAt = scheduledPayment?.effectiveAt
    ? moment(scheduledPayment?.effectiveAt)
    : moment(balance.get('effectiveAt'));

  // Have to add one because date / time cannot be toggled.
  const activeItems = columns.filter(c => c).count() + 1;

  return (
    <Card
      classes={{
        root: 'balances-report__row',
        content: 'balances-report__row-content',
      }}
      contentDirection="row"
      onClick={() =>
        BalanceDetailsDrawerActions.openDrawer(balance.get('compoundId'))
      }
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        <Grid
          item
          className={`balances-report__row-item even-cols-${activeItems}`}
        >
          <Typography variant="body2">
            {effectiveAt.isSame(moment(), 'day')
              ? t('.date_today', intl, __filenamespace, {
                  date: effectiveAt.format('h:mma'),
                })
              : effectiveAt.format('MM/DD/YYYY • h:mma')}
          </Typography>
        </Grid>
        {columns.get('buyer') && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Button
              fullWidth
              disableRipple
              disableElevation
              disableFocusRipple
              color="dark"
              className="balances-report__buyer-desktop"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                ClientProfileDrawerActions.mounted({ clientId: client.id });
              }}
            >
              <Avatar
                size="1x"
                src={
                  client && client.get('profile_image').getAlternative('avatar')
                }
                classes={{
                  root: 'balances-report__avatar',
                }}
              >
                {balance.get('clientName')}
              </Avatar>
              <Typography variant="body2">
                {balance.get('clientName')}
              </Typography>
            </Button>
          </Grid>
        )}
        {columns.get('product') && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">{balance.get('product')}</Typography>
          </Grid>
        )}
        {columns.get('productType') && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {balance.productTypeNeedsIntl
                ? t(`.${balance.get('productType')}`, intl, __filenamespace)
                : balance.get('productType')}
            </Typography>
          </Grid>
        )}
        {columns.get('paymentType') && !scheduledPayment && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            {balance.get('paymentType') && (
              <Typography variant="body2">
                {balance
                  .get('paymentType')
                  .split(',')
                  .map(type =>
                    t(`.payment_method_${type}`, intl, __filenamespace)
                  )
                  .join(', ')}
              </Typography>
            )}
          </Grid>
        )}
        {columns.get('paymentType') && scheduledPayment && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              {t('.scheduled_payment', intl, __filenamespace)}
            </Typography>
          </Grid>
        )}
        {columns.get('paid') && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              <FormattedCurrency value={paidAmount} fromCents />
            </Typography>
          </Grid>
        )}
        {columns.get('balance') && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">
              <FormattedCurrency value={unpaidAmount} fromCents />
            </Typography>
          </Grid>
        )}
        {columns.get('quantity') && (
          <Grid
            item
            className={`balances-report__row-item even-cols-${activeItems}`}
          >
            <Typography variant="body2">{balance.get('quantity')}</Typography>
          </Grid>
        )}
      </Grid>
      <div className="balances-report__row-item balances-report__row-action">
        <ChevronRightIcon />
      </div>
    </Card>
  );
}

RowDesktop.propTypes = {
  balance: PropTypes.instanceOf(Balance).isRequired,
  client: PropTypes.instanceOf(Client),
  columns: PropTypes.instanceOf(Map).isRequired,
  scheduledPayment: PropTypes.instanceOf(ScheduledPayment),
  intl: PropTypes.object.isRequired,
};

export default RowDesktop;
