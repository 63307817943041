import * as React from 'react';
import PropTypes from 'prop-types';
import Coupon from 'records/Coupon';

import ViewCard from './ViewCard.jsx';
import EditForm from './EditForm.jsx';

function CouponCard({
  actions = {},
  coupon = new Coupon(),
  createModal = false,
  editMode = false,
  errors = {},
  updatedCoupon = new Coupon(),
  intl,
}) {
  return editMode ? (
    <EditForm
      actions={actions}
      createModal={createModal}
      coupon={updatedCoupon}
      errors={errors}
      intl={intl}
    />
  ) : (
    <ViewCard actions={actions} coupon={coupon} intl={intl} />
  );
}

CouponCard.propTypes = {
  actions: PropTypes.object,
  coupon: PropTypes.object,
  createModal: PropTypes.bool,
  editMode: PropTypes.bool,
  errors: PropTypes.object,
  updatedCoupon: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default CouponCard;
