import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Button } from '@upperhand/playmaker';

import CurrencyTextField from 'shared/components/CurrencyTextField.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import './styles.scss';

function AccountCreditForm({
  intl = {},
  total = 0,
  amount = null,
  amountAvailable = 0,
  onAccountCreditChanged = () => null,
}) {
  const [creditsAmount, setCreditsAmount] = useState(0);
  const disableApplying =
    creditsAmount > total ||
    creditsAmount > amountAvailable ||
    !amountAvailable;

  const handleToggle = () => {
    onAccountCreditChanged(amount === null ? 0 : null);
    setCreditsAmount(0);
  };

  const handleApply = () => {
    onAccountCreditChanged(creditsAmount);
  };

  if (amount === null) {
    return (
      <div className="qp-account-credit-apply">
        <div role="presentation" onClick={handleToggle}>
          <Typography>{t('.apply', intl, __filenamespace)}</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="qp-account-credit">
      {amount > 0 && (
        <div className="qp-account-credit__applied-container">
          <Typography variant="body1">
            <FormattedCurrency fromCents value={amount} />
            &nbsp;
            {t('.account_credit', intl, __filenamespace)}
          </Typography>
          <Typography variant="body1">
            <FormattedCurrency fromCents value={total} />
          </Typography>
        </div>
      )}
      {amount === 0 && (
        <div className="qp-account-credit__form-container">
          <div>
            <Typography variant="body1">
              {t('.account_credit', intl, __filenamespace)}
            </Typography>
            <Typography variant="caption">
              {t('.amount_available', intl, __filenamespace)}
              &nbsp;
              <FormattedCurrency fromCents value={amountAvailable} />
            </Typography>
          </div>
          <CurrencyTextField
            disabled={!amountAvailable}
            variant="standard"
            value={creditsAmount}
            converter={e => parseInt(e, 10)}
            onChange={(e, value) => {
              e.preventDefault();
              setCreditsAmount(
                value < amountAvailable ? value : amountAvailable
              );
            }}
          />
          <Button
            fullWidth
            disabled={disableApplying}
            classes={{ root: 'qp-account-credit__apply-btn' }}
            onClick={handleApply}
          >
            {t('.apply_btn', intl, __filenamespace)}
          </Button>
        </div>
      )}
      <div className="qp-account-credit__remove-container">
        <div role="presentation" onClick={handleToggle}>
          <Typography>{t('.remove', intl, __filenamespace)}</Typography>
        </div>
      </div>
    </div>
  );
}

AccountCreditForm.propTypes = {
  intl: PropTypes.object,
  total: PropTypes.number,
  amount: PropTypes.number,
  amountAvailable: PropTypes.number,
  onAccountCreditChanged: PropTypes.func,
};

export default compose(memo, injectIntl)(AccountCreditForm);
