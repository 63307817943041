import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Dropdown, Grid, Typography } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils';

import LocationStore from 'shared/stores/LocationStore.jsx';
import TimeField from './components/TimeField.jsx';
import ConfirmChangesModal from './components/ConfirmChangesModal.jsx';

import EditDateTimeActions from './actions';
import EditDateTimeStore from './store';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    flexGrow: 1,
  },
  childrenContainer: {
    margin: '16px 8px 0',
  },
  title: {
    marginBottom: 16,
  },
  formElement: {
    margin: '16px 0 8px 0',
  },
  textField: {
    '& .UH-MuiInputBase-root': {
      height: '42px',
      borderRadius: '2px',
    },
  },
};

const schedulingDeadlineUnits = [
  { label: 'Min', value: 'min' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
];

const schedulingTimeFrameUnits = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

const cancellationUnits = [
  { label: 'Min', value: 'min' },
  { label: 'Hour', value: 'hour' },
];

function EditDateTime({
  sessionId,
  intl = {},
  locationStore = {},
  isTeamEvent = false,
  editDateTimeStore = {},
}) {
  const { allLocations: locations } = locationStore;
  const locationsItems = locations
    .map(l => ({
      label: l.name || '',
      value: l.id,
    }))
    .toArray();
  React.useEffect(() => {
    EditDateTimeActions.mounted.defer(sessionId);
  }, [sessionId]);

  const getValue = ({ e, defaultValue = 1, minValue = 1 }) => {
    const value = parseInt(e.target.value, 10);

    return value >= minValue ? value : defaultValue;
  };

  const {
    data,
    isConfirmationOpen,
    scheduleDeadlineUnit,
    cancellationDeadlineUnit,
    schedulingTimeFrameUnit,
    locationId,
  } = editDateTimeStore;
  const scheduleDeadlineValue = data.get('scheduling_deadline');
  const cancellationDeadlineValue = data.get('cancellation_deadline');
  const schedulingTimeFrameValue = data.get('scheduling_timeframe');
  const isFixedSchedule = data.get('is_fixed_schedule');
  const isSspOn = data.get('is_ssp_on');
  const maxSize = data.get('max_size', 999);
  const minMaxSize = data.get('client_ids').size || 1;
  const showMaxSize = !isFixedSchedule || (isFixedSchedule && isSspOn);

  return (
    <div className="edit-date-time-container" style={styles.mainContainer}>
      <Divider />
      <div style={styles.childrenContainer}>
        <div style={styles.title}>
          <Typography variant="h5">
            <FormattedMessage id={messageId('.title', __filenamespace)} />
          </Typography>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t('.labels.date', intl, __filenamespace)}
            </Typography>
            <DatePicker
              disableMaskedInput
              inputFormat="ddd, MMM DD, YYYY"
              value={data.get('date')}
              renderInput={params => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField fullWidth style={{ marginTop: '5px' }} {...params} />
              )}
              DialogProps={{ disableEnforceFocus: true }}
              onChange={value => EditDateTimeActions.update('date', value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {t('.labels.start_time', intl, __filenamespace)}
            </Typography>
            <TimeField
              disableKeyboardEditing
              value={data.get('startTime')}
              onAccept={value => EditDateTimeActions.update('startTime', value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">
              {t('.labels.end_time', intl, __filenamespace)}
            </Typography>
            <TimeField
              disableKeyboardEditing
              value={data.get('endTime')}
              onAccept={value => EditDateTimeActions.update('endTime', value)}
            />
          </Grid>
          {isTeamEvent && (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                className="events-session-creation__form-title"
              >
                {t('.location', intl, __filenamespace)}
              </Typography>
              <Box className="events-session-creation__form-item">
                <Dropdown
                  fullWidth
                  value={locationId}
                  onChange={e => {
                    EditDateTimeActions.handleLocationChange(e.target.value);
                  }}
                  items={locationsItems}
                />
              </Box>
            </Grid>
          )}
          {showMaxSize && !isTeamEvent && (
            <Grid item xs={12}>
              <div className="edit-date-time-container__form-title">
                <Typography variant="h5">
                  {t('.max_size', intl, __filenamespace)}
                </Typography>
              </div>
              <div className="events-session-creation__form-item">
                <TextField
                  value={maxSize}
                  fullWidth
                  type="number"
                  inputProps={{ min: minMaxSize }}
                  onChange={e =>
                    EditDateTimeActions.update(
                      'max_size',
                      getValue({
                        e,
                        minValue: minMaxSize,
                        defaultValue: minMaxSize,
                      })
                    )
                  }
                />
              </div>
            </Grid>
          )}
          {!isFixedSchedule && !isTeamEvent && (
            <>
              <Grid item xs={12}>
                <div className="edit-date-time-container__form-title">
                  <Typography variant="h5">
                    {t('.scheduling_deadline', intl, __filenamespace)}
                  </Typography>
                  <div className="checkbox">
                    <Checkbox
                      checked={!!scheduleDeadlineValue}
                      onChange={() =>
                        EditDateTimeActions.update(
                          'scheduling_deadline',
                          scheduleDeadlineValue
                            ? null
                            : getValue({ e: { target: { value: 1 } } })
                        )
                      }
                    />
                  </div>
                </div>
                <div className="events-session-creation__form-item">
                  {!!scheduleDeadlineValue && (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={5}>
                        <Typography variant="fieldLabel">
                          {t('.before_session', intl, __filenamespace)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          sx={styles.textField}
                          value={scheduleDeadlineValue}
                          fullWidth
                          type="number"
                          onChange={e =>
                            EditDateTimeActions.update(
                              'scheduling_deadline',
                              getValue({ e })
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Dropdown
                          fullWidth
                          value={scheduleDeadlineUnit}
                          items={schedulingDeadlineUnits}
                          onChange={e =>
                            EditDateTimeActions.setSchedulingUnit(
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {!scheduleDeadlineValue && (
                    <Typography>
                      {t('.scheduling_info', intl, __filenamespace)}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="edit-date-time-container__form-title">
                  <Typography variant="h5">
                    {t('.cancellation_deadline', intl, __filenamespace)}
                  </Typography>
                  <div className="checkbox">
                    <Checkbox
                      checked={!!cancellationDeadlineValue}
                      onChange={() =>
                        EditDateTimeActions.update(
                          'cancellation_deadline',
                          cancellationDeadlineValue
                            ? null
                            : getValue({ e: { target: { value: 24 } } })
                        )
                      }
                    />
                  </div>
                </div>
                <div className="events-session-creation__form-item">
                  {!!cancellationDeadlineValue && (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={5}>
                        <Typography variant="fieldLabel">
                          {t('.before_session', intl, __filenamespace)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          sx={styles.textField}
                          value={cancellationDeadlineValue}
                          fullWidth
                          type="number"
                          onChange={e =>
                            EditDateTimeActions.update(
                              'cancellation_deadline',
                              getValue({ e })
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Dropdown
                          fullWidth
                          value={cancellationDeadlineUnit}
                          items={cancellationUnits}
                          onChange={e =>
                            EditDateTimeActions.setCancellationUnit(
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {!cancellationDeadlineValue && (
                    <Typography>
                      {t('.cancellation_info', intl, __filenamespace)}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="edit-date-time-container__form-title">
                  <Typography variant="h5">
                    {t('.scheduling_timeframe', intl, __filenamespace)}
                  </Typography>
                  <div className="checkbox">
                    <Checkbox
                      checked={!!schedulingTimeFrameValue}
                      onChange={() =>
                        EditDateTimeActions.update(
                          'scheduling_timeframe',
                          schedulingTimeFrameValue
                            ? null
                            : getValue({ e: { target: { value: 1 } } })
                        )
                      }
                    />
                  </div>
                </div>
                <div className="events-session-creation__form-item">
                  {!!schedulingTimeFrameValue && (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={5}>
                        <Typography variant="fieldLabel">
                          {t('.from_today', intl, __filenamespace)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          sx={styles.textField}
                          value={schedulingTimeFrameValue}
                          fullWidth
                          type="number"
                          onChange={e =>
                            EditDateTimeActions.update(
                              'scheduling_timeframe',
                              getValue({ e })
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Dropdown
                          fullWidth
                          value={schedulingTimeFrameUnit}
                          items={schedulingTimeFrameUnits}
                          onChange={e =>
                            EditDateTimeActions.setSchedulingTimeFrameUnit(
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                  {!schedulingTimeFrameValue && (
                    <Typography>
                      {t('.scheduling_timeframe_info', intl, __filenamespace)}
                    </Typography>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <ConfirmChangesModal
        isOpen={isConfirmationOpen}
        intl={intl}
        sessionId={sessionId}
        note={data.get('note')}
      />
    </div>
  );
}

EditDateTime.propTypes = {
  editDateTimeStore: PropTypes.object,
  intl: PropTypes.object,
  sessionId: PropTypes.string.isRequired,
  locationStore: PropTypes.object,
  isTeamEvent: PropTypes.bool,
};

export default compose(
  altContainer({
    stores: {
      editDateTimeStore: EditDateTimeStore,
      locationStore: LocationStore,
    },
  })
)(EditDateTime);
