import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ContentModal,
  Grid,
  Typography,
  TextField,
} from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import CancelCompleteScheduleActions from './Actions';
import CancelCompleteScheduleModalStore from './Store';

function CompleteScheduleModal({
  cancelCompleteScheduleActions = {},
  cancelCompleteScheduleModalStore = {},
  intl,
}) {
  const {
    completeScheduleNoteUpdated,
    completeScheduleModalCancelClicked,
    completeScheduleModalConfirmClicked,
  } = cancelCompleteScheduleActions;
  const {
    completeScheduleModalOpen,
    completeScheduleModalNote,
    futureRegistrationCount,
  } = cancelCompleteScheduleModalStore;

  return (
    <ContentModal
      open={completeScheduleModalOpen}
      title={t('.complete_schedule_modal_title', intl, __filenamespace)}
      onSuccess={completeScheduleModalConfirmClicked}
      onAbort={completeScheduleModalCancelClicked}
    >
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Typography variant="body1">
            <FormattedMessage
              id={messageId('.complete_schedule_modal_body', __filenamespace)}
              values={{
                count: futureRegistrationCount,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t(
              '.complete_schedule_modal_note_label',
              intl,
              __filenamespace
            )}
            fullWidth
            value={completeScheduleModalNote}
            onChange={e => completeScheduleNoteUpdated(e.target.value)}
          />
        </Grid>
      </Grid>
    </ContentModal>
  );
}

CompleteScheduleModal.propTypes = {
  cancelCompleteScheduleActions: PropTypes.object,
  intl: PropTypes.object.isRequired,
  cancelCompleteScheduleModalStore: PropTypes.object,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      cancelCompleteScheduleModalStore: CancelCompleteScheduleModalStore,
    },
    actions: {
      cancelCompleteScheduleActions: CancelCompleteScheduleActions,
    },
  })
)(CompleteScheduleModal);
