import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Drawer,
  Grid,
  CurrencyTextField,
  Typography,
} from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';

import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

function CalculatorDrawer({
  intl,
  open,
  onClose,
  cashIn = 0,
  cashToday = 0,
  onFieldChange = () => {},
}) {
  return (
    <Drawer
      title={t('.title', intl, __filenamespace)}
      open={open}
      classes={{ root: 'calculator-drawer' }}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrencyTextField
              name="cashIn"
              label={t('.cash_in', intl, __filenamespace)}
              placeholder={t('.cash_in_placeholder', intl, __filenamespace)}
              value={cashIn || ''}
              onChange={e => onFieldChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyTextField
              disabled
              name="cashToday"
              label={t('.cash_today', intl, __filenamespace)}
              value={cashToday}
              onChange={() => null}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography variant="fieldLabel">
                {t('.total', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={6} className="calculator-drawer__total">
              <FormattedCurrency value={cashIn + cashToday} />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}

CalculatorDrawer.propTypes = {
  intl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  cashIn: PropTypes.number,
  cashToday: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func,
};

export default compose(memo, injectIntl)(CalculatorDrawer);
