import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';
import ClearIcon from '@mui/icons-material/Clear';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

import BalancesReportActions from 'containers/reports/balancesReport/Actions';
import { Filters, FilterPeriod } from 'containers/reports/types';
import { FlexBox } from 'shared/components/FlexBox.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { formatCurrency } from 'shared/utils/FormattingUtils.jsx';

function ActiveFilters({
  intl,
  onRemoveFilterChips,
  activeFilters = {},
  defaultFilters = {},
}) {
  const getFilterButton = (key, value) => {
    let label = value;
    if (key === Filters.PERIOD) {
      if (value.value === FilterPeriod.all_time) {
        return null;
      }

      if (
        value.value === FilterPeriod.today ||
        value.value === FilterPeriod.yesterday
      ) {
        label = value.from.format('MM/DD/YYYY');
      } else {
        label = `
          ${value.from.format('MM/DD/YYYY')}
          -
          ${value.to.format('MM/DD/YYYY')}
        `;
      }
    }

    if (key === Filters.LOCATION) {
      if (value && value.length === defaultFilters.get('location').length) {
        return null;
      }
      label = `${value.length} location${value.length > 1 ? 's' : ''}`;
    }

    if (key === Filters.PAYMENT_TYPES) {
      if (value.length === defaultFilters.get('paymentTypes').length) {
        return null;
      }
      label = value
        .map(pt => t(`.payment_method_${pt}`, intl, __filenamespace))
        .join(', ');
    }

    if (key === Filters.BALANCE_REMAINING_MIN) {
      label = t('.balance_min', intl, __filenamespace, {
        value: formatCurrency({ value }),
      });
    }

    if (key === Filters.BALANCE_REMAINING_MAX) {
      label = t('.balance_max', intl, __filenamespace, {
        value: formatCurrency({ value }),
      });
    }

    if (key === Filters.PRODUCT || key === Filters.PRODUCT_TYPE) {
      if (value === '') {
        return null;
      }
    }

    if (key === Filters.SEARCH && value === '') {
      return null;
    }

    return (
      <button
        key={key}
        type="button"
        className="filter-chip"
        onClick={() => onRemoveFilterChips(key)}
      >
        <Typography>{label}</Typography>
        <ClearIcon />
      </button>
    );
  };

  const hideCleanBtn = activeFilters.equals(
    Map({
      period: defaultFilters.get(Filters.PERIOD),
    })
  );

  return (
    <Grid
      container
      item
      spacing={1}
      justify="space-between"
      xs={12}
      className="active-filters"
    >
      <Grid item justify="flex-start" xs>
        <FlexBox>
          {activeFilters.entrySeq().map(([key, value]) =>
            // we need to add icon button to Playmaker to handle click on X icon
            getFilterButton(key, value)
          )}
        </FlexBox>
      </Grid>
      {!hideCleanBtn && (
        <Grid item justify="flex-end">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            onClick={BalancesReportActions.clearFilters}
            className="active-filters__link"
          >
            <Typography variant="body2">
              {t('.clear', intl, __filenamespace)}
            </Typography>
          </a>
        </Grid>
      )}
    </Grid>
  );
}

ActiveFilters.propTypes = {
  activeFilters: PropTypes.object,
  defaultFilters: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onRemoveFilterChips: PropTypes.func.isRequired,
};

export default injectIntl(ActiveFilters);
