import * as React from 'react';
import PropTypes from 'prop-types';
import Clear from '@mui/icons-material/Clear';
import Client from 'shared/records/Client.jsx';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { grey } from '@mui/material/colors';
import Staff from 'shared/records/Staff.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxJustify, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root(hasClick) {
    return {
      padding: '10px 15px',
      marginBottom: 12,
      cursor: hasClick ? 'pointer' : 'default',
    };
  },
  flexBox: {
    flex: '1 1 auto',
    minWidth: 0,
  },
  avatar: {
    flex: '0 0 auto',
  },
  textContainer: {
    flex: '1 1 auto',
    minWidth: 0,
  },
  name: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtext: {
    marginTop: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconButton: {
    marginRight: '-12px',
    alignSelf: 'center',
  },
  dragIcon: {
    flex: '0 0 auto',
    color: 'var(--color-nav-icon-gray)',
    marginRight: '10px',
  },
};

function ProfileCard({
  className = '',
  client = new Client(),
  subtext = null,
  onClick = () => null,
  style = {},
  onClearClick = undefined,
  isDraggable = false,
}) {
  return (
    <Paper className={className} style={merge(styles.root(!!onClick), style)}>
      <FlexBoxJustify>
        <FlexBoxCenter onClick={onClick} style={styles.flexBox}>
          {isDraggable && <DragHandleIcon style={styles.dragIcon} />}
          <UserAvatar
            user={client}
            size={36}
            style={styles.avatar}
            showTypeBadge
          />
          <div style={styles.textContainer}>
            <div className="profile-name" style={styles.name}>
              {client.name()}
            </div>
            {subtext && (
              <div className="profile-subtext" style={styles.subtext}>
                {subtext}
              </div>
            )}
          </div>
        </FlexBoxCenter>
        {onClearClick && (
          <IconButton onClick={onClearClick} style={styles.iconButton}>
            <Clear sx={{ color: grey[300] }} />
          </IconButton>
        )}
      </FlexBoxJustify>
    </Paper>
  );
}

ProfileCard.propTypes = {
  client: PropTypes.oneOfType([
    PropTypes.instanceOf(Client),
    PropTypes.instanceOf(Staff),
  ]).isRequired,
  onClearClick: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  subtext: PropTypes.node,
  isDraggable: PropTypes.bool,
};

export default ProfileCard;
