import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Spinner } from '@upperhand/playmaker';

import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import WDFixedScheduleContent from 'event_mgmt/display/components/waitlist_drawer/_WDFixedScheduleContent.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import WhenSignedIn from 'shared/components/WhenSignedIn.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhContrast } from 'shared/styles/uhStyles.jsx';

const styles = {
  header: merge(uhContrast.lightOnDark, {
    fontSize: 15,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  spinner: {
    textAlign: 'center',
    marginTop: 40,
  },
};

function WaitlistDrawer({
  athletes,
  event,
  onRequestClose,
  open,
  pdRegistrationPackageStore,
  waitlistStore,
  width = STANDARD_DRAWER_WIDTH,
}) {
  return (
    <Drawer
      disableEnforceFocus
      PaperProps={{ sx: { width } }}
      anchor="right"
      open={open}
    >
      <div style={styles.header}>
        <FlexBoxJustify style={{ height: 56, alignItems: 'center' }}>
          <FormattedMessage id={messageId('.waiting_list', __filenamespace)} />
          <IconButton onClick={onRequestClose} style={{ marginRight: -18 }}>
            <CloseIcon sx={{ color: '#5B6A72' }} />
          </IconButton>
        </FlexBoxJustify>
      </div>
      <div style={{ padding: '2rem', textAlign: 'start', fontSize: 15 }}>
        <WhenSignedIn
          notSignedIn={
            <SignInPrompt
              redirectHash="%23wdo"
              loginMessageId=".login_to_join_waitlist"
            />
          }
        >
          {event.isLoadingEvent ? (
            <div style={styles.spinner}>
              <Spinner type="indeterminate" />
            </div>
          ) : (
            <WDFixedScheduleContent
              eventStore={event}
              pdRegistrationPackageStore={pdRegistrationPackageStore}
              waitlistStore={waitlistStore}
              athletes={athletes}
              onRequestClose={onRequestClose}
            />
          )}
        </WhenSignedIn>
      </div>
    </Drawer>
  );
}

export default WaitlistDrawer;
