import * as React from 'react';
import { List } from 'immutable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';
import ResourceIcon from 'shared/components/icons/Resource.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import CalendarView from 'calendar/records/CalendarView.jsx';
import CalendarViewActions from 'calendar/actions/CalendarViewActions.jsx';
import OWNER_TYPE from 'calendar/types/OwnerType.jsx';

const styles = {
  SortableCalendars: {
    margin: '16px 0',
  },
  SortableElement: {
    listStyle: 'none',
    cursor: 'move',
  },
  SortableElementFlexBoxJustify: {
    alignItems: 'center',
  },
  ResourceIcon: {
    width: '32px',
    height: '32px',
    margin: '0 0.75em 0 0',
  },
  Literal: `
    .sortableHelper {
      z-index: 1500;
    }
  `,
};

const handleOnSortEnd = ({ oldIndex, newIndex }) => {
  CalendarViewActions.swap({ oldIndex, newIndex });
};

const handleRemoveClick = (id, ownerType) => {
  CalendarViewActions.removeCalendar({ id, ownerType });
};

const StaffItem = SortableElement(({ staffMember }) => (
  <li style={styles.SortableElement}>
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <AvatarWithName user={staffMember} />
      <IconButton
        // TODO: Need to investigate how to use onClick. Now we using onPointerDown because react-sortable-hoc prevent onClick events inside SortableContainer
        onPointerDown={() =>
          handleRemoveClick(staffMember.id, OWNER_TYPE.STAFF)
        }
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  </li>
));

const ResourceItem = SortableElement(({ resource }) => (
  <li style={styles.SortableElement}>
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Stack spacing={1} direction="row" alignItems="center">
        <ResourceIcon color={uhColors.iconGrey} style={styles.ResourceIcon} />
        <div>{resource.name}</div>
      </Stack>
      <IconButton
        // TODO: Need to investigate how to use onClick. Now we using onPointerDown because react-sortable-hoc prevent onClick events inside SortableContainer
        onPointerDown={() =>
          handleRemoveClick(resource.id, OWNER_TYPE.RESOURCE)
        }
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  </li>
));

const currentStaffItem = staff =>
  staff
    .filter(staffMember => currentUser().id === staffMember.user_id)
    .map((s, index) => (
      <StaffItem key={`StaffItem-${s.id}`} index={index} staffMember={s} />
    ));

const allStaffItems = staff =>
  staff.map((s, index) => (
    <StaffItem key={`StaffItem-${s.id}`} index={index} staffMember={s} />
  ));

const allResourceItems = resources =>
  resources.map((r, index) => (
    <ResourceItem key={`ResourceItem-${r.id}`} index={index} resource={r} />
  ));

const SortableCalendarsContainer = SortableContainer(
  ({ calendarView, staff, resources }) => {
    // SortableContainer requires a DOM node root
    // so we can't use a component here. -joshuamanns
    if (calendarView.isCurrentStaff()) {
      return <ul>{currentStaffItem(staff)}</ul>;
    }
    if (calendarView.isAllStaff()) {
      return <ul>{allStaffItems(staff)}</ul>;
    }
    if (calendarView.isAllResources()) {
      return <ul>{allResourceItems(resources)}</ul>;
    }

    const calendars = calendarView
      .calendars()
      .sort((a, b) => a.positionIndex - b.positionIndex)
      .map(calendar => {
        switch (calendar.ownerType) {
          case OWNER_TYPE.STAFF: {
            const staffMember = staff.find(s => s.id === calendar.id);

            return staffMember ? (
              <StaffItem
                key={`StaffItem-${staffMember.id}`}
                index={calendar.positionIndex}
                staffMember={staffMember}
              />
            ) : null;
          }
          case OWNER_TYPE.RESOURCE: {
            const resource = resources.find(r => r.id === calendar.id);

            return resource ? (
              <ResourceItem
                key={`ResourceItem-${resource.id}`}
                index={calendar.positionIndex}
                resource={resource}
              />
            ) : null;
          }
          default:
            return null;
        }
      });

    return <ul>{calendars}</ul>;
  }
);

function SortableCalendars({
  calendarView = new CalendarView(),
  staff = List(),
  resources,
}) {
  return (
    <div style={styles.SortableCalendars}>
      <style>{styles.Literal}</style>
      <SortableCalendarsContainer
        calendarView={calendarView}
        staff={staff}
        resources={resources}
        onSortEnd={handleOnSortEnd}
        helperClass="sortableHelper"
      />
    </div>
  );
}

export default SortableCalendars;
