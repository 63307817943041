import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Clock4pm({ color = '#9A9EAD', ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 16" color={color} {...props}>
      <g
        id="Facility-/-Resource-3.0"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Quick-Schedule-Flow"
          transform="translate(-118.000000, -177.000000)"
          fill="#9A9EAD"
        >
          <g id="Event" transform="translate(103.000000, 94.000000)">
            <g id="Clock-Icon" transform="translate(15.000000, 83.000000)">
              <path
                d="M8,14.4 C4.464,14.4 1.6,11.536 1.6,8 C1.6,4.464 4.464,1.6 8,1.6 C11.535,1.6 14.399,4.464 14.399,8 C14.399,11.536 11.535,14.4 8,14.4 M7.991,0 C3.575,0 0,3.584 0,8 C0,12.416 3.575,16 7.991,16 C12.416,16 16,12.416 16,8 C16,3.584 12.416,0 7.991,0"
                id="Fill-1"
              />
              <polygon
                id="Fill-4"
                points="8 3 7 3 7 8.234 11.315 10.754 11.758 9.77 8 7.634"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Clock4pm;
