import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils';

import { SessionDataStore } from 'dataStores';

import CancelSessionActions from 'containers/events/admin/schedule/cancelSessionModal/Actions';
import SessionSummaryDrawerActions from '../Actions';

function EditMenu({
  intl,
  anchorEl = null,
  sessionId = null,
  allowCancel = false,
  sessionDataStore = {},
}) {
  const { sessions } = sessionDataStore;
  const session = sessions.get(sessionId);
  const isCancelable = allowCancel && !session?.isCancelled();

  const handleMenuItemClick = () => {
    SessionSummaryDrawerActions.toggleEditMenu();
    SessionSummaryDrawerActions.setEditMode(true);
  };

  const menuItems = [
    {
      label: t('.edit', intl, __filenamespace),
      onClick: handleMenuItemClick,
    },
  ];

  if (isCancelable) {
    menuItems.push({
      label: t('.cancel', intl, __filenamespace),
      onClick: () => CancelSessionActions.initCancellation(sessionId),
    });
  }

  return (
    <Menu
      anchor={anchorEl}
      onClose={SessionSummaryDrawerActions.toggleEditMenu}
      items={menuItems}
    />
  );
}

EditMenu.propTypes = {
  anchorEl: PropTypes.any,
  intl: PropTypes.object.isRequired,
  allowCancel: PropTypes.bool,
  sessionId: PropTypes.string,
  sessionDataStore: PropTypes.object,
};

export default compose(
  React.memo,
  altContainer({ stores: { sessionDataStore: SessionDataStore } })
)(EditMenu);
