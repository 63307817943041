import * as React from 'react';
import PropTypes from 'prop-types';
import CALENDAR_TYPE from 'calendar/types/CalendarType.jsx';
import moment from 'moment-timezone';
import PickerCalendar from 'shared/components/calendar/_PickerCalendar.jsx';

function MiniCalendar({
  dayTooltip,
  firstDayOfWeek,
  onSelectDate,
  shouldDisableDate,
  shouldSelectDate,
  initialDate = new Date(),
  minDate = moment().add(-100, 'years').toDate(),
  maxDate = moment().add(100, 'years').toDate(),
}) {
  const [displayDate] = React.useState(moment(initialDate).startOf('month'));

  return (
    <div className="mini-calendar">
      <PickerCalendar
        key={displayDate.toISOString()}
        currentDate={moment()}
        dayTooltip={dayTooltip}
        displayDate={displayDate}
        firstDayOfWeek={firstDayOfWeek}
        handleRequestClose={() => null}
        onSelectDate={onSelectDate}
        ref={() => 'calendar'}
        shouldDisableDate={shouldDisableDate}
        shouldSelectDate={shouldSelectDate}
        minDate={minDate}
        maxDate={maxDate}
        view={CALENDAR_TYPE.DAY}
      />
    </div>
  );
}

MiniCalendar.propTypes = {
  dayTooltip: PropTypes.func.isRequired,
  firstDayOfWeek: PropTypes.number.isRequired,
  initialDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onSelectDate: PropTypes.func.isRequired,
  shouldDisableDate: PropTypes.func.isRequired,
  shouldSelectDate: PropTypes.func.isRequired,
};

export default MiniCalendar;
