import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';
import {
  DateTimePicker,
  Checkbox,
  Typography,
  Dropdown,
  TextField,
  Grid,
  Switch,
} from '@upperhand/playmaker';

import DayTimes from 'containers/eventSessionCreation/components/DayTimes.jsx';
import BlackoutDates from 'containers/eventSessionCreation/components/BlackoutDates.jsx';
import WeekdayPicker from 'shared/components/scheduling/WeekdayPicker.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';

import { LocationDataStore } from 'dataStores';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';
import EventSessionCreationStore, {
  REPEAT_MODES,
} from 'containers/eventSessionCreation/Store';
import ScheduleTypeOption from 'containers/events/admin/schedule/contants';

function TeamScheduleDetails({ label, errors, schedule, isTeamsPage, intl }) {
  if (!isTeamsPage) return null;
  return (
    <>
      <Box className="events-session-creation__form-item">
        <Box
          className="events-session-creation__form-item-content"
          sx={{ flexDirection: 'column' }}
        >
          <Typography
            variant="h5"
            className="events-session-creation__form-title"
          >
            {t('.name_team_schedule', intl, __filenamespace)}
          </Typography>
          <Box className="events-session-creation__form-item">
            <TextField
              value={label}
              fullWidth
              errorText={errors.getErrors('scheduleName', intl)}
              onChange={(_, value) => {
                EventSessionCreationActions.handleFieldChange({
                  field: 'label',
                  value,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.schedule_type', intl, __filenamespace)}
      </Typography>
      <Box className="events-session-creation__form-item">
        <Dropdown
          fullWidth
          value={schedule?.team_schedule_type}
          errorText={errors.getErrors('scheduleType', intl)}
          placeholder={t('.select_schedule_type', intl, __filenamespace)}
          onChange={event =>
            EventSessionCreationActions.handleFieldChange({
              field: ['team_schedule_type'],
              value: event.target.value,
            })
          }
          items={ScheduleTypeOption}
        />
      </Box>
    </>
  );
}

function RepeatingScheduleDetails({
  intl,
  errors,
  interval,
  frequency,
  weekDays,
  repeatMode,
  endDate,
  repeatDuration,
  daytimesAreUnique,
  daytimes,
  exclusions,
  availabilitySchedule,
  isRepeating,
}) {
  if (!isRepeating) return null;

  return (
    <>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.repeats', intl, __filenamespace)}
      </Typography>
      <Box className="events-session-creation__form-item">
        <Box className="events-session-creation__form-item-content">
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={2}>
              <Typography variant="fieldLabel">{`${t(
                '.every',
                intl,
                __filenamespace
              )}:`}</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type="number"
                value={interval}
                numberInputProps={{ min: 1 }}
                onChange={event =>
                  EventSessionCreationActions.handleFieldChange({
                    field: ['availability_schedule', 'interval'],
                    value: parseInt(event.target.value, 10),
                  })
                }
              />
            </Grid>
            <Grid item xs={5}>
              <Dropdown
                fullWidth
                value={frequency}
                onChange={event =>
                  EventSessionCreationActions.handleFieldChange({
                    field: ['availability_schedule', 'frequency'],
                    value: event.target.value,
                  })
                }
                items={[
                  { label: 'Week(s)', value: 'weekly' },
                  { label: 'Day(s)', value: 'daily' },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.repeat_on', intl, __filenamespace)}
      </Typography>
      <Box className="events-session-creation__form-item">
        <WeekdayPicker
          onChangeWeekdays={EventSessionCreationActions.setWeekdays}
          selectedDays={weekDays}
        />
      </Box>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.ends', intl, __filenamespace)}
      </Typography>
      <Box className="events-session-creation__form-item">
        <Dropdown
          fullWidth
          value={repeatMode}
          onChange={event =>
            EventSessionCreationActions.setRepeatMode(event.target.value)
          }
          items={[
            {
              label: t('.repeat_never', intl, __filenamespace),
              value: REPEAT_MODES.NEVER,
            },
            {
              label: t('.repeat_until', intl, __filenamespace),
              value: REPEAT_MODES.UNTIL,
            },
            {
              label: t('.repeat_occurrences', intl, __filenamespace),
              value: REPEAT_MODES.NUMBER_OF_TIMES,
            },
          ]}
        />
      </Box>
      {repeatMode === REPEAT_MODES.UNTIL && (
        <Box className="events-session-creation__form-item">
          <DateTimePicker
            fullWidth
            showClearIcon={false}
            type="date"
            placeholder="MM/DD/YYYY"
            classes={{ root: 'date-picker' }}
            errorText={errors.getErrors('stops_by_date', intl)}
            value={endDate}
            onChange={value => {
              EventSessionCreationActions.handleFieldChange({
                field: ['availability_schedule', 'end_date'],
                value,
              });
              EventSessionCreationActions.handleFieldChange({
                field: ['availability_schedule', 'stops_by_date'],
                value,
              });
            }}
          />
        </Box>
      )}
      {repeatMode === REPEAT_MODES.NUMBER_OF_TIMES && (
        <>
          <Typography
            variant="fieldLabel"
            className="events-session-creation__form-title"
          >
            {t('.after', intl, __filenamespace)}
          </Typography>
          <Box className="events-session-creation__form-item">
            <TextField
              fullWidth
              type="number"
              value={repeatDuration}
              onChange={event =>
                EventSessionCreationActions.setRepeatDuration(
                  parseInt(event.target.value, 10)
                )
              }
            />
          </Box>
        </>
      )}
      <Box className="events-session-creation__form-title">
        <Typography variant="h5">
          {t('.daily', intl, __filenamespace)}
        </Typography>
        <Box className="switch">
          <Switch
            checked={daytimesAreUnique}
            labelValue={t('.day_specific', intl, __filenamespace)}
            onChange={EventSessionCreationActions.setDailyTime}
          />
        </Box>
      </Box>
      <Box className="events-session-creation__form-item">
        <DayTimes
          dayTimes={daytimes}
          weekDays={weekDays}
          dayTimesAreUnique={daytimesAreUnique}
        />
      </Box>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.dates', intl, __filenamespace)}
      </Typography>
      <Box className="events-session-creation__form-item">
        <BlackoutDates
          exclusions={exclusions}
          availabilitySchedule={availabilitySchedule}
          onRemove={EventSessionCreationActions.removeBlackoutDate}
        />
      </Box>
    </>
  );
}

function SingleSessionTimePicker({ intl, errors, defaultTime, isRepeating }) {
  if (isRepeating) return null;
  return (
    <>
      <Grid item xs={3}>
        <DateTimePicker
          fullWidth
          format="hh:mm A"
          showClearIcon={false}
          type="time"
          placeholder="HH:MM"
          errorText={errors.getErrors('start_time', intl)}
          classes={{ root: 'date-picker', errorText: 'error' }}
          value={defaultTime.get('start_time', '')}
          onChange={value =>
            EventSessionCreationActions.setDayTimes({
              key: 'start_time',
              value,
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <DateTimePicker
          fullWidth
          format="hh:mm A"
          showClearIcon={false}
          type="time"
          placeholder="HH:MM"
          errorText={errors.getErrors('end_time', intl)}
          classes={{ root: 'date-picker', errorText: 'error' }}
          value={defaultTime.get('end_time', '')}
          onChange={value =>
            EventSessionCreationActions.setDayTimes({
              key: 'end_time',
              value,
            })
          }
        />
      </Grid>
    </>
  );
}

function LocationSelector({
  intl,
  location,
  locationsLoading,
  errors,
  locationsItems,
  locations,
}) {
  if (locationsLoading) return null;
  return (
    <>
      <Typography variant="h5" className="events-session-creation__form-title">
        {t('.location', intl, __filenamespace)}
      </Typography>
      <Box className="events-session-creation__form-item">
        {!locationsLoading && (
          <Dropdown
            fullWidth
            value={location?.id}
            errorText={errors.getErrors('location', intl)}
            onChange={event =>
              EventSessionCreationActions.handleFieldChange({
                field: ['location'],
                value: locations?.get(event.target.value),
              })
            }
            items={locationsItems}
          />
        )}
      </Box>
    </>
  );
}

function DateTimeSelector({ intl, errors, startDate, defaultTime }) {
  return (
    <Box className="events-session-creation__form-item">
      <Box className="events-session-creation__form-item-content">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DateTimePicker
              autoOk
              fullWidth
              disablePast
              showClearIcon={false}
              type="date"
              placeholder="MM/DD/YYYY"
              classes={{ root: 'date-picker' }}
              errorText={errors.getErrors('start_date', intl)}
              value={startDate}
              onChange={value => {
                EventSessionCreationActions.handleFieldChange({
                  field: ['availability_schedule', 'start_date'],
                  value: value.tz(customerTZ(), true),
                });
                EventSessionCreationActions.handleFieldChange({
                  field: ['availability_schedule', 'end_date'],
                  value: value.tz(customerTZ(), true),
                });
              }}
            />
          </Grid>
          <SingleSessionTimePicker
            defaultTime={defaultTime}
            errors={errors}
            intl={intl}
            EventSessionCreationActions={EventSessionCreationActions}
          />
        </Grid>
      </Box>
    </Box>
  );
}

function ScheduleOptions({ intl, isRepeating, isTeamsPage }) {
  return (
    <Box className="events-session-creation__form-title">
      <Typography variant="h5">
        {isRepeating || isTeamsPage
          ? t('.start_date', intl, __filenamespace)
          : t('.create_schedule', intl, __filenamespace)}
      </Typography>
      <Box className="checkbox">
        <Checkbox
          label={t('.repeat', intl, __filenamespace)}
          checked={isRepeating}
          onChange={(_, value) =>
            EventSessionCreationActions.setRepeating(value)
          }
        />
      </Box>
    </Box>
  );
}
function Schedule({
  intl = {},
  eventSessionCreationStore = {},
  locationDataStore = {},
  isTeamsPage = false,
}) {
  const {
    schedule,
    isRepeating,
    defaultTimes,
    locationsIds,
    locationsLoading,
    errors,
  } = eventSessionCreationStore;
  const { records: locations } = locationDataStore;
  const {
    availability_schedule: availabilitySchedule,
    location,
    label,
  } = schedule;
  const startDate = availabilitySchedule.get('start_date');
  const endDate = availabilitySchedule.get('end_date');
  const interval = availabilitySchedule.get('interval');
  const frequency = availabilitySchedule.get('frequency');
  const repeatMode = availabilitySchedule.get('repeat_mode');
  const repeatDuration = availabilitySchedule.get('repeat_duration');
  const daytimesAreUnique = availabilitySchedule.get('daytimesAreUnique');
  const daytimes = availabilitySchedule.get('daytimes');
  const exclusions = availabilitySchedule.get('exclusions');
  const weekDays = availabilitySchedule.repeatingWeekdays();
  const defaultTime = defaultTimes.get(0);
  const locationsItems = locationsIds
    .map(id => ({
      label: locations?.get(id)?.name,
      value: locations?.get(id)?.id,
    }))
    .toArray();

  return (
    <Box className="events-session-creation__form">
      <TeamScheduleDetails
        label={label}
        errors={errors}
        intl={intl}
        schedule={schedule}
        isTeamsPage={isTeamsPage}
      />
      <ScheduleOptions
        intl={intl}
        isRepeating={isRepeating}
        isTeamsPage={isTeamsPage}
      />

      <DateTimeSelector
        intl={intl}
        defaultTime={defaultTime}
        startDate={startDate}
        errors={errors}
      />
      <RepeatingScheduleDetails
        intl={intl}
        errors={errors}
        interval={interval}
        frequency={frequency}
        weekDays={weekDays}
        repeatMode={repeatMode}
        endDate={endDate}
        repeatDuration={repeatDuration}
        daytimesAreUnique={daytimesAreUnique}
        daytimes={daytimes}
        exclusions={exclusions}
        availabilitySchedule={availabilitySchedule}
        isRepeating={isRepeating}
      />
      <LocationSelector
        locations={locations}
        location={location}
        errors={errors}
        locationsLoading={locationsLoading}
        locationsItems={locationsItems}
        intl={intl}
      />
    </Box>
  );
}

Schedule.propTypes = {
  intl: PropTypes.object,
  eventSessionCreationStore: PropTypes.object,
  locationDataStore: PropTypes.object,
  isTeamsPage: PropTypes.bool,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      eventSessionCreationStore: EventSessionCreationStore,
      locationDataStore: LocationDataStore,
    },
  })
)(Schedule);
