import PropTypes from 'prop-types';
import * as React from 'react';
import { FlexBox, FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    color: uhColors.leftNavGrey,
    flexDirection: 'column',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS - 3,
    marginRight: LEFT_MARGIN_PIXELS - 3,
    marginBottom: LEFT_MARGIN_PIXELS - 3,
    padding: '0 3px 3px 3px',
    maxWidth: '90%',
    overflow: 'hidden',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '-45px',
    marginRight: '-45px',
  },
  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    margin: `${LEFT_MARGIN_PIXELS}px 45px 0 45px`,
    maxWidth: '100%',
  },
};

const getStyles = (style, key) => (style ? style[key] : null) || {};

function ColumnarForm({ actions = null, columns, style, ...props }) {
  return (
    <FlexBoxJustifyCenter style={merge(styles.root, getStyles(style, 'root'))}>
      <FlexBox
        style={merge(
          styles.row,
          { justifyContent: 'flex-start' },
          getStyles(style, 'contentRow')
        )}
      >
        {columns.map((c, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={merge(
              styles.column,
              getStyles(style, 'column'),
              getStyles(style, `column${i}`)
            )}
          >
            {React.cloneElement(c, props)}
          </div>
        ))}
      </FlexBox>
      {actions && (
        <FlexBox
          style={merge(
            styles.row,
            { justifyContent: 'flex-start' },
            getStyles(style, 'actionRow')
          )}
        >
          <FlexBox
            style={merge(
              styles.column,
              { justifyContent: 'flex-start' },
              getStyles(style, 'column'),
              getStyles(style, 'actionColumn')
            )}
          >
            {React.cloneElement(actions, props)}
          </FlexBox>
        </FlexBox>
      )}
    </FlexBoxJustifyCenter>
  );
}

ColumnarForm.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  actions: PropTypes.node,
};

export default ColumnarForm;
