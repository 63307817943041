import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon } from '@upperhand/playmaker';

import PageHeader from 'components/PageHeader/PageHeader.jsx';

import { t } from 'shared/utils/LocaleUtils';

import CouponActions from 'containers/coupons/couponView/Actions';

import SearchField from './SearchField.jsx';

function Header({ actions = {}, search = '', settingTab = false, intl }) {
  return (
    <Grid container item spacing={2} alignContent="center" justify="center">
      {!settingTab && (
        <Grid item xs={12}>
          <PageHeader
            title={t('.header', intl, __filenamespace)}
            wrapperStyles={{ marginBottom: 0 }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container item spacing={1}>
          <div className="coupons__add-button">
            <Button
              onClick={CouponActions.openCreateModal}
              rounded
              icon={<Icon name="add" className="coupons__add-button-icon" />}
              size="2x"
            />
          </div>
          <Grid item xs>
            <SearchField
              onChange={event => actions.updateFilter(event.target.value)}
              value={search}
              intl={intl}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  intl: PropTypes.object.isRequired,
  settingTab: PropTypes.bool,
  search: PropTypes.string,
  actions: PropTypes.object,
};

export default Header;
