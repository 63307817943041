import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map, Set } from 'immutable';
import { Grid, Typography } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { t } from 'shared/utils/LocaleUtils';

const defaultStaff = avatarName => ({
  first_name: avatarName,
  last_name: '',
  alternativeImage: () => {},
});

function StaffSection({ staffIds = OrderedSet(), staff = Map(), intl }) {
  if (!staffIds) return null;

  const existingStaffCount = staff.filter(s => staffIds.has(s.id)).count();

  if (existingStaffCount === 0) {
    return null;
  }
  const avatarStaff =
    existingStaffCount === 1
      ? staff.get(staffIds.first(), defaultStaff('S'))
      : defaultStaff(existingStaffCount.toString());

  const staffText =
    existingStaffCount === 1
      ? `${avatarStaff.first_name} ${avatarStaff.last_name}`
      : t('.staff_count', intl, __filenamespace);

  return (
    <Grid className="staff-section" container item alignItems="center">
      <Grid item>
        <UserAvatar user={avatarStaff} />
      </Grid>
      <Grid item>
        <Typography>{staffText}</Typography>
      </Grid>
    </Grid>
  );
}

StaffSection.propTypes = {
  intl: PropTypes.object.isRequired,
  staffIds: PropTypes.oneOfType([
    PropTypes.instanceOf(OrderedSet),
    PropTypes.instanceOf(Set),
  ]),
  staff: PropTypes.instanceOf(Map),
};

export default StaffSection;
