import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { TextField } from '@upperhand/playmaker';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '@mui/material/Button';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const COPY_URL_TEXT = 'Copy';
const COPIED_URL_TEXT = 'Copied';

const styles = {
  inputRoot: {
    cursor: 'pointer !important',
  },
  input: {
    cursor: 'pointer !important',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  copyContainer: {
    flexDirection: 'row',
    marginLeft: -12,
    paddingTop: 4,
  },
  icon: {
    margin: 0,
    height: '1.2em',
  },
  buttonRoot: {
    minWidth: 'inherit',
    height: 'inherit',
    lineHeight: 'inherit',
  },
  buttonTitle: {
    padding: 0,
    color: uhColors.blue,
    fontSize: '1em',
  },
};

function CopyHref({ label, href, text, classes = {} }) {
  const [copyUrlToClipboardText, setCopyUrlToClipboardText] =
    useState(COPY_URL_TEXT);

  const handleResetCopyText = () => {
    setCopyUrlToClipboardText(COPY_URL_TEXT);
  };

  const handleOnUrlCopy = () => {
    setCopyUrlToClipboardText(COPIED_URL_TEXT);
  };

  return (
    <div>
      <TextField
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        disabled
        fullWidth
        label={label}
        value={text}
        onClick={() => {
          window.location = href;
        }}
        onChange={() => {}}
      />
      <div className={classes.copyContainer}>
        <CopyToClipboard text={href} onCopy={handleOnUrlCopy}>
          <Button
            disableTouchRipple
            onBlur={handleResetCopyText}
            onMouseOut={handleResetCopyText}
            style={styles.buttonRoot}
            startIcon={
              <ContentCopyIcon
                className={classes.icon}
                sx={{ color: uhColors.blue }}
              />
            }
          >
            <span>{copyUrlToClipboardText}&nbsp; to clipboard</span>
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
}

CopyHref.propTypes = {
  classes: PropTypes.object,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(CopyHref);
