import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import clsx from 'clsx';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

function OrderDate({ date, intl, isMobile }) {
  if (!date) {
    return null;
  }
  const format = isMobile ? 'MM/DD/YY • h:mma' : 'MM/DD/YYYY • h:mma';
  const orderDate = moment(date);

  return (
    <Typography variant="body1" className={clsx(isMobile && 'event-date')}>
      {orderDate.isSame(moment(), 'day')
        ? t('.date_today', intl, __filenamespace, {
            date: orderDate.format('h:mma'),
          })
        : orderDate.format(format)}
    </Typography>
  );
}

function DesktopView({ order, orderItem, intl }) {
  return (
    <>
      <Grid item className="orders__row-item" xs={3}>
        <Typography variant="subtitle1" className="event-title">
          {orderItem.primary_display_text}
        </Typography>
      </Grid>
      <Grid item className="orders__row-item" xs={3}>
        <OrderDate intl={intl} date={orderItem.ordered_at} />
      </Grid>
      <Grid item className="orders__row-item" xs={3}>
        <Typography variant="subtitle1">
          <FormattedCurrency value={order.total} fromCents />
        </Typography>
      </Grid>
      <Grid item className="orders__row-item" xs={3}>
        <Typography variant="body1">{order.order_number}</Typography>
      </Grid>
    </>
  );
}

function MobileView({ order, orderItem, intl }) {
  return (
    <>
      <Grid item className="orders__row-item" xs={6}>
        <Typography variant="subtitle1" className="event-title">
          {orderItem.primary_display_text}
        </Typography>
        <OrderDate intl={intl} date={orderItem.ordered_at} isMobile />
      </Grid>
      <Grid item className="orders__row-item" xs={6}>
        <Typography variant="subtitle1">
          <FormattedCurrency value={order.total} fromCents />
        </Typography>
        <Typography variant="body1">{order.order_number}</Typography>
      </Grid>
    </>
  );
}

function OrderItem({
  isMobile = false,
  onMenuItemClick = () => null,
  intl,
  orders,
  orderId,
}) {
  const order = orders.get(orderId);
  const orderItem = order.getIn(['order_items', 0]);

  if (!orderItem) {
    return null;
  }

  const menuItems = [
    {
      onClick: () => onMenuItemClick('details', order),
      label: t('.order_details', intl, __filenamespace),
    },
  ];

  return (
    <Card
      contentDirection="row"
      classes={{
        root: 'orders__row',
        content: 'orders__row-content',
      }}
      menuOptions={menuItems}
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        {!isMobile && (
          <DesktopView intl={intl} order={order} orderItem={orderItem} />
        )}
        {isMobile && (
          <MobileView intl={intl} order={order} orderItem={orderItem} />
        )}
      </Grid>
    </Card>
  );
}

OrderItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
  onMenuItemClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default OrderItem;
