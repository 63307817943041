import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Dropdown } from '@upperhand/playmaker';

import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function DiscountItemsSelector({
  placeholder = '',
  items = List(),
  onItemSelect = () => {},
  ...props
}) {
  const dropdownItems = items
    .map(item => ({
      value: item?.id,
      label: (
        <Box className="event-type-editor__menu-item">
          <FiberManualRecordIcon sx={{ color: item?.color }} />
          <p>{item?.name}</p>
        </Box>
      ),
    }))
    .toArray();

  return (
    <Box>
      <Dropdown
        fullWidth
        placeholder={placeholder}
        onChange={onItemSelect}
        items={dropdownItems}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Box>
  );
}

DiscountItemsSelector.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.instanceOf(List),
  onItemSelect: PropTypes.func,
};

export default memo(DiscountItemsSelector);
