import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { DateTimePicker, Grid, Typography, Button } from '@upperhand/playmaker';
import { Map, Set, List } from 'immutable';

import { t } from 'shared/utils/LocaleUtils';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';

const weekdays = Map({
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
});

const DayTimeItem = injectIntl(
  ({ intl = {}, item = List(), day = 1, dayTimesAreUnique = false }) => (
    <div className="day-time-container">
      <Typography variant="fieldLabel" className="week-name">
        {weekdays.get(day)}
      </Typography>
      {item?.map((timeItem, index) => {
        const startTime = timeItem.get('start_time', '');
        const endTime = timeItem.get('end_time', '');
        const showAddButton = item.size - 1 === index && dayTimesAreUnique;
        const showRemoveButton = item.size > 1 && dayTimesAreUnique;

        return (
          <div key={uuidv4()}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              className="day-time-item"
            >
              <Grid item xs={4}>
                <DateTimePicker
                  fullWidth
                  format="hh:mm A"
                  showClearIcon={false}
                  type="time"
                  placeholder="HH:MM"
                  classes={{ root: 'date-picker' }}
                  value={startTime}
                  onChange={value => {
                    EventSessionCreationActions.updateDayTime({
                      keyPath: [
                        'availability_schedule',
                        'daytimes',
                        day,
                        index,
                        'start_time',
                      ],
                      time: value,
                      dayTimesAreUnique,
                    });
                  }}
                />
              </Grid>
              <Grid item container justify="center" xs={2}>
                —
              </Grid>
              <Grid item xs={4}>
                <DateTimePicker
                  fullWidth
                  format="hh:mm A"
                  showClearIcon={false}
                  type="time"
                  placeholder="HH:MM"
                  classes={{ root: 'date-picker' }}
                  value={endTime}
                  onChange={value =>
                    EventSessionCreationActions.updateDayTime({
                      keyPath: [
                        'availability_schedule',
                        'daytimes',
                        day,
                        index,
                        'end_time',
                      ],
                      time: value,
                      dayTimesAreUnique,
                    })
                  }
                />
              </Grid>
              <Grid item container justify="center" xs={2}>
                {showRemoveButton && (
                  <Button
                    rounded
                    type="tertiary"
                    classes={{ root: 'remove-btn' }}
                    icon="delete"
                    onClick={() =>
                      EventSessionCreationActions.removeDayTime({
                        dayNumber: day,
                        index,
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>
            {showAddButton && (
              <Button
                classes={{ root: 'add-btn' }}
                type="tertiary"
                icon="add"
                onClick={() =>
                  EventSessionCreationActions.addDayTime({
                    dayNumber: day,
                    index: index + 1,
                  })
                }
              >
                {t('.add_another', intl, __filenamespace)}
              </Button>
            )}
          </div>
        );
      })}
    </div>
  )
);

DayTimeItem.propTypes = {
  intl: PropTypes.object,
  item: PropTypes.instanceOf(List),
  day: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dayTimesAreUnique: PropTypes.bool,
};

function DayTimes({
  dayTimes = Map(),
  weekDays = Set(),
  dayTimesAreUnique = false,
}) {
  return (
    <>
      {!dayTimesAreUnique && (
        <DayTimeItem
          key={uuidv4()}
          item={dayTimes.first()}
          dayTimesAreUnique={dayTimesAreUnique}
        />
      )}
      {dayTimesAreUnique && (
        <>
          {weekDays.sort().map(day => {
            const item = dayTimes.get(day);

            return (
              <DayTimeItem
                key={uuidv4()}
                item={item}
                day={day}
                dayTimesAreUnique={dayTimesAreUnique}
              />
            );
          })}
        </>
      )}
    </>
  );
}

DayTimes.propTypes = {
  dayTimes: PropTypes.instanceOf(Map),
  weekDays: PropTypes.instanceOf(Set),
  dayTimesAreUnique: PropTypes.bool,
};

export default DayTimes;
