import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import { injectIntl } from 'react-intl';
import { t } from 'shared/utils/LocaleUtils.js';

function FilterButton({ large = false, style = {}, active = false, intl }) {
  const FilterIconColor = active ? uhColors.activeBlue : uhColors.iconGrey;

  if (large) {
    return (
      <div style={{ display: 'flex', justifyContent: 'end', flexGrow: 4 }}>
        <Button
          color="default"
          onClick={CalendarActions.filterDrawerOpen}
          style={{ ...style, color: uhColors.charcoalBlack }}
          endIcon={<FilterListIcon sx={{ color: FilterIconColor }} />}
        >
          {t('.filters', intl, __filenamespace)}
        </Button>
      </div>
    );
  }
  return (
    <IconButton style={style} onClick={CalendarActions.filterDrawerOpen}>
      <FilterListIcon sx={{ color: FilterIconColor }} />
    </IconButton>
  );
}

FilterButton.propTypes = {
  style: PropTypes.object,
  large: PropTypes.bool,
  active: PropTypes.bool,
};

export default injectIntl(FilterButton);
