import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Button } from '@upperhand/playmaker';
import { OrderedSet, Map } from 'immutable';

import Schedule from 'event_mgmt/shared/records/Schedule.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import StaffDrawer from 'containers/eventSessionCreation/components/StaffDrawer.jsx';
import ResourcesDrawer, {
  ResourceCard,
} from 'containers/eventSessionCreation/components/ResourcesDrawer.jsx';
import ProfileCard from 'user_management/shared/components/ProfileCard.jsx';

import { StaffDataStore, ResourceDataStore } from 'dataStores';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';
import EventSessionCreationStore from 'containers/eventSessionCreation/Store';

function StaffAndResources({
  intl = {},
  eventSessionCreationStore = {
    openStaffDrawer: false,
    openResourcesDrawer: false,
    availableStaffIds: OrderedSet(),
    selectedStaffIds: OrderedSet(),
    availableResourcesIds: OrderedSet(),
    selectedResourcesIds: OrderedSet(),
    staffLoading: false,
    resourcesLoading: false,
    staffAndResourcesLoading: false,
    schedule: new Schedule(),
    searchText: '',
    resourceSearchText: '',
  },
  staffDataStore = { staff: Map() },
  resourceDataStore = { records: Map() },
}) {
  React.useEffect(() => {
    EventSessionCreationActions.listStaffAndResources();
  }, []);

  const {
    openStaffDrawer,
    openResourcesDrawer,
    availableStaffIds,
    selectedStaffIds,
    availableResourcesIds,
    selectedResourcesIds,
    staffLoading,
    resourcesLoading,
    staffAndResourcesLoading,
    schedule,
    searchText,
    resourceSearchText,
  } = eventSessionCreationStore;
  const { staff } = staffDataStore;
  const { records: resources } = resourceDataStore;
  const scheduledStaff = schedule.get('schedule_staff');
  const scheduledResources = schedule.get('schedule_resources');

  return (
    <div className="staff-and-resources">
      <div className="events-session-creation__form-title">
        <Typography variant="h5">
          {t('.staff', intl, __filenamespace)}
        </Typography>
        <Button
          classes={{ root: 'add-btn' }}
          type="tertiary"
          icon="add"
          onClick={EventSessionCreationActions.toggleStaffDrawer}
        />
      </div>
      <div className="events-session-creation__form-item">
        {scheduledStaff.size === 0 && (
          <Typography>{t('.no_staff', intl, __filenamespace)}</Typography>
        )}
        {scheduledStaff.size > 0 && (
          <>
            {scheduledStaff.map(id => {
              const staffMember = staff.get(id);

              if (!staffMember) return null;

              return (
                <ProfileCard
                  key={staffMember.get('id')}
                  client={staffMember}
                  subtext={t('.staff_available', intl, __filenamespace)}
                  style={{ width: '100%' }}
                  onClearClick={() =>
                    EventSessionCreationActions.removeFromList({
                      listName: 'schedule_staff',
                      idToRemove: staffMember.get('id'),
                    })
                  }
                />
              );
            })}
          </>
        )}
      </div>
      <div className="events-session-creation__form-title">
        <Typography variant="h5">
          {t('.resources', intl, __filenamespace)}
        </Typography>
        <Button
          classes={{ root: 'add-btn' }}
          type="tertiary"
          icon="add"
          onClick={EventSessionCreationActions.toggleResourceDrawer}
        />
      </div>
      <div className="events-session-creation__form-item">
        {scheduledResources.size === 0 && (
          <Typography>{t('.no_resources', intl, __filenamespace)}</Typography>
        )}
        {scheduledResources.size > 0 &&
          scheduledResources.map(id => {
            const resource = resources.get(id);

            if (!resource) return null;

            return (
              <ResourceCard
                key={id}
                resource={resource}
                onRemove={resId =>
                  EventSessionCreationActions.removeFromList({
                    idToRemove: resId,
                  })
                }
              />
            );
          })}
      </div>
      <StaffDrawer
        open={openStaffDrawer}
        searchText={searchText}
        staffIds={availableStaffIds}
        selectedIds={selectedStaffIds}
        isLoading={staffLoading || staffAndResourcesLoading}
        onClose={EventSessionCreationActions.toggleStaffDrawer}
        onSearch={EventSessionCreationActions.searchStaff}
        onItemClick={id =>
          EventSessionCreationActions.addToList({
            idToInsert: id,
            listName: 'schedule_staff',
          })
        }
        onSave={() =>
          EventSessionCreationActions.saveList({
            listName: 'schedule_staff',
          })
        }
      />
      <ResourcesDrawer
        open={openResourcesDrawer}
        searchText={resourceSearchText}
        resourceIds={availableResourcesIds}
        selectedIds={selectedResourcesIds}
        isLoading={resourcesLoading || staffAndResourcesLoading}
        onClose={EventSessionCreationActions.toggleResourceDrawer}
        onSearch={EventSessionCreationActions.searchResources}
        onItemClick={id =>
          EventSessionCreationActions.addToList({ idToInsert: id })
        }
        onSave={EventSessionCreationActions.saveList}
      />
    </div>
  );
}

StaffAndResources.propTypes = {
  intl: PropTypes.object,
  eventSessionCreationStore: PropTypes.shape({
    openStaffDrawer: PropTypes.bool,
    openResourcesDrawer: PropTypes.bool,
    availableStaffIds: PropTypes.instanceOf(OrderedSet),
    selectedStaffIds: PropTypes.instanceOf(OrderedSet),
    availableResourcesIds: PropTypes.instanceOf(OrderedSet),
    selectedResourcesIds: PropTypes.instanceOf(OrderedSet),
    staffLoading: PropTypes.bool,
    resourcesLoading: PropTypes.bool,
    staffAndResourcesLoading: PropTypes.bool,
    schedule: PropTypes.instanceOf(Schedule),
    searchText: PropTypes.string,
    resourceSearchText: PropTypes.string,
  }),
  staffDataStore: PropTypes.shape({ staff: PropTypes.instanceOf(Map) }),
  resourceDataStore: PropTypes.shape({ records: PropTypes.instanceOf(Map) }),
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      eventSessionCreationStore: EventSessionCreationStore,
      staffDataStore: StaffDataStore,
      resourceDataStore: ResourceDataStore,
    },
  })
)(StaffAndResources);
