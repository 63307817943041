import { List, Map } from 'immutable';

import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { isTexasTwelveTheme } from 'shared/utils/ThemeUtils';
import { isNative } from 'shared/utils/UserAgentUtils';

// Need to move these to a auth store/object at some point and start replacing the Utils version
// checks if the use is ANY of these roles
const AuthorizedUser = (roles = []) => {
  const r = List(roles);

  if (r.isEmpty()) {
    return true;
  }

  if (currentUser().isInstructor()) {
    return r.contains('Instructor');
  }
  if (currentUser().isManager()) {
    return r.contains('Manager');
  }

  if (currentUser().isCoach()) {
    return r.contains('Coach');
  }

  return r.contains(currentUser().role);
};

// Need to move these to a auth store/object at some point and start replacing the Utils version
// check if the customer has ALL of these roles
const AuthorizedCustomer = (features = []) => {
  const f = List(features);

  const enabledFlags = List(
    Map(currentCustomer().features)
      .filter(v => v)
      .keys()
  );

  return List(f).isSubset(enabledFlags);
};

// Need to move these to a auth store/object at some point and start replacing the Utils version
const Authorized = (roles = [], features = []) =>
  AuthorizedUser(roles) && AuthorizedCustomer(features);

/**
 * Required values:
 *   title: intl key of title of the nav item
 *   icon: key of the icon in the icons const in _SideNavMenuItem.jsx
 * Optional values:
 *   link: where the nav item will direct to
 *   subitems: list of subitems, following same pattern as this doc
 *   right: object containing fields to set up a right side action button
 *     icon: key of the icon in the icons const in _SideNavMenuItem.jsx
 *     action: key of the action in the actions const in _SideNavMenuItem.jsx
 *   useHref: force use of a link with href property
 *   useDeepLink: when 'link' option is a deep link need to set this option to true
 */
const SideNavItems = mobile => {
  let items = List();

  if (isLoggedIn() && Authorized(['Admin', 'StaffAdmin'])) {
    items = items.push({
      title: '.dashboard',
      icon: 'dashboard',
      link: customerScopedRoute('/dashboard'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'StaffMember', 'Manager', 'Coach'])
  ) {
    items = items.push({
      title: '.events',
      icon: 'event',
      link: customerScopedRoute('/events'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized(
      ['Admin', 'StaffAdmin', 'StaffMember', 'Manager'],
      ['beta_classes']
    )
  ) {
    items = items.push({
      title: '.classes',
      icon: 'classes',
      link: customerScopedRoute('/classes'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'Manager', 'Coach'], ['teams'])
  ) {
    items = items.push({
      title: '.teams',
      icon: 'teams',
      link: customerScopedRoute('/teams'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized([
      'Admin',
      'StaffAdmin',
      'StaffMember',
      'Instructor',
      'Manager',
      'Coach',
    ])
  ) {
    items = items.push({
      title: '.calendar',
      icon: 'calendar',
      link: customerScopedRoute('/calendar'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized([
      'Admin',
      'StaffAdmin',
      'StaffMember',
      'Instructor',
      'Manager',
      'Coach',
    ])
  ) {
    // any one logged into customer view can see these
    items = items.push({
      title: '.contacts',
      icon: 'contacts',
      link: customerScopedRoute('/contacts'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized([
      'Admin',
      'StaffAdmin',
      'StaffMember',
      'Instructor',
      'Manager',
      'Coach',
    ])
  ) {
    items = items.push({
      title: '.marketing',
      icon: 'marketing',
      link: customerScopedRoute('/marketing'),
    });
  }

  // REPORT SUB MENU

  if (
    isLoggedIn() &&
    Authorized(
      ['Admin', 'StaffAdmin', 'StaffMember', 'Instructor', 'Manager'],
      []
    )
  ) {
    const reportsSubnav = [];
    if (isLoggedIn() && Authorized(['Admin', 'StaffAdmin']) && !mobile) {
      reportsSubnav.push({
        title: '.contacts_report',
        link: customerScopedRoute('/reports/contacts_report'),
      });
    }

    if (
      isLoggedIn() &&
      (Authorized(
        ['StaffAdmin', 'StaffMember'],
        ['time_tracking', 'payroll']
      ) ||
        Authorized(['Admin', 'Instructor', 'Manager']))
    ) {
      reportsSubnav.push({
        title: '.payroll_report',
        link: customerScopedRoute('/reports/payroll'),
      });
    }

    if (isLoggedIn() && Authorized(['Admin', 'StaffAdmin'])) {
      reportsSubnav.push(
        {
          title: '.orders_report',
          link: customerScopedRoute('/reports/orders'),
        },
        {
          title: '.ledger_report',
          link: customerScopedRoute('/reports/ledger'),
        }
      );

      if (!mobile) {
        reportsSubnav.push(
          {
            title: '.sales_detail',
            link: customerScopedRoute('/reports/sales_detail'),
          },
          {
            title: '.revenue_report',
            link: customerScopedRoute('/reports/revenue_report'),
          }
        );
      }

      if (currentCustomer().mrr_enabled && !mobile) {
        reportsSubnav.push({
          title: '.monthly_recurring_revenue_report',
          link: customerScopedRoute(
            '/reports/monthly_recurring_revenue_report'
          ),
        });
      }

      if (!mobile) {
        reportsSubnav.push({
          title: '.waivers_report',
          link: customerScopedRoute('/reports/waivers_report'),
        });
      }
    }

    if (
      isLoggedIn() &&
      (Authorized(['StaffAdmin'], ['retail']) || Authorized(['Admin'])) &&
      !mobile
    ) {
      reportsSubnav.push({
        title: '.inventory_report',
        link: customerScopedRoute('/reports/inventory'),
      });
    }

    if (isLoggedIn() && Authorized(['Admin', 'StaffMember', 'StaffAdmin'])) {
      reportsSubnav.push({
        title: '.balances_report',
        link: customerScopedRoute('/reports/balances'),
      });
    }

    if (
      isLoggedIn() &&
      Authorized(['Admin', 'StaffMember', 'StaffAdmin', 'Manager'])
    ) {
      reportsSubnav.push({
        title: '.daily_closeout_report',
        link: customerScopedRoute('/reports/daily_closeout'),
      });
    }

    const rightIcon = Authorized(['Admin', 'StaffAdmin']) && {
      action: 'export',
      icon: 'export',
    };

    const reports = {
      title: '.reports',
      icon: 'reports',
      subitems: reportsSubnav,
      right: rightIcon,
    };

    items = items.push(reports);
  }
  // END REPORT SUB MENU

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin'], ['embedded_mode_report'])
  ) {
    const intelligenceSubnav = [];
    const { mode_report_labels: modeReportLabels } = currentCustomer();

    modeReportLabels.sort().map(label =>
      intelligenceSubnav.push({
        useTranslation: false,
        title: label,
        link: customerScopedRoute(`/reports/intelligence?label=${label}`),
      })
    );

    items = items.push({
      title: '.mode_report',
      icon: 'intelligence',
      subitems: intelligenceSubnav,
    });
  }

  if (
    isLoggedIn() &&
    (Authorized(['StaffAdmin', 'StaffMember', 'Manager'], ['memberships']) ||
      Authorized(['Admin']))
  ) {
    items = items.push({
      title: '.memberships',
      icon: 'memberships',
      link: customerScopedRoute('/memberships'),
    });
  }

  if (
    isLoggedIn() &&
    (Authorized(['StaffAdmin', 'StaffMember', 'Manager'], ['credit_passes']) ||
      Authorized(['Admin']))
  ) {
    items = items.push({
      title: '.credit_passes',
      icon: 'credit_passes',
      link: customerScopedRoute('/credit_passes'),
    });
  }

  if (
    isLoggedIn() &&
    (Authorized(['StaffAdmin', 'Manager'], ['resources']) ||
      Authorized(['Admin']))
  ) {
    items = items.push({
      title: '.resources',
      icon: 'resources',
      link: customerScopedRoute('/resources'),
    });
  }

  if (
    isLoggedIn() &&
    (Authorized(['StaffAdmin', 'Manager'], ['retail']) || Authorized(['Admin']))
  ) {
    items = items.push({
      title: '.retail',
      icon: 'retail',
      link: customerScopedRoute('/retail'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'StaffMember', 'Manager']) &&
    !(isTexasTwelveTheme() && Authorized(['Manager']))
  ) {
    items = items.push({
      title: '.coupons',
      icon: 'coupons',
      link: customerScopedRoute('/coupons'),
    });
  }

  if (isLoggedIn() && Authorized(['Admin', 'StaffAdmin'])) {
    items = items.push({
      title: '.settings',
      icon: 'settings',
      link: customerScopedRoute('/settings'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'StaffMember', 'Manager'])
  ) {
    const isOnDevice = isNative();
    const subitems = [
      {
        title: '.ar_vision',
        link: isOnDevice
          ? 'pendo-ced707c0://'
          : 'https://apps.apple.com/us/app/athletic-republic-vision/id1541936144',
        useDeepLink: isOnDevice,
        useHref: !isOnDevice,
        target: !isOnDevice ? '_blank' : '_self',
      },
    ];

    items = items.push({
      title: '.assessment',
      icon: 'ar_vision',
      subitems,
    });
  }
  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'Manager'], ['add_ons'])
  ) {
    items = items.push({
      title: '.add_ons',
      icon: 'add_ons',
      link: customerScopedRoute('/add-ons'),
    });
  }

  return items;
};

export default SideNavItems;
