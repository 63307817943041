import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import uhTheme from '_uh_theme.jsx';

function Calendar({ color = uhTheme.palette.lightGrey, ...restProps }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...restProps}>
      <g
        id="Contacts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Export-Clients"
          transform="translate(-18.000000, -138.000000)"
          fill={color}
        >
          <g id="Left-Nav" transform="translate(0.000000, 60.000000)">
            <path
              d="M33.7505,93.3116 L31.5005,93.3116 L31.5005,88.0616 C31.5005,87.6476 31.1645,87.3116
            30.7505,87.3116 L26.2505,87.3116 C25.8355,87.3116 25.5005,87.6476 25.5005,88.0616
            L25.5005,89.5616 C25.5005,89.9766 25.8355,90.3116 26.2505,90.3116 L28.5005,90.3116
            L28.5005,93.3116 L26.2505,93.3116 C25.8355,93.3116 25.5005,93.6476 25.5005,94.0616
            L25.5005,95.5616 C25.5005,95.9766 25.8355,96.3116 26.2505,96.3116 L33.7505,96.3116
            C34.1645,96.3116 34.5005,95.9766 34.5005,95.5616 L34.5005,94.0616
            C34.5005,93.6476 34.1645,93.3116 33.7505,93.3116 L33.7505,93.3116 Z M39.0005,98.5616
            C39.0005,98.9766 38.6645,99.3116 38.2505,99.3116 L21.7505,99.3116 C21.3355,99.3116
            21.0005,98.9766 21.0005,98.5616 L21.0005,85.0616 C21.0005,84.6476 21.3355,84.3116
            21.7505,84.3116 L38.2505,84.3116 C38.6645,84.3116 39.0005,84.6476 39.0005,85.0616
            L39.0005,98.5616 Z M40.5005,81.3116 L36.0005,81.3116 L36.0005,79.7966 C36.0005,78.9766
            35.3345,78.3116 34.5155,78.3116 L34.4845,78.3116 C33.6645,78.3116 33.0005,78.9766
            33.0005,79.7966 L33.0005,81.3116 L27.0005,81.3116 L27.0005,79.7966 C27.0005,78.9766
            26.3345,78.3116 25.5155,78.3116 L25.4845,78.3116 C24.6645,78.3116 24.0005,78.9766
            24.0005,79.7966 L24.0005,81.3116 L19.5005,81.3116 C18.6715,81.3116 18.0005,81.9836
            18.0005,82.8116 L18.0005,100.8116 C18.0005,101.6406 18.6715,102.3116 19.5005,102.3116
            L40.5005,102.3116 C41.3285,102.3116 42.0005,101.6406 42.0005,100.8116 L42.0005,82.8116
            C42.0005,81.9836 41.3285,81.3116 40.5005,81.3116 L40.5005,81.3116 Z"
              id="Calendar"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Calendar;
