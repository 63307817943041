import * as React from 'react';
import PropTypes from 'prop-types';
import { MultipleSelect } from '@upperhand/playmaker';

function FilterDropdown({
  mobile = false,
  filterValue,
  items,
  name,
  onChange,
}) {
  return (
    <MultipleSelect
      fullWidth
      id={`multiselect-${name}`}
      items={items}
      name={name}
      onChange={(selectedValue, allSelected) =>
        onChange(selectedValue, allSelected)
      }
      outline={mobile}
      rounded={!mobile}
      selectAll
      selectedItems={filterValue}
    />
  );
}

FilterDropdown.propTypes = {
  filterValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  mobile: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterDropdown;
