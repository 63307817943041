import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function AddOnsIcon({ color = '#9B9EAC', ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fill={color}
        d="M7.585 19C3.403 19 0 15.502 0 11.202s3.403-7.797 7.585-7.797 7.584 3.498 7.584 7.797c0 4.3-3.403 7.798-7.584 7.798z"
      />
      <path
        fill={color}
        d="M3.879 4.4a7.392 7.392 0 017.843.27L13.865 0H9.402a.544.544 0 00-.504.349l-1.04 2.086L6.683 0H2.674c-.39 0-.652.41-.499.777L3.88 4.401z"
      />
      <path
        fill="#3A3C43"
        d="M7.584 16.858c-3.033 0-5.5-2.537-5.5-5.655 0-3.119 2.467-5.656 5.5-5.656s5.5 2.537 5.5 5.656c0 3.118-2.467 5.655-5.5 5.655z"
      />
      <path
        fill={color}
        d="M14.872 0v1.586c0 .452.358.82.797.82h.961l-8.525 8.562L5.68 8.293l-2.145 2.053 3.53 3.896c.244.27.574.424.931.435h.043a1.3 1.3 0 00.913-.374l9.671-9.692v.833c0 .452.358.819.797.819H21V0h-6.128z"
      />
      <path
        fill={color}
        d="M13.145 5.906l-5.04 5.062L5.68 8.292l-2.145 2.054 3.53 3.896c.244.27.574.423.931.435h.043a1.3 1.3 0 00.913-.374l5.758-5.77a7.858 7.858 0 00-1.566-2.628z"
      />
    </SvgIcon>
  );
}

export default AddOnsIcon;
