import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '@mui/material/Button';
import { merge } from 'shared/utils/ObjectUtils.jsx';

function StateChangingButton({
  label = 'Save',
  disabled = false,
  labelInProgress = 'Saving',
  inProgress = false,
  style = {},
  variant = 'contained',
  ...otherProps
}) {
  return (
    <Button
      style={style}
      variant={variant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...merge(otherProps, { disabled: disabled || inProgress })}
    >
      {inProgress ? labelInProgress : label}
    </Button>
  );
}

StateChangingButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelInProgress: PropTypes.string,
  inProgress: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.string,
};

export default StateChangingButton;
