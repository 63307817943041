/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs as MUITabs, Tab, Button, Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import TabPanel from 'components/Tabs/TabPanel.jsx';

import { windowWidth } from 'shared/utils/DOMUtils';

import './styles.scss';

function Tabs({
  value = false,
  tabItems = [],
  showPrevNextButtons = false,
  defaultActiveTab = false,
  handleChange = () => null,
  ...restProps
}) {
  const [tabValue, setValue] = React.useState(value || defaultActiveTab);
  const tabs = tabItems.filter(t => t.tabVisible);
  const showIcon = windowWidth() < 768;
  const disableNext = showPrevNextButtons
    ? tabs[tabs.length - 1].tabIndex === tabValue
    : true;
  const disablePrevious = showPrevNextButtons
    ? tabs[0]?.tabIndex === tabValue
    : true;

  const handleTabChange = useCallback(
    (e, newValue) => {
      setValue(newValue);
      handleChange(e, newValue);
    },
    [handleChange]
  );

  const handleNextTab = useCallback(
    e => {
      const tabIndexes = tabs.map(tab => tab.tabIndex);
      const nextTab = tabIndexes[tabIndexes.indexOf(tabValue) + 1];

      setValue(nextTab);
      handleChange(e, nextTab);
    },
    [handleChange, tabs, tabValue]
  );

  const handlePrevTab = useCallback(
    e => {
      const tabIndexes = tabs.map(tab => tab.tabIndex);
      const nextTab = tabIndexes[tabIndexes.indexOf(tabValue) - 1];

      setValue(nextTab);
      handleChange(e, nextTab);
    },
    [handleChange, tabs, tabValue]
  );

  const renderIcon = tab => (
    <>
      {showIcon ? tab.tabIcon : null}
      {tab.tabHasErrors && (
        <FiberManualRecordIcon
          classes={{ root: 'tabs-container__tab-error' }}
        />
      )}
    </>
  );

  return (
    <>
      <MUITabs
        value={tabValue}
        onChange={handleTabChange}
        classes={{ root: 'tabs-container' }}
        {...restProps}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.tabIndex}
            value={tab.tabIndex}
            label={showIcon && tab.tabIcon ? undefined : tab.tabLabel}
            icon={renderIcon(tab)}
            classes={{ root: 'tabs-container__tab' }}
          />
        ))}
      </MUITabs>
      {tabs.map((tab, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TabPanel key={`tab-${index}`} value={tabValue} index={tab.tabIndex}>
          {tab.tabContent}
        </TabPanel>
      ))}
      {showPrevNextButtons && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="tabs-navigation"
          spacing={1}
        >
          <Button
            disabled={disablePrevious}
            variant="contained"
            color="default"
            startIcon={<ChevronLeftIcon />}
            onClick={handlePrevTab}
          >
            Previous
          </Button>
          <Button
            disabled={disableNext}
            variant="contained"
            endIcon={<ChevronRightIcon />}
            onClick={handleNextTab}
          >
            Next
          </Button>
        </Stack>
      )}
    </>
  );
}

Tabs.propTypes = {
  tabItems: PropTypes.arrayOf(
    PropTypes.shape({
      tabLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tabContent: PropTypes.node,
      tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tabVisible: PropTypes.bool,
      tabIcon: PropTypes.node,
      tabHasErrors: PropTypes.bool,
      tabHashValue: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  defaultActiveTab: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  handleChange: PropTypes.func,
  showPrevNextButtons: PropTypes.bool,
};

export default Tabs;
