import * as React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { uhColors } from 'shared/styles/uhStyles.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';

const styles = {
  Root: {
    position: 'relative',
  },
  ClickAwayLayer: {
    position: 'fixed',
    zIndex: 10,
    // Negative position because this component already the child
    // of a fixed element. --joshuamanns
    top: '-9999px',
    right: '-9999px',
    bottom: '-9999px',
    left: '-9999px',
  },
  TextField: {
    padding: '5px 0px 5px',
    fontSize: 16,
    alignItems: 'center',
    cursor: 'pointer',
  },
  Menu: {
    zIndex: 100,
    // top: 45,
    position: 'absolute',
    width: 'calc(100% - 32px)',
    overflowY: 'auto',
    margin: '0 16px',
  },
  MenuItems: anchorEl => ({
    maxHeight: anchorEl
      ? `${window.innerHeight - anchorEl.getBoundingClientRect().bottom - 64}px`
      : 'none',
    overflowY: 'auto',
  }),
  MenuItem: {
    width: '100%',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class Selectbox extends React.Component {
  constructor(props) {
    super(props);

    this.rootEl = null;

    this.state = {
      open: false,
      searchTerm: '',
      anchorEl: null,
    };
  }

  handleSearchFocus = () => {
    const { onSearchTermChange = () => null, locked = false } = this.props;
    if (!locked) {
      const { searchTerm } = this.state;
      this.setState({
        anchorEl: this.rootEl,
        open: true,
      });
      onSearchTermChange(searchTerm);
    }
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, anchorEl } = this.state;

    const {
      footer = null,
      header = null,
      hintText = '',
      icon = null,
      menuItems = null,
      style = {},
      locked = false,
    } = this.props;

    const menuItemProps = props => ({
      ...props,
      onClick: e => {
        e.preventDefault();

        if (props.onClick) {
          props.onClick(e);
        }

        this.handleRequestClose();
      },
    });

    return (
      <div
        ref={ref => {
          this.rootEl = ref;
        }}
        style={style}
      >
        {open && (
          <div
            role="presentation"
            style={styles.ClickAwayLayer}
            onClick={this.handleRequestClose}
          />
        )}
        <FlexBox
          title={hintText}
          onClick={this.handleSearchFocus}
          style={styles.TextField}
        >
          <div style={{ flex: '0 0 10%', paddingTop: 5 }}>{icon}</div>
          <div style={{ flex: '0 0 81%' }}>{hintText}</div>
          <div style={{ flex: '0 0 9%', textAlign: 'right' }}>
            {locked ? (
              <LockIcon
                sx={{ height: 20, width: 20, color: uhColors.iconLightGrey }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{ height: 24, width: 24, color: uhColors.iconLightGrey }}
              />
            )}
          </div>
        </FlexBox>
        {open && (
          // We can't use a Material-UI Menu or Popover here because
          // it commands focus from TextField --joshuamanns
          <Paper style={styles.Menu}>
            {header}
            <div style={styles.MenuItems(anchorEl)}>
              {menuItems.map
                ? menuItems.map((item, key) =>
                    React.createElement(
                      item.type,
                      menuItemProps({ key, ...item.props })
                    )
                  )
                : menuItems}
            </div>
            {footer}
          </Paper>
        )}
      </div>
    );
  }
}

Selectbox.propTypes = {
  onSearchTermChange: PropTypes.func,
  hintText: PropTypes.string,
  menuItems: PropTypes.any,
  style: PropTypes.object,
  header: PropTypes.node,
  footer: PropTypes.node,
  icon: PropTypes.node,
  locked: PropTypes.bool,
};

export default Selectbox;
