import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ADJUSTMENT_REASONS } from 'retail/stores/InventoryAdjustmentDialogStore.js';
import InventoryAdjustmentDialogActions from 'retail/actions/InventoryAdjustmentDialogActions.js';
import Scrollbar from 'shared/components/Scrollbar.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  container: {
    height: 300,
    padding: 0,
  },
  currencyIcon: {
    display: 'none',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
  },
  tableCell: {
    color: 'var(--color-old-gray)',
    fontWeigh: 'normal',
    fontSize: 13,
    padding: '0 10px',
    height: 44,
  },
  fieldHeader: {
    color: '#807F7F',
    fontWeigh: '600',
    fontSize: 13,
    padding: '10px',
    textAlign: 'left',
  },
  tableRow: {
    verticalAlign: 'midddle',
  },
  tableFirstCell: {
    paddingLeft: 24,
  },
  tableLastCell: {
    paddingRight: 24,
  },
  oddRow: {
    backgroundColor: '#f9f9f9',
  },
  editedRow: {
    backgroundColor: 'var(--color-highlight-yellow)',
  },
};

const VariantTableRow = injectIntl(
  ({ intl, variant, index, purchaseOrderItem, isEditMode, isEdited }) => {
    const intlLabels = ADJUSTMENT_REASONS.map(key => ({
      label: t(key, intl, __filenamespace),
      value: key,
    }));
    const error = purchaseOrderItem.errors.getErrors('actual_Quantity', intl);
    return (
      <tr
        style={{
          ...styles.tableRow,
          ...(index % 2 === 0 ? styles.oddRow : {}),
          ...(isEdited ? styles.editedRow : {}),
        }}
      >
        <td style={{ ...styles.tableCell, ...styles.tableFirstCell }}>
          {variant.name}
        </td>
        <td style={{ ...styles.tableCell, width: '10em' }}>
          {variant.variant}
        </td>
        <td style={{ ...styles.tableCell, width: '8em' }}>
          <div>{variant.sku || ''}</div>
        </td>
        <td style={{ ...styles.tableCell, width: '8em' }}>
          <div>{variant.live_qty || '0'}</div>
        </td>
        <td
          style={{
            ...styles.tableCell,
            ...styles.tableFirstCell,
            width: '8em',
          }}
        >
          {isEditMode ? (
            <TextField
              variant="standard"
              error={!!error}
              helperText={error}
              name="actual_quantity"
              onChange={event => {
                InventoryAdjustmentDialogActions.localUpdatePurchaseOrderItem(
                  purchaseOrderItem,
                  variant,
                  'actual_quantity',
                  event.target.value
                );
              }}
              placeholder="0"
              style={{ width: '6em' }}
              type="number"
              value={purchaseOrderItem?.actual_quantity}
            />
          ) : (
            purchaseOrderItem?.actual_quantity
          )}
        </td>
        <td
          style={{
            ...styles.tableCell,
            ...styles.tableFirstCell,
            width: '8em',
          }}
        >
          {isEditMode ? (
            <Select
              fullWidth
              variant="standard"
              style={styles.durationUnits}
              name="adjusted_reason"
              value={purchaseOrderItem.adjusted_reason}
              onChange={e =>
                InventoryAdjustmentDialogActions.localUpdatePurchaseOrderItem(
                  purchaseOrderItem,
                  variant,
                  'adjusted_reason',
                  e.target.value
                )
              }
            >
              {intlLabels.map(({ label, value }) => (
                <MenuItem value={label}>
                  {t(value, intl, __filenamespace)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            purchaseOrderItem.adjusted_reason
          )}
        </td>
      </tr>
    );
  }
);

function VariantAdjustmentTable({
  inventoryAdjustmentDialogStore = {},
  isProductPage = false,
}) {
  const {
    variants,
    totalCount,
    purchaseOrder,
    isEditMode,
    editedOrderItems,
    isInventory,
  } = inventoryAdjustmentDialogStore;

  return (
    <Scrollbar
      style={styles.container}
      vertical
      showLoader
      manualHeight
      onScroll={
        isProductPage
          ? InventoryAdjustmentDialogActions.variantsList
          : InventoryAdjustmentDialogActions.purchaseOrderItemList
      }
      dataCount={variants.size}
      totalCount={totalCount}
    >
      <table style={styles.table}>
        <thead>
          <tr>
            <td
              aria-label="name"
              style={{ ...styles.fieldHeader, ...styles.tableFirstCell }}
            >
              <FormattedMessage id={messageId('.name', __filenamespace)} />
            </td>
            <td aria-label="variant" style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.variant', __filenamespace)} />
            </td>
            <td aria-label="sku" style={styles.fieldHeader}>
              <FormattedMessage id={messageId('.sku', __filenamespace)} />
            </td>
            <td
              aria-label="expected_quantity"
              style={{ ...styles.fieldHeader, ...styles.tableLastCell }}
            >
              <FormattedMessage
                id={messageId('.expected_quantity', __filenamespace)}
              />
            </td>
            <td
              aria-label="actual_quantity"
              style={{ ...styles.fieldHeader, ...styles.tableLastCell }}
            >
              <FormattedMessage
                id={messageId('.actual_quantity', __filenamespace)}
              />
            </td>
            <td
              aria-label="adjustment_reason"
              style={{ ...styles.fieldHeader, ...styles.tableLastCell }}
            >
              <FormattedMessage
                id={messageId('.adjustment_reason', __filenamespace)}
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {variants.map((variant, index) => (
            <VariantTableRow
              key={variant.id}
              variant={variant}
              index={index}
              purchaseOrderItem={purchaseOrder.getItem(variant.id)}
              isEditMode={isEditMode}
              isEdited={
                !isInventory &&
                editedOrderItems.findIndex(
                  item => item.variant_id === variant.id
                ) >= 0
              }
            />
          ))}
          {variants.size === 0 && (
            <tr style={{ ...styles.tableRow }}>
              <td
                aria-label="empty_variant_table"
                colSpan={5}
                style={{
                  ...styles.tableCell,
                  ...styles.tableFirstCell,
                  textAlign: 'center',
                }}
              >
                <FormattedMessage
                  id={messageId('.empty_variant_table', __filenamespace)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Scrollbar>
  );
}

VariantAdjustmentTable.propTypes = {
  inventoryAdjustmentDialogStore: PropTypes.object,
  isProductPage: PropTypes.bool,
};

export default VariantAdjustmentTable;
