/* eslint-disable class-methods-use-this */
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CouponIcon from '@mui/icons-material/Loyalty';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import uhTheme from '_uh_theme.jsx';

import CalendarIcon from 'shared/components/icons/Calendar.jsx';
import ContactsIcon from 'shared/components/icons/Contacts.jsx';
import DashboardIcon from 'shared/components/icons/Dashboard.jsx';
import EventIcon from 'shared/components/icons/Event.jsx';
import GearIcon from 'shared/components/icons/Gear.jsx';
import AddOnsIcon from 'shared/components/icons/AddOnsIcon.jsx';
import HelpIcon from 'shared/components/icons/Help.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import CreditPassIcon from 'shared/components/icons/CreditPass.jsx';
import ResourceNavIcon from 'shared/components/icons/ResourceNav.jsx';
import RetailIcon from 'shared/components/icons/Retail.jsx';
import SignOutIcon from 'shared/components/icons/SignOut.jsx';
import Classes from 'shared/components/icons/Classes.jsx';
import Intelligence from 'shared/components/icons/Intelligence.jsx';
import ArVision from 'shared/components/icons/ArVision.jsx';
import TeamsIcon from 'shared/components/icons/Teams.jsx';
import Messaging from 'shared/components/icons/Messaging.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import SideNavActions from 'shared/actions/SideNavActions.jsx';
import ExportModalActions from 'containers/reports/exportModal/Actions.js';
import Browser from 'shared/native/Browser';

const icons = {
  dashboard: <DashboardIcon />,
  event: <EventIcon />,
  teams: <TeamsIcon />,
  calendar: <CalendarIcon />,
  marketing: <Messaging />,
  contacts: <ContactsIcon />,
  reports: <AssessmentIcon />,
  memberships: <MembershipIcon />,
  credit_passes: <CreditPassIcon />,
  resources: <ResourceNavIcon />,
  retail: <RetailIcon />,
  settings: <GearIcon />,
  signOut: <SignOutIcon />,
  coupons: <CouponIcon />,
  help: <HelpIcon />,
  classes: <Classes />,
  export: <CloudDownloadIcon />,
  intelligence: <Intelligence />,
  ar_vision: <ArVision />,
  add_ons: <AddOnsIcon />,
  get: (key, props = {}) => {
    const icon = icons[key];
    if (icon) {
      return React.cloneElement(icon, props);
    }
    return null;
  },
};

const actions = {
  export: ExportModalActions.openModal,
  // Wraps the provided action in the necessary code to prevent navitem actions happening.
  get: key => e => {
    e.stopPropagation();
    e.preventDefault();

    const toRun = actions[key];
    if (toRun) toRun();
  },
};

function NestedSideNavMenuItem(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InjectedSideNavMenuItem nested {...props} />;
}

class SideNavMenuItem extends React.Component {
  state = {
    nestedItemsOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { drawerExpanded, item } = this.props;

    if (prevProps.drawerExpanded !== drawerExpanded) {
      const { subitems } = item;

      if (
        drawerExpanded &&
        subitems &&
        !!subitems.filter(i => i.selected).length
      ) {
        this.setState({ nestedItemsOpen: true });
      } else {
        this.setState({ nestedItemsOpen: false });
      }
    }
  }

  handleNestedListToggle = () => {
    const { nestedItemsOpen } = this.state;

    this.setState({ nestedItemsOpen: !nestedItemsOpen });
  };

  handleItemClick = (isDeepLink, link) => {
    if (isDeepLink) {
      Browser.open(link);
    }

    SideNavActions.close();
  };

  shouldSelect(link, currentPath, subitems) {
    if (!subitems || subitems.length === 0) {
      return currentPath.includes(link);
    }
    return subitems.some(i => currentPath.includes(i.link.match(/^[^?]*/)));
  }

  rebuildSubItemSelection(subItems, currentPath) {
    return (
      subItems &&
      subItems.map(subitem => ({
        selected: currentPath.startsWith(subitem.link),
        link: subitem.link,
        title: subitem.title,
        target: subitem.target || '_self',
        useTranslation: subitem.useTranslation ?? true,
        useHref: Boolean(subitem.useHref),
        useDeepLink: Boolean(subitem.useDeepLink),
      }))
    );
  }

  shouldUseReactRouterLink(item, _currentPath) {
    if (item.useHref) {
      return false;
    }
    // NEVER ADD ANYTHING TO THIS LIST! THINGS IN THIS LIST FALL OUTSIDE THE SPA!
    const excludedLinks = ['uh_admin', 'getupperhand'];

    let result = true;
    excludedLinks.forEach(exclusion => {
      if (typeof item.link === 'string' && item.link.includes(exclusion)) {
        result = false;
      }
    });
    if (!result) {
      return result;
    }

    // Use unless menu item has subitems
    return typeof item.subitems === 'undefined';
  }

  render() {
    const { item, drawerExpanded, intl } = this.props;
    const { nestedItemsOpen } = this.state;
    const {
      title,
      link,
      icon,
      useTranslation = true,
      useDeepLink,
      target,
    } = item;

    const currentPath = window.location.pathname;
    const selected = this.shouldSelect(link, currentPath, item.subitems);
    const subitems = this.rebuildSubItemSelection(item.subitems, currentPath);
    const useReactRouterLink = this.shouldUseReactRouterLink(item, currentPath);
    const iconColor = selected
      ? uhTheme.sideNav.selectedColor
      : uhTheme.palette.lightGrey;
    const itemClick = !subitems
      ? () => this.handleItemClick(useDeepLink, link)
      : () => null;

    if (useReactRouterLink) {
      return (
        <Link
          to={link == null ? '' : link}
          target={target}
          style={{ textDecoration: 'none' }}
        >
          <ListItem disablePadding onClick={itemClick}>
            <ListItemButton
              sx={{
                textDecoration: 'none',
                color: uhTheme.palette.lightGrey,
                '& a': {
                  textDecoration: 'none',
                  color: uhTheme.palette.lightGrey,
                },
              }}
              onClick={this.handleNestedListToggle}
            >
              {icon && (
                <ListItemIcon>
                  {icons.get(icon, {
                    style: {
                      color: iconColor,
                    },
                    color: iconColor,
                  })}
                </ListItemIcon>
              )}
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                {useTranslation ? t(title, intl, __filenamespace) : title}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Collapse in={nestedItemsOpen} timeout="auto" unmountOnExit>
            {subitems &&
              subitems.map((subitem, index) => (
                <NestedSideNavMenuItem
                  key={`NestedSideNavMenuItem-${subitem.title}-${subitem.selected}`}
                  index={index}
                  item={subitem}
                  drawerExpanded={drawerExpanded}
                />
              ))}
          </Collapse>
        </Link>
      );
    }

    return (
      <>
        <ListItem
          disablePadding
          sx={{
            '& a': {
              textDecoration: 'none',
              color: uhTheme.palette.lightGrey,
            },
          }}
          onClick={itemClick}
        >
          <ListItemButton
            component="a"
            href={link}
            target={target}
            sx={{
              textDecoration: 'none',
              color: uhTheme.palette.lightGrey,
            }}
            onClick={this.handleNestedListToggle}
          >
            {icon && (
              <ListItemIcon>
                {icons.get(icon, {
                  style: {
                    color: iconColor,
                  },
                  color: iconColor,
                })}
              </ListItemIcon>
            )}
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {useTranslation ? t(title, intl, __filenamespace) : title}
            </ListItemText>
            {item.right && (
              <ListItemIcon>
                {icons.get(item.right.icon, {
                  style: {
                    color: uhTheme.palette.lightGrey,
                  },
                  color: uhTheme.palette.lightGrey,
                  onClick: actions.get(item.right.action),
                })}
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={nestedItemsOpen} timeout="auto" unmountOnExit>
          {subitems &&
            subitems.map((subitem, index) => (
              <NestedSideNavMenuItem
                key={`NestedSideNavMenuItem-${subitem.title}-${subitem.selected}`}
                index={index}
                item={subitem}
                drawerExpanded={drawerExpanded}
              />
            ))}
        </Collapse>
      </>
    );
  }
}

// used by subNav recursive call
const InjectedSideNavMenuItem = injectIntl(SideNavMenuItem);

export default InjectedSideNavMenuItem;
