import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer, Grid, CurrencyTextField, Button } from '@upperhand/playmaker';

import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

function FiltersDrawer({
  intl,
  open,
  totalMin = 0,
  totalMax = 0,
  onClose,
  filtersChanged = false,
  onFieldChange = () => {},
  onApply = () => {},
}) {
  const handleApply = () => {
    onApply();
    onClose();
  };

  return (
    <Drawer
      title={t('.title', intl, __filenamespace)}
      open={open}
      classes={{ root: 'filters-drawer', footer: 'filters-drawer__footer' }}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrencyTextField
              name="amount_min"
              label={t('.min_total', intl, __filenamespace)}
              placeholder={t('.min_total_placeholder', intl, __filenamespace)}
              value={totalMin || ''}
              onChange={e => onFieldChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyTextField
              name="amount_max"
              label={t('.max_total', intl, __filenamespace)}
              placeholder={t('.max_total_placeholder', intl, __filenamespace)}
              value={totalMax || ''}
              onChange={e => onFieldChange(e)}
            />
          </Grid>
        </Grid>
      }
      footer={
        <Button
          fullWidth
          disabled={!filtersChanged}
          classes={{ root: 'filters-drawer__apply-btn' }}
          onClick={handleApply}
        >
          {t('.apply', intl, __filenamespace)}
        </Button>
      }
    />
  );
}

FiltersDrawer.propTypes = {
  intl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  totalMin: PropTypes.number,
  totalMax: PropTypes.number,
  filtersChanged: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func,
  onApply: PropTypes.func,
};

export default compose(memo, injectIntl)(FiltersDrawer);
