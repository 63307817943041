import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InfiniteScroller,
  Spinner,
  Typography,
} from '@upperhand/playmaker';
import { FormattedMessage, injectIntl } from 'react-intl';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import EmptyState from './EmptyState.jsx';
import HeaderDesktop from './TableHeaderDesktop.jsx';

function Table({
  isLoading = true,
  emptyState = null,
  recordCard = () => {},
  actions,
  intl,
  ids,
  page,
  perPage,
  titleIds,
  totalCount,
  isFromMembersList,
}) {
  const recordCards = ids.map(id => recordCard(id));
  return (
    <Grid container item spacing={1} alignContent="center" justify="center">
      {isLoading ? (
        <Grid container item alignContent="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <ResponsiveElement
          largeScreen={
            <>
              <Grid item xs={12}>
                <HeaderDesktop
                  titleIds={titleIds}
                  intl={intl}
                  isFromMembersList={isFromMembersList}
                />
              </Grid>
              {ids.size === 0 ? (
                <Grid item xs={12}>
                  {emptyState || <EmptyState intl={intl} />}
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    {recordCards}
                  </Grid>
                  <Grid item xs={12}>
                    <Paginator
                      className="table__pagination"
                      currentPage={page}
                      perPage={perPage}
                      totalCount={totalCount}
                      onPageSelect={actions.selectPage}
                      pageLimit={0}
                      showInfo
                    />
                  </Grid>
                </>
              )}
            </>
          }
          smallScreen={
            ids.size === 0 ? (
              <Grid item xs={12}>
                {emptyState || <EmptyState intl={intl} />}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {!isFromMembersList && (
                  <Typography variant="subtitle1">
                    <FormattedMessage
                      id={messageId('.mobile_subtitle', __filenamespace)}
                      values={{
                        totalCount,
                      }}
                    />
                  </Typography>
                )}
                {isFromMembersList ? (
                  <>
                    <Grid item xs={12}>
                      {recordCards}
                    </Grid>
                    <Grid item xs={12}>
                      <Paginator
                        className="table__pagination"
                        currentPage={page}
                        perPage={perPage}
                        totalCount={totalCount}
                        onPageSelect={actions.selectPage}
                        pageLimit={0}
                        showInfo
                      />
                    </Grid>
                  </>
                ) : (
                  <InfiniteScroller
                    hasMore={ids.size < totalCount}
                    onScroll={actions.list.defer}
                    isLoading={isLoading}
                    showLoader
                  >
                    {recordCards}
                  </InfiniteScroller>
                )}
              </Grid>
            )
          }
        />
      )}
    </Grid>
  );
}

Table.propTypes = {
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  ids: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  recordCard: PropTypes.func,
  emptyState: PropTypes.node,
  titleIds: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default injectIntl(Table);
