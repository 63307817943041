import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import MailTo from 'shared/components/MailTo.jsx';

import { uhColors } from 'shared/styles/uhStyles.jsx';

const inlineStyles = {
  btn: {
    fontSize: '13px',
    fontWeight: '700',
    textTransform: 'none',
    backgroundColor: uhColors.primaryMain,
    color: uhColors.white,
    '&:hover': {
      backgroundColor: uhColors.primaryMain,
      color: uhColors.white,
    },
  },
};

// NOTE: Cannot use Playmaker for linked buttons outside of React Router. Playmaker requires refactor.
function MailToBtn({
  emailBody = '',
  btnLabel = 'Contact us',
  emailSubject = '',
  mailTo,
}) {
  return (
    <MailTo email={mailTo} subject={emailSubject} body={emailBody}>
      <Button variant="contained" sx={inlineStyles.btn}>
        {btnLabel}
      </Button>
    </MailTo>
  );
}

MailToBtn.propTypes = {
  btnLabel: PropTypes.string,
  emailBody: PropTypes.string,
  emailSubject: PropTypes.string,
  mailTo: PropTypes.string.isRequired,
};

export default MailToBtn;
