import * as React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import AvailableTimesStore from 'event_mgmt/shared/stores/PurchaseDrawerAvailableTimesStore.js';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import BasePurchaseDrawer from 'event_mgmt/display/components/purchase_drawer/PurchaseDrawer.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import EventStaffStore from 'event_mgmt/display/stores/EventStaffStore.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import MembershipDiscountStore from 'event_mgmt/display/stores/MembershipDiscountStore.js';
import PDRegistrationPackageStore from 'event_mgmt/display/stores/PurchaseDrawerRegistrationPackageStore.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import SingleSessionPurchasableContentStore from 'event_mgmt/display/stores/single_session_purchase/SingleSessionPurchasableContentStore.jsx';

import { SessionDataStore } from 'dataStores';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

import PurchaseDrawerActions from './Actions';
import PurchaseDrawerStore from './Store.js';

import './styles.scss';

function PurchaseDrawer({
  availableTimesStore = {},
  cart = {},
  event = {},
  eventStaffStore = {},
  membershipDiscountStore = {},
  pdRegistrationPackageStore = {},
  purchaseDrawerStore = {},
  sessionDataStore = {},
  sspStore = {},
}) {
  const { filteredAthletes, isOpen, isLoading } = purchaseDrawerStore;
  const { sessions } = sessionDataStore;

  if (isLoggedIn()) {
    const { staff } = eventStaffStore;

    return (
      <BasePurchaseDrawer
        athletes={filteredAthletes}
        cart={cart}
        event={event}
        availableTimesStore={availableTimesStore}
        membershipDiscountStore={membershipDiscountStore}
        onRequestClose={PurchaseDrawerActions.closeDrawer}
        open={isOpen}
        isLoading={isLoading}
        pdRegistrationPackageStore={pdRegistrationPackageStore}
        sspStore={sspStore}
        staff={staff}
        sessions={sessions}
        sessionsShowMore={event.sessionsHasMore}
        sessionsLoadingMore={event.sessionsLoading}
        onSessionsLoadMore={() => EventActions.loadMoreSessions()}
        width={smallScreen() ? window.innerWidth * 0.9 : STANDARD_DRAWER_WIDTH}
      />
    );
  }

  return (
    <Drawer
      className="purchase-non-logged"
      anchor="right"
      open={isOpen}
      PaperProps={{
        className: 'purchase-non-logged__content',
      }}
      onClose={PurchaseDrawerActions.closeDrawer}
    >
      <Stack
        className="purchase-non-logged__header"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className="purchase-non-logged__header-title">
          Login
        </Typography>
        <IconButton
          className="purchase-non-logged__close-btn"
          onClick={PurchaseDrawerActions.closeDrawer}
        >
          <Close />
        </IconButton>
      </Stack>
      <SignInPrompt styles={{ padding: '15px 30px' }} />
    </Drawer>
  );
}

PurchaseDrawer.propTypes = {
  availableTimesStore: PropTypes.object,
  cart: PropTypes.object,
  event: PropTypes.object,
  eventStaffStore: PropTypes.object,
  pdRegistrationPackageStore: PropTypes.object,
  purchaseDrawerStore: PropTypes.shape({
    isOpen: PropTypes.bool,
    eventId: PropTypes.number,
  }),
  sessionDataStore: PropTypes.object,
  sspStore: PropTypes.object,
};

export default compose(
  React.memo,
  altContainer({
    stores: {
      availableTimesStore: AvailableTimesStore,
      cart: CartStore,
      event: EventStore,
      eventStaffStore: EventStaffStore,
      membershipDiscountStore: MembershipDiscountStore,
      pdRegistrationPackageStore: PDRegistrationPackageStore,
      purchaseDrawerStore: PurchaseDrawerStore,
      sessionDataStore: SessionDataStore,
      sspStore: SingleSessionPurchasableContentStore,
    },
  })
)(PurchaseDrawer);
