/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import * as React from 'react';

import Client from 'shared/records/Client.jsx';
import Staff from 'shared/records/Staff.jsx';
import User from 'event_mgmt/shared/records/User.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';

function AvatarWithName({
  user = null,
  name = '',
  letter = '',
  avatarOptions = {},
  src = undefined,
  style = {},
  ...props
}) {
  const userObject = user || {
    first_name: letter,
    alternativeImage: () => src,
  };

  return (
    <FlexBoxCenter style={style} {...props}>
      <UserAvatar
        {...avatarOptions}
        user={userObject}
        style={{
          flex: '0 0 auto',
        }}
      />
      {user ? user.name() : name}
    </FlexBoxCenter>
  );
}

AvatarWithName.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.instanceOf(Client),
    PropTypes.instanceOf(Staff),
    PropTypes.instanceOf(User),
  ]),
  name: PropTypes.string,
  letter: PropTypes.string,
  avatarOptions: PropTypes.shape({
    showTypeBadge: PropTypes.bool,
    darkOnLight: PropTypes.bool,
    membershipColor: PropTypes.string,
  }),
  src: PropTypes.string,
  style: PropTypes.object,
};

export default AvatarWithName;
