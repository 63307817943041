/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Map } from 'immutable';
import moment from 'moment-timezone';
import Clear from '@mui/icons-material/Clear';
import ContentAdd from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import DiscountValue from 'shared/components/DiscountValue.jsx';
import NoClientCredits from 'point_of_sale/components/_NoClientCredits.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import RegistrationScheduler from 'shared/components/registration/RegistrationScheduler.jsx';
import ScheduledSession from 'shared/components/registration/ScheduledSession.jsx';
import TentativeRegistration from 'shared/records/TentativeRegistration';

import { FlexBox, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import SummaryConflict from 'shared/components/registration/SummaryConflict.jsx';
import ConflictSchedule from 'shared/components/registration/ConflictSchedule.jsx';
import CompleteBooking from 'shared/components/registration/CompleteBooking.jsx';
import PackageSelector from 'shared/components/registration/PackageSelector.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import RepeatBookingActions from 'event_mgmt/shared/actions/RepeatBookingActions.jsx';
import { compose } from 'shared/utils/SharedUtils';
import altContainer from 'shared/hocs/altContainer.jsx';
import RegistrationStore from 'event_mgmt/display/stores/RegistrationStore.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import POSEventStaffActions from 'point_of_sale/actions/POSEventStaffActions.js';

const styles = {
  button: {
    height: 50,
    marginBottom: 10,
  },

  buttonLabel: {
    fontSize: 16,
  },

  membershipDiscountBanner: {
    margin: '-2rem -2rem 2rem -2rem',
    padding: '2rem',
    height: 56,
    backgroundColor: uhColors.lightGrey,
    alignItems: 'center',
  },
};

function ScheduleNowLabel({ registrationPackage }) {
  return (
    <FlexBoxJustify
      style={{ padding: '0 22px', textTransform: 'none', width: '100%' }}
    >
      <FormattedMessage id={messageId('.schedule_now', __filenamespace)} />
      <FormattedMessage
        id={messageId('.n_of_m', __filenamespace)}
        values={{
          n: (registrationPackage.scheduledRegistrationCount + 1).toString(),
          m: registrationPackage.quantity.toString(),
        }}
      />
    </FlexBoxJustify>
  );
}

function MembershipDiscountBanner({ eventDiscount }) {
  return (
    <FlexBoxJustify style={styles.membershipDiscountBanner}>
      <div style={{ fontWeight: 'bold' }}>
        <FormattedMessage id={messageId('.member_discount', __filenamespace)} />
      </div>
      <div style={{ fontSize: 18 }}>
        <DiscountValue discount={eventDiscount.discount} />
      </div>
    </FlexBoxJustify>
  );
}

function ProfileAndPackageSelection({
  allowProfileClear,
  athletes,
  clientEventCredits,
  eventStore,
  isNavigatingToCart,
  isUpdatingCart,
  managingUserId,
  membershipEventDiscounts = List(),
  onAddToCart,
  onContinueToCart,
  schedulingActions,
  schedulingStore,
  showAddToCart,
  staff,
  intl,
  availableTimesStore,
}) {
  const { repeat } = availableTimesStore;
  const { price } = eventStore.customerEvent;
  const {
    orderItemId,
    packagePricingDescription,
    registrationPackage,
    selectedClientId,
  } = schedulingStore;
  const [selectedValue, setSelectedValue] = React.useState(
    registrationPackage?.quantity
  );
  const selectedProfile = athletes.find(
    a => a.id === schedulingStore.selectedClientId
  );
  const activeMembershipId = selectedProfile?.active_membership_id;
  const activeMembershipTierId = selectedProfile?.active_membership_tier_id;
  const eventDiscount = membershipEventDiscounts.find(
    med =>
      (med.subscribable_type === 'MembershipTier' &&
        med.subscribable_id === activeMembershipTierId) ||
      (med.subscribable_type === 'Membership' &&
        med.subscribable_id === activeMembershipId)
  );
  const packageSelected =
    !packagePricingDescription || registrationPackage.automation_option_uuid;
  const { scheduledRegistrationCount } = registrationPackage;

  const showNoCreditsButtons =
    eventStore.event &&
    eventStore.event.isClassSchedule() &&
    selectedClientId &&
    !clientEventCredits.get(selectedClientId);

  return (
    <div>
      {eventDiscount && (
        <MembershipDiscountBanner eventDiscount={eventDiscount} />
      )}
      {orderItemId && (
        <div style={{ fontSize: 20, marginBottom: 8 }}>
          <FormattedMessage id={messageId('.edit_item', __filenamespace)} />
        </div>
      )}

      {!selectedClientId && (
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          <FormattedMessage
            id={messageId('.select_an_attendee', __filenamespace)}
          />
        </div>
      )}

      <FlexBox>
        <ProfileSelector
          afterProfileCreate={schedulingActions.profileCreatedOpenBooking}
          athletes={athletes}
          fullWidth
          managingUserId={managingUserId}
          onChange={(_, __, customerUserId) => {
            const eventIds = eventStore?.event?.id;
            const clientIds = customerUserId;
            // commented click to open schudle and open schudle here
            ClientCreditActions.list({ eventIds, clientIds });
            // schedulingActions.scheduleNowClicked();
            schedulingActions.registrationAthleteChanged(customerUserId);
          }}
          preventProfileCreation={eventStore.customerEvent.isMembershipExclusive()}
          style={{ marginBottom: 10, flex: '1 0 auto' }}
          value={selectedClientId}
        />
        {allowProfileClear && (
          <IconButton
            style={{
              marginRight: -8,
              padding: 0,
              height: 32,
              width: 32,
              alignSelf: 'center',
            }}
            onClick={() => schedulingActions.registrationAthleteChanged(null)}
          >
            <Clear sx={{ color: uhColors.lightGrey }} />
          </IconButton>
        )}
      </FlexBox>
      {showNoCreditsButtons && <NoClientCredits intl={intl} />}
      {selectedClientId && !showNoCreditsButtons && (
        <div>
          <PackageSelector
            label={t('.select_a_package', intl, __filenamespace)}
            discount={eventDiscount && eventDiscount.discount}
            packagePricingDescription={packagePricingDescription}
            price={price}
            selectedValue={selectedValue}
            registrationPackage={registrationPackage}
            onAutomationOptionSelect={(_, __, uuid) => {
              schedulingActions.automatedPackageSelected(uuid);
            }}
            onDefaultOptionSelect={(_, __, size) => {
              setSelectedValue(size);
              schedulingActions.defaultPackageSelected(size);
            }}
            style={{ marginBottom: 10 }}
          />
          {registrationPackage?.quantity === 0 && !currentUser().isClient() ? (
            <>
              <div style={{ textAlign: 'center', marginBottom: 10 }}>or</div>
              <Button
                fullWidth
                variant="contained"
                style={styles.button}
                onClick={() => {
                  schedulingActions.scheduleNowClicked();
                  RepeatBookingActions.repeatButtonHandler(repeat);
                }}
              >
                {t('.repeatBooking', intl, __filenamespace)}
              </Button>
            </>
          ) : (
            ''
          )}
          {registrationPackage.tentative_details.map((registration, i) => (
            <ScheduledSession
              key={`${registration.client_id}-
                ${registration.staff_ids.toString()}-
                ${registration.starts_at.format()}`}
              index={i}
              event={eventStore.customerEvent}
              onRegistrationCancel={({ index }) =>
                schedulingActions.registrationCancelled(index)
              }
              profiles={athletes}
              client_id={registration.client_id}
              starts_at={registration.starts_at}
              staff_ids={registration.staff_ids}
              schedulingActions={schedulingActions}
              staff={staff}
              style={{ marginBottom: 10 }}
            />
          ))}

          {scheduledRegistrationCount > 0 && (
            <div
              style={{
                margin: '15px 0',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
            >
              <FormattedMessage
                id={messageId('.continue_to_save_sessions', __filenamespace)}
              />
            </div>
          )}
        </div>
      )}
      {selectedClientId && packageSelected && !showNoCreditsButtons && (
        <div>
          {(!currentUser().isClient() ||
            !currentCustomer().disable_client_scheduling) &&
            scheduledRegistrationCount < registrationPackage.quantity && (
              <div>
                <Button
                  fullWidth
                  variant="contained"
                  style={styles.button}
                  onClick={() => schedulingActions.scheduleNowClicked()}
                >
                  <ScheduleNowLabel registrationPackage={registrationPackage} />
                </Button>
                <div style={{ textAlign: 'center', marginBottom: 10 }}>or</div>
              </div>
            )}
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            disabled={
              isUpdatingCart ||
              isNavigatingToCart ||
              registrationPackage.quantity === 0
            }
            style={styles.button}
            onClick={() => onContinueToCart(registrationPackage, orderItemId)}
          >
            {t('.continue_to_cart', intl, __filenamespace)}
          </Button>
          {showAddToCart && (
            <div style={{ textAlign: 'center' }}>
              <Button
                disabled={isUpdatingCart || isNavigatingToCart}
                style={{ marginBottom: 10 }}
                startIcon={<ContentAdd sx={{ color: uhColors.activeBlue }} />}
                onClick={() => {
                  onAddToCart(registrationPackage, orderItemId);
                  setSelectedValue(0);
                }}
              >
                {t('.add_another_attendee', intl, __filenamespace)}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function Scheduling({
  schedulingStore,
  schedulingActions,
  athletes,
  eventStore,
  availableTimesStore,
  staff,
  intl,
  onContinueToCart,
  clientCredits,
  completeClicked,
  onRegistrationBooked,
  RegistrationStore,
  cart,
}) {
  const {
    date,
    time,
    isCurrentTimeAvailable,
    isLoading,
    allDates,
    availableTimesRepeat,
    startTimeRepeat,
    sessionConflicts,
    suggestedTimes: ssTimes,
    membershipEventDiscounts = List(),
    summaryScreen,
    conflictScreen,
    bookableSessions,
    cartScreen,
    repeat,
    nonBookableSessions,
  } = availableTimesStore;
  const { isOrderItem } = cart;

  const selectedProfile = athletes.find(
    a => a.id === schedulingStore.selectedClientId
  );

  const eventDiscount = membershipEventDiscounts.find(
    med =>
      med.membership_id ===
      (selectedProfile && selectedProfile.active_membership_id)
  );

  const {
    registrationPackage,
    selectedClientId,
    packagePricingDescription,
    orderItemId,
    allProfiles,
  } = schedulingStore;
  const { scheduledRegistrationCount } = registrationPackage;
  const currentRegistration = registrationPackage.tentative_details.get(
    scheduledRegistrationCount,
    new TentativeRegistration()
  );

  function handleCredits() {
    if (currentUser().isStaff()) {
      const profiles = allProfiles;
      const { event } = eventStore;
      const clientCreditsFromStore = clientCredits.get(event?.id, Map());
      let creditCount;
      profiles
        .sortBy(p => p.id)
        .map(profile => {
          const authorized = profiles.some(p => p.hasAccessTo(event));
          if (!authorized) {
            return null;
          }
          const newClientCredits = clientCreditsFromStore?.get(profile?.id);
          if (newClientCredits) {
            creditCount = newClientCredits.get('unlimited')
              ? Infinity
              : newClientCredits.get('credits_remaining');
          }
        });
      if (creditCount !== undefined) {
        return creditCount;
      }
      return 0;
    }
    return 0;
  }

  const [staffId, setStaffId] = React.useState(null);
  const [numberOfSessions, setNumberOfSessions] = React.useState(0);
  // const [_bookableSessions, setBookableSessions] = React.useState([]);
  const [_count, setCounts] = React.useState(0);
  const [conflictActiveDates, setConflictActiveDates] = React.useState([]);
  const [_activeDates, setActives] = React.useState([]);
  const [activeDays, setActiveDays] = React.useState({
    Sun: {
      active: false,
    },
    Mon: {
      active: false,
    },
    Tue: {
      active: false,
    },
    Wed: {
      active: false,
    },
    Thu: {
      active: false,
    },
    Fri: {
      active: false,
    },
    Sat: {
      active: false,
    },
  });
  const [addDay, setAddDay] = React.useState([]);
  const [newTentiveDetailState, setNewTentiveDetailState] = useState([]);
  useEffect(() => {
    let temp = [];
    registrationPackage.tentative_details.map(item =>
      temp.push(item.toServer())
    );
    if (ssTimes.length > 0 && !cartScreen) {
      temp = temp.filter(
        ({ starts_at: startAt }) =>
          !ssTimes.some(
            ({ conflicted_date: conflictedDate }) =>
              startAt?._i === conflictedDate
          )
      );
    }
    if (nonBookableSessions.length > 0 && !cartScreen) {
      temp = temp.filter(
        ({ starts_at: startAt }) =>
          !nonBookableSessions.some(({ d }) => startAt?._i === d)
      );
    }
    setNewTentiveDetailState(temp);
    return () => {
      temp = [];
      setNewTentiveDetailState(temp);
    };
  }, [
    cartScreen,
    registrationPackage.tentative_details,
    ssTimes,
    nonBookableSessions,
  ]);
  const handleBooking = ({
    key,
    time,
    count,
    activeDates,
    client_id,
    staff_id,
    scheduledRegistrationCount,
  }) => {
    const registrationCallback = () => {
      const updatedWithBookAbleDates = newTentiveDetailState.filter(item =>
        bookableSessions.some(({ date, resource_id: resourceId }) => {
          if (item?.starts_at?._i === date && resourceId !== null) {
            return (item.resource_ids = [...resourceId]);
          }
          if (item?.starts_at?._i === date && resourceId === null) {
            return item.resource_ids;
          }
        })
      );
      onRegistrationBooked({
        tentativeRegistration: updatedWithBookAbleDates,
        repeatBooking: true,
      });
      RepeatBookingActions.setBookAbleSessions(newTentiveDetailState);
      POSEventStaffActions.handleResetStaff();
    };
    schedulingActions.registrationTimeChanged(
      key,
      time,
      count,
      activeDates,
      client_id,
      staff_id,
      registrationCallback,
      true,
      scheduledRegistrationCount
    );
  };

  const handleAddDays = value => {
    setAddDay(value);
    const newDays = {
      Sun: {
        active: false,
      },
      Mon: {
        active: false,
      },
      Tue: {
        active: false,
      },
      Wed: {
        active: false,
      },
      Thu: {
        active: false,
      },
      Fri: {
        active: false,
      },
      Sat: {
        active: false,
      },
    };
    value.map(day => {
      newDays[day] = {
        active: true,
      };
    });
    setActiveDays(newDays);
  };

  const filterObject = (obj, callback) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => callback(val, key))
    );
  const setHandleDay = days => {
    const newday = filterObject(days, day => day.active);
    const object = Object.keys(newday);
    handleAddDays(object);
    return object;
  };

  // function to calculate the days
  const daysArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // extract the days by available dates
  const daysAll = allDates.map(x => {
    const day = moment(x.date);
    const dayName = daysArray[day?._d?.getDay()];
    return dayName;
  });

  const handleBookable = (isChecked, item) => {
    if (isChecked) {
      const temp = [];
      const {
        conflicted_date: conflictedDate,
        time_slot: timeSlot,
        resource_id: resourceId,
      } = item;
      const updatedBookableDates = [...bookableSessions];
      let updatedItem = {
        date: conflictedDate,
        end_time: timeSlot.end_time,
        resource_id: null,
        start_time: timeSlot.start_time,
      };
      if (resourceId !== null) {
        updatedItem = {
          date: conflictedDate,
          end_time: timeSlot.end_time,
          resource_id: resourceId,
          start_time: timeSlot.start_time,
        };
      }
      updatedBookableDates.push(updatedItem);
      RepeatBookingActions.setBookAbleSessions(updatedBookableDates);
      registrationPackage.tentative_details.map(detail =>
        temp.push(detail.toServer())
      );
      const newItem = temp?.find(x => {
        const { starts_at: startAt } = x;
        return startAt?._i === conflictedDate;
      });
      const { start_time: startTime, end_time: endTime } = timeSlot;
      const { starts_at: newItemStartAt, ends_at: endsAt } = newItem;
      const startTimeFromTimeZone = moment.tz(
        `${startTime}`,
        'HH:mm',
        customerTZ()
      )?._d;
      const endTimeFromTimeZone = moment.tz(
        `${endTime}`,
        'HH:mm',
        customerTZ()
      )?._d;
      const newStartTime = `${startTimeFromTimeZone.getHours()}:${startTimeFromTimeZone.getMinutes()}:00`;
      const newEndTime = `${endTimeFromTimeZone.getHours()}:${endTimeFromTimeZone.getMinutes()}:00`;
      const { _d: updatedMomentStartTime } = moment(
        `${conflictedDate} ${newStartTime}`
      );
      const { _d: updatedMomentEndTime } = moment(
        `${conflictedDate} ${newEndTime}`
      );
      newItemStartAt._d = updatedMomentStartTime;
      endsAt._d = updatedMomentEndTime;
      const updatedTentiveArray = [...newTentiveDetailState];
      updatedTentiveArray.push(newItem);
      setNewTentiveDetailState(updatedTentiveArray);
    } else {
      const { conflicted_date: conflictedDate } = item;
      let updatedTentiveArray = [...newTentiveDetailState];
      let updatedBookableDates = [...bookableSessions];
      updatedBookableDates = updatedBookableDates?.filter(x => {
        const { date } = x;
        return date !== conflictedDate;
      });
      RepeatBookingActions.setBookAbleSessions(updatedBookableDates);
      updatedTentiveArray = updatedTentiveArray?.filter(x => {
        const { starts_at: startAt } = x;
        return startAt?._i !== conflictedDate;
      });
      setNewTentiveDetailState(updatedTentiveArray);
    }
  };
  return (
    <div>
      {!conflictScreen && !summaryScreen && !cartScreen ? (
        <>
          <RegistrationScheduler
            clientCreditCount={handleCredits()}
            repeatButton={false}
            repeatEnabled={repeat}
            handleRepeat={RepeatBookingActions.repeatButtonHandler}
            dateAndTimeOnly
            hideBookButton
            athletes={athletes}
            selectedAthleteId={undefined}
            afterProfileCreate={() => {}}
            event={eventStore}
            availableTimesStore={availableTimesStore}
            managingUserId={undefined}
            registration={currentRegistration}
            allRegistrationTimes={availableTimesStore.existingScheduledTimes}
            currentScheduleCount={scheduledRegistrationCount + 1}
            totalRegistrationCount={registrationPackage.quantity}
            onStaffChange={(_, __, staffId) => {
              schedulingActions.registrationStaffChanged.defer(
                staffId,
                scheduledRegistrationCount
              );
              setStaffId(staffId);
            }}
            onDateChange={(date, key) => {
              schedulingActions.registrationDateChanged(
                key,
                date,
                scheduledRegistrationCount
              );
            }}
            onTimeChange={(
              time,
              key,
              count,
              activeDates,
              client_id,
              staff_id,
              callBack,
              schedulingNow
            ) => {
              schedulingActions.registrationTimeChanged(
                key,
                time,
                count,
                activeDates,
                client_id,
                staff_id,
                callBack,
                schedulingNow,
                scheduledRegistrationCount
              );
            }}
            fetchAvailabilities={schedulingActions.fetchAvailabilities}
            onAthleteChange={() => {}}
            onBookRegistration={() =>
              schedulingActions.registrationBooked(scheduledRegistrationCount)
            }
            staff={staff}
            staffId={staffId}
            style={{ marginBottom: 10 }}
            setHandleDay={setHandleDay}
            activeDays={activeDays}
            _availableDays={daysAll}
            handleAddDays={handleAddDays}
            numberOfSessions={numberOfSessions}
            setNumberOfSessions={setNumberOfSessions}
            addDay={addDay}
            customerUserId={selectedClientId}
            _saveSessions={(key = false) => {
              if (key === 'repeatedScheduling') {
                schedulingActions.registrationBooked(
                  scheduledRegistrationCount,
                  key
                );
              } else {
                schedulingActions.registrationBooked(
                  scheduledRegistrationCount
                );
              }
            }}
            setCounts={setCounts}
            setActives={setActives}
          />
          {!repeat && (
            <>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  !date || !time || !isCurrentTimeAvailable || isLoading
                }
                style={styles.button}
                onClick={() =>
                  schedulingActions.registrationBooked(
                    scheduledRegistrationCount
                  )
                }
              >
                {t('.save_session', intl, __filenamespace)}
              </Button>
              <Button
                fullWidth
                style={{ ...styles.button, color: uhColors.activeBlue }}
                onClick={() => schedulingActions.scheduleLaterClicked()}
              >
                {t('.schedule_later', intl, __filenamespace)}
              </Button>
            </>
          )}
        </>
      ) : (
        ''
      )}

      {conflictScreen ? (
        <ConflictSchedule
          conflictActiveDates={conflictActiveDates}
          setConflictActiveDates={setConflictActiveDates}
          numberOfDays={addDay}
          staffId={staffId}
          registrationPackage={registrationPackage}
          repeatBookingActions={RepeatBookingActions}
          startTimeRepeat={startTimeRepeat}
          numberOfSessions={numberOfSessions}
          sessionConflicts={sessionConflicts}
          availableTimesRepeat={availableTimesRepeat}
          selectedClientId={selectedClientId}
          athletes={athletes}
          duration={eventStore?.event.scheduleText()}
          bookableSessions={newTentiveDetailState}
          nonBookableSessions={nonBookableSessions}
          suggestedTimes={ssTimes}
          isLoading={isLoading}
          handleBookable={handleBookable}
          isOrderItem={isOrderItem}
        />
      ) : (
        ''
      )}

      {summaryScreen ? (
        <SummaryConflict
          cart={cart}
          isOrderItem={isOrderItem}
          selectedClientId={selectedClientId}
          athletes={athletes}
          discount={eventDiscount && eventDiscount.discount}
          packagePricingDescription={packagePricingDescription}
          price={eventStore.customerEvent.price}
          registrationPackage={registrationPackage}
          schedulingActions={schedulingActions}
          bookableSessions={newTentiveDetailState}
          sessionforBooking={bookableSessions}
          clientCreditCount={handleCredits()}
          onContinueToCart={onContinueToCart}
          orderItemId={orderItemId}
          schedulingStore={schedulingStore}
          eventDiscount={eventDiscount}
          SchedulingLoading={RegistrationStore?.schedulingLoading}
          RegistrationStore={RegistrationStore}
          _saveSessions={(_key = false) => {
            schedulingActions.registrationBooked(scheduledRegistrationCount);
          }}
          onBookRegistration={() => {
            handleBooking({
              key: 'repeatFunctionsCall',
              time,
              count: _count,
              activeDates: _activeDates,
              client_id: selectedClientId,
              staff_id: staffId,
              scheduledRegistrationCount,
            });
          }}
        />
      ) : (
        ''
      )}

      {cartScreen ? (
        <CompleteBooking
          athletes={athletes}
          selectedClientId={selectedClientId}
          bookableSessions={bookableSessions}
          completeClicked={completeClicked}
        />
      ) : (
        ''
      )}
    </div>
  );
}

function OpenBookingScheduling(props) {
  return props.schedulingStore.isSchedulingNow ? (
    <Scheduling {...props} />
  ) : (
    <ProfileAndPackageSelection {...props} />
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      RegistrationStore,
      cart: CartStore,
    },
  })
)(OpenBookingScheduling);
