import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Event({ color = '#9A9EAD', ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 32 36" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-28.000000, -503.000000)" fill={color}>
          <g transform="translate(0.000000, 320.000000)">
            <path d="M49.375,192 L42.625,192 C42.0025,192 41.5,192.5025 41.5,193.125 L41.5,195.375 C41.5,195.996 42.0025,196.5 42.625,196.5 L49.375,196.5 C49.996,196.5 50.5,195.996 50.5,195.375 L50.5,193.125 C50.5,192.5025 49.996,192 49.375,192 L49.375,192 Z M55,204.4455 C55,205.0665 54.496,205.5705 53.875,205.5705 L38.125,205.5705 C37.5025,205.5705 37,205.0665 37,204.4455 L37,188.625 C37,188.0025 37.5025,187.5 38.125,187.5 L53.875,187.5 C54.496,187.5 55,188.0025 55,188.625 L55,204.4455 Z M55,213.375 C55,213.996 54.496,214.5 53.875,214.5 L37,214.5 L34.726,214.5 C33.496,214.5 32.5,213.5025 32.5,212.2725 L32.5,212.2275 C32.5,210.9975 33.496,210 34.726,210 L37,210 L53.875,210 C54.496,210 55,210.5025 55,211.125 L55,213.375 Z M57.25,183 L55,183 L37,183 L32.5,183 C30.0145,183 28,185.0145 28,187.5 L28,214.5 C28,216.9855 30.0145,219 32.5,219 L37,219 L57.25,219 C58.492,219 59.5,217.992 59.5,216.75 L59.5,215.625 L59.5,214.5 L59.5,210 L59.5,205.5705 L59.5,187.5 L59.5,185.25 C59.5,184.0065 58.492,183 57.25,183 L57.25,183 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Event;
