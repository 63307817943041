import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { List } from 'immutable';
import { Typography, Grid, Spinner } from '@upperhand/playmaker';
import IconButton from '@mui/material/IconButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';

import { t } from 'shared/utils/LocaleUtils';

import ContactsFiltersDrawerActions from 'containers/contacts/contactsFilters/Actions';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';

import { STEPS } from 'containers/contacts/contactsFilters/Store';

function InitialStep({ contactFilters = List(), filtersLoading = false }) {
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="fieldLabel">
          {t('.saved_filters', intl, __filenamespace)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {filtersLoading && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item>
              <Spinner />
            </Grid>
            <Grid item>
              <Typography>
                {t('.loading_filters', intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
        )}
        {contactFilters.size === 0 && !filtersLoading && (
          <Typography>
            {t('.no_saved_filters', intl, __filenamespace)}
          </Typography>
        )}
        {contactFilters.size > 0 && !filtersLoading && (
          <div className="contacts-filters-drawer__filter-list">
            {contactFilters.map(filter => (
              <div
                key={filter.id}
                className="contacts-filters-drawer__filter-list-item"
              >
                <div
                  role="presentation"
                  className="contacts-filters-drawer__filter-list-item-title"
                  onClick={() =>
                    ContactsFiltersDrawerActions.changeStep({
                      step: STEPS.CREATE,
                      filter,
                    })
                  }
                >
                  <p>{filter.name}</p>
                  {filter.contact_group_id && (
                    <GroupIcon className="contacts-filters-drawer__group-added" />
                  )}
                </div>
                {!filter.contact_group_id && (
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() =>
                      ContactGroupActions.create({ contactFilterId: filter.id })
                    }
                  >
                    <GroupAddIcon className="contacts-filters-drawer__add-group" />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        )}
      </Grid>
    </Grid>
  );
}

InitialStep.propTypes = {
  contactFilters: PropTypes.instanceOf(List),
  filtersLoading: PropTypes.bool,
};

export default memo(InitialStep);
