import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function Messaging({ color = '#9A9EAD', ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 20" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-722.000000, -179.000000)"
          fillRule="nonzero"
          fill={color}
        >
          <g transform="translate(722.000000, 179.000000)">
            <path
              d="M5.8,7.1 L17.6,7.1 C18.06,7.1 18.42,6.74 18.42,6.28 C18.42,5.82 18.06,5.46 17.6,5.46
               L5.8,5.46 C5.34,5.46 4.98,5.82 4.98,6.28 C4.98,6.74 5.34,7.1 5.8,7.1 Z"
            />
            <path
              d="M5.8,11.06 L17.6,11.06 C18.06,11.06 18.42,10.7 18.42,10.24 C18.42,9.78 18.06,9.42
               17.6,9.42 L5.8,9.42 C5.34,9.42 4.98,9.78 4.98,10.24 C4.98,10.7 5.34,11.06 5.8,11.06
               Z"
            />
            <path
              d="M0.9,16.36 L3.54,16.36 L3.54,19.06 C3.54,19.38 3.72,19.66 4,19.8 C4.12,19.86 4.24,
               19.88 4.36,19.88 C4.54,19.88 4.72,19.82 4.86,19.72 L9.26,16.38 L22.5,16.38 C22.96,
               16.38 23.32,16.02 23.32,15.56 L23.32,0.98 C23.32,0.52 22.96,0.16 22.5,0.16 L0.9,0.16
               C0.44,0.16 0.08,0.52 0.08,0.98 L0.08,15.54 C0.08,16 0.46,16.36 0.9,16.36 Z M1.72,1.8
               L21.66,1.8 L21.66,14.72 L8.98,14.72 C8.8,14.72 8.62,14.78 8.48,14.88 L5.18,17.4
               L5.18,15.54 C5.18,15.08 4.82,14.72 4.36,14.72 L1.72,14.72 L1.72,1.8 Z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default Messaging;
