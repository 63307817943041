import * as React from 'react';
import { injectIntl } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import MenuDivider from 'side_nav/_MenuDivider.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import ModalRegistrationActions from 'shared/components/signUp/Actions';

const styles = {
  ListItemButton: {
    color: 'white',
    padding: 0,
  },
};

function NonLoggedInItems({ intl, onItemClick = () => null }) {
  const handleSignIn = () => {
    onItemClick();
    ModalRegistrationActions.toggleSignInModal({});
  };

  const handleSignUp = () => {
    onItemClick();
    ModalRegistrationActions.toggleSignUpModal({});
  };

  return (
    <div>
      <ListItem>
        <ListItemButton style={styles.ListItemButton} onClick={handleSignIn}>
          <ListItemText>{t('.login', intl, __filenamespace)}</ListItemText>
        </ListItemButton>
      </ListItem>
      <MenuDivider />
      <ListItem>
        <ListItemButton style={styles.ListItemButton} onClick={handleSignUp}>
          <ListItemText>
            {t('.create_account', intl, __filenamespace)}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </div>
  );
}

export default injectIntl(NonLoggedInItems);
