import * as React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { customerScopedRoute } from 'shared/utils/RouteUtils';
import { isLoggedIn, currentUser } from 'shared/utils/UserUtils.jsx';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import Unauthorized from 'shared/components/Unauthorized.jsx';
import ContentLayoutLarge from 'layouts/ContentLayoutLarge.jsx';
import ContentLayoutSmall from 'layouts/ContentLayoutSmall.jsx';

function CheckRedirection({ redirectWhenLoggedOut, redirectOnLogin }) {
  const user = currentUser();

  if (!isLoggedIn() && redirectWhenLoggedOut) {
    return (
      <Navigate
        to={`/accounts/login?redirect_to=${window.location.pathname}`}
      />
    );
  }

  if ((user.isStaffAdmin() || user.isAdmin()) && redirectOnLogin) {
    return <Navigate to={customerScopedRoute('dashboard')} />;
  }

  // take staff to events page (this will automatically be staff events)
  if (user.isInstructor() && redirectOnLogin) {
    return <Navigate to={customerScopedRoute('calendar')} />;
  }

  // take staff to events page (this will automatically be staff events)
  if (
    (user.isStaffMember() || user.isCoach() || user.isManager()) &&
    redirectOnLogin
  ) {
    return <Navigate to={customerScopedRoute('events')} />;
  }

  // if client default to my_profile
  if (user.isClient() && redirectOnLogin) {
    return <Navigate to={customerScopedRoute('my_profile')} />;
  }

  return null;
}

function PageContent({ children, allowedRoles }) {
  const authorized = allowedRoles.find(ruleFunc => ruleFunc());

  return (
    <ResponsiveElement
      largeScreen={<ContentLayoutLarge />}
      smallScreen={<ContentLayoutSmall />}
    >
      {authorized ? children : <Unauthorized />}
    </ResponsiveElement>
  );
}

function AuthorizedPageWrapper({
  children,
  allowedRoles,
  redirectOnLogin = false,
  redirectWhenLoggedOut = true,
}) {
  return (
    <>
      <CheckRedirection
        redirectOnLogin={redirectOnLogin}
        redirectWhenLoggedOut={redirectWhenLoggedOut}
      />
      <PageContent allowedRoles={allowedRoles}>{children}</PageContent>
    </>
  );
}

AuthorizedPageWrapper.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.func).isRequired,
  redirectOnLogin: PropTypes.bool,
  redirectWhenLoggedOut: PropTypes.bool,
};

export default AuthorizedPageWrapper;
