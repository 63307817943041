import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { OrderedSet, Map } from 'immutable';
import { Drawer, Spinner, TextField, Button } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import ProfileCard from 'user_management/shared/components/ProfileCard.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import Staff from 'shared/records/Staff.jsx';

import { StaffDataStore } from 'dataStores';

function StaffDrawer({
  intl = {},
  open = false,
  isLoading = false,
  staffIds = OrderedSet(),
  selectedIds = OrderedSet(),
  searchText = '',
  onClose = () => null,
  onSearch = () => null,
  onItemClick = () => null,
  onSave = () => null,
  staffDataStore = {
    staff: Map(),
  },
}) {
  if (!open) {
    return null;
  }

  const { staff } = staffDataStore;
  // I don't like this, but it's the only way to sort the staff members on the FE part.
  const sortedStaff = staffIds
    .map(id => staff.get(id, new Staff({ first_name: '' })))
    .sort((a, b) =>
      a.get('first_name', '').localeCompare(b.get('first_name', ''))
    );

  return (
    <Drawer
      classes={{ footer: 'staff-drawer-footer' }}
      title={t('.title', intl, __filenamespace)}
      open={open}
      onClose={onClose}
      content={
        <div className="available-staff">
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <TextField
                value={searchText}
                classes={{ root: 'search-field' }}
                fullWidth
                placeholder={t('.search', intl, __filenamespace)}
                onChange={e => onSearch(e.target.value)}
              />
              {sortedStaff.map(staffMember => {
                if (!staffMember?.id || staffMember.isArchived()) return null;

                const staffId = staffMember.get('id');
                const isSelected = selectedIds.includes(staffId);

                return (
                  <ProfileCard
                    key={staffId}
                    className={clsx(isSelected && 'available-staff__selected')}
                    client={staffMember}
                    subtext={t('.available', intl, __filenamespace)}
                    style={{ width: '100%' }}
                    onClick={() => onItemClick(staffId)}
                  />
                );
              })}
            </>
          )}
        </div>
      }
      footer={
        selectedIds.size !== 0 ? (
          <Button fullWidth onClick={onSave}>
            {t('.save_btn', intl, __filenamespace)}
          </Button>
        ) : null
      }
    />
  );
}

StaffDrawer.propTypes = {
  intl: PropTypes.object,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  staffIds: PropTypes.instanceOf(OrderedSet),
  selectedIds: PropTypes.instanceOf(OrderedSet),
  searchText: PropTypes.string,
  onClose: PropTypes.func,
  onItemClick: PropTypes.func,
  onSave: PropTypes.func,
  staffDataStore: PropTypes.shape({ staff: PropTypes.instanceOf(Map) }),
};

export default compose(
  injectIntl,
  altContainer({ stores: { staffDataStore: StaffDataStore } })
)(StaffDrawer);
