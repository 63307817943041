import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import CreditPassCredits from './CreditPassCredits.jsx';
import ExpirationMessage from './ExpirationMessage.jsx';

function CreditPassCard({
  clients,
  creditPasses,
  inner,
  events,
  eventTypes,
  intl,
  orderItem = {},
}) {
  const creditPass = creditPasses.get(
    orderItem.getIn(['orderable', 'credit_pass_id'])
  );
  const clientIds = orderItem.getIn(['orderable', 'client_ids']);

  return inner ? (
    <>
      {creditPass && (
        <Grid container item xs={12}>
          <Typography className="order-item__name" align="left" variant="h4">
            {creditPass.get('name')}
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.client', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          {clientIds.map(clientId => (
            <Typography variant="subtitle2" align="right">
              {clients?.get(clientId)?.name()}
            </Typography>
          ))}
        </Grid>
      </Grid>

      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.credits_for', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          {creditPass ? (
            <CreditPassCredits
              creditPass={creditPass}
              events={events}
              eventTypes={eventTypes}
            />
          ) : (
            <Typography className="order-item__title" variant="subtitle2">
              {t('.na', intl, __filenamespace)}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.expires', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          {creditPass ? (
            <Typography variant="subtitle2" align="right">
              <ExpirationMessage creditPass={creditPass} />
            </Typography>
          ) : (
            <Typography variant="subtitle2" align="right">
              {t('.na', intl, __filenamespace)}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} justify="space-between">
        <Grid item>
          <Typography className="order-item__title" variant="subtitle2">
            {t('.price', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" align="right">
            <FormattedCurrency
              value={orderItem.getIn(['orderable', 'price']) || 0}
              fromCents
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid item>
        <Typography className="order-item__title" variant="subtitle2">
          {t('.qty', intl, __filenamespace, {
            qty: orderItem.get('display_quantity'),
          })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" align="right">
          <FormattedCurrency value={orderItem.get('total') || 0} fromCents />
        </Typography>
      </Grid>
    </>
  );
}

CreditPassCard.propTypes = {
  intl: PropTypes.object.isRequired,
  orderItem: PropTypes.object,
};

export default CreditPassCard;
