import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';
import {
  Checkbox,
  Grid,
  MultipleSelect,
  TextField,
} from '@upperhand/playmaker';

import DateRangeDropdown from 'components/DateRangeDropdown';
import ExportMenu from 'reporting/inventory/components/ExportMenu.jsx';
import InventoryReportActions from 'reporting/inventory/actions/InventoryReportActions.js';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import FilteredSelection from 'shared/records/FilteredSelection.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import { RetailVendorsDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

const getRecordLabelAndValue = recordList =>
  recordList
    .map(r => {
      // Our record types are inconsistent in how "name" is accessed.
      const recordName = typeof r.name === 'function' ? r.name() : r.name;

      return {
        label: recordName || '',
        value: r.id,
      };
    })
    .toArray();

function ContentFilters({
  intl,
  filters,
  exportColumns,
  retailCategoryListingStore = {},
  retailVendorsDataStore = {},
}) {
  const { retailCategories } = retailCategoryListingStore;
  const { records: vendors } = retailVendorsDataStore;

  return (
    <>
      <Grid container item spacing={1}>
        <Grid item xs={2}>
          <DateRangeDropdown
            label={false}
            onChange={value =>
              InventoryReportActions.updateFilter('dateRange', value)
            }
            value={filters.getIn(['dateRange', 'value'])}
          />
        </Grid>
        <Grid item xs={3}>
          <MultipleSelect
            classes={{
              formControl: 'inventory__filter-element',
            }}
            items={getRecordLabelAndValue(retailCategories)}
            name={t('.retail_category', intl, __filenamespace)}
            fullWidth
            rounded
            outline={false}
            onChange={value =>
              InventoryReportActions.updateFilter('retailCategoryIds', value)
            }
            selectAll
            selectedItems={filters.get('retailCategoryIds').toJS()}
          />
        </Grid>
        <Grid item xs={3}>
          <MultipleSelect
            classes={{
              formControl: 'inventory__filter-element',
            }}
            items={getRecordLabelAndValue(vendors)}
            name={t('.retail_vendor', intl, __filenamespace)}
            fullWidth
            rounded
            outline={false}
            onChange={value =>
              InventoryReportActions.updateFilter('retailVendorIds', value)
            }
            selectAll
            selectedItems={filters.get('retailVendorIds').toJS()}
          />
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={2} />
          <Grid item xs={5}>
            <Checkbox
              label={t('.out_of_stock', intl, __filenamespace)}
              checked={filters.get('outOfStock')}
              onChange={(_, value) =>
                InventoryReportActions.updateFilter('outOfStock', value)
              }
            />
          </Grid>
          <Grid item xs={5}>
            <Checkbox
              label={t('.low_stock', intl, __filenamespace)}
              checked={filters.get('lowStock')}
              onChange={(_, value) =>
                InventoryReportActions.updateFilter('lowStock', value)
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <ExportMenu
            intl={intl}
            filteredSelection={exportColumns}
            label={t('.btn_export', intl, __filenamespace)}
            onChange={InventoryReportActions.updateExportColumns}
            onExport={InventoryReportActions.export}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <TextField
          classes={{
            root: 'inventory__search-field',
          }}
          fullWidth
          icon="search"
          onChange={event =>
            InventoryReportActions.updateFilter('search', event.target.value)
          }
          placeholder={t('.search', intl, __filenamespace)}
          rounded
          value={filters.get('search')}
        />
      </Grid>
    </>
  );
}

ContentFilters.propTypes = {
  intl: PropTypes.object.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  exportColumns: PropTypes.instanceOf(FilteredSelection).isRequired,
  retailCategoryListingStore: PropTypes.object,
  retailVendorsDataStore: PropTypes.object,
};

export default altContainer({
  stores: {
    retailCategoryListingStore: RetailCategoryListingStore,
    retailVendorsDataStore: RetailVendorsDataStore,
  },
})(injectIntl(ContentFilters));
