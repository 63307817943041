import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { messageId, t } from 'shared/utils/LocaleUtils';
import PaymentActions from '../../Actions';
import { CardUsageType } from '../../types';

function PaymentMethodUsageField({
  cardUsage,
  hasAccess,
  intl,
  isAch = false,
}) {
  return (
    <>
      <div className="card-usage-field" data-tip data-for="card-usage-field">
        <FormGroup>
          <FormControlLabel
            className={clsx(
              !hasAccess && 'card-usage-field__checkbox_disabled'
            )}
            disabled={!hasAccess}
            control={<Checkbox />}
            label={t(isAch ? '.save_ach' : '.save_card', intl, __filenamespace)}
            checked={cardUsage === CardUsageType.SAVE}
            onChange={PaymentActions.updateCardUsage}
          />
        </FormGroup>
      </div>
      <ReactTooltip
        id="card-usage-field"
        className="uh-tooltip"
        place="bottom"
        disable={hasAccess}
        overridePosition={(_position, _e, target) => {
          const boundingRect = target.getBoundingClientRect();

          return {
            left: boundingRect.left - boundingRect.width / 2,
            top: boundingRect.top + boundingRect.height,
          };
        }}
      >
        <FormattedMessage id={messageId('.tooltip', __filenamespace)} />
      </ReactTooltip>
    </>
  );
}

PaymentMethodUsageField.propTypes = {
  isAch: PropTypes.bool,
  cardUsage: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PaymentMethodUsageField);
