import * as React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@upperhand/playmaker';

const ITEMS = [
  {
    value: 'America/New_York',
    label: 'EST/EDT',
  },
  {
    value: 'America/Chicago',
    label: 'CST/CDT',
  },
  {
    value: 'America/Denver',
    label: 'MST/MDT',
  },
  {
    value: 'America/Phoenix',
    label: 'MST/MST',
  },
  {
    value: 'America/Los_Angeles',
    label: 'PST/PDT',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'HAST/HADT',
  },
  {
    value: 'America/Juneau',
    label: 'AKST/AKDT',
  },
  {
    value: 'Pacific/Guam',
    label: 'ChST',
  },
  {
    value: 'America/Halifax',
    label: 'AST',
  },
  {
    value: 'Pacific/Apia',
    label: 'SST',
  },
];

function TimeZoneSelector({
  classes = {},
  disabled = false,
  errorText = '',
  name = 'time-zone',
  label,
  onChange,
  placeholder,
  value,
}) {
  return (
    <Dropdown
      classes={classes}
      disabled={disabled}
      fullWidth
      label={label}
      placeholder={placeholder}
      name={name}
      items={ITEMS}
      onChange={e => onChange(e, e.target.value, name)}
      value={value}
      errorText={errorText}
    />
  );
}

TimeZoneSelector.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TimeZoneSelector;
