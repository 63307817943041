import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import InventoryDialogActions from 'retail/actions/InventoryDialogActions.js';
import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

function TabMenu({
  intl = {},
  retailEditingStore = {},
  isCreatingProduct = false,
}) {
  const { product = {} } = retailEditingStore;

  return (
    <ButtonMenu
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      targetOrigin={{ horizontal: 'left', vertical: 'top' }}
      label={t('.button_menu.inventory', intl, __filenamespace)}
      labelPosition="after"
      labelStyle={{ fontSize: 15 }}
      icon={<MoreVertIcon sx={{ color: uhColors.grey }} />}
      disabled={isCreatingProduct && !product.id}
      color="dark"
    >
      <MenuItem
        onClick={() => {
          InventoryDialogActions.openDialog({ product });
        }}
      >
        {t('.menu.add', intl, __filenamespace)}
      </MenuItem>
    </ButtonMenu>
  );
}

TabMenu.propTypes = {
  intl: PropTypes.object,
  retailEditingStore: PropTypes.object,
  isCreatingProduct: PropTypes.bool,
};

export default injectIntl(TabMenu);
