import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { OrderedSet, Map } from 'immutable';
import {
  Button,
  Spinner,
  Dropdown,
  TextField,
  Grid,
} from '@upperhand/playmaker';

import EventClass from 'shared/records/EventClass';

import ClassCard from 'containers/classes/classesList/components/ClassCard.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import ClassesListActions from 'containers/classes/classesList/Actions';

import { ClassesDataStore } from 'dataStores';

function Classes({
  intl,
  selectedClass = null,
  searchClassText = '',
  loading = false,
  hasMoreClasses = false,
  classesIds = OrderedSet(),
  statusFilter = 'all',
  onItemClick = () => null,
  classesDataStore = { classes: Map() },
}) {
  const { classes } = classesDataStore;

  const handleLoadMore = () => {
    ClassesListActions.listClasses();
  };

  return (
    <div style={{ position: 'relative', height: '100%', minHeight: 400 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            rounded
            placeholder="Search"
            outline={false}
            value={searchClassText}
            onChange={(_, value) => ClassesListActions.searchClasses(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            id="resource"
            name="resource"
            classes={{ selectedItem: 'elipsis' }}
            rounded
            fullWidth
            outline={false}
            items={[
              {
                label: t('.status_all', intl, __filenamespace),
                value: 'all',
              },
              {
                label: t('.status_active', intl, __filenamespace),
                value: 'active',
              },
              {
                label: t('.status_completed', intl, __filenamespace),
                value: 'completed',
              },
              {
                label: t('.status_cancelled', intl, __filenamespace),
                value: 'cancelled',
              },
              {
                label: t('.status_draft', intl, __filenamespace),
                value: 'draft',
              },
              {
                label: t('.status_deleted', intl, __filenamespace),
                value: 'deleted',
              },
            ]}
            value={statusFilter}
            onChange={e =>
              ClassesListActions.statusFilterChange(e.target.value)
            }
          />
        </Grid>
      </Grid>
      <div className="classes-wrapper">
        <div className="classes-inner-wrapper">
          {classesIds.map(id => {
            const classObj = classes.get(id);

            if (!classObj) return null;

            return (
              <ClassCard
                key={classObj.id}
                isClassSelected={selectedClass?.id === classObj.id}
                classItem={classObj}
                onClick={() => onItemClick(classObj)}
              />
            );
          })}
          {loading && (
            <Grid container alignItems="center" justify="center">
              <Spinner />
            </Grid>
          )}
          {hasMoreClasses && !loading && (
            <Grid container alignItems="center" justify="center">
              <Button
                rounded
                size="1x"
                type="tertiary"
                onClick={handleLoadMore}
              >
                {t('.view_more', intl, __filenamespace)}
              </Button>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

Classes.propTypes = {
  intl: PropTypes.object.isRequired,
  searchClassText: PropTypes.string,
  selectedClass: PropTypes.instanceOf(EventClass),
  loading: PropTypes.bool,
  hasMoreClasses: PropTypes.bool,
  classesIds: PropTypes.instanceOf(OrderedSet),
  statusFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onItemClick: PropTypes.func,
  classesDataStore: PropTypes.shape({ classes: PropTypes.instanceOf(Map) }),
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      classesDataStore: ClassesDataStore,
    },
  })
)(Classes);
