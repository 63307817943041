export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'Play Hub',
  siteURL: 'https://jumpstartsports.com',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/jumpstartsports_logo.webp',
    width: 325,
  },
  login: {
    desktopSignInColor: '#1E3664',
    mobileSignInColor: '#1E3664',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#1E3664',
    accent1Color: '#ab233b',

    primary: {
      main: '#1E3664',
    },
    secondary: {
      main: '#ab233b',
    },
  },
  sideNav: {
    selectedColor: '#1E3664',
  },
  icons: {
    cart: '#1E3664',
  },
  statusBar: {
    backgroundColor: '#1E3664',
  },
};
