import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { uhColors } from 'shared/styles/uhStyles.jsx';

function LinkIcon({ color = uhColors.pmIconGray }) {
  return (
    <SvgIcon viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        d="M17.073 10.985L14.78 8.69a3.06 3.06 0 0 0-2.25-.926c-.897 0-1.662.324-2.294.97l-.97-.97a3.1 3.1 0 0 0 .971-2.305c0-.882-.305-1.63-.915-2.24L7.048.938A3.02 3.02 0 0 0 4.798 0c-.882 0-1.63.305-2.24.916l-1.62 1.6C.312 3.136 0 3.882 0 4.765a3.06 3.06 0 0 0 .926 2.25L3.22 9.31a3.06 3.06 0 0 0 2.25.927c.897 0 1.662-.324 2.294-.97l.97.97a3.1 3.1 0 0 0-.971 2.305c0 .882.305 1.628.915 2.24l2.272 2.283a3.02 3.02 0 0 0 2.25.938c.883 0 1.628-.305 2.24-.916l1.62-1.6A3.01 3.01 0 0 0 18 13.235c0-.882-.31-1.632-.927-2.25zm-9.31-4.72l-.204-.2-.237-.237c-.044-.04-.114-.096-.2-.166a.89.89 0 0 0-.281-.143c-.092-.026-.193-.04-.303-.04a1.02 1.02 0 0 0-.75.309 1.02 1.02 0 0 0-.309.75c0 .1.013.21.04.303s.074.186.143.28l.165.2.237.237.2.204c-.22.228-.485.342-.794.342-.3 0-.552-.1-.75-.298L2.426 5.515a1.02 1.02 0 0 1-.309-.75c0-.287.103-.533.31-.74l1.62-1.6c.213-.2.463-.298.75-.298a1.02 1.02 0 0 1 .75.309L7.82 4.7a1.02 1.02 0 0 1 .309.75 1.07 1.07 0 0 1-.364.805zm6.188 9.32l1.62-1.6c.206-.205.31-.452.31-.738a1.02 1.02 0 0 0-.309-.75l-2.294-2.294a1.02 1.02 0 0 0-.75-.309 1.05 1.05 0 0 0-.794.353l.2.204.237.237a2.64 2.64 0 0 1 .165.21c.07.095.118.19.144.28a1.13 1.13 0 0 1 .039.303 1.02 1.02 0 0 1-.309.75 1.02 1.02 0 0 1-.75.309 1.12 1.12 0 0 1-.303-.039.88.88 0 0 1-.281-.143l-.21-.166-.237-.237-.023-.023-.18-.186c-.243.228-.364.496-.364.805a1.02 1.02 0 0 0 .309.75l2.272 2.283c.2.2.448.298.75.298.294 0 .544-.096.75-.287z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default LinkIcon;
