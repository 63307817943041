import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { Chip } from '@upperhand/playmaker';

import { snakeToTitleCase } from 'event_mgmt/shared/utils/FormattingUtils.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import ContactsClientsActions from 'containers/contacts/contactsClients/Actions';

function ActiveFilters({ appliedFilters = new ContactFilter() }) {
  const handleRemoveFilter = (name, value) =>
    ContactsClientsActions.removeAppliedFilter({ name, value });

  const getDateLabel = field => {
    const operator = appliedFilters.getIn([field, 'operator']);

    return operator !== 'between'
      ? moment(appliedFilters.getIn([field, 'start_date'])).format('MM/DD/YYYY')
      : `${moment(appliedFilters.getIn([field, 'start_date'])).format(
          'MM/DD/YYYY'
        )} - ${moment(appliedFilters.getIn([field, 'end_date'])).format(
          'MM/DD/YYYY'
        )}`;
  };

  const appliedEvents = appliedFilters.getIn(
    ['purchased_products', 'events'],
    []
  );
  const appliedMemberships = appliedFilters.getIn(
    ['purchased_products', 'memberships'],
    []
  );
  const appliedCreditPasses = appliedFilters.getIn(
    ['purchased_products', 'credit_passes'],
    []
  );
  const appliedEventTypes = appliedFilters.getIn(
    ['purchased_products', 'event_types'],
    []
  );
  const appliedTeamTypes = appliedFilters.getIn(
    ['purchased_products', 'team_types'],
    []
  );
  const appliedCreditPassTypes = appliedFilters.getIn(
    ['purchased_products', 'purchased_credit_pass'],
    []
  );
  const appliedMembershipTypes = appliedFilters.getIn(
    ['purchased_products', 'purchased_membership'],
    []
  );

  return (
    <>
      {appliedFilters.name && (
        <Chip
          label={snakeToTitleCase(appliedFilters.name)}
          onDelete={() => handleRemoveFilter('name')}
          classes={{ root: 'contacts__active-filters-name-chip' }}
        />
      )}

      {appliedFilters.min_age && appliedFilters.max_age && (
        <Chip
          label={`${appliedFilters.min_age}-${appliedFilters.max_age}`}
          onDelete={() => handleRemoveFilter('age', null)}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedFilters.gender && (
        <Chip
          label={snakeToTitleCase(appliedFilters.gender)}
          onDelete={() => handleRemoveFilter('gender', null)}
          style={{ margin: 4 }}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedFilters.account_statuses.map(status => (
        <Chip
          label={snakeToTitleCase(status)}
          onDelete={() => handleRemoveFilter('account_statuses', status)}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedFilters.account_type && (
        <Chip
          label={snakeToTitleCase(appliedFilters.account_type)}
          onDelete={() => handleRemoveFilter('account_type')}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedFilters.subscription_statuses.map(status => (
        <Chip
          key={status}
          label={snakeToTitleCase(status)}
          onDelete={() => handleRemoveFilter('subscription_statuses', status)}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedFilters.get('date_of_birth').size > 0 && (
        <Chip
          label={getDateLabel('date_of_birth')}
          onDelete={() => handleRemoveFilter('date_of_birth', Map())}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedFilters.month_of_birth.map(month => (
        <Chip
          label={snakeToTitleCase(
            moment()
              .month(month - 1)
              .format('MMMM')
          )}
          onDelete={() => handleRemoveFilter('month_of_birth', month)}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedFilters.get('first_purchase_date').size > 0 && (
        <Chip
          label={getDateLabel('first_purchase_date')}
          onDelete={() => handleRemoveFilter('first_purchase_date', Map())}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedFilters.get('last_purchase_date').size > 0 && (
        <Chip
          label={getDateLabel('last_purchase_date')}
          onDelete={() => handleRemoveFilter('last_purchase_date', Map())}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedEvents?.map((event, index) => (
        <Chip
          key={event.id}
          label={event.name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.events',
              appliedEvents.toSpliced(index, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedMemberships?.map((membership, index) => (
        <Chip
          key={membership.id}
          label={membership.name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.memberships',
              appliedMemberships.toSpliced(index, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedCreditPasses?.map((creditPass, index) => (
        <Chip
          key={creditPass.id}
          label={creditPass.name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.credit_passes',
              appliedCreditPasses.toSpliced(index, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedEventTypes?.map((eventType, index) => (
        <Chip
          key={eventType.id}
          label={eventType.name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.event_types',
              appliedEventTypes.toSpliced(index, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedTeamTypes?.map((teamType, index) => (
        <Chip
          key={teamType.id}
          label={teamType.name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.team_types',
              appliedTeamTypes.toSpliced(index, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      ))}

      {appliedCreditPassTypes.length > 0 && (
        <Chip
          label={appliedCreditPassTypes[0].name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.purchased_credit_pass',
              appliedCreditPassTypes.toSpliced(0, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedMembershipTypes.length > 0 && (
        <Chip
          label={appliedMembershipTypes[0].name}
          onDelete={() =>
            handleRemoveFilter(
              'purchased_products.purchased_membership',
              appliedMembershipTypes.toSpliced(0, 1)
            )
          }
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}

      {appliedFilters.zip_code && (
        <Chip
          label={appliedFilters.zip_code}
          onDelete={() => handleRemoveFilter('zip_code')}
          classes={{ root: 'contacts__active-filters-chip' }}
        />
      )}
    </>
  );
}

ActiveFilters.propTypes = {
  appliedFilters: PropTypes.instanceOf(ContactFilter),
};

export default memo(ActiveFilters);
