import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { List } from 'immutable';
import { Drawer, Button } from '@upperhand/playmaker';

import MembershipTierConfig from 'memberships/components/MembershipTiers/MembershipTierConfig.jsx';
import DiscountsSelector from 'memberships/components/DiscountsSelector/DiscountsSelector.jsx';
import EventCreditSelector from 'memberships/components/EventCreditSelector.jsx';
import ExclusiveEventTypeSelector from 'memberships/components/ExclusiveEventTypeSelector.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import MembershipTiersActions from 'memberships/actions/MembershipTiersActions';
import MembershipTiersStore from 'memberships/stores/MembershipTiersStore';

function MembershipTiersCreationDrawer({
  events = List(),
  specificEvents = List(),
  eventTypes = List(),
  creditPasses = List(),
  retailCategories = List(),
  membershipTiersStore = {},
}) {
  const intl = useIntl();
  const {
    record,
    disabledAddingNewCredit,
    existingAllEventsCredits,
    unselectedEventTypes,
    selectedEventIds,
    selectedEventTypeIds,
    exclusiveExpanded,
    drawerOpened,
    joinFeeChecked,
  } = membershipTiersStore;
  const [step, setStep] = useState(1);

  if (!drawerOpened) return null;

  return (
    <Drawer
      open={drawerOpened}
      onClose={MembershipTiersActions.toggleCreationDrawer}
      classes={{
        root: 'tier-creation-drawer',
        footer: 'tier-creation-drawer__actions',
        header: 'tier-creation-drawer__header',
        content: 'tier-creation-drawer__content',
      }}
      title={t('.title', intl, __filenamespace)}
      content={
        <div className="membership-tiers">
          {step === 1 && (
            <MembershipTierConfig
              tier={record}
              joinFeeChecked={joinFeeChecked}
              onJoinFeeToggle={MembershipTiersActions.toggleJoinFee}
              onFieldChange={MembershipTiersActions.fieldChanged}
            />
          )}
          {step === 2 && (
            <div className="membership-tiers__discounts-container">
              <DiscountsSelector
                actions={MembershipTiersActions}
                membership={record}
                eventTypes={eventTypes}
                creditPasses={creditPasses}
                retailCategories={retailCategories}
              />
              {currentCustomer().membership_credits_enabled && (
                <EventCreditSelector
                  actions={MembershipTiersActions}
                  disabledAddingNewCredit={disabledAddingNewCredit}
                  specificEvents={specificEvents}
                  events={events}
                  selectedEventIds={selectedEventIds}
                  selectedEventTypeIds={selectedEventTypeIds}
                  unselectedEventTypes={unselectedEventTypes}
                  existingAllEventsCredits={existingAllEventsCredits}
                  eventTypes={eventTypes}
                  membership={record}
                  expanded={!record.membership_event_credits.isEmpty()}
                />
              )}
              <ExclusiveEventTypeSelector
                actions={MembershipTiersActions}
                eventTypes={eventTypes}
                membership={record}
                expanded={exclusiveExpanded}
              />
            </div>
          )}
        </div>
      }
      footer={
        <>
          {step === 1 && (
            <>
              <Button
                type="secondary"
                onClick={MembershipTiersActions.toggleCreationDrawer}
              >
                {t('.cancel', intl, __filenamespace)}
              </Button>
              <Button onClick={() => setStep(2)}>
                {t('.next', intl, __filenamespace)}
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <Button type="secondary" onClick={() => setStep(1)}>
                {t('.back', intl, __filenamespace)}
              </Button>
              <Button onClick={MembershipTiersActions.saveTier}>
                {t(record?.id ? '.update' : '.save', intl, __filenamespace)}
              </Button>
            </>
          )}
        </>
      }
    />
  );
}

MembershipTiersCreationDrawer.propTypes = {
  events: PropTypes.instanceOf(List),
  specificEvents: PropTypes.instanceOf(List),
  eventTypes: PropTypes.instanceOf(List),
  creditPasses: PropTypes.instanceOf(List),
  retailCategories: PropTypes.instanceOf(List),
  membershipTiersStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      membershipTiersStore: MembershipTiersStore,
    },
  })
)(MembershipTiersCreationDrawer);
