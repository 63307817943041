import { Map, List, Set, Record, fromJS } from 'immutable';

import Address from 'shared/records/Address.jsx';
import BankAccount from 'shared/records/BankAccount.jsx';
import LegalEntity from 'shared/records/LegalEntity.jsx';
import { Image } from 'records';
import { isPlainObjectAt, merge } from 'shared/utils/ObjectUtils.jsx';
import { convertDateToClientValue } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

class Customer extends Record({
  id: null,
  uuid: null,
  name: null,
  active_payment_gateway: '',
  announcement_text: '',
  primary_payment_gateway: '',
  address: new Address(),
  bank_account_validated: '',
  bank_accounts: List(),
  client_payment_methods: Set(),
  corporate_account: true,
  default_currency: null,
  email: null,
  external_url: null,
  features: Map(),
  first_name: null,
  last_name: null,
  legal_entity: new LegalEntity(),
  logo: new Image({ public_file: true }),
  marketing_admin_id: null,
  paysafe_account_status: '',
  paysafe_onboarding_step: '',
  policy_and_terms: '',
  membership_agreement: '',
  policy_updated_at: null,
  preferences: Map(),
  random: null,
  raw_policy_and_terms: '',
  raw_membership_agreement: '',
  status: '',
  tenant_preferences: Map(),
  time_zone: null,
  transfer_schedule: null,
  created_at: '',
  blackout_dates: [],
  business_type: null,
  sports_offered: List(),
  date_specific_daytimes: Map(),
  mode_report_labels: [],
  agreement_updated_at: null,
}) {
  constructor(o = {}, options = {}) {
    let obj = o;
    if (isPlainObjectAt(obj, 'logo')) {
      obj = {
        ...obj,
        logo: new Image({ ...obj.logo, public_file: true }),
      };
    }

    if (isPlainObjectAt(obj, 'address')) {
      if (!obj.id && !obj.address.line_1) {
        delete obj.address;
      } else {
        obj = {
          ...obj,
          address: new Address(obj.address),
        };
      }
    }

    if (isPlainObjectAt(obj, 'bank_accounts')) {
      obj = {
        ...obj,
        bank_accounts: List(obj.bank_accounts).map(e => new BankAccount(e)),
      };
    }

    if (isPlainObjectAt(obj, 'legal_entity')) {
      obj = {
        ...obj,
        legal_entity: new LegalEntity(obj.legal_entity),
      };
    }

    if (isPlainObjectAt(obj, 'preferences')) {
      obj = {
        ...obj,
        preferences: Map(obj.preferences),
      };
    }

    if (isPlainObjectAt(obj, 'features')) {
      obj = {
        ...obj,
        features: Map(obj.features),
      };
    }

    // Has to be before RichTextEditor.createValueFromString because that function modifies the
    // string passed in.
    obj = merge(obj, {
      raw_policy_and_terms: obj.policy_and_terms || '',
      raw_membership_agreement: obj.membership_agreement || '',
    });

    obj = merge(obj, {
      date_specific_daytimes: fromJS(obj.date_specific_daytimes),
    });

    obj = merge(obj, {
      policy_updated_at: convertDateToClientValue(obj.policy_updated_at),
    });
    super(obj, options);
  }

  alternativeImage(name) {
    if (!this.logo) {
      return null;
    }
    return this.logo.getAlternative(name);
  }

  fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  emailPreferences() {
    return Map(this.preferences.get('client_emails') || {});
  }

  async toServer() {
    const payload = this.toJS();

    if (payload.logo && !payload.logo.file) {
      delete payload.logo;
    } else {
      payload.logo = await this.logo.toServer();
    }
    function updatePayloadWithHTMLText(field, htmlText) {
      const divEl = document.createElement('div');
      divEl.innerHTML = htmlText;

      const text = divEl.textContent || divEl.innerText || '';
      payload[field] = text.trim().length ? htmlText : '';
    }

    const policyAndTerms = this.get('policy_and_terms')?.toString('html');
    updatePayloadWithHTMLText('policy_and_terms', policyAndTerms);

    const membershipAgreement = this.get('membership_agreement')?.toString(
      'html'
    );
    updatePayloadWithHTMLText('membership_agreement', membershipAgreement);
    if (this.get('address') && this.get('address').isBlank()) {
      delete payload.address;
    }

    if (this.get('legal_entity')) {
      const legalEntity = this.get('legal_entity');
      payload.legal_entity = legalEntity.toServer();
      if (legalEntity.get('address') && legalEntity.get('address').isBlank()) {
        delete payload.legal_entity.address;
      }
      if (
        legalEntity.get('personal_address') &&
        legalEntity.get('personal_address').isBlank()
      ) {
        delete payload.legal_entity.personal_address;
      }
    }

    // bank accounts are updated separately
    delete payload.bank_accounts;
    // these are default values (coming from the server) that can be used on the legal_entity
    delete payload.first_name;
    delete payload.last_name;
    delete payload.features;
    delete payload.status;
    delete payload.marketing_admin_id;
    return payload;
  }
}

export default Customer;
