import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@upperhand/playmaker';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'components/LocationEditor/Modal.jsx';

import AddressTypeActions from 'shared/actions/AddressTypeActions.jsx';
import AddressTypeStore from 'shared/stores/AddressTypeStore.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import Location from 'shared/records/Location.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

function LocationEditor({
  disableEdit = false,
  errorText = '',
  initialLocation = new Location(),
  label = null,
  placeholder = null,
  selectedLocationId = null,
  addressTypes,
  intl,
  locationStore,
  onChange,
  parentObject,
}) {
  const id = useRef(uuidv4());
  const [removedLocation, setRemovedLocation] = useState(null);

  useEffect(() => {
    AddressTypeActions.list.defer();
    LocationActions.list.defer();
  }, []);

  useEffect(() => {
    if (
      !removedLocation &&
      initialLocation &&
      locationStore.isLoading === false &&
      !locationStore.findById(initialLocation.id)
    ) {
      setRemovedLocation(initialLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedLocation, initialLocation, locationStore.isLoading]);

  const handleEditClick = () => {
    LocationActions.stageLocation(selectedLocationId);
    LocationActions.inlineEditorToggled(id.current);
  };

  const handleOnChange = event => {
    if (event.target.value === 0) {
      LocationActions.clearLocation();
      LocationActions.inlineEditorToggled(id.current);
    } else {
      onChange(event.target.value);
    }
  };

  const items = locationStore.allLocations
    .map(location => ({
      value: location.id,
      label: location.name,
    }))
    .toArray();

  const locationRemoved =
    removedLocation && removedLocation.id === selectedLocationId;

  return (
    <div className="location-editor">
      <Dropdown
        fullWidth
        value={selectedLocationId}
        label={label || t('.label', intl, __filenamespace)}
        placeholder={placeholder || t('.placeholder', intl, __filenamespace)}
        errorText={errorText}
        onChange={handleOnChange}
        items={[
          {
            value: 0,
            label: t('.new_item', intl, __filenamespace),
          },
          ...items,
          ...(removedLocation && removedLocation.id
            ? [
                {
                  value: removedLocation.id,
                  label: removedLocation.name,
                },
              ]
            : []),
        ]}
      />
      <Modal
        intl={intl}
        isOpen={locationStore.inlineEditorOpen === id.current}
        onSuccess={() => {
          LocationActions.createOrUpdate({ parentObject });
        }}
        onAbort={() => {
          LocationActions.inlineEditorToggled();
        }}
        addressTypes={addressTypes.addressTypes}
        errors={locationStore.errors}
        location={locationStore.location}
      />
      {!disableEdit && selectedLocationId && (
        <div className="location-editor__edit-button">
          {locationRemoved ? (
            <FormattedMessage
              id={messageId('.edit_message', __filenamespace)}
            />
          ) : (
            <Button variant="outlined" onClick={handleEditClick}>
              {t('.edit_button', intl, __filenamespace)}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

LocationEditor.propTypes = {
  addressTypes: PropTypes.object.isRequired,
  disableEdit: PropTypes.bool,
  errorText: PropTypes.string,
  initialLocation: PropTypes.instanceOf(Location),
  intl: PropTypes.object.isRequired,
  label: PropTypes.string,
  locationStore: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  parentObject: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selectedLocationId: PropTypes.number,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      locationStore: LocationStore,
      addressTypes: AddressTypeStore,
    },
  })
)(LocationEditor);
