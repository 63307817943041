import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@mui/material/Button';

import { messageId, t } from 'shared/utils/LocaleUtils.js';

import ModalRegistrationActions from 'shared/components/signUp/Actions';

const styles = {
  loginButton: {
    margin: '1rem 0',
    height: 50, // MUI hardcodes a PX unit for height. See https://github.com/callemall/material-ui/issues/4473
    fontSize: 16,
  },
};

function SignInPrompt({
  intl,
  loginMessageId = '.login_to_purchase_message',
  redirectHash = '',
  redirectTo = '',
  styles: propStyles = {},
}) {
  return (
    <div style={{ textAlign: 'center', ...propStyles }}>
      <div style={{ fontSize: 13.7 }}>
        <FormattedMessage id={messageId(loginMessageId, __filenamespace)} />
      </div>
      <Button
        fullWidth
        onClick={() =>
          ModalRegistrationActions.toggleSignInModal({
            redirectTo: redirectTo || window.location.pathname,
            redirectHash,
          })
        }
        variant="contained"
        style={styles.loginButton}
      >
        {t('.login_button_label', intl, __filenamespace)}
      </Button>
      <div>
        <FormattedMessage
          id={messageId('.or_create_account_message', __filenamespace)}
          values={{
            link: (
              <a
                aria-label="Create an account"
                href="/"
                onClick={e => {
                  e.preventDefault();
                  ModalRegistrationActions.toggleSignUpModal({
                    redirectTo,
                    redirectHash,
                  });
                }}
              >
                <FormattedMessage
                  id={messageId('.create_an_account', __filenamespace)}
                />
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
}

SignInPrompt.propTypes = {
  intl: PropTypes.object.isRequired,
  loginMessageId: PropTypes.string,
  redirectHash: PropTypes.string,
  redirectTo: PropTypes.string,
  styles: PropTypes.object,
};

export default injectIntl(SignInPrompt);
