import * as React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class Staff extends React.PureComponent {
  render() {
    const { className = '', style = {}, color = '#9A9EAD' } = this.props;

    return (
      <SvgIcon className={className} style={style} viewBox="0 0 44 48">
        <g
          id="Staff"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Staff-Mobile---Group-Dropdown"
            transform="translate(-260.000000, -271.000000)"
            fillRule="nonzero"
            fill={color}
          >
            <g id="Staff-Nav" transform="translate(0.000000, 243.000000)">
              <path
                d="M298.4,32.8 L288.368,32.8 C287.36,30.016 284.72,28 281.6,28 C278.48,28 275.84,30.016 274.832,32.8 L264.8,32.8 C262.16,32.8 260,34.96 260,37.6 L260,71.2 C260,73.84 262.16,76 264.8,76 L298.4,76 C301.04,76 303.2,73.84 303.2,71.2 L303.2,37.6 C303.2,34.96 301.04,32.8 298.4,32.8 Z M281.6,32.8 C282.92,32.8 284,33.88 284,35.2 C284,36.52 282.92,37.6 281.6,37.6 C280.28,37.6 279.2,36.52 279.2,35.2 C279.2,33.88 280.28,32.8 281.6,32.8 Z M281.6,42.4 C285.584,42.4 288.8,45.616 288.8,49.6 C288.8,53.584 285.584,56.8 281.6,56.8 C277.616,56.8 274.4,53.584 274.4,49.6 C274.4,45.616 277.616,42.4 281.6,42.4 Z M296,71.2 L267.2,71.2 L267.2,67.84 C267.2,63.04 276.8,60.4 281.6,60.4 C286.4,60.4 296,63.04 296,67.84 L296,71.2 Z"
                id="Staff"
              />
            </g>
          </g>
        </g>
      </SvgIcon>
    );
  }
}

Staff.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
};

export default Staff;
