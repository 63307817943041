import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import { messageId, t } from 'shared/utils/LocaleUtils';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils.js';
import CouponUsageLimit from './CouponUsageLimit.jsx';

function DesktopCouponCard({ couponId = undefined, coupon = null, intl }) {
  // if no id, we need to render empty div for now... want to make this safe.
  // eventualy if no id and edit mode = true then "Create" mode
  if (!coupon) {
    return <div>{couponId}</div>;
  }
  return (
    <div className="coupons__link">
      <Card
        classes={{
          root: 'coupons__row',
          content: 'coupons__row-content',
        }}
        contentDirection="row"
        onClick={e => {
          redirectTo({
            path: customerScopedRoute(`/coupons/${coupon.get('id')}`),
          });
          e.preventDefault();
        }}
      >
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item coupons__name">
            <Typography variant="body2">{coupon.get('name')}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item coupons__code">
            <Typography variant="body2">{coupon.get('code')}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item">
            <Typography variant="body2">
              {t(`.${coupon.get('coupon_type')}`, intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item">
            <Typography variant="body2">
              <FormattedMessage
                id={messageId('.value', __filenamespace)}
                values={{
                  value: coupon.discount.isPercent() ? (
                    <FormattedNumber
                      value={coupon.get('value') / 100}
                      // eslint-disable-next-line react/style-prop-object
                      style="percent"
                    />
                  ) : (
                    <FormattedCurrency value={coupon.get('value')} fromCents />
                  ),
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item">
            <Typography variant="body2">
              {moment(coupon.get('start_date')).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item">
            <Typography variant="body2">
              {moment(coupon.get('end_date')).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          <Grid item className="coupons__row-item">
            <Typography variant="body2">
              <CouponUsageLimit coupon={coupon} />
            </Typography>
          </Grid>
        </Grid>
        <div className="balances-report__row-item balances-report__row-action">
          <ChevronRightIcon />
        </div>
      </Card>
    </div>
  );
}

DesktopCouponCard.propTypes = {
  couponId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  coupon: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default DesktopCouponCard;
