import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';

function AthleteBasedReport({ intl }) {
  const [isLoading, setLoading] = useState(true);
  const [reportUrl, setReportUrl] = useState(null);

  useEffect(() => {
    const { id } = currentCustomer();

    setLoading(true);
    uhApiClient
      .asyncGet(`customers/${id}/athlete_assessment_report`)
      .then(({ data = { url: null } }) => {
        const { url } = data;

        setReportUrl(url);
        setLoading(false);
      })
      .catch(_e => {
        setReportUrl(null);
        setLoading(false);
      });
  }, []);

  return (
    <SpinWhileLoading
      isLoading={isLoading}
      contentContainerStyle={{ height: '100%' }}
      outerContainerStyle={{ height: '100%' }}
    >
      <div className="athlete-report">
        {reportUrl ? (
          <iframe
            className="athlete-report__iframe-report"
            title={t('.iframe_title', intl, __filenamespace)}
            src={reportUrl}
          />
        ) : (
          <div className="athlete-report__error">
            <Typography align="center">
              {t('.error', intl, __filenamespace)}
            </Typography>
          </div>
        )}
      </div>
    </SpinWhileLoading>
  );
}

export default compose(memo, injectIntl)(AthleteBasedReport);
