import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { messageId } from 'shared/utils/LocaleUtils.js';

import Client from 'shared/records/Client.jsx';

function InvitedStatus({ inviteSentAt }) {
  return (
    <FormattedMessage
      id={messageId('.invited_time', __filenamespace)}
      values={{ time: moment(inviteSentAt).fromNow() }}
    />
  );
}

function UnclaimedStatus() {
  return (
    <FormattedMessage id={messageId('.unclaimed_account', __filenamespace)} />
  );
}

function AccountStatus({ client = new Client() }) {
  if (!client) return null;

  const { invite_sent_at: inviteSentAt } = client;
  const isPendingInvite = client.isInvitePending();
  const isUnclaimedAccount = client.isUnclaimedAccount();

  if (client.isLead()) {
    return <FormattedMessage id={messageId('.lead_msg', __filenamespace)} />;
  }

  if (isUnclaimedAccount) {
    return <UnclaimedStatus />;
  }

  if (isPendingInvite) {
    return <InvitedStatus inviteSentAt={inviteSentAt} />;
  }

  return null;
}

AccountStatus.propTypes = {
  client: PropTypes.instanceOf(Client),
};

export default AccountStatus;
