import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { grey } from '@mui/material/colors';

import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import { hasCustomer } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    zIndex: 500,
    backgroundColor: 'var(--color-bg-gray)',
    height: 'inherit',
    padding: '10px 36px 0',
    fontSize: '16px',
    color: grey[800],
    flexWrap: 'wrap',
    minHeight: '24px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  eventIndexLink: {
    fontWeight: 400,
    fontSize: '15px',
    textTransform: 'none',
    paddingLeft: 'none',
    color: grey[800],
  },
};

function TeamToolbar(props) {
  const { actions: propsActions = [], style = {}, intl = {} } = props;

  const actions = propsActions.map((action, i) =>
    // eslint-disable-next-line react/no-array-index-key
    action ? React.cloneElement(action, { key: `action-${i}` }) : action
  );

  return (
    <Stack direction="row" sx={{ ...styles.root, ...style }}>
      {hasCustomer() && (
        <ReactRouterLink
          id="event-link"
          to={customerScopedRoute('/teams')}
          style={{ textDecoration: 'none' }}
        >
          <Button
            disableRipple
            startIcon={<ChevronLeftIcon sx={{ color: uhColors.activeBlue }} />}
            style={styles.eventIndexLink}
            sx={{
              padding: 0,
              '.MuiButton-startIcon': { marginRight: '4px' },
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            {t('.teams', intl, __filenamespace)}
          </Button>
        </ReactRouterLink>
      )}
      <Box sx={{ display: 'flex' }}>{actions}</Box>
    </Stack>
  );
}

TeamToolbar.propTypes = {
  actions: PropTypes.array,
  style: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(TeamToolbar);
