import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import { DateTimeField, Dropdown, Button, Popover } from '@upperhand/playmaker';
import moment from 'moment-timezone';

import { t } from 'shared/utils/LocaleUtils';
import { FilterPeriod } from 'containers/reports/types';

const FORMAT = 'MM/DD/YYYY';

class DateRangeDropdown extends React.PureComponent {
  state = {
    anchorEl: null,
    customStartDate: moment().startOf('day'),
    customEndDate: moment().endOf('day'),
    customStartDateError: '',
    customEndDateError: '',
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.value || null,
  };

  static getDerivedStateFromProps(props, state) {
    const { value } = state;
    const { value: propValue } = props;

    if (value !== propValue) {
      return {
        value: propValue,
      };
    }
    return null;
  }

  getItems() {
    const { intl } = this.props;

    return [
      {
        value: FilterPeriod.today,
        label: t(`.item_${FilterPeriod.today}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.yesterday,
        label: t(`.item_${FilterPeriod.yesterday}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_week,
        label: t(`.item_${FilterPeriod.this_week}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_week,
        label: t(`.item_${FilterPeriod.last_week}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_month,
        label: t(`.item_${FilterPeriod.this_month}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_month,
        label: t(`.item_${FilterPeriod.last_month}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_quarter,
        label: t(`.item_${FilterPeriod.this_quarter}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_quarter,
        label: t(`.item_${FilterPeriod.last_quarter}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.this_year,
        label: t(`.item_${FilterPeriod.this_year}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.last_year,
        label: t(`.item_${FilterPeriod.last_year}`, intl, __filenamespace),
      },
      {
        value: FilterPeriod.custom,
        label: t(`.item_${FilterPeriod.custom}`, intl, __filenamespace),
      },
    ];
  }

  getPeriodRange(period) {
    const { customStartDate, customEndDate } = this.state;

    switch (period) {
      case FilterPeriod.today:
        return {
          from: moment().startOf('day'),
          to: moment().endOf('day'),
        };

      case FilterPeriod.yesterday:
        return {
          from: moment().startOf('day').subtract(1, 'days'),
          to: moment().endOf('day').subtract(1, 'days'),
        };

      case FilterPeriod.this_week:
        return {
          from: moment().startOf('week'),
          to: moment().endOf('week'),
        };

      case FilterPeriod.last_week:
        return {
          from: moment().startOf('week').subtract(1, 'weeks'),
          to: moment().startOf('week').subtract(1, 'weeks').endOf('week'),
        };

      case FilterPeriod.this_month:
        return {
          from: moment().startOf('month'),
          to: moment().endOf('month'),
        };

      case FilterPeriod.last_month:
        return {
          from: moment().startOf('month').subtract(1, 'months'),
          to: moment().startOf('month').subtract(1, 'months').endOf('month'),
        };

      case FilterPeriod.this_quarter:
        return {
          from: moment().startOf('quarter'),
          to: moment().endOf('quarter'),
        };

      case FilterPeriod.last_quarter:
        return {
          from: moment().startOf('quarter').subtract(1, 'quarters'),
          to: moment()
            .startOf('quarter')
            .subtract(1, 'quarters')
            .endOf('quarter'),
        };

      case FilterPeriod.this_year:
        return {
          from: moment().startOf('year'),
          to: moment().endOf('year'),
        };

      case FilterPeriod.last_year:
        return {
          from: moment().startOf('year').subtract(1, 'years'),
          to: moment().startOf('year').subtract(1, 'years').endOf('year'),
        };

      case FilterPeriod.custom:
        return {
          from: customStartDate.startOf('day'),
          to: customEndDate.endOf('day'),
        };

      default:
        return {
          from: moment().startOf('day'),
          to: moment().endOf('day'),
        };
    }
  }

  handleChange = event => {
    const { anchorEl } = this.state;
    const { onChange } = this.props;
    const period = event.target.value || FilterPeriod.custom;
    if (period === FilterPeriod.custom && !anchorEl) {
      this.setState({
        value: FilterPeriod.custom,
        anchorEl: this.rootEl,
      });
    } else {
      onChange({
        value: period,
        ...this.getPeriodRange(period),
      });

      if (period === FilterPeriod.custom) {
        this.setState({ anchorEl: null });
      }
    }
  };

  handlePopoverClose = () => {
    const { value = null } = this.props;
    this.setState({ value, anchorEl: null });
  };

  handleStartDateChange = value => {
    const { customEndDate } = this.state;
    const { intl } = this.props;

    if (!value) {
      this.setState({
        customStartDate: null,
        customStartDateError: t(
          'records.errors.required',
          intl,
          __filenamespace
        ),
      });
      return;
    }
    if (customEndDate?.isBefore(value)) {
      this.setState({
        customStartDate: value,
        customEndDate: value,
        customStartDateError: '',
      });
    } else {
      this.setState({
        customStartDate: value,
        customStartDateError: '',
      });
    }
    if (!value?.isValid()) {
      this.setState({
        customStartDateError: t(
          'records.errors.invalid',
          intl,
          __filenamespace
        ),
      });
    }
  };

  handleEndDateChange = value => {
    const { customStartDate } = this.state;
    const { intl } = this.props;

    if (!value) {
      this.setState({
        customEndDate: null,
        customEndDateError: t('records.errors.required', intl, __filenamespace),
      });
      return;
    }
    if (customStartDate?.isAfter(value)) {
      this.setState({
        customStartDate: value,
        customEndDate: value,
      });
    } else {
      this.setState({
        customEndDate: value,
        customEndDateError: '',
      });
    }
    if (!value?.isValid()) {
      this.setState({
        customEndDateError: t('records.errors.invalid', intl, __filenamespace),
      });
    }
  };

  rangeValid() {
    const { customStartDate, customEndDate } = this.state;

    if (customStartDate?.isValid() && customEndDate?.isValid()) return true;

    return false;
  }

  renderValue = label => {
    const { value = null } = this.props;
    const { customStartDate, customEndDate } = this.state;

    if (value === FilterPeriod.custom) {
      return `${customStartDate.format(FORMAT)} - ${customEndDate.format(
        FORMAT
      )}`;
    }

    return label;
  };

  render() {
    const { label = '', intl, className = '', disabled = false } = this.props;
    const {
      value,
      anchorEl,
      customStartDate,
      customEndDate,
      customStartDateError,
      customEndDateError,
    } = this.state;

    return (
      <div
        ref={ref => {
          this.rootEl = ref;
        }}
        className={clsx('date-range-dropdown', className)}
      >
        <Dropdown
          disabled={disabled}
          fullWidth
          id="dropdown-period"
          items={this.getItems()}
          name="period"
          onChange={this.handleChange}
          outline={false}
          rounded
          label={
            label === false ? '' : label || t('.label', intl, __filenamespace)
          }
          renderValue={this.renderValue}
          value={value}
        />
        <Popover
          anchor={anchorEl}
          onClose={this.handlePopoverClose}
          width={300}
        >
          <div className="date-range-dropdown__popover">
            <DateTimeField
              autoOk
              label={t('.start_date', intl, __filenamespace)}
              errorText={customStartDateError}
              fullWidth
              onChange={this.handleStartDateChange}
              maxDate={customEndDate}
              value={customStartDate}
              type="date"
              name="stop_by_date"
              placeholder={FORMAT}
              format={FORMAT}
            />
            <div className="date-range-dropdown__form-element">
              <DateTimeField
                autoOk
                label={t('.end_date', intl, __filenamespace)}
                fullWidth
                errorText={customEndDateError}
                onChange={this.handleEndDateChange}
                minDate={customStartDate}
                value={customEndDate}
                type="date"
                name="stop_by_date"
                placeholder={FORMAT}
                format={FORMAT}
              />
            </div>
            <div className="date-range-dropdown__form-element">
              <Button
                type="primary"
                onClick={this.handleChange}
                disabled={!this.rangeValid()}
              >
                {t('actions.apply', intl, __filenamespace)}
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

DateRangeDropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default injectIntl(DateRangeDropdown);
