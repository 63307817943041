import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider as PmThemeProvider } from '@upperhand/playmaker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NativeStatusBar from 'shared/native/components/NativeStatusBar.jsx';

import Routes from 'routes/Routes.jsx';
import UHTheme from '_uh_theme.jsx';
import UhIntl from 'shared/components/UhIntl.jsx';
import * as logger from 'ErrorLogger';
import ErrorBoundary from '@honeybadger-io/react';
import * as Trackers from 'Trackers.js';

import 'styles/theme.scss';
import 'styles/shared.scss';

/* Need to generate unique class names for mui v5 because we are using playmaker components that using mui v4 and some styles has a conflicts */
ClassNameGenerator.configure(componentName => `UH-${componentName}`);

const honeybadger = logger.init();
Trackers.init();
class AppRoot extends React.PureComponent {
  componentDidMount() {
    document.title = UHTheme.name;
    window.Stripe.setPublishableKey(window.stripePublishKey);
  }

  render() {
    return (
      <ThemeProvider theme={createTheme(UHTheme)}>
        <PmThemeProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <UhIntl>
              <>
                <NativeStatusBar />
                <Routes />
              </>
            </UhIntl>
          </LocalizationProvider>
        </PmThemeProvider>
      </ThemeProvider>
    );
  }
}

const container = document.getElementById('appContainer');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <AppRoot />
  </ErrorBoundary>
);

export default AppRoot;
