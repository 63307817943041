import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';

import SessionScheduling from 'containers/sessionScheduling/SessionScheduling.jsx';
import AccountProfiles from 'containers/accountProfiles/AccountProfiles.jsx';

import BrowseEventsBookDrawerActions from '../Actions';

const styles = {
  root: {
    height: '100%',
    overflow: 'hidden',
    padding: '30px 30px', // TODO - Should be deleted after fixing https://www.pivotaltracker.com/story/show/169818567
  },
  accountsRoot: {
    padding: '0 30px',
  },
};

function Content({ eventId = null, clientId = null, sessionIds = Set() }) {
  return clientId ? (
    <SessionScheduling
      clientId={clientId}
      eventId={eventId}
      selectedSessionIds={sessionIds}
      allowPast={false}
      onNext={BrowseEventsBookDrawerActions.sessionBooked}
      onBack={BrowseEventsBookDrawerActions.closeDrawer}
    />
  ) : (
    <div style={styles.accountsRoot}>
      <AccountProfiles
        eventId={eventId}
        scheduleSession={BrowseEventsBookDrawerActions.scheduleSession}
      />
    </div>
  );
}

Content.propTypes = {
  eventId: PropTypes.number,
  clientId: PropTypes.number,
  sessionIds: PropTypes.instanceOf(Set), // This should be a Set of strings
};

export default Content;
