import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Icon, Avatar } from '@upperhand/playmaker';

import uhTheme from '_uh_theme.jsx';
import User from 'event_mgmt/shared/records/User.jsx';
import Staff from 'shared/records/Staff.jsx';
import Client from 'shared/records/Client.jsx';

import WrapperPreview from 'components/ImageUpload/components/WrapperPreview.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

function AvatarPreview({
  alternativeImage = 'avatar',
  image = null,
  onPopupOpen = () => {},
  cropAspect,
  intl,
  uploadContainerHeight,
  uploadContainerWidth,
  user,
}) {
  if (!user || !image || image.isBlank()) {
    return (
      <WrapperPreview
        intl={intl}
        cropAspect={cropAspect}
        height={uploadContainerHeight}
        width={uploadContainerWidth}
        image={image}
      />
    );
  }
  const text = user.isBlank()
    ? t('.default_name', intl, __filenamespace)
    : user.name();

  return (
    <div className="avatar-preview">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="avatar-preview__popup-container" onClick={onPopupOpen}>
        <Avatar
          size="4x"
          color={uhTheme.palette.lightGrey}
          src={user.alternativeImage(alternativeImage)}
        >
          {text}
        </Avatar>
        <Button
          classes={{ root: 'avatar-preview__popup' }}
          size="1x"
          type="secondary"
          icon={<Icon name="camera" className="avatar-preview__icon" />}
          rounded
        />
      </div>
      <div className="avatar-preview__info">
        <div className="avatar-preview__name">{text}</div>
        {user.email && (
          <div className="avatar-preview__email">{user.email}</div>
        )}
      </div>
    </div>
  );
}

AvatarPreview.propTypes = {
  alternativeImage: PropTypes.string,
  user: PropTypes.oneOfType([
    PropTypes.instanceOf(User),
    PropTypes.instanceOf(Staff),
    PropTypes.instanceOf(Client),
  ]).isRequired,
  image: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onPopupOpen: PropTypes.func,
};

export default injectIntl(AvatarPreview);
