import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Grid, Spinner } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';

import RegistrationPackageCard from 'containers/creditListDrawer/components/RegistrationPackageCard.jsx';
import RegistrationPackageAdjustmentModal from 'containers/creditListDrawer/components/RegistrationPackageAdjustmentModal.jsx';
import EmptyState from 'containers/creditListDrawer/components/EmptyState.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';

import { CreditCountDataStore } from 'dataStores';
import CreditListDrawerStore from 'containers/creditListDrawer/Store';

function RegistrationsPackageTab({
  intl,
  clientId,
  creditListDrawerStore = {
    packagesLoading: true,
    packageAdjustment: Map(),
    isLoadingRegistrationPackages: false,
    isLoadingEvents: false,
  },
  creditCountDataStore = {},
}) {
  const {
    packagesLoading,
    packageAdjustment,
    isLoadingRegistrationPackages,
    isLoadingEvents,
  } = creditListDrawerStore;
  const { clientEvents } = creditCountDataStore;
  const isLoading = isLoadingRegistrationPackages || isLoadingEvents;
  const clientCreditCounts = clientEvents.get(clientId, Map());

  return (
    <>
      {isLoading && (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      )}
      {!isLoading && clientCreditCounts.isEmpty() && (
        <Grid container xs={12} alignItems="center" justify="center">
          <EmptyState intl={intl} />
        </Grid>
      )}
      {!isLoading && clientCreditCounts.size > 0 && (
        <>
          {clientCreditCounts.toArray().map(credit => (
            <RegistrationPackageCard
              key={credit.event_id}
              credit={credit}
              packagesLoading={packagesLoading}
              clientId={clientId}
            />
          ))}
        </>
      )}
      <RegistrationPackageAdjustmentModal
        clientId={clientId}
        packageAdjustment={packageAdjustment}
      />
    </>
  );
}

RegistrationsPackageTab.propTypes = {
  intl: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  creditListDrawerStore: PropTypes.object,
  creditCountDataStore: PropTypes.object,
};

export default compose(
  injectIntl,
  React.memo,
  altContainer({
    stores: {
      creditListDrawerStore: CreditListDrawerStore,
      creditCountDataStore: CreditCountDataStore,
    },
  })
)(RegistrationsPackageTab);
