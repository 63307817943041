import * as React from 'react';
import { Menu, MenuItem } from '@mui/material';

import { t } from 'shared/utils/LocaleUtils.js';
import BalanceDetailsDrawerActions from 'containers/reports/balanceDetailsDrawer/Actions';
import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions.js';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import PaymentPlanDetailsDrawerActions from '../Actions';

function DotsMenu({ intl, balance, showCancelAll, anchorEl = null }) {
  const isClient = currentUser().isClient();
  const handleMenuClose = () => {
    PaymentPlanDetailsDrawerActions.toggleDotsMenu();
  };

  const handlePayBalance = () => {
    BalanceDetailsDrawerActions.closeDrawer();
    PaymentPlanDetailsDrawerActions.closeDrawer();
    QuickpayModalActions.toggleQuickpayModal(balance, 'payment_plan');
    handleMenuClose();
  };

  const handleWaiveBalance = () => {
    BalanceDetailsDrawerActions.closeDrawer();
    PaymentPlanDetailsDrawerActions.closeDrawer();
    RefundModalActions.openModal(balance, 'PaymentPlan', 'waive_payment_plan');
    handleMenuClose();
  };

  const handleEditPayments = () => {
    PaymentPlanDetailsDrawerActions.toggleEditPayments();
    handleMenuClose();
  };

  const handleCancelAllPayments = () => {
    PaymentPlanDetailsDrawerActions.toggleCancelAllPayments();
    handleMenuClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {isClient ? (
        <MenuItem onClick={handlePayBalance}>
          {t('.pay_balance', intl, __filenamespace)}
        </MenuItem>
      ) : (
        <>
          <MenuItem onClick={handlePayBalance}>
            {t('.pay_balance', intl, __filenamespace)}
          </MenuItem>
          <MenuItem onClick={handleWaiveBalance}>
            {t('.waive_balance', intl, __filenamespace)}
          </MenuItem>
          <MenuItem onClick={handleEditPayments}>
            {t('.edit_payments', intl, __filenamespace)}
          </MenuItem>
          {showCancelAll && (
            <MenuItem onClick={handleCancelAllPayments}>
              {t('.cancel_all_payments', intl, __filenamespace)}
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
}

export default React.memo(DotsMenu);
