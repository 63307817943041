import * as React from 'react';
import SquareIcon from '@mui/icons-material/Square';
import RetailCategory from 'shared/records/RetailCategory.js';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

const styles = {
  root: {
    padding: 1,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -4,
  },
  spacer: {
    color: 'var(--color-primary)',
  },
  name: {
    marginLeft: 4,
    fontSize: 15,
  },
};

function RetailCategoryBar({
  retailCategory = new RetailCategory(),
  style = {},
  textStyle = {},
}) {
  return (
    <FlexBox style={merge(styles.root, style)}>
      <SquareIcon style={styles.spacer} />
      <div style={merge(styles.name, textStyle)}>{retailCategory.name}</div>
    </FlexBox>
  );
}

export default RetailCategoryBar;
