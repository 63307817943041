import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Drawer, Grid, Button } from '@upperhand/playmaker';

import Name from 'containers/clientProfile/components/Name.jsx';
import MembershipInfo from 'containers/clientProfile/components/MembershipInfo/MembershipInfo.jsx';
import MembershipEmbedInfo from 'containers/clientProfile/components/MembershipEmbed/MembershipEmbedInfo.jsx';
import ContactInfo from 'containers/clientProfile/components/ContactInfo.jsx';
import Notes from 'containers/clientProfile/components/Notes.jsx';
import ManagedProfiles from 'containers/clientProfile/components/ManagedProfiles.jsx';
import BalanceInfo from 'containers/clientProfile/components/BalanceInfo.jsx';
import CreditInfo from 'containers/clientProfile/components/CreditInfo.jsx';
import AccountCreditsInfo from 'containers/clientProfile/components/AccountCredits/AccountCreditsInfo.jsx';
import CashCreditsInfo from 'containers/clientProfile/components/CashCreditsInfo.jsx';

import SchedulingDrawer from 'shared/components/SchedulingDrawer.jsx';
import BalanceListDrawer from 'containers/reports/balanceListDrawer/BalanceListDrawer.jsx';
import OrderSummaryDrawer from 'containers/reports/orderSummaryDrawer/OrderSummaryDrawer.jsx';
import BalanceDetailsDrawer from 'containers/reports/balanceDetailsDrawer/BalanceDetailsDrawer.jsx';
import CreditListDrawer from 'containers/creditListDrawer/CreditListDrawer.jsx';
import PaymentPlanDetailsDrawer from 'containers/reports/paymentPlanDetailsDrawer/PaymentPlanDetailsDrawer.jsx';
import SessionSummaryDrawer from 'containers/events/admin/sessionSummaryDrawer/SessionSummaryDrawer.jsx';
import RefundModal from 'containers/reports/refundModal/RefundModal.jsx';
import CancellationDialog from 'memberships/components/_CancellationDialog.jsx';
import SuspensionDialog from 'memberships/components/_SuspensionDialog.jsx';
import ReactivationDialog from 'memberships/components/_ReactivationDialog.jsx';
import ClientTermDialog from 'customers/ClientTermDialog.jsx';
import QuickpayModal from 'quickpay/components/QuickpayModal.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils.js';
import { isDBatTheme } from 'shared/utils/ThemeUtils';
import altContainer from 'shared/hocs/altContainer.jsx';

import {
  ClientDataStore,
  MembershipSubscriptionDataStore,
  RegistrationDataStore,
} from 'dataStores';
import SessionSummaryDrawerStore from 'containers/events/admin/sessionSummaryDrawer/Store';

import ClientProfileActions from 'containers/clientProfile/Actions';
import ChargeDateChangeDialog from 'memberships/components/_ChargeDateChangeDialog.jsx';

import PaymentDetailsDrawer from 'containers/reports/paymentDetailsDrawer/PaymentDetailsDrawer.jsx';
import SchedulesDrawer from '../SchedulesDrawer.jsx';
import OrdersDrawer from '../OrdersDrawer.jsx';
import PaymentsDrawer from '../PaymentsDrawer.jsx';

import DrawerTitle from '../DrawerTitle.jsx';

import ClientProfileDrawerStore from './Store';
import ClientProfileDrawerActions from './Actions';
import ScheduleCancelRegistrationModal from '../../Schedule/CancelRegistrationModal.jsx';

import '../styles.scss';
import AdditionalEmails from '../../AdditionalEmails/_AdditionalEmails.jsx';

export function Content({
  intl,
  clientId,
  subscriptionId,
  managedProfileIds,
  outstandingBalance,
  totalCredits,
  totalAccountCredit,
  totalCashCredits,
  clientObj,
  useOutsideDrawer,
  membershipSubscriptions,
  currentEmailData,
  fieldErrors,
  additionalEmailsList,
  isModalOpen,
}) {
  const isStaff = currentUser().isStaff();
  const isClient = currentUser().isClient() || useOutsideDrawer;
  const isManagedBy = currentUser().managed_by_id;
  const isViewClientManagedBy = clientObj?.get('managed_by_id');
  const isDBatInstance = isDBatTheme() && currentUser().isClient();
  const showBalanceBlock =
    !isManagedBy && !isViewClientManagedBy && !isDBatInstance;
  const onClientSave = (client, attributes) =>
    ClientProfileDrawerActions.clientSaved({ client, attributes });
  const onImageSelect = (client, image) =>
    ClientProfileDrawerActions.imageSelected({ client, image });

  return (
    <Grid container spacing={0} className="client-drawer">
      <Grid item xs={12}>
        <Name
          isMobile
          clientId={clientId}
          intl={intl}
          subscriptionId={subscriptionId}
          onImageSelect={onImageSelect}
          onClientSave={onClientSave}
          onPrimaryAccountClick={data =>
            useOutsideDrawer
              ? ClientProfileActions.mounted(data)
              : ClientProfileDrawerActions.mounted(data)
          }
          membershipSubscriptions={membershipSubscriptions}
        />
      </Grid>
      <Grid item container spacing={2}>
        {showBalanceBlock && (
          <Grid item xs={6}>
            <BalanceInfo
              intl={intl}
              clientId={clientId}
              outstandingBalance={outstandingBalance}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <CreditInfo
            intl={intl}
            clientId={clientId}
            totalCredits={totalCredits}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AccountCreditsInfo
          clientId={clientId}
          creditsAmount={totalAccountCredit}
        />
      </Grid>
      <Grid item xs={12}>
        <CashCreditsInfo
          intl={intl}
          clientId={clientId}
          totalCredits={totalCashCredits}
          subscriptionId={subscriptionId}
        />
      </Grid>
      {subscriptionId && (
        <Grid item xs={12}>
          <MembershipInfo
            showTitle={false}
            clientId={clientId}
            subscriptionId={subscriptionId}
            intl={intl}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <MembershipEmbedInfo subscriptionId={subscriptionId} />
      </Grid>
      <Grid
        item
        container
        spacing={1}
        className="client-drawer__actions-container"
      >
        <Grid item xs={4}>
          <Button
            type="secondary"
            classes={{ root: 'action-btn' }}
            onClick={() =>
              ClientProfileDrawerActions.openScheduleDrawer({ clientId })
            }
            rounded
          >
            {t('.schedule', intl, __filenamespace)}
          </Button>
        </Grid>
        {!isManagedBy && (
          <>
            <Grid item xs={4}>
              <Button
                type="secondary"
                classes={{ root: 'action-btn' }}
                rounded
                onClick={() =>
                  ClientProfileDrawerActions.toggleOrdersDrawer({ clientId })
                }
              >
                {t('.orders', intl, __filenamespace)}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                type="secondary"
                classes={{ root: 'action-btn' }}
                rounded
                onClick={() => {
                  ClientProfileDrawerActions.togglePaymentsDrawer({
                    clientId,
                    userId: clientObj?.get('user_id'),
                    accessToVault: clientObj?.get('access_to_vault'),
                  });
                }}
              >
                {t('.payments', intl, __filenamespace)}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <ContactInfo
          intl={intl}
          clientId={clientId}
          onClientSave={onClientSave}
          onVerifyEmail={() =>
            ClientProfileActions.verifyEmail(clientObj.email)
          }
          onResetPassword={() =>
            ClientProfileActions.resetPassword(clientObj.email)
          }
        />
      </Grid>
      {isStaff && (
        <Grid item xs={12}>
          <Notes clientId={clientId} intl={intl} onClientSave={onClientSave} />
        </Grid>
      )}
      <Grid item xs={12}>
        <AdditionalEmails
          actions={isClient ? ClientProfileActions : ClientProfileDrawerActions}
          additionalEmailsList={additionalEmailsList}
          currentEmailData={currentEmailData}
          fieldErrors={fieldErrors}
          isModalOpen={isModalOpen}
          intl={intl}
        />
      </Grid>
      <Grid item xs={12}>
        <ManagedProfiles
          isMobile
          useOutsideDrawer={useOutsideDrawer}
          clientId={clientId}
          managedProfileIds={managedProfileIds}
          intl={intl}
        />
      </Grid>
    </Grid>
  );
}

export function AdditionalDrawers({
  defaultSelectedTab,
  selectedSessionId,
  selectedEventId,
  intl,
  registrationToRemove,
  eventForRemovingRegistration,
  waiveBalance,
  creditOperation,
  client,
  onNextSession,
  onPreviousSession,
}) {
  const isStaff = currentUser().isStaff();
  const isAcceptTerms = client?.get('has_accepted_terms', false);
  const isLoginEnabled = client?.get('login_enabled', false);
  const showTermsDialog = isStaff && !isAcceptTerms && isLoginEnabled;

  return (
    <>
      {showTermsDialog && (
        <ClientTermDialog client={client} clientId={client?.id} />
      )}
      <SuspensionDialog />
      <ChargeDateChangeDialog />
      <ReactivationDialog />
      <CancellationDialog />
      <BalanceListDrawer />
      <CreditListDrawer />
      <OrderSummaryDrawer />
      <BalanceDetailsDrawer withBackLink />
      <PaymentDetailsDrawer />
      <RefundModal />
      <PaymentPlanDetailsDrawer withBackLink />
      <SchedulingDrawer />
      <QuickpayModal
        onSuccess={ClientProfileDrawerActions.onPaymentSuccess.defer}
      />
      <ScheduleCancelRegistrationModal
        intl={intl}
        registration={registrationToRemove}
        event={eventForRemovingRegistration}
        waiveBalance={waiveBalance}
        creditOperation={creditOperation}
        onCancel={() =>
          ClientProfileDrawerActions.setRegistrationToRemove({
            registration: null,
            event: null,
          })
        }
        onCreditOperationChange={ClientProfileDrawerActions.setCreditOperation}
        onWaiveChange={ClientProfileDrawerActions.setWaive}
        onConfirm={ClientProfileDrawerActions.registrationCancel}
      />
      <SessionSummaryDrawer
        useSSD={false}
        defaultSelectedTab={defaultSelectedTab}
        isOpen={!!selectedSessionId}
        sessionId={selectedSessionId}
        eventId={selectedEventId}
        onNextSession={onNextSession}
        onPreviousSession={onPreviousSession}
        onClose={ClientProfileDrawerActions.closeSessionSummaryDrawer}
      />
      <SchedulesDrawer
        onSessionSummaryOpen={() => {
          ClientProfileDrawerActions.onSessionSummaryOpen({
            closeDrawers: false,
          });
          ClientProfileActions.onSessionSummaryOpen({});
        }}
      />
      <OrdersDrawer />
      <PaymentsDrawer />
    </>
  );
}

function ClientProfileDrawer({
  intl,
  onSessionSummaryOpen = () => null,
  withSingleSessionDrawer = true,
  clientProfileDrawerStore: {
    clientId,
    subscriptionId,
    managedProfileIds,
    outstandingBalance,
    totalCredits,
    totalAccountCredit,
    totalCashCredits,
    selectedSessionId,
    selectedEventId,
    registrationToRemove,
    eventForRemovingRegistration,
    waiveBalance,
    creditOperation,
    clientIdsHistory,
    isLoading,
    currentEmailData,
    fieldErrors,
    additionalEmailsList,
    isModalOpen,
    profileDrawerOpen,
    registrationIds,
    pastRegistrationIds,
    defaultSelectedTab,
  },
  clientDataStore: { clients },
  membershipSubscriptionDataStore: { membershipSubscriptions },
  sessionSummaryDrawerStore: { selectedTab },
  registrationDataStore: { registrations },
}) {
  const client = clients.get(clientId);

  const allRegistrationIds = registrationIds.concat(pastRegistrationIds);
  const ssdSessionIds = allRegistrationIds.map(
    id => registrations.get(id).session_id
  );

  const handleNextSession = () => {
    const sessionsArray = ssdSessionIds.toArray();
    const registrationsArray = allRegistrationIds.toArray();

    const index = sessionsArray.indexOf(selectedSessionId);
    const nextSessionId = sessionsArray[index + 1];

    if (nextSessionId)
      ClientProfileDrawerActions.openSessionSummaryDrawer({
        sessionId: nextSessionId,
        eventId: selectedEventId,
        defaultSelectedTab: selectedTab,
        registrationId: registrationsArray[index + 1],
      });
  };

  const handlePreviousSession = () => {
    const sessionsArray = ssdSessionIds.toArray();
    const registrationsArray = allRegistrationIds.toArray();

    const index = sessionsArray.indexOf(selectedSessionId);
    const previousSessionId = sessionsArray[index - 1];

    if (previousSessionId)
      ClientProfileDrawerActions.openSessionSummaryDrawer({
        sessionId: previousSessionId,
        eventId: selectedEventId,
        defaultSelectedTab: selectedTab,
        registrationId: registrationsArray[index + 1],
      });
  };

  return (
    <>
      <Drawer
        open={profileDrawerOpen}
        title={
          <DrawerTitle
            title={t('.title', intl, __filenamespace)}
            clientIdsHistory={clientIdsHistory}
          />
        }
        onClose={() => ClientProfileDrawerActions.closeDrawer()}
        content={
          <SpinWhileLoading isLoading={isLoading} absolute={false}>
            <Content
              clientId={clientId}
              clientObj={client}
              intl={intl}
              subscriptionId={subscriptionId}
              managedProfileIds={managedProfileIds}
              outstandingBalance={outstandingBalance}
              totalCredits={totalCredits}
              totalAccountCredit={totalAccountCredit}
              totalCashCredits={totalCashCredits}
              membershipSubscriptions={membershipSubscriptions}
              currentEmailData={currentEmailData}
              fieldErrors={fieldErrors}
              additionalEmailsList={additionalEmailsList}
              isModalOpen={isModalOpen}
            />
          </SpinWhileLoading>
        }
      />
      <AdditionalDrawers
        intl={intl}
        defaultSelectedTab={defaultSelectedTab || selectedTab}
        eventForRemovingRegistration={eventForRemovingRegistration}
        waiveBalance={waiveBalance}
        creditOperation={creditOperation}
        registrationToRemove={registrationToRemove}
        selectedEventId={selectedEventId}
        selectedSessionId={selectedSessionId}
        client={client}
        onSessionSummaryOpen={onSessionSummaryOpen}
        withSingleSessionDrawer={withSingleSessionDrawer}
        onNextSession={handleNextSession}
        onPreviousSession={handlePreviousSession}
      />
    </>
  );
}

ClientProfileDrawer.propTypes = {
  onSessionSummaryOpen: PropTypes.func,
  withSingleSessionDrawer: PropTypes.bool,
  clientProfileDrawerStore: PropTypes.object.isRequired,
  clientDataStore: PropTypes.object.isRequired,
  membershipSubscriptionDataStore: PropTypes.object.isRequired,
};

export default compose(
  React.memo,
  injectIntl,
  altContainer({
    stores: {
      clientProfileDrawerStore: ClientProfileDrawerStore,
      clientDataStore: ClientDataStore,
      membershipSubscriptionDataStore: MembershipSubscriptionDataStore,
      sessionSummaryDrawerStore: SessionSummaryDrawerStore,
      registrationDataStore: RegistrationDataStore,
    },
  })
)(ClientProfileDrawer);
