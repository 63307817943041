import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';
import ManagedClientStore from 'shared/stores/ManagedClientStore.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';

import AltContainer from 'alt-container';
import EditProfile from 'user_management/shared/components/_EditProfile.jsx';
import ManagedProfileEditor from 'user_management/shared/components/ManagedProfileEditor.jsx';
import { uhFlexbox } from 'shared/styles/uhStyles.jsx';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { REQUIRED_MANAGED_ACCOUNT_FIELDS } from 'containers/accounts/createUser/const.js';
import { FormModal } from '@upperhand/playmaker';

const handleCreateClick = (closeDialog, afterCreate) => {
  // TODO this needs a spinner somewhere, as this can take a while
  ManagedClientActions.validateUser({
    requiredFields: REQUIRED_MANAGED_ACCOUNT_FIELDS,
    isNewUser: false,
  });

  if (ManagedClientStore.getState().isValid) {
    ManagedClientActions.create({ afterCreate });
    closeDialog();
  }
};

function ClientEditor({ intl, managedClientStore }) {
  const { client, fieldErrors, isSaving } = managedClientStore;

  return (
    <EditProfile>
      <ManagedProfileEditor
        actionButtons={
          // Bit of a hack to make the styles look correct in the new modal.
          <FlexBoxCenter
            style={{
              alignSelf: 'center',
              marginTop: '1em',
              marginBottom: '1em',
            }}
          />
        }
        errors={fieldErrors}
        flat
        intl={intl}
        isSaving={isSaving}
        onChange={handleChange({ actions: ManagedClientActions })}
        userProfile={client}
      />
    </EditProfile>
  );
}

function ManageProfilesDialog({
  closeDialog = () => {},
  open = false,
  afterCreate = () => {},
  intl = {},
}) {
  return (
    <FormModal
      autoScrollBodyContent
      bodyStyle={{
        ...{ padding: '1em 2em 1em 2em', alignItems: 'center' },
        ...uhFlexbox.column,
      }}
      open={open}
      contentStyle={{ width: 350 }}
      title="Create Profile"
      actions={[
        {
          type: 'primary',
          label: 'Create',
          action: () => handleCreateClick(closeDialog, afterCreate),
        },
        {
          type: 'tertiary',
          label: 'Back',
          action: closeDialog,
        },
      ]}
    >
      <AltContainer stores={{ managedClientStore: ManagedClientStore }}>
        <ClientEditor
          intl={intl}
          closeDialog={closeDialog}
          afterCreate={afterCreate}
        />
      </AltContainer>
    </FormModal>
  );
}

ManageProfilesDialog.propTypes = {
  afterCreate: PropTypes.func,
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(ManageProfilesDialog);
