import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';

import { Grid, Spinner, Typography } from '@upperhand/playmaker';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const SALE_SOURCE_ONLINE = 'online';

function OrderDetails({
  client,
  isLoading,
  refundedAmount,
  waivedAmount,
  paidAmount,
  remainingAmount,
  intl,
  order = {},
  orderItem = {},
}) {
  const totalDiscount = order
    .get('order_items')
    .flatMap(oi => oi.applied_adjustments)
    .reduce(
      (sum, adj) => sum - (adj.get('amount') < 0 ? adj.get('amount') : 0),
      0
    );

  return (
    <>
      <Grid className="order-details__header" container spacing={1}>
        <Grid item xs={12}>
          <div className="order-details__title">
            <Typography align="center" variant="body1">
              {t('.order_number', intl, __filenamespace, {
                n: order.get('order_number'),
              })}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            <FormattedCurrency value={order.total || 0} fromCents />
          </Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <ClientDetails client={client} />
      )}
      <div className="order-details__card">
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Typography className="order-details__title" variant="subtitle2">
              {t('.order_details', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.date', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t('.order_date', intl, __filenamespace, {
                  date: orderItem.get('ordered_at')
                    ? moment(orderItem.get('ordered_at')).format('MMM DD, YYYY')
                    : '-',
                  time: orderItem.get('ordered_at')
                    ? moment(orderItem.get('ordered_at')).format('h:mm a')
                    : '-',
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.order_method', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t(`.${order.get('sale_source')}`, intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
          {order.get('sale_source') !== SALE_SOURCE_ONLINE &&
            order.get('checkout_by_name') && (
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.sold_by', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    {order.get('checkout_by_name')}
                  </Typography>
                </Grid>
              </Grid>
            )}
        </Grid>
      </div>
      {isLoading ? (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <>
          <div className="order-details__card order-details__with-bottom order-details__with-middle">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  className="order-details__title"
                  variant="subtitle2"
                >
                  {t('.total', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.subtotal', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    <FormattedCurrency value={order.subtotal || 0} fromCents />
                  </Typography>
                </Grid>
              </Grid>
              {totalDiscount > 0 && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.discounts', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={totalDiscount} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.taxes', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    <FormattedCurrency value={order.tax || 0} fromCents />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.fees', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    <FormattedCurrency value={order.fee || 0} fromCents />
                  </Typography>
                </Grid>
              </Grid>
              {Boolean(order.insurance_amount) && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.insurance_amount', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency
                        value={order.insurance_amount || 0}
                        fromCents
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="order-details__middle-block">
            <div className="order-details__middle-inner">
              <Grid container spacing={1}>
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.total', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={order.total || 0} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.paid', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={paidAmount} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
                {refundedAmount > 0 && (
                  <Grid container item xs={12} justify="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        {t('.refunds', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" align="right">
                        <FormattedCurrency value={refundedAmount} fromCents />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {waivedAmount > 0 && (
                  <Grid container item xs={12} justify="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        {t('.waives', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" align="right">
                        <FormattedCurrency value={waivedAmount} fromCents />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <div className="order-details__bottom-block ">
            <Grid container item xs={12} justify="space-between">
              <Grid item>
                <Typography variant="body1">
                  {t('.remaining', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="right">
                  <FormattedCurrency value={remainingAmount} fromCents />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}

OrderDetails.propTypes = {
  order: PropTypes.object,
  orderItem: PropTypes.object,
};

export default injectIntl(OrderDetails);
