import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@upperhand/playmaker';

import Header from './components/Header/Header.jsx';
import Table from './components/Table/Table.jsx';

import './styles.scss';

function ListComponent({
  isLoading = true,
  recordCard = () => {},
  emptyState = null,
  actions,
  header,
  ids,
  page,
  perPage,
  titleIds,
  totalCount,
  isFromMembersList,
}) {
  return (
    <div className="table">
      <Grid container spacing={2} alignContent="flex-start" justify="center">
        <Grid item xs={12}>
          <Header>{header}</Header>
        </Grid>
        <Grid item xs={12}>
          <Table
            actions={actions}
            isLoading={isLoading}
            ids={ids}
            recordCard={recordCard}
            page={page}
            perPage={perPage}
            titleIds={titleIds}
            emptyState={emptyState}
            totalCount={totalCount}
            isFromMembersList={isFromMembersList}
          />
        </Grid>
      </Grid>
    </div>
  );
}

ListComponent.propTypes = {
  actions: PropTypes.object.isRequired,
  ids: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  emptyState: PropTypes.node,
  recordCard: PropTypes.func,
  titleIds: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default ListComponent;
