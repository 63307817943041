import * as React from 'react';
import { Spinner } from '@upperhand/playmaker';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import EmptyIcon from 'shared/components/icons/empty_states/Events.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import {
  EventDataStore,
  RegistrationDataStore,
  SessionDataStore,
} from 'dataStores';

import ScheduleListItem from './ScheduleListItem.jsx';

function SchedulesList({
  isUpcoming,
  loading = false,
  isMobile = false,
  registrationDataStore = {},
  eventDataStore = {},
  sessionDataStore = {},
  onPageSelect = () => null,
  onSessionSummaryOpen = () => null,
  onRescheduleOpen = () => null,
  actions,
  intl,
  registrationIds,
  pagination,
}) {
  const { registrations } = registrationDataStore;
  const { events } = eventDataStore;
  const { sessions } = sessionDataStore;

  return (
    <>
      {loading && (
        <FlexBoxJustifyCenter>
          <Spinner />
        </FlexBoxJustifyCenter>
      )}
      {!loading && registrationIds.size === 0 && (
        <EmptyState
          image={<EmptyIcon />}
          headerText={t('.empty_sessions', intl, __filenamespace)}
        />
      )}
      {!loading && registrationIds.size > 0 && (
        <>
          {registrationIds.map(registration => (
            <ScheduleListItem
              isUpcoming={isUpcoming}
              actions={actions}
              isMobile={isMobile}
              key={registration}
              intl={intl}
              registrationId={registration}
              events={events}
              registrations={registrations}
              sessions={sessions}
              onSessionSummaryOpen={onSessionSummaryOpen}
              onRescheduleOpen={onRescheduleOpen}
            />
          ))}
          <Paginator
            className="schedule__pagination"
            currentPage={pagination.page}
            perPage={pagination.perPage}
            totalCount={pagination.totalCount}
            onPageSelect={onPageSelect}
            pageLimit={0}
            showInfo
          />
        </>
      )}
    </>
  );
}

SchedulesList.propTypes = {
  isUpcoming: PropTypes.bool,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
  registrationDataStore: PropTypes.object,
  eventDataStore: PropTypes.object,
  sessionDataStore: PropTypes.object,
  onPageSelect: PropTypes.func,
  onSessionSummaryOpen: PropTypes.func,
  onRescheduleOpen: PropTypes.func,
  registrationIds: PropTypes.instanceOf(OrderedSet).isRequired,
  pagination: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default altContainer({
  stores: {
    eventDataStore: EventDataStore,
    registrationDataStore: RegistrationDataStore,
    sessionDataStore: SessionDataStore,
  },
})(React.memo(SchedulesList));
