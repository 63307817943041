import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { TextField, Grid, Spinner, Typography } from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';

import StaffCard from 'containers/classes/classesList/components/Staff/StaffCard.jsx';
import EmptyState from 'containers/classes/classesList/components/Staff/EmptyState.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import ClassesListActions from 'containers/classes/classesList/Actions';
import ClassesListStore from 'containers/classes/classesList/Store';

function Staff({ classesListStore = {}, intl }) {
  React.useEffect(() => {
    ClassesListActions.listStaff.defer({ listAll: false });
  }, []);

  const { staffIds, loadingStaff, searchStaffText, pagination } =
    classesListStore;
  const staffPagination = pagination.get('staff');

  return (
    <Grid container spacing={1} className="class-staff">
      <Grid item xs={12}>
        <TextField
          rounded
          fullWidth
          value={searchStaffText}
          outline={false}
          placeholder="Search Staff"
          onChange={(_event, value) => ClassesListActions.searchStaff(value)}
        />
      </Grid>
      {loadingStaff && (
        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <Spinner type="indeterminate" />
          </div>
        </Grid>
      )}
      {staffIds.size > 0 && !loadingStaff && (
        <>
          <Grid item xs={12} className="class-staff__table-header">
            <div className="class-staff__table-header-content">
              <Grid container alignItems="center" xs={12} spacing={1}>
                <Grid container item alignItems="center" xs={12} md={4}>
                  <Typography variant="fieldLabel">
                    {t('.name', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="fieldLabel">
                    {t('.phone', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography variant="fieldLabel">
                    {t('.email', intl, __filenamespace)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {staffIds.map(id => (
            <Grid key={id} item xs={12}>
              <StaffCard staffId={id} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Paginator
              currentPage={staffPagination.page}
              perPage={staffPagination.perPage}
              totalCount={staffPagination.totalCount}
              onPageSelect={ClassesListActions.staffPageChange}
            />
          </Grid>
        </>
      )}
      {staffIds.size === 0 && !loadingStaff && (
        <Grid item xs={12}>
          <EmptyState />
        </Grid>
      )}
    </Grid>
  );
}

Staff.propTypes = {
  classesListStore: PropTypes.object,
};

export default compose(
  React.memo,
  injectIntl,
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
    },
  })
)(Staff);
