import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, Grid, Typography } from '@upperhand/playmaker';

import { messageId } from 'shared/utils/LocaleUtils.js';

function EmptyState({ isClassSelected = false, listUpcoming = true }) {
  return (
    <Card>
      <Grid container justify="center">
        <Grid item>
          <Typography>
            {isClassSelected ? (
              <FormattedMessage
                id={
                  listUpcoming
                    ? messageId('.no_upcoming_sessions', __filenamespace)
                    : messageId('.no_past_sessions', __filenamespace)
                }
              />
            ) : (
              <FormattedMessage
                id={messageId('.no_class_selected', __filenamespace)}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

EmptyState.propTypes = {
  isClassSelected: PropTypes.bool,
  listUpcoming: PropTypes.bool,
};

export default EmptyState;
