import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';

import Roster from 'containers/classes/classesList/components/Tabs/Roster.jsx';
import Schedule from 'containers/classes/classesList/components/Tabs/Schedule.jsx';
import Staff from 'containers/classes/classesList/components/Tabs/Staff.jsx';

import ClassesListActions from 'containers/classes/classesList/Actions';
import ClassesListStore, {
  CLASS_TABS,
} from 'containers/classes/classesList/Store';

import './styles.scss';

function ClassesTabs({ classesListStore = {} }) {
  const { selectedTab, selectedClass } = classesListStore;
  const tabItems = [
    { label: 'Roster', content: <Roster />, value: CLASS_TABS.ROSTER },
    {
      label: 'Schedule',
      content: <Schedule listUpcoming />,
      value: CLASS_TABS.SCHEDULE,
      disabled: !selectedClass,
    },
    {
      label: 'Completed',
      content: <Schedule listUpcoming={false} />,
      value: CLASS_TABS.COMPLETED,
      disabled: !selectedClass,
    },
    {
      label: 'Staff',
      content: <Staff />,
      value: CLASS_TABS.STAFF,
      disabled: !selectedClass,
    },
  ];

  const handleTabChange = (_, value) =>
    ClassesListActions.handleTabChange(value);

  return (
    <Tabs
      classes={{ root: 'tabs' }}
      variant="fullWidth"
      tabItems={tabItems}
      value={selectedTab}
      onChange={handleTabChange}
    />
  );
}

ClassesTabs.propTypes = {
  classesListStore: PropTypes.object,
};

export default compose(
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
    },
  })
)(ClassesTabs);
